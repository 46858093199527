//el-table过滤出最大长度的字符
export function stringlength(list, widthobj, weight = '', total = '') {
    for (const key in widthobj) {
        let textarr = [];
        list.forEach(item => {
            if (total != '' && total != undefined && total != null && total.bet_count > 0) {
                for (const key1 in total) {
                    if (key1 == key) {
                        textarr.push(total[key] + '');
                    } else {
                        textarr.push(item[key] + '');
                    }
                    widthobj[key].value = total[key1] + '';
                }
            } else {
                textarr.push(item[key] + '');
            }
        })
        let text = textarr.sort((a, b) => b.length - a.length)[0];
        // console.log(textarr);
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        context.font = `Verdana 12px`;
        let result = context.measureText(text);
        if (weight == '') {
            widthobj[key].minwidth = result.width > widthobj[key].label.length * 12 ? result.width : widthobj[key].label.length * 12;
        } else {
            if (widthobj.value != undefined && key != 'real_name') {
                widthobj[key].minwidth = widthobj[key].value.length * 9 < widthobj[key].label.length * 12 ? widthobj[key].label.length * 12 : widthobj[key].value.length * 9;
                // if (widthobj[key].minwidth) {

                // }
            } else {
                widthobj[key].minwidth = result.width > widthobj[key].label.length * 12 ? result.width * 1.1 : widthobj[key].label.length * 12;
            }
            if (key == 'real_name' && text != undefined) {
                widthobj[key].minwidth = text.length > 3 ? text.length * 8 : widthobj[key].label.length * 12;
            }
        }
        if (key == 'bet_count' && total.bet_count > 0) {
            widthobj[key].minwidth = widthobj[key].value.length * 9.5 < 40 ? 40 : widthobj[key].value.length * 9.5
        }
        widthobj[key].minwidth = widthobj[key].minwidth + 'px';
    }
    // console.log(widthobj.real_name);
    return widthobj;
}