<!--
 * @Author: your name
 * @Date: 2022-04-22 15:47:36
 * @LastEditTime: 2025-01-22 14:33:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\inner\views\peilvsheding.vue
-->
<template>
    <div class="peilvsheding">
        <div :class="parentMode != 'agency' ? 'panel' : ''">
            <div class="panel-title" v-if="parentMode != 'agency'">
                <h2>{{ dialog.visible }}</h2>
                <el-button v-if="dialog.visible == '球号设定' || dialog.visible == '特殊用户'" style="float: right" type="warning" size="small" @click="dialog.visible = '赔率设定'">返回</el-button>
            </div>
            <div v-else>
                <el-button v-if="dialog.visible == '球号设定'" style="float: right" type="warning" size="small" @click="dialog.visible = '赔率设定'">返回</el-button>
            </div>
            <div class="panel-body">
                <template v-if="dialog.visible == '赔率设定'">
                    <div class="game-select">
                        <div style="margin: 10px 0">
                            <div class="game-btn-group">
                                <div v-for="item in game_list" :key="item.id" :class="`game-btn ${active_gid == item.id ? 'theme-bg white-text' : ''}`" @click="change_game(item.id)">{{ item.gname }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-show="active_gid == 10">
                        <el-table :data="set_data" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                            <el-table-column prop="name" label="下注类型" align="center">
                                <template #default="scope">
                                    <span v-show="scope.row.name == '口口XX' || scope.row.name == '口X口X' || scope.row.name == '口XX口' || scope.row.name == 'X口口X' || scope.row.name == 'X口X口' || scope.row.name == 'XX口口'" style="color: red">二字定：</span>
                                    <span v-show="scope.row.name == '口口口X' || scope.row.name == '口口X口' || scope.row.name == '口X口口' || scope.row.name == 'X口口口'" style="color: blue">三字定：</span>
                                    <span style="margin-right: 6px">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="straightbet_low" label="单注最低" align="center" width="80">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.straightbet_low"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="straightbet_high" label="单注限额" align="center" width="100">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.straightbet_high"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="single_high" label="单期限额" align="center" width="100">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.single_high"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="high_rate" label="最高赔率" align="left">
                                <template #default="scope">
                                    <template v-if="scope.row.name.includes('字现')">
                                        <el-input style="width: 60px" v-model="scope.row.high_rate[0]" size="small" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-model="scope.row.high_rate[1]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-if="scope.row.name == '三字现' || scope.row.name == '四字现'" v-model="scope.row.high_rate[2]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-if="scope.row.name == '四字现'" v-model="scope.row.high_rate[3]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                    </template>
                                    <template v-else>
                                        <el-input size="small" v-model="scope.row.high_rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column prop="play_rate" label="开盘赔率" align="left">
                                <template #default="scope">
                                    <template v-if="scope.row.name.includes('现')">
                                        <el-input style="width: 60px" v-model="scope.row.play_rate[0]" size="small" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-model="scope.row.play_rate[1]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-if="scope.row.play_rate[2]" v-model="scope.row.play_rate[2]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                        <el-input style="width: 60px;margin-left: 5px;" v-if="scope.row.play_rate[3]" v-model="scope.row.play_rate[3]" size="small" :disabled="$store.state.userinfo.group_id > 2"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                    </template>
                                    <template v-else>
                                        <el-input v-model="scope.row.play_rate" size="small" :disabled="$store.state.userinfo.group_id > 2"></el-input>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column prop="water_break_A" label="开盘退水" align="left">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.water_break_A" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="sub">
                            <el-button size="small" type="info" @click="reset_pl5">重置</el-button>
                            <el-button size="small" type="primary" @click="sub_pl5">保存设置</el-button>
                        </div>
                    </div>
                    <div v-show="active_gid != 10">
                        <el-table :data="set_data" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                            <el-table-column prop="name" label="下注类型" align="center">
                                <template #default="scope">
                                    <span style="margin-right: 6px">{{ scope.row.name }}</span>
                                    <el-button v-if="
                                        scope.row.name === '1-5球' ||
                                        scope.row.name === '1-10球' ||
                                        scope.row.name === '特码' ||
                                        scope.row.name === '正码' ||
                                        scope.row.name === '特码B' ||
                                        scope.row.name === '正特' ||
                                        scope.row.name === '正特一' ||
                                        scope.row.name === '正特二' ||
                                        scope.row.name === '正特三' ||
                                        scope.row.name === '正特四' ||
                                        scope.row.name === '正特五' ||
                                        scope.row.name === '正特六' ||
                                        scope.row.name === '连码三中二中二' ||
                                        scope.row.name === '连码三全中' ||
                                        scope.row.name === '连码二全中' ||
                                        scope.row.name === '连码二中特中二' ||
                                        scope.row.name === '连码特串' ||
                                        scope.row.name === '四中一' ||
                                        scope.row.name === '五不中' ||
                                        scope.row.name === '六不中' ||
                                        scope.row.name === '七不中' ||
                                        scope.row.name === '八不中' ||
                                        scope.row.name === '九不中' ||
                                        scope.row.name === '十不中' ||
                                        scope.row.name === '十一不中' ||
                                        scope.row.name === '十二不中'
                                    " plain size="small" type="primary" @click="ball_set(scope.row)">球号设置</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="type" label="降赔类型" align="center" v-if="$store.state.userinfo.group_id == 2 || show_type">
                                <template #default="scope">
                                    <el-radio-group v-model="scope.row.type" @change="change_type($event, scope.row)">
                                        <el-radio label="1" size="small">单码</el-radio>
                                        <template v-if="
                                            scope.row.name.includes('连码') ||
                                            scope.row.name.includes('连肖') ||
                                            scope.row.name.includes('肖连') ||
                                            scope.row.name.includes('尾连') ||
                                            scope.row.name === '五不中' ||
                                            scope.row.name === '六不中' ||
                                            scope.row.name === '七不中' ||
                                            scope.row.name === '八不中' ||
                                            scope.row.name === '九不中' ||
                                            scope.row.name === '十不中' ||
                                            scope.row.name === '十一不中' ||
                                            scope.row.name === '十二不中' ||
                                            scope.row.name === '四中一'
                                        ">
                                            <el-radio label="2" size="small">单组</el-radio>
                                        </template>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column prop="high_rate" label="最高赔率" align="left">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.high_rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="play_rate" label="开盘赔率（A盘）" align="center">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.play_rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="b_rate" label="B盘赔率" align="center">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.b_rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="c_rate" label="C盘赔率" align="center">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.c_rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="lower" label="自动降赔率额度" align="center">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.lower" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="rate" label="每次降赔率" align="center">
                                <template #default="scope">
                                    <el-input size="small" v-model="scope.row.rate" :disabled="$store.state.userinfo.group_id > 2" :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                </template>
                            </el-table-column>
                            <template #append>
                                <div class="fast-set-row">
                                    <div class="fast-set-item">
                                        <el-button type="primary" plain size="small" class="fast-btn" @click="fast_set_col">快速设置</el-button>
                                    </div>
                                    <div class="fast-set-item" v-for="(item, key) in fast" :key="key">
                                        <el-input v-model="fast[key]" size="small" placeholder="快速设置" class="fast-input" :disabled="$store.state.userinfo.group_id != 1 && key !== 'lower' && key !== 'rate'"
                                            :formatter="value => $filters.inputForNumber(value, '大于0的数')"></el-input>
                                    </div>
                                </div>
                            </template>
                        </el-table>
                        <div class="sub">
                            <el-button size="small" type="info" @click="reset">重置</el-button>
                            <el-button size="small" type="primary" @click="sub_set">保存设置</el-button>
                        </div>
                    </div>
                </template>
                <!-- 特殊用户 -->
                <template v-if="dialog.visible == '特殊用户'">
                    <usersBatchSetLoss ref="usersBatchSetLoss" :game_list="game_list" />
                </template>
                <!-- 定位胆单独设置 -->
                <template v-if="dialog.visible == '球号设定'">
                    <ballLossSet ref="ballLossSet" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
import usersBatchSetLoss from "./usersBatchSetLoss.vue";
import ballLossSet from "./ballLossSet.vue";
export default {
    name: "peilvsheding",
    components: { usersBatchSetLoss, ballLossSet },
    data() {
        return {
            set_data: [],
            active_gid: "",
            game_list: [],
            dialog: {
                visible: "赔率设定",
            },
            game_group: [] /**彩种分组 */,
            play_param: "",
            type: "one", //当前模式，单个/分类
            active_pid: "", //当前激活的分类pid
            fast: {
                play_rate: "",
                high_rate: "",
                b_rate: "",
                c_rate: "",
                lower: "",
                rate: "",
            },
            parentMode: "",
            user_id: "",
            checked_name: "",
            show_type: false,
            db: "",
            user_group_id: "",
        };
    },
    beforeRouteEnter(to, from, next) {
        // console.log(to.query.user_id);
        let group = [];
        axios
            .post("play/bonus_set", {
                pid: to.query.gid,
                login_user: to.query.user_id,
            })
            .then((result) => {
                if (result.data.status == 200) {
                    next((vm) => {
                        vm.game_list = result.data.repsoneContent.game_list;
                        vm.active_gid = "";
                        vm.checked_name = vm.game_list[0].gname;
                        vm.checked_name = vm.game_list.find((item) => item.id == vm.active_gid).gname;
                        let data = result.data.repsoneContent.list;
                        vm.set_data = [];
                        for (let item in data) {
                            if (data[item].gid == 10 && data[item].name.includes("现") && data[item].high_rate.toString().includes("/")) {
                                data[item].play_rate = data[item].play_rate.split("/");
                                // data[item].high_rate = data[item].high_rate.split("/");
                            }
                            vm.set_data.push(data[item]);
                        }
                        //处理排列5
                        // if (vm.active_gid == 10) {
                        //     vm.make_pl5_pl_hs_list();
                        // }
                        let game_group = result.data.repsoneContent.play_arr;
                        for (const key in game_group) {
                            game_group[key].pid = key;
                            group.push(game_group[key]);
                        }
                        vm.game_group = group;
                        vm.type = "one";
                        // vm.active_pid = group[0].pid;
                        // vm.checked_name = vm.game_group.find((item) => item.pid == vm.active_pid).name;
                        if (vm.$store.state.userinfo.user_id != to.query.user_id) {
                            vm.user_id = to.query.user_id;
                        }
                    });
                }
            });
    },
    methods: {
        //切换降赔类型
        change_type(value, row) {
            // console.log(value, row);
            let list = [];
            if (row.name == "连肖二肖正肖" || row.name == "连肖二肖平��") {
                list.push("连肖二肖正肖");
                list.push("连肖二肖平肖");
            }
            if (row.name == "连肖三肖正肖" || row.name == "连肖三肖平肖") {
                list.push("连肖三肖正肖");
                list.push("连肖三肖平肖");
            }
            if (row.name == "连肖四肖正肖" || row.name == "连肖四肖平肖") {
                list.push("连肖四肖正肖");
                list.push("连肖四肖平肖");
            }
            if (row.name == "连肖五肖正肖" || row.name == "连肖五肖平肖") {
                list.push("连肖五肖正肖");
                list.push("连肖五肖平肖");
            }
            if (row.name == "二肖连不中正肖" || row.name == "二肖连不中平肖") {
                list.push("二肖连不中正肖");
                list.push("二肖连不中平肖");
            }
            if (row.name == "三肖连不中正肖" || row.name == "三肖连不中平肖") {
                list.push("三肖连不中正肖");
                list.push("三肖连不中平肖");
            }
            if (row.name == "四肖连不中正肖" || row.name == "四肖连不中平肖") {
                list.push("四肖连不中正肖");
                list.push("四肖连不中平肖");
            }
            if (row.name == "五肖连不中正肖" || row.name == "五肖连不中平肖") {
                list.push("五肖连不中正肖");
                list.push("五肖连不中平肖");
            }
            if (row.name == "二尾连0" || row.name == "二尾连1-9") {
                list.push("二尾连0");
                list.push("二尾连1-9");
            }
            if (row.name == "三尾连0" || row.name == "三尾连1-9") {
                list.push("三尾连0");
                list.push("三尾连1-9");
            }
            if (row.name == "四尾连0" || row.name == "四尾连1-9") {
                list.push("四尾连0");
                list.push("四尾连1-9");
            }
            if (row.name == "五尾连0" || row.name == "五尾连1-9") {
                list.push("五尾连0");
                list.push("五尾连1-9");
            }
            if (row.name == "二尾连0不中" || row.name == "二尾连1-9不中") {
                list.push("二尾连0不中");
                list.push("二尾连1-9不中");
            }
            if (row.name == "三尾连0不中" || row.name == "三尾连1-9不中") {
                list.push("三尾连0不中");
                list.push("三尾连1-9不中");
            }
            if (row.name == "四尾连0不中" || row.name == "四尾连1-9不中") {
                list.push("四尾连0不中");
                list.push("四尾连1-9不中");
            }
            if (row.name == "五尾连0不中" || row.name == "五尾连1-9不中") {
                list.push("五尾连0不中");
                list.push("五尾连1-9不中");
            }
            list.forEach((name) => {
                this.set_data.forEach((item) => {
                    if (name == item.name) {
                        item.type = value;
                    }
                });
            });
        },
        //定位胆设置
        ball_set(row) {
            let obj = {
                high_bonus: row.high_rate,
                play_str: row.play_str,
                db: this.db,
            };
            if (this.$store.state.userinfo.user_id != this.user_id) {
                obj.user_id = this.user_id;
            }
            if (this.active_gid) {
                obj.gid = this.active_gid;
            } else {
                obj.pid = this.active_pid;
            }
            this.axios.post("play/auto_ball_rate", obj).then((result) => {
                if (result.data.status == 200) {
                    this.dialog.visible = "球号设定";
                    this.$nextTick(() => {
                        this.$refs["ballLossSet"].get_ball_set(row, result.data.repsoneContent.list, obj, this.user_id, this.db);
                    });
                }
            });
        },
        //特殊用户设置
        get_users_set() {
            this.dialog.visible = "特殊用户";
            this.$nextTick(() => {
                this.$refs.usersBatchSetLoss.get_users();
            });
        },
        //提交赔率设置
        sub_set() {
            let post_data = "";
            let url = "";
            if (this.type === "one") {
                post_data = {
                    db: this.db,
                    gid: this.active_gid,
                    play_arr_str: [],
                    high_rate: [],
                    high_b_rate: [],
                    high_c_rate: [],
                    play_rate: [],
                    b_rate: [],
                    c_rate: [],
                    lower: [],
                    rate: [],
                    type: [],
                };
                url = "play/bonus_set_exe";
            } else {
                post_data = {
                    db: this.db,
                    pid: this.active_pid,
                    play_arr_str: [],
                    play_rate: [],
                    high_rate: [],
                    high_b_rate: [],
                    high_c_rate: [],
                    b_rate: [],
                    c_rate: [],
                    lower: [],
                    rate: [],
                    type: [],
                };
                url = "play/class_bonus_set";
            }
            for (let item of this.set_data) {
                if (item.play_rate * 1 > item.high_rate * 1) {
                    return this.$message.error(item.name + "赔率设定不能超过上限");
                }
                if (condition) {
                }
                if (item.b_rate * 1 > item.high_rate * 1) {
                    return this.$message.error(item.name + "赔率设定不能超过上限");
                }
                if (item.c_rate * 1 > item.high_rate * 1) {
                    return this.$message.error(item.name + "赔率设定不能超过上限");
                }
                post_data.play_arr_str.push(item.play_str);
                post_data.play_rate.push(item.name.includes("字现") ? item.play_rate.join("/") : item.play_rate);
                post_data.high_rate.push(item.name.includes("字现") ? item.high_rate.join("/") : item.high_rate);
                post_data.high_b_rate.push(item.name.includes("字现") ? item.high_rate.join("/") : item.high_rate);
                post_data.high_c_rate.push(item.name.includes("字现") ? item.high_rate.join("/") : item.high_rate);
                post_data.b_rate.push(item.name.includes("字现") ? item.play_rate.join("/") : item.b_rate);
                post_data.c_rate.push(item.name.includes("字现") ? item.play_rate.join("/") : item.c_rate);
                post_data.lower.push(item.lower);
                post_data.rate.push(item.rate);
                post_data.type.push(item.type);
            }
            if (this.user_id != "") {
                post_data.user_id = this.user_id;
            }
            this.axios.post(url, post_data).then((result) => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    this.$message.success(result.data.shortMessage);
                }
            });
        },
        //重置
        reset() {
            this.get_game_set_data(this.type, this.active_gid, this.user_id);
            for (const key in this.fast) {
                this.fast[key] = "";
            }
        },
        //快速设置某一列
        fast_set_col() {
            for (const key in this.fast) {
                if (this.fast[key]) {
                    for (let i = 0; i < this.set_data.length; i++) {
                        this.set_data[i][key] = this.fast[key];
                    }
                }
            }
        },
        //切换分类
        change_group(pid) {
            this.type = "list";
            this.get_game_set_data(this.type, pid, this.user_id);
        },
        //切换彩种
        change_game(gid) {
            this.type = "one";
            if (gid == 10) {
                this.get_pl5_data(gid);
            } else {
                this.get_game_set_data(this.type, gid, this.user_id);
            }
        },
        get_data(d = "", user_id = "", show = false, db = "", user_group_id = "") {
            if (user_group_id != '') {
                this.user_group_id = user_group_id;
            }
            this.db = db;
            this.game_list = d.game_list;
            let data = d.list;
            this.set_data = [];
            for (let item in data) {
                if (data[item].gid == 10 && data[item].name.includes("现") && data[item].high_rate.toString().includes("/")) {
                    data[item].play_rate = data[item].play_rate.split("/");
                    data[item].high_rate = data[item].high_rate.split("/");
                }
                this.set_data.push(data[item]);
            }
            let group = [];
            let game_group = d.play_arr;
            for (const key in game_group) {
                game_group[key].pid = key;
                group.push(game_group[key]);
            }
            this.game_group = group;
            this.type = "one";
            this.parentMode = "agency";
            this.active_gid = this.game_list[0].id;
            this.user_id = user_id;
            this.checked_name = this.game_list.find((item) => item.id == this.active_gid).gname;
            this.show_type = this.active_gid == 10 ? false : show;
        },

        get_game_set_data(type, id, user_id = "") {
            let group = [];
            let urlstr = {};
            if (this.$store.state.userinfo.group_id > 1) {
                if (type === "one") {
                    urlstr = {
                        gid: id,
                    };
                } else {
                    urlstr = {
                        pid: id,
                    };
                }
            } else {
                if (type === "one") {
                    urlstr = {
                        gid: id,
                    };
                } else {
                    urlstr = {
                        pid: id,
                    };
                }
            }
            if (this.user_id != this.$store.state.userinfo.user_id) {
                urlstr.user_id = this.user_id;
            }
            urlstr.login_user = this.$store.state.userinfo.user_id;
            this.axios.post("play/bonus_set", { db: this.db, ...urlstr }).then((result) => {
                // console.log(result.data.repsoneContent)
                if (result.data.status == 200) {
                    let data = result.data.repsoneContent.list;
                    this.set_data = [];
                    for (let item in data) {
                        this.set_data.push(data[item]);
                    }
                    let game_group = result.data.repsoneContent.play_arr;
                    for (const key in game_group) {
                        game_group[key].pid = key;
                        group.push(game_group[key]);
                    }
                    this.game_group = group;
                    if (type === "one") {
                        this.checked_name = this.game_list.find((item) => item.id == this.active_gid).gname;
                    } else {
                        this.checked_name = this.game_group.find((item) => item.pid == this.active_pid).name;
                    }
                    this.type = type;
                    if (type === "one") {
                        this.active_pid = "";
                        this.active_gid = id;
                    } else {
                        this.active_gid = "";
                        this.active_pid = id;
                    }
                    if (user_id != "") {
                        this.user_id = user_id;
                        this.parentMode = "agency";
                    } else {
                        this.user_id = "";
                        this.parentMode = "";
                    }
                }
            });
        },
        reset_pl5() {
            this.get_pl5_data(10);
        },
        get_pl5_data(gid) {
            this.axios
                .post(`user_version2/tuishui?uid=${this.user_id}`, {
                    uid: this.user_id,
                    gids: [gid],
                    play_ids: "",
                    times: 2,
                    is_single: 1,
                    db: this.db,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        let list = result.data.repsoneContent.list[0]?.list;
                        list.forEach((row) => {
                            // row.earn_water_top = row.earn_water_top * 1;
                            row.old_straightbet_low = row.straightbet_low;
                            row.old_straightbet_high = row.straightbet_high;
                            row.old_single_high = row.single_high;
                            row.old_water_break_A = row.water_break_A;
                            row.old_high_rate = row.high_rate;
                            row.old_play_rate = row.play_rate;
                            if (row.name.includes("现")) {
                                row.high_rate = row.high_rate.split("/");
                                row.play_rate = row.play_rate.split("/");
                            };
                            // else {
                            //     row.hs_list = [];
                            //     row.pl_hs_list = [];
                            //     // 遍历从0到earn_water_top，步长0.01
                            //     for (let i = 0; i < row.earn_water_top + 0.001; i += 0.001) {
                            //         let hs = parseFloat(i.toFixed(3) * 1);
                            //         // 根据不同玩法计算赔率
                            //         if (["二字现", "三字现", "四字现"].includes(row.name)) {
                            //             let arr_play_rate = row.high_rate.split("/");
                            //             let arr_cur_rate = row.play_rate.split("/");
                            //             let num = row.name == "二字现" ? 2 : 1;
                            //             // 计算每个位置的赔率
                            //             let rates = arr_play_rate.map((rate, index) => ((rate - hs * (row.name === "二字现" ? arr_play_rate : arr_cur_rate)[index]) * 1).toFixed(num) * 1);
                            //             // 四字现需要重复第一个赔率
                            //             if (row.name === "四字现") {
                            //                 rates.push(rates[0]);
                            //             }
                            //             row.pl_hs_list.push({ hs, pl: rates.join("/") });
                            //         } else {
                            //             row.pl_hs_list.push({ hs, pl: ((row.rate - hs) * row.high_rate).toFixed(2) * 1 });
                            //         }
                            //     }
                            // }
                        });
                        this.show_type = false;
                        this.active_gid = gid;
                        this.set_data = list;
                    }
                });
        },
        sub_pl5() {
            const positions = { '二字现': 2, '三字现': 3, '四字现': 4, };
            let list = JSON.parse(JSON.stringify(this.set_data));
            let data_list = [];
            list.forEach(item => {
                const posCount = positions[item.name];
                for (let i = 0; i < posCount; i++) {
                    if (item.play_rate[i] * 1 > item.high_rate[i] * 1) {
                        return this.$message.error(`${item.name}第${i + 1}位赔率设定不能超过上限`);
                    }
                }
                item.play_rate = item.name.includes("现") ? item.play_rate.join("/") : item.play_rate;
                item.high_rate = item.name.includes("现") ? item.high_rate.join("/") : item.high_rate;
                if (
                    item.old_straightbet_low != item.straightbet_low ||
                    item.old_straightbet_high != item.straightbet_high ||
                    item.old_single_high != item.single_high ||
                    item.old_water_break_A != item.water_break_A ||
                    item.old_high_rate != item.high_rate ||
                    item.old_play_rate != item.play_rate
                ) {
                    data_list.push(item);
                }
            });
            if (data_list.length == 0) {
                return this.$message("数据未改动，已保持以往设置");
            }
            console.log(data_list);

            let obj = {
                user_id: this.user_id,
                group_id: this.user_group_id,
                update_tuishui: data_list,
                db: this.db,
            };
            // return console.log(obj);
            this.axios.post("user_version2/update_tuishui", obj).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                }
            });
        },
    },
};
</script>

<style scoped>
.fast-input {
    margin: 0 6px;
}

.fast-set-item {
    display: inline-block;
    width: 11.11%;
    padding: 0 15px 0 5px;
    box-sizing: border-box;
}

.fast-set-item:first-child {
    width: 33.33%;
}

.fast-set-row {
    padding: 6px 0;
    background-color: #e4e7ed;
}

.game-select {
    text-align: left;
    margin-bottom: 10px;
}

.sub {
    margin-top: 10px;
}

.userset-title {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #409eff;
}

.game-btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.game-btn {
    padding: 3px 0;
    width: 100px;
    text-align: center;
    border: 1px solid #999;
    cursor: pointer;
}
</style>
