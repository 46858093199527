<!--
 * @Author: your name
 * @Date: 2022-05-02 12:02:13
 * @LastEditTime: 2022-11-20 19:08:01
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\self\views\zidongbuhuobiangengjilu.vue
-->
<template>
    <div class="zidongbuhuobiangengjilu">
        <div class="panel">
            <div class="panel-title">
                <h2>自动补货变更记录</h2>
            </div>
            <div class="panel-body">
                <el-table :data="list" border small highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                    <el-table-column property="gname" label="彩种类型" align="center" width="100"></el-table-column>
                    <el-table-column property="write_time" label="变更时间" align="center" width="180">
                        <template #default="scope">
                            <span>{{ $filters.time(scope.row.write_time) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="remark" label="变更说明" align="center"></el-table-column>
                    <el-table-column property="old_value" label="原值" align="center" width="80"></el-table-column>
                    <el-table-column property="new_value" label="新值" align="center" width="80"></el-table-column>
                    <el-table-column property="operator" label="变更人" align="center" width="120"></el-table-column>
                    <el-table-column property="ip" label="IP" align="center" width="150"></el-table-column>
                </el-table>
                <el-pagination
                    small
                    class="paging"
                    @current-change="get_data"
                    v-model="page"
                    :page-size="20"
                    :pager-count="5"
                    :total="pagination.total_records * 1"
                    layout="total,prev,pager,next,jumper"
                    prev-text="上一页"
                    next-text="下一页"
                    hide-on-single-page
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
export default {
    components: {},
    data() {
        return {
            list: [],
            page: 1,
            pagination: {
                page_size: 0,
                total_pages: 0,
                total_records: 0,
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        axios.post("user_version2/viewAutoSaleLog", { page: 1, limit: 20, db: this.$route.query.db ? this.$route.query.db : "" }).then((result) => {
            if (result.data.status == 200) {
                next((vm) => {
                    vm.list = result.data.repsoneContent.list;
                    vm.pagination = result.data.repsoneContent.pagination;
                    // 通过 `vm` 访问组件实例
                });
            }
        });
    },
    methods: {
        get_data(page = 1) {
            this.axios.post("user_version2/viewAutoSaleLog", { page, limit: 20, db: this.$route.query.db ? this.$route.query.db : "" }).then((result) => {
                if (result.data.status == 200) {
                    this.list = result.data.repsoneContent.list;
                    this.pagination = result.data.repsoneContent.pagination;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>