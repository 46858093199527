import axios from '../../../axios/axios';
export default {
  components: {},
  data: function data() {
    return {
      data: ''
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("play/rules").then(function (result) {
      if (result.data.status == 1) {
        next(function (vm) {
          vm.data = result.data.res;
        });
      }
    });
  },
  created: function created() {},
  methods: {}
};