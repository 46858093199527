<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-09 18:34:28
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-13 16:45:31
 * @FilePath: \pc_admin\src\components\self\views\erciyanzheng.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="erciyanzheng">
        <div class="panel" :style="{ width: platform == 'pc' ? '800px' : '80%' }">
            <div class="panel-title" v-if="platform == 'pc'">
                <h2>二次验证</h2>
            </div>
            <div class="panel-body">
                <div class="myform">
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">用途</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <div>通过二次验证(也称为双重身份验证)，您可以为自己的帐号添加一道额外的安全保障。在完成设置后，您将使用以下对象通过两个步骤登录帐号:</div>
                            <div>1. 您的密码</div>
                            <div>2. 您设备上安装的Google认证器为您生成的二次验证码</div>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">步骤</el-col>
                        <el-col class="myform-item-value redcolor" :span="20">
                            <div>1. 下载安装Google认证器 ( Google Authenticator ) 客户端。目前客户端有: <span style="color: blue">android版|iOS版</span></div>
                            <div>2. 然后选择”扫描二维码"。</div>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">二维码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <div class="redcolor">3.扫描以下二维码，再输入下方二次验证码完成认证。</div>
                            <!-- <div style="margin-left: 20px">
                                <img :src="qrcode" alt="二维码" style="width: 200px; height: 200px" />
                            </div> -->
                            <div ref="qrcode"></div>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">二次验证码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <template v-if="is_bind == 0">
                                <el-input ref="top_up_value" v-model="auth" size="small" clearable placeholder="未绑定" style="width: 200px"></el-input>
                            </template>
                            <template v-if="is_bind == 1">
                                <el-button type="info" size="small" disabled>已绑定</el-button>
                                <el-button type="primary" size="small" @click="jiebang">解 绑</el-button>
                            </template>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4"></el-col>
                        <el-col class="myform-item-value redcolor" :span="20">
                            4. 二次验证启用后，这时您也可以看到刚添加的二次验证已经出现在Google认证器里了 之后登录需要输入二次验证码的时候，打开Google认证器，把对应的验证码添到登录页面进行验证就可以了。
                        </el-col>
                    </el-row>
                </div>
                <el-button style="margin-top: 10px" type="warning" @click="sub">确 认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    props: ["platform"],
    data() {
        return {
            auth: "",
            is_bind: 0,
            qrcode: "",
        };
    },
    created() {
        this.get_info();
        // console.log(this.$route);
    },
    methods: {
        get_info(qrdata = "") {
            if (qrdata != undefined && qrdata != "") {
                if (qrdata.is_bind != undefined && qrdata.is_bind != "" && qrdata.is_bind != null) {
                    this.is_bind = qrdata.is_bind;
                }
                if (qrdata.qrcode != undefined && qrdata.qrcode != "" && qrdata.qrcode != null) {
                    // this.qrcode = qrdata.qrcode;
                    this.qrcode = new QRCode(this.$refs.qrcode, {
                        width: 200,
                        height: 200,
                        text: qrdata.qrcode,
                        correctLevel: QRCode.CorrectLevel.H,
                    });
                }
            } else {
                this.axios.post("user_version2/setup_second_auth").then((result) => {
                    if (result.data.status == 200) {
                        // console.log(result.data.repsoneContent);
                        let data = result.data.repsoneContent;
                        if (data.is_bind != undefined && data.is_bind != "" && data.is_bind != null) {
                            this.is_bind = data.is_bind;
                        }
                        if (data.qrcode != undefined && data.qrcode != "" && data.qrcode != null) {
                            // this.qrcode = data.qrcode;
                            this.qrcode = new QRCode(this.$refs.qrcode, {
                                width: 200,
                                height: 200,
                                text: data.qrcode,
                                correctLevel: QRCode.CorrectLevel.H,
                            });
                        }
                    }
                });
            }
        },
        sub() {
            if (this.is_bind == 1) {
                this.$message("二次验证码已经绑定！");
            }
            if (this.is_bind == 0) {
                this.axios.post("user_version2/setup_second_auth", { type: "add", auth: this.auth }).then((result) => {
                    if (result.data.status == 200) {
                        // this.get_info();
                        if (this.$route.path == "/") {
                            this.$alert(result.data.shortMessage, "提示", {
                                confirmButtonText: "确定",
                                type: "success",
                            });
                            this.$emit("done_google");
                        } else {
                            this.$message.success(result.data.shortMessage);
                            this.$refs.qrcode.innerHTML = "";
                            // this.get_info();
                        }
                    }
                });
            }
        },
        jiebang() {
            this.axios.post("user_version2/setup_second_auth", { type: "del" }).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                    // this.get_info();
                }
            });
        },
    },
};
</script>

<style scoped>
</style>