import "core-js/modules/es.array.splice.js";
import usersBatchSetLossGames from "./usersBatchSetLossGames";
export default {
  components: {
    usersBatchSetLossGames: usersBatchSetLossGames
  },
  props: ["game_list"],
  data: function data() {
    return {
      modified_user_list: [],
      //已修改过的用户
      inputVisible: false,
      inputValue: "",
      //当前输入的用户
      // checkAll: false,
      // checkedUsers: [],
      // isIndeterminate: false,
      newUsers: [],
      dialog: {
        visible: false
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    close: function close() {
      this.dialog.visible = false;
      this.get_users();
    },
    cancel: function cancel() {
      this.dialog.visible = false;
    },
    refresh: function refresh() {
      this.get_users();
      this.newUsers = [];
      this.inputValue = "";
    },
    //提交批量设置
    sub_users: function sub_users() {
      var _this = this;

      if (!this.newUsers.length) {
        this.$message({
          message: '请先添加用户再进行批量设置',
          type: 'error',
          showClose: true,
          offset: 300
        });
        return;
      }

      this.dialog.visible = true;
      this.$nextTick(function () {
        _this.$refs.usersBatchSetLossGames.get_data_list("many", _this.newUsers, '');
      });
    },
    // 已设置过用户单个修改
    sub_user: function sub_user(user) {
      var _this2 = this;

      this.dialog.visible = true;
      this.$nextTick(function () {
        _this2.$refs.usersBatchSetLossGames.get_data_list("single", user, '');
      });
    },
    //获取当前设置用户列表
    get_users: function get_users() {
      var _this3 = this;

      this.axios.post("play/bonus_member", {
        act: "list"
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this3.modified_user_list = [];
          var list = result.data.repsoneContent;

          for (var i = 0; i < list.length; i++) {
            _this3.modified_user_list.push(list[i]);
          }
        }
      });
    },
    //删除已添加用户
    handleClose: function handleClose(type, tag) {
      var _this4 = this;

      if (type === 'old') {
        // this.modified_user_list.splice(this.modified_user_list.indexOf(tag), 1);
        this.axios.post("play/bonus_member", {
          act: "delete_user",
          uid: tag
        }).then(function (result) {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            _this4.$message({
              message: result.data.shortMessage,
              type: 'success',
              showClose: true,
              offset: 300
            });

            _this4.get_users();
          }
        });
      } else {
        this.newUsers.splice(this.newUsers.indexOf(tag), 1);
      }
    },
    //添加用户时切换成input
    showInput: function showInput() {
      var _this5 = this;

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this5.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //添加用户
    handleInputConfirm: function handleInputConfirm() {
      var _this6 = this;

      var inputValue = this.inputValue;

      if (inputValue) {
        // console.log(this.modified_user_list.length)
        if (this.modified_user_list.length + this.newUsers.length > 14) {
          this.$message({
            message: '最多添加15个用户',
            type: 'error',
            showClose: true,
            offset: 300
          });
          return;
        }

        for (var i = 0; i < this.modified_user_list.length; i++) {
          if (inputValue === this.modified_user_list[i].username) {
            this.$message({
              message: '您已经添加了此用户，不能重复添加',
              type: 'error',
              showClose: true,
              offset: 300
            });
            return;
          }
        }

        for (var _i = 0; _i < this.newUsers.length; _i++) {
          if (inputValue === this.newUsers[_i]) {
            this.$message({
              message: '您已经添加了此用户，不能重复添加',
              type: 'error',
              showClose: true,
              offset: 300
            });
            return;
          }
        }

        this.axios.post("play/bonus_set_member_exe4", {
          type: 1,
          user_name: inputValue
        }).then(function (result) {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent.list)
            _this6.newUsers.push(inputValue);
          }
        });
      }

      this.inputVisible = false;
      this.inputValue = '';
    }
  }
};