export default {
  props: ["userinfo"],
  data: function data() {
    return {
      log_data: [],
      page: 1,
      //请求页码
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      }
    };
  },
  // created() {
  //     this.get_log_data()
  // },
  methods: {
    get_log_data: function get_log_data() {
      var _this = this;

      // console.log(this.uid)
      this.axios.post("log/admin_login_log", {
        uid: this.userinfo.id,
        page: this.page,
        limit: 20,
        db: this.userinfo.db
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.log_data = result.data.repsoneContent.list;
          _this.pagination = result.data.repsoneContent.pagination; // console.log(result.data.repsoneContent)
        }
      });
    },
    change_page: function change_page(p) {
      this.page = p;
      this.get_log_data();
    },
    get_data: function get_data(data) {
      this.log_data = data.list;
      this.pagination = data.pagination;
    }
  }
};