import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.concat.js";
export default {
  props: ["game_info_data", "chang_long", "sum", "pl_value", "show_change_pl_btn", "is_manlet"],
  data: function data() {
    return {
      data_list: [{
        name: "口口XX",
        list: []
      }, {
        name: "口X口X",
        list: []
      }, {
        name: "口XX口",
        list: []
      }, {
        name: "X口口X",
        list: []
      }, {
        name: "X口X口",
        list: []
      }, {
        name: "XX口口",
        list: []
      }],
      title: "口口XX",
      data: [],
      pl_popver: {
        show: false,
        row: {},
        pl_value: "",
        pl_change_value: "",
        top: "",
        left: ""
      },
      bh: {
        show: false,
        row: {},
        pl_value: "",
        bh_value: "",
        rate: "",
        top: "",
        left: ""
      },
      ballPopover: {
        show: false,
        row: {},
        top: "",
        left: ""
      }
    };
  },
  created: function created() {
    var _this = this;

    this.data_list.forEach(function (menu) {
      menu.list = _this.int_balls(menu.name);
    });
  },
  deactivated: function deactivated() {
    this.bh.show = false;
    this.pl_popver.show = false;
    this.ballPopover.show = false;
  },
  methods: {
    get_bhmx_data: function get_bhmx_data(row) {
      this.$emit("get_bhmx_data", row);
      this.ballPopover.show = false;
    },
    showBallPopover: function showBallPopover(row, event) {
      this.pl_popver.show = false;
      this.bh.show = false;
      this.ballPopover.show = false;
      this.ballPopover.row = row;
      this.$refs["ball_popover"].style.left = event.pageX + "px";
      this.$refs["ball_popover"].style.top = event.pageY + "px";
      this.ballPopover.show = true;
    },
    set_ball_ty: function set_ball_ty(ball) {
      var ballData = {
        ball: ball.ball,
        play_id: 10,
        level_two: ball.level_two,
        level_three: ball.level_three,
        level_four: ball.level_four,
        place: ball.place,
        is_lock: ball.is_lock == 1 ? 0 : 1
      };
      this.$emit("set_ball_ty", [ballData]);
    },
    sub_bh: function sub_bh() {
      this.$emit("sub_bh", this.bh);
    },
    show_bh: function show_bh(row, event) {
      this.pl_popver.show = false;
      this.bh.show = false;
      this.ballPopover.show = false;

      if (row.profit_bill < 0) {
        if (this.is_manlet == "1" || this.is_manlet == "0") {
          this.bh.row = row;
          this.bh.pl_value = row.pl;
          var width = document.body.clientWidth - event.clientX;

          if (width < 140) {
            this.$refs["bh_popver"].style.left = event.pageX - 140 + "px";
          } else {
            this.$refs["bh_popver"].style.left = event.pageX + "px";
          }

          this.$refs["bh_popver"].style.top = event.pageY + "px";
          this.bh.show = true;
          this.$refs["bhje_input"].focus();
        }
      }
    },
    sub_change_pl: function sub_change_pl() {
      this.$emit("change_pl", this.pl_popver);
    },
    show_pl_popver: function show_pl_popver(row, event) {
      if (this.$store.state.userinfo.group_id >= 5 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      }

      this.pl_popver.show = false;
      this.bh.show = false;
      this.ballPopover.show = false;

      if (event == "plus" || event == "minus") {
        this.pl_popver.row = row;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.pl = row.pl;
        this.pl_popver.pl_change_value = "";
        this.pl_popver.plusminus = event;
        this.$emit("change_pl", this.pl_popver);
      } else {
        this.pl_popver.row = row;
        this.pl_popver.value = row.pl;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.plusminus = "";
        this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
        var width = document.body.clientWidth - event.clientX;

        if (width < 150) {
          this.$refs["pl_popver"].style.left = event.pageX - 140 + "px";
        } else {
          this.$refs["pl_popver"].style.left = event.pageX + "px";
        }

        this.$refs["pl_popver"].style.top = event.pageY + "px";
        this.pl_popver.show = true;
      }
    },
    get_zdmx_data: function get_zdmx_data(row) {
      if (row.oc_bill != "-") {
        this.$emit("get_zdmx_data", row);
      }

      this.ballPopover.show = false;
    },
    proc: function proc(data) {
      var list = data.play_odds || [];
      this.data_list.forEach(function (menu, index) {
        if (list[index]) {
          list[index].forEach(function (item) {
            item.pl = item.pl * 1;
            item.oc_bill = item.oc_bill == 0 ? "-" : item.oc_bill * 1;
            item.profit_bill = item.profit_bill == 0 ? "-" : item.profit_bill * 1;
          });
          menu.list = list[index];
          menu.list.sort(function (a, b) {
            var profit_a = a.profit_bill === "-" ? 0 : Number(a.profit_bill);
            var profit_b = b.profit_bill === "-" ? 0 : Number(b.profit_bill);
            return profit_a - profit_b;
          });
        }
      });
    },
    int_balls: function int_balls() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var list = [];

      for (var index = 0; index < 100; index++) {
        var numStr = index < 10 ? "0".concat(index) : "".concat(index);
        var name = "";

        if (type === "口口XX") {
          name = "".concat(numStr, "XX");
        } else if (type === "口X口X") {
          name = "".concat(numStr[0], "X").concat(numStr[1], "X");
        } else if (type === "口XX口") {
          name = "".concat(numStr[0], "XX").concat(numStr[1]);
        } else if (type === "X口口X") {
          name = "X".concat(numStr[0]).concat(numStr[1], "X");
        } else if (type === "X口X口") {
          name = "X".concat(numStr[0], "X").concat(numStr[1]);
        } else if (type === "XX口口") {
          name = "XX".concat(numStr);
        }

        list.push({
          ball: name,
          game_type: type,
          id: 0,
          is_open: 1,
          level_four: "0",
          level_key: "17_0_0",
          level_three: "0",
          level_two: "17",
          maxpl: "",
          minpl: "",
          name: name,
          num: 1,
          oc_bill: "-",
          pl: "100",
          place: "6",
          profit_bill: "-",
          sum_occupy_bill: 0,
          type_status: 0
        });
      }

      return list;
    }
  }
};