import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import datepicker from "../homePublic/datepicker.vue";
import axios from '../../axios/axios';
export default {
  components: {
    datepicker: datepicker
  },
  data: function data() {
    return {
      form: {
        report_type: "注单处理",
        BeginDate: '',
        EndDate: '',
        order_id: '',
        username: '',
        t_status: 0,
        //全部0，生效1，注销 -1
        page: 1,
        hyname: ''
      },
      list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      all_selecet_order_id_s: [],
      all_selecet: false
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post('operate/order_list', {
      report_type: "注单处理",
      BeginDate: '',
      EndDate: '',
      order_id: '',
      username: '',
      t_status: 0,
      //全部0，生效1，注销 -1
      page: 1
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.poc_data(result.data.repsoneContent);
        });
      }
    });
  },
  created: function created() {},
  methods: {
    cancel_list: function cancel_list() {
      var _this = this;

      if (this.all_selecet_order_id_s.length == 0) {
        return this.$message.error('未选择注单');
      }

      this.axios.post('order/cancel_list', {
        order_id: this.all_selecet_order_id_s || []
      }).then(function (result) {
        if (result.data.status == 1) {
          _this.$message.success(result.data.msg);

          _this.get_list();
        }

        if (result.data.status == 0) {
          _this.$message.error(result.data.msg);
        }
      });
    },
    hy_cancel_list: function hy_cancel_list() {
      var _this2 = this;

      if (this.form.hyname == '') {
        return this.$message.error('请输入会员账号');
      }

      this.$confirm("\u786E\u5B9A\u64A4\u9500\u8BE5\u4F1A\u5458 (".concat(this.form.hyname, ") \u6240\u6709\u6CE8\u5355\uFF0C\u6B64\u64CD\u4F5C\u4E0D\u53EF\u6062\u590D, \u662F\u5426\u7EE7\u7EED?"), "提示", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.axios.post('order/cancel_list', {
          username: _this2.form.hyname
        }).then(function (result) {
          if (result.data.status == 1) {
            _this2.$message.success(result.data.msg);

            _this2.get_list();
          }

          if (result.data.status == 0) {
            _this2.$message.error(result.data.msg);
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
      ;
    },
    //全选
    all_selecet_change: function all_selecet_change() {
      var _this3 = this;

      if (this.all_selecet) {
        this.list.forEach(function (item) {
          if (item.order_staus != -1) {
            item.seleceted = true;

            _this3.all_selecet_order_id_s.push(item.order_id_s);
          }
        });
      } else {
        this.all_selecet_order_id_s = [];
        this.list.forEach(function (item) {
          return item.seleceted = false;
        });
      }
    },
    //单个选择
    change_selecet: function change_selecet(row) {
      if (row.seleceted) {
        this.all_selecet_order_id_s.push(row.order_id_s);
      } else {
        this.all_selecet_order_id_s.splice(this.all_selecet_order_id_s.findIndex(function (item) {
          return item == row.order_id_s;
        }), 1);
      }

      this.all_selecet = this.all_selecet_order_id_s.length == this.list.length;
    },
    change_page: function change_page(page) {
      this.form.page = page;
      this.get_list();
    },
    //获取注单列表
    get_list: function get_list() {
      var _this4 = this;

      this.axios.post('operate/order_list', this.form).then(function (result) {
        if (result.data.status == 200) {
          _this4.poc_data(result.data.repsoneContent);
        }
      });
    },
    poc_data: function poc_data(data) {
      data.list.forEach(function (item) {
        item.seleceted = false;
      });
      this.list = data.list;
      this.pagination = data.pagination;
    },
    //获取时间
    getDate: function getDate(date, updata) {
      this.form.BeginDate = date.start;
      this.form.EndDate = date.end;

      if (updata === 'updata') {// this.get_report();
      }
    }
  }
};