import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { h } from "vue";
export default {
  props: ["userinfo", "addinfo"],
  data: function data() {
    return {
      usertype: "",
      new_user_info: "",
      cash_out: {
        value: "",
        show: false,
        text: ""
      },
      kuaicai_cash_out: {
        value: "",
        show: false,
        text: ""
      },
      is_liuhe: 0,
      is_kuaicai: 0,
      remark: "",
      jine_type: "0",
      db: ""
    };
  },
  created: function created() {
    if (this.userinfo.db != undefined && this.userinfo.db != "") {
      this.db = this.userinfo.db;
    } else {
      // console.log(this.$route.query);
      this.db = this.$route.query.db;
    }

    this.usertype = this.userinfo.group_id == 0 ? "会员" : "代理";
    this.is_liuhe = this.userinfo.kusaicai_liuhe_status.is_liuhe;
    this.is_kuaicai = this.userinfo.kusaicai_liuhe_status.is_kuaicai;
    this.get_user_info();
  },
  mounted: function mounted() {
    this.$refs["top_up_value"].focus(); // console.log(this.$refs);
  },
  methods: {
    //抽取额度
    chouqu: function chouqu() {
      var _this = this;

      this.$confirm("确定抽取额度吗？", "确认信息", {
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消操作"
      }).then(function (action) {
        _this.axios.post("user_version2/extract_balance", {
          uid: _this.userinfo.id,
          is_kuaicai: _this.is_kuaicai,
          is_liuhe: _this.is_liuhe,
          db: _this.db
        }).then(function (result) {
          if (result.data.status == 200) {
            _this.$message({
              type: "success",
              message: result.data.shortMessage,
              offset: 400
            });

            _this.get_user_info();
          }
        });
      }).catch(function (action) {
        console.log(action);

        _this.$message({
          type: "info",
          message: action === "cancel" ? "已取消操作" : "已取消操作"
        });
      });
    },
    sub_cash_out: function sub_cash_out() {
      var _this2 = this;

      if (this.cash_out.value == "" && this.kuaicai_cash_out.value == "") {
        return;
      }

      if (Number.isNaN(this.kuaicai_cash_out.value * 1) || this.kuaicai_cash_out.value * 1 < 0) {
        this.kuaicai_cash_out.show = true;
        this.kuaicai_cash_out.text = "请输入正确的提现金额(快彩)";
        return;
      }

      if (Number.isNaN(this.cash_out.value * 1) || this.cash_out.value * 1 < 0) {
        this.cash_out.show = true;
        this.cash_out.text = "请输入正确的提现金额(六合)";
        return;
      }

      var obj = {
        uid: this.userinfo.id,
        agent_id: this.userinfo.agent_id,
        type: 2,
        pre_username: this.userinfo.parent_name,
        username: this.userinfo.username,
        group_id: this.userinfo.group_id,
        is_liuhe: this.is_liuhe,
        is_kuaicai: this.is_kuaicai,
        remark: this.remark,
        item_id: this.jine_type,
        usdt: this.cash_out.value,
        is_equal: this.new_user_info.balance == this.cash_out.value ? 1 : 0,
        db: this.db
      };

      if (this.is_liuhe == 1) {
        obj.txt_recharge = this.jine_type == 0 ? this.cash_out.value : this.jine_type == 52 ? (this.cash_out.value * this.new_user_info.usdt).toFixed(0) : "";
      }

      if (this.is_kuaicai == 1) {
        obj.kuaicai_txt_recharge = this.jine_type == 0 ? this.kuaicai_cash_out.value : this.jine_type == 52 ? (this.kuaicai_cash_out.value * this.new_user_info.usdt).toFixed(0) : "";
      }

      this.$confirm(h("div", null, [obj.kuaicai_txt_recharge * 1 > 0 ? h("p", null, "\u63D0\u73B0\u5FEB\u5F69\uFF1A".concat(obj.kuaicai_txt_recharge, "\u5143")) : "", obj.txt_recharge * 1 > 0 ? h("p", null, "\u63D0\u73B0\u516D\u5408\uFF1A".concat(obj.txt_recharge, "\u5143")) : ""]), "\u786E\u5B9A\u5BF9 ".concat(this.userinfo.username, " \u63D0\u73B0\u5417\uFF1F"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "myMessageBox",
        customStyle: {
          backgroundColor: this.$store.state.themeColorLight,
          border: " 2px solid",
          borderColor: " ".concat(this.$store.state.themeColor),
          width: "200px"
        }
      }).then(function () {
        _this2.axios.post("account/credit_recharge_kc", obj).then(function (result) {
          if (result.data.status == 200) {
            _this2.$message.success(result.data.shortMessage);

            _this2.get_user_info();

            _this2.$emit("other_view_back");
          }
        });
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "已取消操作"
        });
      });
    },
    get_user_info: function get_user_info() {
      var _this3 = this;

      this.axios.post("account/credit_recharge_kc", {
        uid: this.userinfo.id,
        agent_id: this.userinfo.agent_id,
        type: "",
        group_id: this.userinfo.group_id,
        db: this.db
      }).then(function (result) {
        if (result.data.status == 200) {
          _this3.new_user_info = result.data.repsoneContent;
        }
      });
    }
  },
  watch: {
    "cash_out.value": function cash_out(newVal, oldVal) {
      if (this.jine_type == 0) {
        if (newVal > this.new_user_info.balance * 1) {
          this.cash_out.show = true;
          this.cash_out.text = "不能超过最大金额：" + this.new_user_info.balance;
        } else {
          this.cash_out.show = false;
        }
      } else if (this.jine_type == 52) {
        if (newVal * this.new_user_info.usdt > this.new_user_info.balance * 1) {
          this.cash_out.show = true;
          this.cash_out.text = "不能超过最大金额：" + this.new_user_info.balance;
        } else {
          this.cash_out.show = false;
        }
      }
    },
    "kuaicai_cash_out.value": function cash_out(newVal, oldVal) {
      if (this.jine_type == 0) {
        if (newVal > this.new_user_info.kuaicai_balance * 1) {
          this.kuaicai_cash_out.show = true;
          this.kuaicai_cash_out.text = "不能超过最大金额：" + this.new_user_info.kuaicai_balance;
        } else {
          this.kuaicai_cash_out.show = false;
        }
      } else if (this.jine_type == 52) {
        if (newVal * this.new_user_info.usdt > this.new_user_info.kuaicai_balance * 1) {
          this.kuaicai_cash_out.show = true;
          this.kuaicai_cash_out.text = "不能超过最大金额：" + this.new_user_info.kuaicai_balance;
        } else {
          this.kuaicai_cash_out.show = false;
        }
      }
    }
  }
};