import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.sort.js";
export default {
  components: {},
  props: ['title', 'chang_long', 'sum', 'pl_value'],
  data: function data() {
    return {
      gyjh: [{
        id: 151,
        name: "3",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 152,
        name: "4",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 153,
        name: "5",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 154,
        name: "6",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 155,
        name: "7",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 156,
        name: "8",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 157,
        name: "9",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 158,
        name: "10",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 159,
        name: "11",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 160,
        name: "12",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 161,
        name: "13",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 162,
        name: "14",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 163,
        name: "15",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 164,
        name: "16",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 165,
        name: "17",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 166,
        name: "18",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 167,
        name: "19",
        game_type: "冠、亚军和指定",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      gyjh_lm: [{
        id: 168,
        name: "大",
        game_type: "冠、亚军和两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 169,
        name: "小",
        game_type: "冠、亚军和两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 170,
        name: "单",
        game_type: "冠、亚军和两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 171,
        name: "双",
        game_type: "冠、亚军和两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      gj: [{
        id: 1,
        name: "1",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 2,
        name: "2",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 3,
        name: "3",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 4,
        name: "4",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 5,
        name: "5",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 6,
        name: "6",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 7,
        name: "7",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 8,
        name: "8",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 9,
        name: "9",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 10,
        name: "10",
        game_type: "冠军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 11,
        name: "大",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 12,
        name: "小",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 13,
        name: "单",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 14,
        name: "双",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 15,
        name: "龙",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 16,
        name: "虎",
        game_type: "冠军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      yj: [{
        id: 17,
        name: "1",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 18,
        name: "2",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 19,
        name: "3",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 20,
        name: "4",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 21,
        name: "5",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 22,
        name: "6",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 23,
        name: "7",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 24,
        name: "8",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 25,
        name: "9",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 26,
        name: "10",
        game_type: "亚军",
        is_open: 1,
        maxpl: "50",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 27,
        name: "大",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 28,
        name: "小",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 29,
        name: "单",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 30,
        name: "双",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 31,
        name: "龙",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 32,
        name: "虎",
        game_type: "亚军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: "",
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d3: [{
        id: 33,
        name: "1",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 34,
        name: "2",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 35,
        name: "3",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 36,
        name: "4",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 37,
        name: "5",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 38,
        name: "6",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 39,
        name: "7",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 40,
        name: "8",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 41,
        name: "9",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 42,
        name: "10",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 43,
        name: "大",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 44,
        name: "小",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 45,
        name: "单",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 46,
        name: "双",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 47,
        name: "龙",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 48,
        name: "虎",
        game_type: "第三名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d4: [{
        id: 49,
        name: "1",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 50,
        name: "2",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 51,
        name: "3",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 52,
        name: "4",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 53,
        name: "5",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 54,
        name: "6",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 55,
        name: "7",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 56,
        name: "8",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 57,
        name: "9",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 58,
        name: "10",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 59,
        name: "大",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 60,
        name: "小",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 61,
        name: "单",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 62,
        name: "双",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 63,
        name: "龙",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 64,
        name: "虎",
        game_type: "第四名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d5: [{
        id: 65,
        name: "1",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 66,
        name: "2",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 67,
        name: "3",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 68,
        name: "4",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 69,
        name: "5",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 70,
        name: "6",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 71,
        name: "7",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 72,
        name: "8",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 73,
        name: "9",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 74,
        name: "10",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 75,
        name: "大",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 76,
        name: "小",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 77,
        name: "单",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 78,
        name: "双",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 79,
        name: "龙",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 80,
        name: "虎",
        game_type: "第五名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d6: [{
        id: 81,
        name: "1",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 82,
        name: "2",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 83,
        name: "3",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 84,
        name: "4",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 85,
        name: "5",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 86,
        name: "6",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 87,
        name: "7",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 88,
        name: "8",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 89,
        name: "9",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 90,
        name: "10",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 91,
        name: "大",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 92,
        name: "小",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 93,
        name: "单",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 94,
        name: "双",
        game_type: "第六名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d7: [{
        id: 95,
        name: "1",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 96,
        name: "2",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 97,
        name: "3",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 98,
        name: "4",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 99,
        name: "5",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 100,
        name: "6",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 101,
        name: "7",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 102,
        name: "8",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 103,
        name: "9",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 104,
        name: "10",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 105,
        name: "大",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 106,
        name: "小",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 107,
        name: "单",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 108,
        name: "双",
        game_type: "第七名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d8: [{
        id: 109,
        name: "1",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 110,
        name: "2",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 111,
        name: "3",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 112,
        name: "4",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 113,
        name: "5",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 114,
        name: "6",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 115,
        name: "7",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 116,
        name: "8",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 117,
        name: "9",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 118,
        name: "10",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 119,
        name: "大",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 120,
        name: "小",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 121,
        name: "单",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 122,
        name: "双",
        game_type: "第八名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d9: [{
        id: 123,
        name: "1",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 124,
        name: "2",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 125,
        name: "3",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 126,
        name: "4",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 127,
        name: "5",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 128,
        name: "6",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 129,
        name: "7",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 130,
        name: "8",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 131,
        name: "9",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 132,
        name: "10",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 133,
        name: "大",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 134,
        name: "小",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 135,
        name: "单",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 136,
        name: "双",
        game_type: "第九名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d10: [{
        id: 137,
        name: "1",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 138,
        name: "2",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 139,
        name: "3",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 140,
        name: "4",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 141,
        name: "5",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 142,
        name: "6",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 143,
        name: "7",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 144,
        name: "8",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 145,
        name: "9",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 146,
        name: "10",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 147,
        name: "大",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 148,
        name: "小",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 149,
        name: "单",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 150,
        name: "双",
        game_type: "第十名",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sum_bet_balance: '',
      max_ks: '',
      //最高亏损
      max_yl: '',
      //最高盈利
      pl_popver: {
        show: false,
        row: {},
        pl_value: '',
        pl_change_value: '',
        top: '',
        left: ''
      },
      bh: {
        show: false,
        row: {},
        pl_value: '',
        je_value: '',
        rate: '',
        top: '',
        left: ''
      }
    };
  },
  created: function created() {},
  methods: {
    get_zdmx_data: function get_zdmx_data(row) {
      if (row.szsz_amount[1] != '-') {
        this.$emit('get_zdmx_data', row);
      }
    },
    sub_change_pl: function sub_change_pl() {
      this.$emit('change_pl', this.pl_popver);
    },
    show_pl_popver: function show_pl_popver(row, event) {
      if (this.$store.state.userinfo.group_id >= 5 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      } // console.log(row, event);


      this.pl_popver.show = false;

      if (event == 'plus' || event == 'minus') {
        this.pl_popver.row = row;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.pl = row.pl;
        this.pl_popver.pl_change_value = '';
        this.pl_popver.plusminus = event;
        this.$emit('change_pl', this.pl_popver);
      } else {
        this.pl_popver.row = row;
        this.pl_popver.value = row.pl;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.plusminus = '';
        this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
        this.$refs['pl_popver'].style.left = event.pageX + 'px';
        this.$refs['pl_popver'].style.top = event.pageY + 'px';
        this.pl_popver.show = true;
      }
    },
    proc: function proc(data) {
      var _this = this;

      this.sum_bet_balance = data.sum_bet_balance;
      var play_odds = data.play_odds;
      var szsz_amount = data.szsz_amount;
      var yk = [];
      ['gyjh', 'gyjh_lm', 'gj', 'yj', 'd3', 'd4', 'd5', 'd6', 'd7', 'd8', 'd9', 'd10'].forEach(function (keyname) {
        _this[keyname].forEach(function (item) {
          play_odds.forEach(function (element) {
            if (element.id == item.id) {
              item.is_open = element.is_open;
              item.maxpl = element.maxpl * 1;
              item.minpl = element.minpl * 1;
              item.pl = element.pl * 1;
              item.level_two = element.level_two;
              item.level_three = element.level_three;
              item.level_four = element.level_four;
              item.control_let = element.control_let;
              item.szsz_amount = ['-', '-', '', '', '-', '-', ''];
              item.yk = 0;
              item.newid = element.id;
            }
          });

          for (var key in szsz_amount) {
            if (key == item.id) {
              item.szsz_amount = szsz_amount[key].split(',');
              item.yk = item.szsz_amount[4] * 1;
            }
          }

          yk.push(item.yk);
        });
      });
      this.max_yl = yk.sort(function (a, b) {
        return b - a;
      })[0];
      this.max_ks = yk.sort(function (a, b) {
        return a - b;
      })[0];
    }
  }
};