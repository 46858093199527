import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";

/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-11 20:50:02
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-29 14:03:43
 * @FilePath: \super_admin\src\plugin\duijiangma.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
var pk10wpp = function pk10wpp(num) {
  if (num == 1 || num == 6) {
    return 'a';
  }

  if (num == 2 || num == 7) {
    return 'b';
  }

  if (num == 3 || num == 8) {
    return 'c';
  }

  if (num == 4 || num == 9) {
    return 'd';
  }

  if (num == 5 || num == 10) {
    return 'e';
  }
};

export function duijiangma(list, gid) {
  // console.log(list);
  //ssc
  if (gid == 1 || gid == 4 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
    list.forEach(function (item) {
      item.djm = [];
      var tlist = item.txid.split('');
      tlist.reverse();
      tlist.forEach(function (t) {
        item.djm.push({
          text: t,
          color: ''
        });
      });
      item.res.forEach(function (num) {
        item.djm.some(function (t) {
          if (t.text == num && t.color == '') {
            return t.color = 'red';
          }
        });
      });
      item.djm.reverse();
    });
  } //pk10


  if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
    list.forEach(function (item) {
      item.djm = [];
      var tlist = item.txid.split('');
      tlist.forEach(function (t) {
        item.djm.push({
          text: t,
          color: ''
        });
      });
      item.res.forEach(function (num) {
        item.djm.some(function (t) {
          if ((t.text == num * 1 || t.text + 10 == num * 1) && t.color == '') {
            return t.color = 'red';
          }
        });
      });
      var rednums = item.djm.filter(function (item) {
        return item.color == 'red';
      }); // console.log(rednums);

      if (rednums.length == 8) {
        var wpp8 = '';
        var wpp9 = '';
        wpp8 = pk10wpp(item.res[8] * 1);
        wpp9 = pk10wpp(item.res[9] * 1);
        item.djm[item.djm.findIndex(function (djm) {
          return djm.text == wpp8 && djm.color == '';
        })].color = 'red';
        item.djm[item.djm.findIndex(function (djm) {
          return djm.text == wpp9 && djm.color == '';
        })].color = 'red';
      }

      if (rednums.length == 9) {
        item.djm.push({
          text: item.res[9] * 1 == 10 ? 0 : item.res[9] * 1,
          color: '#409eff'
        });
      }
    });
  } //lhc


  if (gid == 48) {
    list.forEach(function (item) {
      if (item.txid == '') {
        return list;
      }

      var tlist = item.txid.split(',');
      item.djm = [[], [], [], [], []]; // console.log(tlist);

      tlist.forEach(function (tl, index) {
        tl = tl.split('');
        tl.forEach(function (t) {
          item.djm[index].push({
            text: t,
            color: ''
          });
        });
      }); // console.log(item.djm);

      var sevenlist = [];

      for (var index = 0; index < item.djm[0].length; index++) {
        if (item.djm[0][index].text !== 'f') {
          item.djm[0][index].color = '#409eff';
          sevenlist.push({
            t: item.djm[0][index].text,
            djm: ''
          });

          if (sevenlist.length == 7) {
            break;
          }
        }
      }

      sevenlist.forEach(function (seven) {
        if (seven.t == '1' || seven.t == '6' || seven.t == 'a') {
          seven.djm = item.djm[0];
          seven.index = 0;
        }

        if (seven.t == '2' || seven.t == '7' || seven.t == 'b') {
          seven.djm = item.djm[1];
          seven.index = 1;
        }

        if (seven.t == '3' || seven.t == '8' || seven.t == 'c') {
          seven.djm = item.djm[2];
          seven.index = 2;
        }

        if (seven.t == '4' || seven.t == '9' || seven.t == 'd') {
          seven.djm = item.djm[3];
          seven.index = 3;
        }

        if (seven.t == '5' || seven.t == '0' || seven.t == 'e') {
          seven.djm = item.djm[4];
          seven.index = 4;
        }
      });
      var openlist = [];
      item.res.forEach(function (num) {
        openlist.push((num / 10).toString()[2] || '0');
      });

      for (var _index = 0; _index < 7; _index++) {
        sevenlist[_index].open = openlist[_index];
      }

      sevenlist.forEach(function (seven) {
        if (seven.djm.find(function (djm) {
          return djm.text == seven.open && djm.color == '';
        }) != undefined) {
          seven.djm.some(function (djm) {
            if (djm.text == seven.open && djm.color == '') {
              return djm.color = 'red';
            }
          });
        } else {
          var open = pk10wpp(seven.open);
          seven.djm.some(function (djm) {
            if (djm.text == open && djm.color == '') {
              return djm.color = 'red';
            }
          });
        } // console.log(sevenlist);


        item.djm.forEach(function (djm, index) {
          if (index == seven.index) {
            djm.forEach(function (djm) {
              seven.djm.forEach(function (sdjm) {
                if (djm.text == sdjm.text && sdjm.color == 'red' && djm.color == '') {
                  djm.colo = 'red';
                }
              });
            });
          }
        });
      });
    });
  } //k3


  if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
    list.forEach(function (item) {
      item.djm = [];
      var tlist = item.txid.split('');
      tlist.forEach(function (t) {
        item.djm.push({
          text: t,
          color: ''
        });
      });
      item.res.forEach(function (num) {
        item.djm.some(function (t) {
          if (t.text == num && t.color == '') {
            return t.color = 'red';
          }
        });
      });
    });
  } //xy28


  if (gid == 22 || gid == 23) {} // console.log(list);


  return list;
}