<!--
 * @Author: your name
 * @Date: 2022-04-18 19:45:42
 * @LastEditTime: 2025-01-22 15:11:41
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\plublic\tuishui.vue
-->
<template>
    <div class="tuishui">
        <div class="panel">
            <div class="panel-body">
                <div class="fine-tuning">
                    <template v-if="lh_gid != 10">
                        <label class="label">退水微调</label>
                        <el-input size="small" style="width: 150px" v-model="fine_tuning" :min="0.001">
                            <template #prepend>
                                <el-button size="small" @click="fine_tuning_change('减')">
                                    <el-icon>
                                        <Minus style="color: 000" />
                                    </el-icon>
                                </el-button>
                            </template>
                            <template #append>
                                <el-button size="small" @click="fine_tuning_change('加')">
                                    <el-icon>
                                        <Plus style="color: 000" />
                                    </el-icon>
                                </el-button>
                            </template>
                        </el-input>
                        <template v-if="overall_value != 0">
                            <span style="vertical-align: middle; color: red; margin-left: 10px">( 累计全局调整：{{ overall_value }} )</span>
                            <el-button type="primary" size="small" style="margin-left: 10px" @click="sub">保存设置</el-button>
                        </template>
                    </template>
                    <!-- <el-checkbox style="vertical-align: middle; margin: 0 10px 0 20px" v-model="gameCheckedAll" true-label="1" false-label="0" @change="overall_value = 0">对全局退水进行统一微调</el-checkbox>
                    <span v-if="gameCheckedAll === '1'" style="vertical-align: middle; color: red">( 累计调整：{{ overall_value }} )</span>
                    <el-button style="vertical-align: middle; margin: 0 10px" v-if="gameCheckedAll === '1'" type="primary" size="small" @click="set_overall_value">确认全局修改</el-button>-->
                    <!-- <el-radio-group v-model="lh_gid" size="small" @change="ref_data" style="float: right">
                        <el-radio-button v-for="item in lh_list" :key="item.id" :label="item.id">{{ item.gname }}</el-radio-button>
                    </el-radio-group>-->
                    <div class="game-btn-group">
                        <el-link :underline="false">彩票：</el-link>
                        <div v-for="item in lh_list" :key="item.id" :class="`game-btn ${lh_gid == item.id ? 'theme-bg white-text' : ''}`" @click="ref_data(item.id)">{{ item.gname }}</div>
                    </div>
                </div>
            </div>
            <div class="panel-body" v-if="lh_gid != 10">
                <el-table :data="type_list" border small highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                    <el-table-column prop="name" label="交易类型" align="center">
                        <template #default="scope">
                            <div class="type-color" :style="{ borderColor: scope.row.type_color }">{{ scope.row.game_type }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="water_break_A" label="A盘" align="center" width="100" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('1')">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.water_break_A"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="water_break_B" label="B盘" align="center" width="100" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('2')">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.water_break_B"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="water_break_C" label="C盘" align="center" width="100" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('3')">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.water_break_C"></el-input>
                        </template>
                    </el-table-column>-->
                    <el-table-column prop="straightbet_low" label="单注最低" align="center" width="100" v-if="userinfo.group_id == 2 || userinfo.group_id == 3">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.straightbet_low"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="straightbet_high" label="单注限额" align="center" width="100">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.straightbet_high"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="single_high" label="单期限额" align="center" width="100">
                        <template #default="scope">
                            <el-input size="small" :min="0" v-model="scope.row.single_high"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop label="操作" align="center" width="100">
                        <template #default="scope">
                            <el-button type="primary" size="small" @click="fast_set(scope.row)">快捷设置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div style="margin: 20px" v-if="lh_gid != 10">
            <el-button type="warning" size="small" @click="sub">保存</el-button>
            <el-button type="primary" size="small" @click="back">返回</el-button>
        </div>
        <div class="panel" v-for="game in list" :key="game.gname" style="margin: 10px 0">
            <div class="panel-title">
                <h2>{{ game.gname }}</h2>
            </div>
            <div class="panel-body">
                <el-row>
                    <el-col v-for="list in game.list" :key="list" :span="12">
                        <el-table :data="list" border small highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                            <el-table-column prop="name" label="交易类型" align="right">
                                <template #default="scope">
                                    <div class="type-color" :style="{ borderColor: scope.row.type_color }">
                                        <span v-show="lh_gid == 10 && (scope.row.game_type == '二字定' || scope.row.game_type == '三字定')" :style="{ color: scope.row.game_type == '二字定' ? 'red' : 'blue' }">{{ scope.row.game_type }}：</span>
                                        {{ scope.row.name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <template v-if="list[0].play_id == 10">
                                <el-table-column prop="water_break_A" label="退水" align="center" width="80" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('1')">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.earn_water" placeholder="选择回水" size="small" @change="change_water_pl_break(scope.row, 'earn_water', $event)">
                                            <el-option v-for="item in scope.row.hs_list" :key="item" :label="item" :value="item" />
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="water_break_A" label="赔率" align="center" width v-if="userinfo.level_id == 0 || userinfo.level_id.includes('1')">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.rate" placeholder="选择赔率" size="small" @change="change_water_pl_break(scope.row, 'rate', $event)">
                                            <el-option v-for="item in scope.row.pl_list" :key="item" :label="item" :value="item" />
                                        </el-select>
                                    </template>
                                </el-table-column>
                            </template>
                            <template v-else>
                                <el-table-column prop="water_break_A" label="A盘" align="center" width="80" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('1')">
                                    <template #default="scope">
                                        <el-input size="small" :min="0" v-model="scope.row.water_break_A"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="water_break_B" label="B盘" align="center" width="80" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('2')">
                                    <template #default="scope">
                                        <el-input size="small" :min="0" v-model="scope.row.water_break_B"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="water_break_C" label="C盘" align="center" width="80" v-if="userinfo.level_id == 0 || userinfo.level_id.includes('3')">
                                    <template #default="scope">
                                        <el-input size="small" :min="0" v-model="scope.row.water_break_C"></el-input>
                                    </template>
                                </el-table-column>
                            </template>
                            <el-table-column prop="straightbet_low" label="单注最低" align="center" width="80" v-if="userinfo.group_id == 2 || userinfo.group_id == 3">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.straightbet_low"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="straightbet_high" label="单注限额" align="center" width="100">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.straightbet_high"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="single_high" label="单期限额" align="center" width="100">
                                <template #default="scope">
                                    <el-input size="small" :min="0" v-model="scope.row.single_high"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div style="margin: 20px">
            <el-button type="warning" size="small" @click="sub">保存</el-button>
            <el-button type="primary" size="small" @click="back">返回</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["userinfo"],
    data() {
        return {
            list: [],
            type_list: [
                {
                    game_type: "号码类（单码、冠亚……",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "#ff877f",
                },
                {
                    game_type: "两面类（两面、龙虎……）",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "#7ae774",
                },
                {
                    game_type: "连码类（任选二、任选三……）",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "#7f96fe",
                },
                {
                    game_type: "杂类（方位、豹子、冠亚和……）",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "#c67fff",
                },
                {
                    game_type: "六合彩：特码、正码、正码特",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "red",
                },
                {
                    game_type: "六合彩：连码",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "green",
                },
                {
                    game_type: "六合彩：两面、色波",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "#e6a23c",
                },
                {
                    game_type: "六合彩：其他（特肖、生肖尾数、连肖连尾，五不中)",
                    single_high: "40000",
                    straightbet_high: "20000",
                    straightbet_low: "2",
                    water_break_A: 0.6,
                    water_break_B: 2.8,
                    water_break_C: 3.5,
                    type_color: "blue",
                },
            ],
            all_gids: [],
            fine_tuning: 0.01,
            gameCheckedAll: "0", //全选
            overall_value: 0, //全局微调值
            flag: 0,
            lh_list: [],
            lh_gid: "",
            times: 1,
            s_gid: "",
        };
    },
    created() {
        let gids = [];
        this.all_gids = [];
        this.lh_list = [];
        this.$store.state.game_list.forEach((item) => {
            if (this.userinfo.group_id == 2) {
                gids.push(item.id);
            } else {
                if (this.userinfo.kusaicai_liuhe_status.is_kuaicai == 1) {
                    if (item.id != 10 && item.id != 20 && item.id != 34 && item.id != 49 && item.id != 50 && item.id != 51) {
                        gids.push(item.id);
                    }
                } else {
                    if (item.id == 10) {
                        gids.push(10);
                    }
                    if (item.id == 20) {
                        gids.push(20);
                    }
                    if (item.id == 34) {
                        gids.push(34);
                    }
                    if (item.id == 49) {
                        gids.push(49);
                    }
                    if (item.id == 50) {
                        gids.push(50);
                    }
                    if (item.id == 51) {
                        gids.push(51);
                    }
                }
            }
            this.all_gids.push(item.id);
        });
        if (this.userinfo.kusaicai_liuhe_status.is_kuaicai == 1) {
            this.lh_list.push({ id: 1, gname: "快彩" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_pailie5 == 1 && this.userinfo.group_id > 2) {
            this.lh_list.push({ id: 10, gname: "排列五" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_liuhe == 1) {
            this.lh_list.push({ id: 20, gname: "香港六合彩" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_aomen == 1) {
            this.lh_list.push({ id: 34, gname: "澳门六合彩" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_xinaomen == 1) {
            this.lh_list.push({ id: 49, gname: "新澳门六合彩" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_taiwan == 1) {
            this.lh_list.push({ id: 50, gname: "台湾六合彩" });
        }
        if (this.userinfo.kusaicai_liuhe_status.status_kuaile8 == 1) {
            this.lh_list.push({ id: 51, gname: "快乐8六合彩" });
        }
        if (this.lh_list.length > 0) {
            this.lh_list.unshift({ id: "", gname: "全部" });
        }
        this.axios
            .post(`user_version2/tuishui?uid=${this.userinfo.id}`, {
                uid: this.userinfo.id,
                gids,
                all_gids: this.all_gids,
                play_ids: "",
                times: 2,
                db: this.userinfo.db ? this.userinfo.db : this.$route.query.db,
            })
            .then((result) => {
                if (result.data.status == 200) {
                    this.get_data(result.data.repsoneContent);
                }
            });
    },
    methods: {
        /**全局微调 */
        fine_tuning_change(event) {
            event === "加" ? (this.overall_value += Number.parseFloat(this.fine_tuning)) : (this.overall_value -= Number.parseFloat(this.fine_tuning));
            this.overall_value = Number.parseFloat(this.overall_value.toFixed(3));
            let value = this.fine_tuning;
            event == "减" ? (value *= -1) : (value *= 1);
            for (let game of this.list) {
                for (let list_item of game.list) {
                    for (let item of list_item) {
                        if (this.userinfo.level_id == 0 || this.userinfo.level_id.includes("1")) {
                            item.water_break_A = (item.water_break_A * 1 + value).toFixed(3) * 1;
                            if (item.water_break_A < 0) {
                                item.water_break_A = 0;
                            }
                        }
                        if (this.userinfo.level_id == 0 || this.userinfo.level_id.includes("2")) {
                            item.water_break_B = (item.water_break_B * 1 + value).toFixed(3) * 1;
                            if (item.water_break_B < 0) {
                                item.water_break_B = 0;
                            }
                        }
                        if (this.userinfo.level_id == 0 || this.userinfo.level_id.includes("3")) {
                            item.water_break_C = (item.water_break_C * 1 + value).toFixed(3) * 1;
                            if (item.water_break_C < 0) {
                                item.water_break_C = 0;
                            }
                        }
                    }
                }
            }
        },
        reset_ts() {
            this.$confirm("是否确认重置?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let obj = {
                        uid: this.userinfo.id,
                        group_id: this.userinfo.group_id,
                        is_reset: 1,
                        gids: this.all_gids,
                        all_gids: this.all_gids,
                        play_ids: [],
                        db: this.userinfo.db ? this.userinfo.db : this.$route.query.db,
                    };
                    this.axios.post("user_version2/reset_tuishui", obj).then((result) => {
                        if (result.data.status == 200) {
                            this.$message.success(result.data.shortMessage);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消重置",
                    });
                });
        },
        back() {
            this.$emit("other_view_back");
        },
        sub() {
            let list = [];
            for (const game of this.list) {
                for (const old of game.old_list) {
                    for (const list_item of game.list) {
                        for (const item of list_item) {
                            if (item.play_id == old.play_id && item.name == old.name) {
                                if (item.play_id == 10) {
                                    if (
                                        item.straightbet_low * 1 != old.straightbet_low * 1 ||
                                        item.straightbet_high * 1 != old.straightbet_high * 1 ||
                                        item.single_high * 1 != old.single_high * 1 ||
                                        item.earn_water * 1 != item.old_earn_water * 1 ||
                                        item.rate != item.old_rate
                                    ) {
                                        item.old = old;
                                        list.push(item);
                                    }
                                } else {
                                    if (
                                        item.water_break_A * 1 != old.water_break_A * 1 ||
                                        item.water_break_B * 1 != old.water_break_B * 1 ||
                                        item.water_break_C * 1 != old.water_break_C * 1 ||
                                        item.straightbet_low * 1 != old.straightbet_low * 1 ||
                                        item.straightbet_high * 1 != old.straightbet_high * 1 ||
                                        item.single_high * 1 != old.single_high * 1
                                    ) {
                                        item.old = old;
                                        list.push(item);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (list.length > 0) {
                let obj = {
                    user_id: this.userinfo.id,
                    group_id: this.userinfo.group_id,
                    update_tuishui: list,
                };
                if (this.userinfo.db != "" && this.userinfo.db != null && this.userinfo.db != undefined) {
                    obj.db = this.userinfo.db;
                } else {
                    obj.db = this.$route.query.db;
                }
                // console.log(obj);
                this.axios.post("user_version2/update_tuishui", obj).then((result) => {
                    if (result.data.status == 200) {
                        this.$message.success(result.data.shortMessage);
                        this.overall_value = 0;
                        this.$emit("other_view_back");
                    }
                });
            } else {
                this.$message("数据未改动，已保持以往设置");
            }
        },
        fast_set(row) {
            if (
                // Number.isNaN(row.water_break_A * 1) ||
                // Number.isNaN(row.water_break_B * 1) ||
                // Number.isNaN(row.water_break_C * 1) ||
                Number.isNaN(row.straightbet_high * 1) ||
                Number.isNaN(row.straightbet_low * 1) ||
                Number.isNaN(row.single_high * 1)
            ) {
                this.$message.error("请填写正确的数值");
            }
            if (
                // row.water_break_A * 1 < 0 || row.water_break_B * 1 < 0 || row.water_break_C * 1 < 0 ||
                row.straightbet_high * 1 < 0 ||
                row.straightbet_low * 1 < 0 ||
                row.single_high * 1 < 0
            ) {
                this.$message.error("数值不能小于0");
            }
            for (const game of this.list) {
                for (const list_item of game.list) {
                    for (const item of list_item) {
                        if (item.type_color == row.type_color) {
                            // item.water_break_A = row.water_break_A;
                            // item.water_break_B = row.water_break_B;
                            // item.water_break_C = row.water_break_C;
                            item.straightbet_high = row.straightbet_high;
                            item.straightbet_low = row.straightbet_low;
                            item.single_high = row.single_high;
                        }
                    }
                }
            }
        },
        ref_data(gid = "") {
            this.overall_value = 0;
            // console.log(this.userinfo);
            let obj = {
                db: this.userinfo.db ? this.userinfo.db : this.$route.query.db,
                uid: this.userinfo.id,
                group_id: this.userinfo.group_id,
                gids: gid == "" ? this.all_gids : [gid],
                all_gids: this.all_gids,
                times: gid == "" ? this.times : 2,
                is_single: gid == "" ? 0 : 1,
            };
            this.s_gid = obj.is_single ? gid : "";
            this.axios.post("user_version2/tuishui", obj).then((result) => {
                if (result.data.status == 200) {
                    // this.$message.success(result.data.shortMessage);
                    this.get_data(result.data.repsoneContent);
                    if (gid == "" && this.times == 1) {
                        this.times = 2;
                        this.ref_data("");
                    }
                    if (gid !== "") {
                        this.times = 1;
                    }
                    this.lh_gid = gid;
                }
            });
        },
        get_data(data) {
            let list = data.list;
            if (data.data_list?.length > 0) {
                data.data_list.forEach((item) => {
                    if (item.game_type == "号码类（单码、冠亚……") {
                        item.type_color = "#ff877f";
                    }
                    if (item.game_type == "两面类（两面、龙虎……）") {
                        item.type_color = "#7ae774";
                    }
                    if (item.game_type == "连码类（任选二、任选三……）" || item.game_type == "排列5：三字现") {
                        item.type_color = "#7f96fe";
                    }
                    if (item.game_type == "杂类（方位、豹子、冠亚和……）" || item.game_type == "排列5：四字现") {
                        item.type_color = "#c67fff";
                    }
                    if (item.game_type == "六合彩：特码、正码特" || item.game_type == "排列5：二字定") {
                        item.type_color = "red";
                    }
                    if (item.game_type == "六合彩：连码" || item.game_type == "排列5：四字定") {
                        item.type_color = "green";
                    }
                    if (item.game_type == "六合彩：两面、生肖尾数" || item.game_type == "排列5：二字现") {
                        item.type_color = "#e6a23c";
                    }
                    if (item.game_type == "六合彩：其他全部(特肖，色波，正码，连肖连尾，自选不中,生肖尾数量...)" || item.game_type == "排列5：三字定") {
                        item.type_color = "blue";
                    }
                });
                this.type_list = data.data_list;
            }
            list.forEach((item) => {
                item.old_list = JSON.parse(JSON.stringify(item.list));
                item.list.forEach((g) => {
                    if (g.game_type == "定位胆" || g.game_type.includes("红黑")) {
                        g.type_color = "#ff877f"; //橙色
                    } else if (g.game_type == "整合" || g.game_type == "和值" || g.game_type == "万千(1vs2)" || g.game_type == "第一球" || g.game_type == "总和") {
                        g.type_color = "#7ae774"; //绿色
                    } else if (g.game_type.includes("和值") || g.game_type.includes("前三") || g.game_type == "不定位" || g.game_type == "四字现") {
                        g.type_color = "#c67fff"; //紫色
                    } else if (g.game_type.includes("同号") || g.game_type.includes("连号") || g.game_type == "三字现") {
                        g.type_color = "#7f96fe"; //蓝色
                    }
                    // 六合彩
                    else if (g.game_type == "特码" || g.game_type == "特码B" || g.game_type == "正特" || g.game_type == "正特B" || g.game_type == "二字定") {
                        g.type_color = "red";
                    } else if (g.game_type == "两面" || g.game_type == "一肖" || g.game_type == "一肖不中" || g.game_type == "尾数" || g.game_type == "二字现") {
                        g.type_color = "#e6a23c";
                    } else if (g.game_type == "三全中" || g.game_type == "三中二" || g.game_type == "二全中" || g.game_type == "二中特" || g.game_type == "特串" || g.game_type == "四中一" || g.game_type == "四字定") {
                        g.type_color = "green";
                    } else {
                        g.type_color = "blue";
                    }
                });
                //处理排列5
                if (item.gname == "排列5") {
                    item.list = this.make_pl5_pl_hs_list(item.list);
                }
                item.list = this.reSetData(item.list, Math.round(item.list.length / 2));
            });
            this.list = list;
        },
        reSetData(data, num) {
            //把一个数组分割成想要的数组长度
            let index = 0;
            let newArray = [];
            while (index < data.length) {
                newArray.push(data.slice(index, (index += num)));
            }
            return newArray;
        },
        //排列五选择退水或赔率
        change_water_pl_break(row, key_name, value) {
            // console.log(row, value);
            let index = 0;
            if (key_name == "earn_water") {
                index = row.hs_list.findIndex((item) => item == value);
                row.rate = row.pl_list[index];
            } else {
                index = row.pl_list.findIndex((item) => item == value);
                row.earn_water = row.hs_list[index];
            }
        },
        make_pl5_pl_hs_list(data) {
            data.forEach((row) => {
                row.old_earn_water = row.earn_water;
                row.old_rate = row.rate;
                row.hs_list = [];
                row.pl_list = [];
                // 遍历从0到earn_water_top，步长0.01
                for (let i = 0; i < row.earn_water_top + 0.001; i += 0.001) {
                    let hs = parseFloat(i.toFixed(3) * 1);
                    row.hs_list.push(hs);
                    // 根据不同玩法计算赔率
                    if (["二字现", "三字现", "四字现"].includes(row.game_type)) {
                        let arr_play_rate = row.play_rate.split("/");
                        let arr_cur_rate = row.rate.split("/");
                        let num = row.game_type == "二字现" ? 2 : 1;
                        // 计算每个位置的赔率
                        let rates = arr_cur_rate.map((rate, index) => ((rate - hs * arr_play_rate[index]) * 1).toFixed(num) * 1);
                        row.pl_list.push(rates.join("/"));
                    } else {
                        row.pl_list.push((row.play_rate - hs * row.play_rate).toFixed(2) * 1);
                    }
                }
            });
            return data;
        },
    },
};
</script>

<style scoped>
.type-color {
    height: 28px;
    border-left: 10px solid;
}

.fine-tuning {
    min-height: 20px;
    text-align: left;
}

.label,
.fine-tuning .label {
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
    margin: 0 20px;
}

.game-btn-group {
    display: flex;
    flex-wrap: wrap;
    float: right;
}

.game-btn {
    padding: 3px 8px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
}

/* 移除最后一个按钮右边框 */
.game-btn:not(:last-child) {
    border-right: none;
}
</style>