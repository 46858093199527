import _toConsumableArray from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import zhudanmingxi from "./zhudanmingxi.vue";
export default {
  props: ["form", "search_title_info"],
  components: {
    zhudanmingxi: zhudanmingxi
  },
  data: function data() {
    return {
      data_list: [],
      total: "",
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: false
      },
      rowinfo: "",
      autolet: [{
        result: "0"
      }],
      //补货表
      search_paly_list: []
    };
  },
  created: function created() {},
  methods: {
    search_paly: function search_paly(row) {
      var _this = this;

      var obj = {
        gid: row.play_id,
        BeginDate: this.form.date.start,
        EndDate: this.form.date.end,
        t_status: this.form.settle_state,
        period: this.form.qid,
        page: 1,
        limit: 40,
        is_select: 0,
        username: this.form.username,
        db: row.db
      };

      if (row.method_name != undefined && row.method_name != "" && !row.method_name.includes("-")) {
        obj.level_two = row.level_two || "";
      }

      if (row.method_name != undefined && row.method_name != "" && row.method_name.includes("-")) {
        obj.level_two = row.level_two || "";
        obj.level_three = row.level_three || "";
      }

      this.axios.post("operate/ReportList_T_kc", obj).then(function (result) {
        if (result.data.status == 200) {
          var data = result.data.repsoneContent;
          var tmlist = []; //将特码移到最前面

          data.list.forEach(function (item, index) {
            if (item.method_name == "特码") {
              tmlist.push(item);
              data.list.splice(index, 1);
            }
          });
          data.list.forEach(function (item, index) {
            if (item.method_name == "特码B") {
              tmlist.push(item);
              data.list.splice(index, 1);
            }
          });
          data.list = [].concat(tmlist, _toConsumableArray(data.list));

          _this.proc_data(data);

          _this.search_paly_list.push(data);
        }
      });
    },
    //报表列的颜色
    cellClassName: function cellClassName(_ref) {
      var _this2 = this;

      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex != 0 && columnIndex != 1) {
        //合计列
        this.$nextTick(function () {
          var dom = _this2.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0];

          var val = _this2.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0].innerText;

          dom.style.color = !Number.isNaN(val) && val < 0 ? "red" : "#000";
        }); //数据列

        var columnProperty = this.data_list[rowIndex][column.property];

        if (columnProperty < 0) {
          return "redcolor"; // class名称
        } else {
          return "blackcolor"; // class名称
        }
      }
    },
    //报表合计
    baobiao_sum: function baobiao_sum(param) {
      var _this3 = this;

      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        // console.log(column.label);
        if (column.label === "分类") {
          return sums[index] = "合计";
        }

        if (column.label === "笔数") {
          return sums[index] = _this3.total.count;
        }

        if (column.label === "总投注额") {
          return sums[index] = _this3.total.bet_price;
        }

        if (column.label === "有效金额") {
          return sums[index] = _this3.total.bet_price;
        }

        if (column.label === "会员输赢") {
          return sums[index] = _this3.total.win_price;
        }

        if (column.label === "应收下线") {
          return sums[index] = _this3.total.receive_subline;
        }

        if (column.label === "实占成数") {
          return sums[index] = "";
        }

        if (column.label === "实占注额") {
          return sums[index] = _this3.total.occupy_balance;
        }

        if (column.label === "占货比") {
          return sums[index] = "";
        }

        if (column.label === "实占输赢") {
          return sums[index] = _this3.total.occupy_winlose;
        }

        if (column.label === "实占退水") {
          return sums[index] = _this3.total.occupy_rebate;
        }

        if (column.label === "实占结果") {
          return sums[index] = _this3.total.real_occupy_result;
        }

        if (column.label === "实占结果") {
          return sums[index] = _this3.total.a_rebate;
        }

        if (column.label === "赚取退水") {
          return sums[index] = _this3.total.earn;
        }

        if (column.label === "退水后结果") {
          return sums[index] = _this3.total.real_result;
        }

        if (column.label === "贡献上级") {
          return sums[index] = _this3.total.cont_sup;
        }

        if (column.label === "应付上级") {
          return sums[index] = _this3.total.receive_supline;
        }
      });
      return sums;
    },
    back_search: function back_search() {
      if (this.search_paly_list.length == 1) {
        this.$emit("back_search");
      } else {
        this.search_paly_list.length -= 1;
        this.proc_data(this.search_paly_list[this.search_paly_list.length - 1]);
      }
    },
    back_list: function back_list() {
      this.dialog.page = 1;
      this.$emit("change_page", this.page);
    },
    //补货详情
    get_buhuo_detail: function get_buhuo_detail() {
      var fly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var is_fly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "1";
      this.search_detail(this.rowinfo, "", "", 1);
    },
    //注单明细
    search_detail: function search_detail(row) {
      var _this4 = this;

      var obj = {
        gid: row.play_id,
        userid: "",
        phaseid: this.form.qid,
        page: 1,
        limit: 20,
        t_status: this.form.settle_state,
        m_type: "zd",
        up_id: this.$store.state.userinfo.user_id,
        // level_two: row.level_two,
        // level_three: row.level_three,
        // level_four: row.level_four,
        BeginDate: this.form.date.start,
        EndDate: this.form.date.end,
        bet_count: row.bet_count,
        db: row.db
      };

      if (row.method_name != undefined && row.method_name != "" && !row.method_name.includes("-")) {
        obj.level_two = row.level_two || "";
      }

      if (row.method_name != undefined && row.method_name != "" && row.method_name.includes("-")) {
        obj.level_two = row.level_two || "";
        obj.level_three = row.level_three || "";
      }

      this.rowinfo = obj;
      this.axios.post("operate/ReportDetail_B_kc", obj).then(function (result) {
        if (result.data.status == 200) {
          _this4.dialog.visible = true;

          _this4.$nextTick(function () {
            _this4.$refs["zhudanmingxi"].proc_data(result.data.repsoneContent);
          });
        }
      });
    },
    change_page: function change_page(page) {
      this.page = page;
      this.$emit("change_page", page);
    },
    proc_data: function proc_data(data) {
      var tmlist = []; //将特码移到最前面

      data.list.forEach(function (item, index) {
        if (item.method_name == "特码") {
          tmlist.push(item);
          data.list.splice(index, 1);
        }
      });
      data.list.forEach(function (item, index) {
        if (item.method_name == "特码B") {
          tmlist.push(item);
          data.list.splice(index, 1);
        }
      });
      data.list = [].concat(tmlist, _toConsumableArray(data.list));

      if (this.search_paly_list.length == 0) {
        this.search_paly_list.push(data);
      }

      this.data_list = data.list;

      for (var i = 0; i < this.data_list.length; i++) {
        this.data_list[i].real_result = (this.data_list[i].occupy * 1 + this.data_list[i].a_rebate * 1).toFixed(0);
        this.data_list[i].real_pre = (this.data_list[i].a_rebate_pre * 1 + this.data_list[i].occupy_pre * 1).toFixed(1);
      }

      this.autolet = [{
        result: ""
      }];
      var autolet = ""; // console.log(data);

      if (data.autolet != undefined) {
        autolet = data.autolet;
        autolet.result = autolet.let_balance + autolet.rebate_balance;
        this.autolet[0] = autolet;
      }

      this.pagination = data.pagination;

      if (this.data_list.length > 0) {
        this.total = data.total;
      } else {
        this.total = {
          a_rebate: 0,
          bet_price: 0,
          rebate: 0,
          win_price: 0,
          earn: 0,
          count: 0,
          occupy: 0,
          balance: 0,
          occupy_balance: 0,
          occupy_winlose: 0,
          occupy_rebate: 0,
          bonus: 0,
          occupy_pre: 0,
          a_rebate_pre: 0
        };
      }

      this.total.real_result = this.total.occupy * 1 + this.total.a_rebate * 1;
      this.total.real_pre = this.total.a_rebate_pre * 1 + this.total.occupy_pre * 1;
      this.dialog.visible = false;
    }
  }
};