import axios from '../../../axios/axios';
import caopanrizhi from "./caopanrizhi";
import { pcddNum } from "../../../plugin/pcddNum";
import datepicker from "../../homePublic/datepicker.vue";
export default {
  components: {
    caopanrizhi: caopanrizhi,
    datepicker: datepicker
  },
  data: function data() {
    return {
      date: "",
      award_data: [],
      page: 1,
      active_gid: '',
      game_list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: '奖期管理'
      },
      gs: {
        visible: false,
        list: [],
        checked: '',
        pagination: {
          total_pages: 0,
          total_records: "0",
          page_size: 10
        }
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("play/period_num", {
      page: 1,
      gid: '',
      start_time: '',
      end_time: '',
      db: to.query.db
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          // vm.game_list = vm.$store.state.game_list;
          // vm.active_gid = vm.game_list[0].id;
          vm.get_game_list(to.query.db);
          vm.award_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; //处理是pc蛋蛋的结果

          if (vm.active_gid == 22 || vm.active_gid == 23) {
            var list = result.data.repsoneContent.list;

            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }

            vm.award_data = list;
          }

          vm.gs.checked = to.query.db;
          vm.gs.list = vm.$store.state.gs.list;
        });
      }
    });
  },
  methods: {
    back_money: function back_money(fid, qnum) {
      var _this = this;

      this.$confirm("\u6CE8\u610F\uFF1A\u8FD4\u8FD8\u91D1\u989D\u540E\u5C06\u7528\u6237\u6CE8\u5355\u3001\u62A5\u8868\u5220\u9664\uFF0C\u5E76\u4E0D\u80FD\u6062\u590D\uFF01\u603B\u516C\u53F8\u8FDB\u884C\u6B64\u64CD\u4F5C\u65F6\uFF0C\u53EA\u5BF9\u662F\u6B64\u6E38\u620F\u662F\u73B0\u91D1\u989D\u5EA6\u7684\u76F4\u5C5E\u516C\u53F8\u6709\u6548\uFF01\u786E\u8BA4\u5BF9\u671F\u6570\u3010".concat(qnum, "\u3011\u8FD4\u8FD8\u91D1\u989D\u5417\uFF1F"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.axios.post('play/num', {
          fid: fid,
          db: _this.gs.checked
        }).then(function (result) {
          if (result.data.status == 200) {
            _this.$message.success(result.data.shortMessage);
          }
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    //功能：操盘日志
    get_caopan: function get_caopan(qunm) {
      var _this2 = this;

      this.dialog.visible = '操盘日志';
      this.$nextTick(function () {
        _this2.$refs.caopanrizhi.get_traderRecord_log(1, qunm, _this2.gs.checked);
      });
    },
    //切换游戏
    change_game: function change_game(gid) {
      // console.log(gid)
      this.active_gid = gid;
      this.get_award_data();
    },
    //分页
    change_page: function change_page(page) {
      this.page = page;
      this.get_award_data();
    },
    get_award_data: function get_award_data() {
      var _this3 = this;

      this.axios.post("play/period_num", {
        gid: this.active_gid,
        page: this.page,
        start_time: this.date.start,
        end_time: this.date.end,
        db: this.gs.checked || this.$route.query.db
      }).then(function (result) {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          _this3.award_data = result.data.repsoneContent.list;
          _this3.pagination = result.data.repsoneContent.pagination; //处理是pc蛋蛋的结果

          if (_this3.active_gid == 22 || _this3.active_gid == 23) {
            var list = result.data.repsoneContent.list;

            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }

            _this3.award_data = list; // console.log(list)
          }
        }
      });
    },
    get_game_list: function get_game_list(db) {
      var _this4 = this;

      this.axios.post("main/game_list", {
        db: db
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this4.game_list = result.data.repsoneContent.game_list;
          _this4.active_gid = _this4.game_list[0].id;

          _this4.get_award_data();
        }
      });
    },
    getDate: function getDate(date) {
      this.date = date;
      this.get_award_data();
    },
    change_gs: function change_gs() {
      this.get_game_list(this.gs.checked);
    }
  }
};