<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-08-14 16:34:05
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-08-14 16:55:05
 * @FilePath: \super_admin\src\components\self\views\xinaofeidanbaobiao.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="xinaofeidanbaobiao">
        <div class="panel">
            <div class="panel-title">
                <h2>新奥飞单报表</h2>
            </div>
            <div class="panel-body">
                <datepicker ref="datePicker" from="报表查询" @getDate="getDate" :showBtns="true" :btns="'今天,本周,上周'" :showpicker="false" />
                <el-table :data="list" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                    <el-table-column property="username" label="飞单用户" align="center"></el-table-column>
                    <el-table-column property="orderNum" label="飞单笔数" align="center"></el-table-column>
                    <el-table-column property="qnum" label="奖期" align="center"></el-table-column>
                    <el-table-column property="realBet" label="投注金额" align="center"></el-table-column>
                    <el-table-column property="amount" label="输赢" align="center"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import datepicker from "../../homePublic/datepicker.vue";
export default {
    components: { datepicker },
    data() {
        return {
            date: {},
            list: [],
        };
    },
    methods: {
        getdata() {
            this.axios.post("user/xinao_report", this.date).then((result) => {
                if (result.data.status == 200) {
                    // this.$message.success(result.data.shortMessage);
                    this.list = result.data.repsoneContent.list;
                }
            });
        },
        getDate(date, updata) {
            // console.log(date, updata);
            this.date = date;
            this.getdata();
        },
    },
};
</script>