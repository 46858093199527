import _typeof from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
export default {
  props: ["addinfo"],
  data: function data() {
    var _ref;

    return _ref = {
      sltuid: "",
      add_user_data: {
        group_id: "",
        level_id: ""
      },
      name: "",
      nickname: "",
      //昵称
      userKind_kc: [],
      //六合盘口多选
      kuaicai_userKind_kc: "",
      //快彩盘口单选
      pwd: "",
      userCredit_kc: "",
      //信用额度六合
      kuaicai_userCredit_kc: "",
      //信用额度快彩
      transfer_status: "0",
      //现金模式
      moneyinout_status: "1",
      //是否出入款
      is_inmoney: 0,
      //是否显示入款统计
      autolet_status: 0,
      trader_status: "1",
      //操盘
      trader_diff_user: 0,
      //是否显示赚取赔率差
      trader_diff: false,
      //赚取赔率差
      allowmaxrate_kc: "0",
      //限占1，任占0
      lowmaxrate_kc: "",
      //限占值
      play_list: [{
        old_occupy: ""
      }]
    }, _defineProperty(_ref, "trader_diff", {
      //赚取赔率差
      status: "1",
      diff: [0.0001, 1]
    }), _defineProperty(_ref, "posttime", ""), _defineProperty(_ref, "sup_occupy_fly", "1"), _defineProperty(_ref, "add_oc_url", "0"), _defineProperty(_ref, "agent_url", ""), _defineProperty(_ref, "note", ""), _defineProperty(_ref, "sup_occupy_lock", ""), _defineProperty(_ref, "is_extract", "1"), _defineProperty(_ref, "sup_occupy_catch", "1"), _defineProperty(_ref, "is_manlet", "1"), _defineProperty(_ref, "show_is_manlet", false), _defineProperty(_ref, "bill_reset", ""), _defineProperty(_ref, "kuaicai_bill_reset", ""), _defineProperty(_ref, "allGamesSet", {
      occupy: "",
      game_list_status: "0"
    }), _defineProperty(_ref, "copy_user_id", ""), _defineProperty(_ref, "rebate", {
      min_A: "5.0000",
      min_B: "4.0000",
      min_C: "3.0000",
      min_D: "2.0000"
    }), _defineProperty(_ref, "rebate_max", 0), _defineProperty(_ref, "auto_draw_back", {
      show: false,
      value: "0"
    }), _defineProperty(_ref, "kuaicai_rebate_max", 0), _defineProperty(_ref, "kuaicai_rebate", {
      kuaicai_min_A: "5.0000",
      kuaicai_min_B: "4.0000",
      kuaicai_min_C: "3.0000"
    }), _defineProperty(_ref, "kuaicai_auto_draw_back", {
      show: false,
      value: "0"
    }), _defineProperty(_ref, "kuaicai_sup_occupy_lock", ""), _defineProperty(_ref, "kuaicai_allowmaxrate_kc", "0"), _defineProperty(_ref, "kuaicai_lowmaxrate_kc", ""), _defineProperty(_ref, "auto_manlet_status", 0), _defineProperty(_ref, "intercept_proportion_status", "0"), _defineProperty(_ref, "remainder", "0"), _defineProperty(_ref, "kuaicai_remainder", "0"), _defineProperty(_ref, "status", "1"), _defineProperty(_ref, "user_play_status_list", []), _defineProperty(_ref, "err", {
      name: {
        visible: false,
        content: ""
      },
      nickname: {
        visible: false,
        content: ""
      },
      pwd: {
        visible: false,
        content: ""
      },
      sup_occupy_lock: {
        visible: false,
        content: ""
      },
      lowmaxrate_kc: {
        visible: false,
        content: ""
      },
      kuaicai_sup_occupy_lock: {
        visible: false,
        content: ""
      },
      kuaicai_lowmaxrate_kc: {
        visible: false,
        content: ""
      },
      userCredit_kc: {
        visible: false,
        content: ""
      },
      kuaicai_userCredit_kc: {
        visible: false,
        content: ""
      }
    }), _defineProperty(_ref, "lh_or_dpc", "六合"), _ref;
  },
  created: function created() {
    // console.log(this.addinfo.sub_list);
    this.sltuid = this.addinfo.sub_list[0].options[0].id; // this.get_user_edit(this.sltuid);
  },
  methods: {
    change_user_play_status_list: function change_user_play_status_list(list) {
      if (list.length < 1) {
        return this.$message.error("至少选择一个彩种");
      }

      if (list.length == 1 && list.includes("7") || list.length == 2 && list.includes("1") && list.includes("7")) {
        this.lh_or_dpc = "低频彩";
      } else {
        this.lh_or_dpc = "六合";

        if (this.add_user_data.level_id.length > 1) {
          this.userKind_kc = this.add_user_data.level_id.split(",");
        } else {
          this.userKind_kc = this.add_user_data.level_id == "0" ? ["1", "2", "3"] : [this.add_user_data.level_id.toString()];
        }
      }
    },

    /**切换盘口 */
    change_pk: function change_pk(list) {
      if (list.includes("0") && list[list.length - 1] != "0") {
        this.userKind_kc.shift();
      } else if (list[list.length - 1] == "0") {
        this.userKind_kc = ["0"];
      }
    },

    /**退水选择 */
    draw_back_change: function draw_back_change(type, value) {
      // console.log(value)
      if (type == "lh") {
        if (value == "auto") {
          this.auto_draw_back.show = true;
          this.auto_draw_back.value = "";
        } else {
          this.auto_draw_back.show = false;
        }
      } else {
        if (value == "auto") {
          this.kuaicai_auto_draw_back.show = true;
          this.kuaicai_auto_draw_back.value = "";
        } else {
          this.kuaicai_auto_draw_back.show = false;
        }
      }
    },
    change_rzxz_lhc: function change_rzxz_lhc(s) {
      this.lowmaxrate_kc = this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1;
    },
    change_rzxz_kc: function change_rzxz_kc(s) {
      this.kuaicai_lowmaxrate_kc = this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
    },
    //游戏占成快速设置
    fast_game_set: function fast_game_set(value) {
      var _iterator = _createForOfIteratorHelper(this.play_list),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          item.power_status = this.allGamesSet.power_status;
          item.occupy = value;

          if (item.old_occupy < value) {
            item.occupy = item.old_occupy;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    formatTooltip: function formatTooltip(value) {
      return (value / 200).toFixed(4);
    },
    proc_data: function proc_data(data) {
      this.add_user_data = data;
      this.trader_diff_user = this.add_user_data.trader_diff_user;
      this.is_extract = this.add_user_data.extract_bill.toString();
      this.add_user_data.D_odds *= 1;
      this.add_user_data.com_odds[0] *= 1;
      this.add_user_data.com_odds[1] *= 1;

      if (this.add_user_data.D_odds) {
        if (this.add_user_data.D_odds < this.add_user_data.com_odds[0]) {
          this.add_user_data.D_odds = this.add_user_data.com_odds[0];
        }

        this.add_user_data.D_odds *= 1;
        this.add_user_data.com_odds[0] *= 1;
        this.add_user_data.com_odds[1] *= 1;
      }

      this.play_list = this.add_user_data.play_list.filter(function (item) {
        return item.occupy_status == 1;
      }); // 所有六合彩打开才显示手补-------->

      var lhc = this.play_list.find(function (item) {
        return item.gname == "六合彩";
      });
      var amlhc = this.play_list.find(function (item) {
        return item.gname == "澳门六合彩";
      });

      if (lhc && lhc.occupy_status != undefined && lhc.occupy_status == 1 || amlhc && amlhc.occupy_status != undefined && amlhc.occupy_status == 1) {
        this.show_is_manlet = true;
      } else {
        this.show_is_manlet = false;
      } //------------------------------<


      if (this.add_user_data.add_oc_url == 1) {
        this.add_oc_url = this.add_user_data.add_oc_url;
      } //二级开始，一直往下，新增代理的时候，剩余占成默认开启


      if (this.add_user_data.group_id > 2) {
        this.sup_occupy_fly = "1";
      }

      this.sup_occupy_lock = 0;

      if (this.sup_occupy_lock > this.add_user_data.max_sup_lock * 1) {
        this.sup_occupy_lock = this.add_user_data.max_sup_lock * 1;
      } //快彩------------------------------>


      this.kuaicai_sup_occupy_lock = 0;

      if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
        this.kuaicai_sup_occupy_lock = this.add_user_data.kuaicai_max_sup_lock * 1;
      } //额度模式


      if (this.add_user_data.bill_reset == "1") {
        this.bill_reset = "0";
      } else {
        this.bill_reset = this.add_user_data.bill_reset;
      }

      if (this.add_user_data.kuaicai_bill_reset == "1") {
        this.kuaicai_bill_reset = "0";
      } else {
        this.kuaicai_bill_reset = this.add_user_data.kuaicai_bill_reset;
      } //彩种选择


      this.user_play_status_list = [];

      if (this.add_user_data.status_kuaicai == 1) {
        this.user_play_status_list.push("1"); //快彩盘口

        this.kuaicai_userKind_kc = this.add_user_data.kuaicai_level_id == "0" ? "1" : this.add_user_data.kuaicai_level_id.toString();
      } else {
        if (this.add_user_data.status_liuhe == 1) {
          this.user_play_status_list.push("2");
        }

        if (this.add_user_data.status_aomen == 1) {
          this.user_play_status_list.push("3");
        }

        if (this.add_user_data.status_xinaomen == 1) {
          this.user_play_status_list.push("4");
        }

        if (this.add_user_data.status_taiwan == 1) {
          this.user_play_status_list.push("5");
        }

        if (this.add_user_data.status_kuaile8 == 1) {
          this.user_play_status_list.push("6");
        }

        if (this.add_user_data.status_pailie5 == 1) {
          this.user_play_status_list.push("7");
        } //六合盘口


        if (this.add_user_data.level_id.length > 1) {
          this.userKind_kc = this.add_user_data.level_id.split(",");
        } else {
          this.userKind_kc = this.add_user_data.level_id == "0" ? ["1", "2", "3"] : [this.add_user_data.level_id.toString()];
        }
      }
    },

    /**获取用户新增菜单选项，权限 */
    get_user_edit: function get_user_edit(data) {
      var _this = this;

      // console.log(data);
      if (_typeof(data) == "object") {
        this.proc_data(data);
      } else {
        this.axios.post("user_version2/member_add", {
          sltuid: data,
          groupid_zs: "",
          db: this.$route.query.db
        }).then(function (result) {
          if (result.data.status === 200) {
            _this.proc_data(result.data.repsoneContent);
          }
        });
      }
    },

    /**提交修改 */
    post_change: function post_change() {
      var _this2 = this;

      if (this.name.length < 2) {
        return this.err.name = {
          visible: true,
          content: "账号最少2个字符"
        };
      }

      if (this.nickname.length < 1) {
        return this.err.nickname = {
          visible: true,
          content: "名称最少1个字符"
        };
      }

      if (!this.$filters.passwordReg(this.pwd)) {
        return this.err.pwd = {
          visible: true,
          content: "密码必须包含：大写字母、小写字母、数字，3种组合，8-20位"
        };
      }

      if (this.add_user_data.is_liuhe == 1) {
        if (this.sup_occupy_lock < 0 || this.sup_occupy_lock === "") {
          return this.err.sup_occupy_lock = {
            visible: true,
            content: "".concat(this.lh_or_dpc, "\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5C0F\u4E8E0")
          };
        }

        if (this.add_user_data.max_sup_lock != 0) {
          if (this.allowmaxrate_kc == 1 && this.lowmaxrate_kc < 0) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5C0F\u4E8E0")
            };
          }
        } else {
          this.lowmaxrate_kc = 0;
        }

        if (this.sup_occupy_lock * 1 > this.add_user_data.max_sup_lock * 1) {
          return this.err.sup_occupy_lock = {
            visible: true,
            content: "".concat(this.lh_or_dpc, "\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5927\u4E8E ").concat(this.add_user_data.max_sup_lock, "%")
          };
        }

        if (this.add_user_data.group_id > 3) {
          if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ").concat(this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1, "%")
            };
          }
        } else {
          if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ").concat(this.add_user_data.max_sup_lock, "%")
            };
          }
        }

        if (this.lowmaxrate_kc == "") {
          this.lowmaxrate_kc = this.add_user_data.group_id > 3 ? this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1 : this.add_user_data.max_sup_lock * 1;
        }
      } //快彩------------------------------>


      if (this.add_user_data.is_kuaicai == 1) {
        // if (this.kuaicai_auto_draw_back.value != 'all' && this.kuaicai_auto_draw_back.value > this.kuaicai_rebate_max) {
        //     return this.$message.error("赚取退水不得高于" + this.kuaicai_rebate_max);
        // }
        if (this.kuaicai_sup_occupy_lock < 0 || this.kuaicai_sup_occupy_lock === "") {
          return this.err.kuaicai_sup_occupy_lock = {
            visible: true,
            content: "快彩保留占成不能小于0"
          };
        }

        if (this.add_user_data.kuaicai_max_sup_lock * 1 != 0) {
          if (this.kuaicai_allowmaxrate_kc == 1 && this.kuaicai_lowmaxrate_kc < 0) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: "快彩限制下线可占成数不能小于0"
            };
          }
        } else {
          this.kuaicai_lowmaxrate_kc = 0;
        }

        if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
          return this.err.kuaicai_sup_occupy_lock = {
            visible: true,
            content: "\u5FEB\u5F69\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5927\u4E8E ".concat(this.add_user_data.kuaicai_max_sup_lock, "%")
          };
        }

        if (this.add_user_data.group_id > 3) {
          if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: "\u5FEB\u5F69\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ".concat(this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1, "%")
            };
          }
        } else {
          if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: "\u5FEB\u5F69\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ".concat(this.add_user_data.kuaicai_max_sup_lock, "%")
            };
          }
        }

        if (this.kuaicai_lowmaxrate_kc == "") {
          this.kuaicai_lowmaxrate_kc = this.add_user_data.group_id > 3 ? this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1 : this.add_user_data.kuaicai_max_sup_lock * 1;
        }
      }

      if (this.auto_draw_back.value > this.rebate_max) {
        return this.$message.error("退水不得高于" + this.rebate_max); // return this.$message.error("六合彩赚取退水不得高于" + this.rebate_max);
      }

      if (Number.isNaN(this.userCredit_kc * 1) || this.userCredit_kc < 0 || this.userCredit_kc > this.add_user_data.userCredit_kc * 1) {
        return this.err.userCredit_kc = {
          visible: true,
          content: "\u6E05\u8F93\u5165\u6B63\u786E\u7684".concat(this.lh_or_dpc, "\u989D\u5EA6")
        };
      }

      if (Number.isNaN(this.kuaicai_userCredit_kc * 1) || this.kuaicai_userCredit_kc < 0 || this.kuaicai_userCredit_kc > this.add_user_data.kuaicai_userCredit_kc * 1) {
        return this.err.kuaicai_userCredit_kc = {
          visible: true,
          content: "清输入正确的快彩额度"
        };
      }

      if ((this.user_play_status_list.includes("2") || this.user_play_status_list.includes("3") || this.user_play_status_list.includes("4") || this.user_play_status_list.includes("5") || this.user_play_status_list.includes("6") || this.user_play_status_list.includes("7")) && this.userKind_kc.length < 1) {
        return this.$message.error("至少选择一个盘口");
      }

      if (this.user_play_status_list.length == 0) {
        return this.$message.error("至少选择一个彩种");
      }

      var game_change_list = []; //生成每个游戏占成修改的数组数据

      for (var i = 0; i < this.play_list.length; i++) {
        game_change_list.push({
          id: this.play_list[i].id,
          power_status: this.play_list[i].power_status,
          // occupy_status: this.play_list[i].occupy_status,
          occupy_status: 1,
          // occupy: this.play_list[i].occupy == '' ? 'kong' : this.play_list[i].occupy,
          occupy: "kong",
          oid: this.play_list[i].oid,
          old_occupy: this.play_list[i].old_occupy
        });
      }

      var obj = {
        sltupuser: this.add_user_data.par_id,
        group_id: 0,
        agent_mark: this.add_user_data.agent_mark,
        userName: this.name,
        userNicker: this.nickname,
        userPassword: this.pwd,
        userKind_kc: this.userKind_kc.toString(),
        kuaicai_userKind_kc: this.kuaicai_userKind_kc.toString(),
        autolet_status: this.autolet_status,
        trader_status: Number(this.trader_status),
        transfer_status: this.transfer_status,
        moneyinout_status: this.moneyinout_status,
        is_inmoney: this.is_inmoney,
        userAllowSale_kc: Number(this.autolet_status),
        allowmaxrate_kc: this.allowmaxrate_kc,
        lowmaxrate_kc: this.lowmaxrate_kc,
        userzhan_kc: "1",
        game_change_list: game_change_list,
        sup_occupy_fly: this.sup_occupy_fly,
        add_oc_url: this.add_oc_url,
        agent_url: this.agent_url,
        note: this.note,
        // sup_occupy_value: this.sup_occupy_value,
        sup_occupy_catch: this.sup_occupy_catch,
        bill_reset: this.bill_reset,
        kuaicai_bill_reset: this.kuaicai_bill_reset,
        sup_occupy_lock: this.sup_occupy_lock,
        game_list_status: this.allGamesSet.game_list_status,
        auto_manlet_status: this.auto_manlet_status,
        intercept_proportion_status: this.intercept_proportion_status,
        sltDrawback_kc: this.auto_draw_back.value,
        max_sup_occupy_lock: this.add_user_data.max_sup_lock,
        //快彩-------------
        kuaicai_sltDrawback_kc: this.kuaicai_auto_draw_back.value,
        kuaicai_allowmaxrate_kc: this.kuaicai_allowmaxrate_kc,
        kuaicai_lowmaxrate_kc: this.kuaicai_lowmaxrate_kc,
        kuaicai_sup_occupy_lock: this.kuaicai_sup_occupy_lock,
        max_kuaicai_sup_occupy_lock: this.add_user_data.kuaicai_max_sup_lock,
        //占余成数归
        remainder: this.remainder,
        kuaicai_remainder: this.kuaicai_remainder,
        //彩种
        user_play_status: this.user_play_status_list.toString(),
        db: this.$route.query.db
      };

      if (this.add_user_data.group_id == 2) {
        obj.copy_user = this.add_user_data.copy_list.find(function (item) {
          return item.id == _this2.copy_user_id;
        }) || "";
      }

      if (this.trader_diff.status === "1") {
        obj.trader_diff = {
          status: "1",
          diff: this.trader_diff.diff.toString()
        };
      } else {
        obj.trader_diff = {
          status: "0",
          diff: ""
        };
      }

      if (this.posttime) {
        obj.posttime = this.posttime;
        obj.value_status = 1;
      }

      if (this.userKind_kc.includes("4") || this.userKind_kc.includes("0")) {
        obj.D_odds = this.add_user_data.D_odds;
        obj.sltDrawback_D = (this.add_user_data.max_rebate / (this.add_user_data.com_odds[1] - this.add_user_data.com_odds[0]) * (this.add_user_data.D_odds - this.add_user_data.com_odds[0])).toFixed(3);
      }

      if (this.add_user_data.extract_bill == 1) {
        obj.extract_bill = this.is_extract;
      }

      if (this.add_user_data.group_id > 1) {
        obj.is_manlet = this.is_manlet;
      }

      this.axios.post("user_version2/member_save", obj).then(function (result) {
        if (result.data.status == 200) {
          _this2.$message.success(result.data.shortMessage); //刷新列表


          _this2.finish_cancel();
        }
      });
    },
    finish_cancel: function finish_cancel() {
      this.$emit("other_view_back", "");
    }
  }
};