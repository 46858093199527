import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  data: function data() {
    return {
      name: "",
      level_three: "",
      // list: [],
      data: [],
      post_obj: {}
    };
  },
  methods: {
    get_list: function get_list(list, name) {
      var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";

      // console.log(list);
      if (obj != "") {
        this.post_obj = obj;
      }

      list.sort(function (a, b) {
        return a.profit_bill - b.profit_bill;
      });
      this.name = name;
      var data = [];

      if (name == "lm") {
        data = [{
          title: "三全中",
          list: []
        }, {
          title: "三中二",
          list: []
        }, {
          title: "二全中",
          list: []
        }, {
          title: "二中特",
          list: []
        }, {
          title: "特串",
          list: []
        }, {
          title: "四中一",
          list: []
        }];
      }

      if (name == "bz") {
        data = [{
          title: "五不中",
          list: []
        }, {
          title: "六不中",
          list: []
        }, {
          title: "七不中",
          list: []
        }, {
          title: "八不中",
          list: []
        }, {
          title: "九不中",
          list: []
        }, {
          title: "十不中",
          list: []
        }, {
          title: "十一不中",
          list: []
        }, {
          title: "十二不中",
          list: []
        }];
      }

      if (name == "lx") {
        data = [{
          title: "连肖二肖",
          list: []
        }, {
          title: "连肖三肖",
          list: []
        }, {
          title: "连肖四肖",
          list: []
        }, {
          title: "连肖五肖",
          list: []
        }];
      }

      if (name == "wl") {
        data = [{
          title: "2尾连",
          list: []
        }, {
          title: "3尾连",
          list: []
        }, {
          title: "4尾连",
          list: []
        }, {
          title: "5尾连",
          list: []
        }];
      }

      list.forEach(function (item) {
        data.forEach(function (element) {
          if (item.title == element.title) {
            element.list.push(item);
          }
        });
      });
      this.data = data;
    },
    get_data: function get_data() {
      var _this = this;

      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var three = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      this.post_obj.level_three = three;

      if (name == "bz") {
        this.post_obj.level_three = "";
        this.post_obj.level_four = three;
      }

      this.axios.post("userlet/getAllTraderLet", this.post_obj).then(function (result) {
        if (result.data.status == 200) {
          // this.paihang.list = result.data.repsoneContent.list;
          _this.get_list(result.data.repsoneContent.list, name);
        }
      });
    }
  }
};