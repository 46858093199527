import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.slice.js";
export default {
  props: ["game_info_data", "chang_long", "sum", "pl_value", "show_change_pl_btn", "is_manlet"],
  data: function data() {
    return {
      data_list: [{
        name: "口口口X",
        title: "口口口X",
        list: []
      }, {
        name: "口口X口",
        title: "口口X口",
        list: []
      }, {
        name: "口X口口",
        title: "口X口口",
        list: []
      }, {
        name: "X口口口",
        title: "X口口口",
        list: []
      }],
      title: "口口口X",
      data: [],
      cache_data: {
        play_odds: []
      },
      pl_popver: {
        show: false,
        row: {},
        pl_value: "",
        pl_change_value: "",
        top: "",
        left: ""
      },
      bh: {
        show: false,
        row: {},
        pl_value: "",
        bh_value: "",
        rate: "",
        top: "",
        left: ""
      },
      page_info: {
        page: 1,
        total: 4000,
        pager_count: 11
      },
      ballPopover: {
        show: false,
        row: {},
        top: "",
        left: ""
      }
    };
  },
  created: function created() {
    var _this = this;

    this.data_list.forEach(function (menu) {
      menu.list = _this.int_balls(menu.name);
    });
  },
  deactivated: function deactivated() {
    this.bh.show = false;
    this.pl_popver.show = false;
    this.ballPopover.show = false;
  },
  methods: {
    get_bhmx_data: function get_bhmx_data(row) {
      this.$emit("get_bhmx_data", row);
      this.ballPopover.show = false;
    },
    showBallPopover: function showBallPopover(row, event) {
      this.pl_popver.show = false;
      this.bh.show = false;
      this.ballPopover.show = false;
      this.ballPopover.row = row;
      this.$refs["ball_popover"].style.left = event.pageX + "px";
      this.$refs["ball_popover"].style.top = event.pageY + "px";
      this.ballPopover.show = true;
    },
    set_ball_ty: function set_ball_ty(ball) {
      var ballData = {
        ball: ball.ball,
        play_id: 10,
        level_two: ball.level_two,
        level_three: ball.level_three,
        level_four: ball.level_four,
        place: ball.place,
        is_lock: ball.is_lock == 1 ? 0 : 1
      };
      this.$emit("set_ball_ty", [ballData]);
    },
    sub_bh: function sub_bh() {
      this.$emit("sub_bh", this.bh);
    },
    show_bh: function show_bh(row, event) {
      this.pl_popver.show = false;
      this.bh.show = false;

      if (row.profit_bill < 0) {
        if (this.is_manlet == "1" || this.is_manlet == "0") {
          this.bh.row = row;
          this.bh.pl_value = row.pl;
          var width = document.body.clientWidth - event.clientX;

          if (width < 140) {
            this.$refs["bh_popver"].style.left = event.pageX - 140 + "px";
          } else {
            this.$refs["bh_popver"].style.left = event.pageX + "px";
          }

          this.$refs["bh_popver"].style.top = event.pageY + "px";
          this.bh.show = true;
          this.$refs["bhje_input"].focus();
        }
      }
    },
    int_balls: function int_balls() {
      var list = [];

      for (var i = 0; i < 100; i++) {
        list.push({
          ball: "",
          game_type: "",
          id: "",
          is_open: 1,
          name: "",
          pl: 1000,
          oc_bill: "-",
          profit_bill: "-"
        });
      }

      return list;
    },
    sub_change_pl: function sub_change_pl() {
      this.$emit("change_pl", this.pl_popver);
    },
    show_pl_popver: function show_pl_popver(row, event) {
      if (this.$store.state.userinfo.group_id >= 5 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      } // console.log(row, event);


      this.bh.show = false;
      this.pl_popver.show = false;

      if (event == "plus" || event == "minus") {
        this.pl_popver.row = row;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.pl = row.pl;
        this.pl_popver.pl_change_value = "";
        this.pl_popver.plusminus = event;
        this.$emit("change_pl", this.pl_popver);
      } else {
        this.pl_popver.row = row;
        this.pl_popver.value = row.pl;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.plusminus = "";
        this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
        var width = document.body.clientWidth - event.clientX;

        if (width < 150) {
          this.$refs["pl_popver"].style.left = event.pageX - 140 + "px";
        } else {
          this.$refs["pl_popver"].style.left = event.pageX + "px";
        }

        this.$refs["pl_popver"].style.top = event.pageY + "px";
        this.pl_popver.show = true;
      }
    },
    get_zdmx_data: function get_zdmx_data(row) {
      if (row.oc_bill != "-") {
        this.$emit("get_zdmx_data", row);
      }

      this.ballPopover.show = false;
    },
    change_page: function change_page(page) {
      this.page_info.page = page;
      this.proc(this.cache_data, page);
      this.ballPopover.show = false;
      this.bh.show = false;
      this.pl_popver.show = false;
    },
    proc: function proc() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.cache_data;
      var page = arguments.length > 1 ? arguments[1] : undefined;
      this.cache_data = JSON.parse(JSON.stringify(data));
      var list = data.play_odds; // 处理每个球的数据

      list.forEach(function (item, index) {
        item.forEach(function (ball) {
          ball.pl = ball.pl * 1;
          ball.oc_bill = ball.oc_bill == 0 ? "-" : ball.oc_bill * 1;
          ball.profit_bill = ball.profit_bill == 0 ? "-" : ball.profit_bill * 1;
        });
        item.sort(function (a, b) {
          return b.profit_bill == "-" ? 0 : a.profit_bill == "-" ? 0 : a.profit_bill - b.profit_bill;
        });
      }); // 使用传入的page参数或保持当前页码

      var currentPage = page || this.page_info.page;
      var pageSize = 100; // 修改为100条

      var startIndex = (currentPage - 1) * pageSize;
      var endIndex = startIndex + pageSize; // 为每个类型分配对应页的数据

      this.data_list.forEach(function (item, index) {
        var fullList = list[index] || [];
        item.list = fullList.slice(startIndex, endIndex);
      });
    }
  }
};