import "core-js/modules/es.json.stringify.js";
import axios from "../../../axios/axios";
export default {
  data: function data() {
    return {
      list: [],
      page: 1,
      active_gid: 0,
      game_list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      qnum: ""
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("trader/trader_record", {
      page: 1,
      gid: "0",
      qnum: ""
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.game_list = JSON.parse(JSON.stringify(vm.$store.state.game_list));
          vm.game_list.unshift({
            id: 0,
            gname: "全部"
          });
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },
  methods: {
    change_game: function change_game(gid) {
      this.active_gid = gid;
      this.get_traderRecord_log();
    },
    get_traderRecord_log: function get_traderRecord_log() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var qnum = arguments.length > 1 ? arguments[1] : undefined;
      var db = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";

      if (qnum == undefined && this.qnum == "") {
        qnum = "";
      }

      this.qnum = qnum;
      this.axios.post("trader/trader_record", {
        gid: this.active_gid,
        page: page,
        qnum: this.qnum,
        db: db
      }).then(function (result) {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          _this.list = result.data.repsoneContent.list;
          _this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }
  }
};