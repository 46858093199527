import _objectSpread from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
export default {
  data: function data() {
    return {
      ball_list: [],
      gid: "",
      pid: "",
      play_str: "",
      fast: {
        play_rate: "",
        low_rate: "",
        high_rate: "",
        b_rate: "",
        c_rate: "",
        added: "",
        lower: ""
      },
      obj: {},
      user_id: "",
      db: ""
    };
  },
  methods: {
    //重置
    reset: function reset() {
      this.get_ball_set("", this.obj);

      for (var key in this.fast) {
        this.fast[key] = "";
      }
    },
    //快速设置某一列
    fast_set_col: function fast_set_col() {
      for (var key in this.fast) {
        if (this.fast[key]) {
          for (var i = 0; i < this.ball_list.length; i++) {
            this.ball_list[i][key] = this.fast[key];
          }
        }
      }
    },
    //提交赔率设置
    sub_set: function sub_set() {
      var _this = this;

      var post_data = {
        oid: [],
        ball: [],
        place: [],
        low_rate: [],
        b_rate: [],
        c_rate: [],
        high_rate: [],
        high_b_rate: [],
        high_c_rate: [],
        added: [],
        lower: [],
        play_str: [],
        play_rate: [],
        db: this.db
      };

      if (this.user_id != "") {
        post_data.user_id = this.user_id;
      }

      var url = "play/auto_ball_rate_exe";

      if (this.gid) {
        post_data.gid = this.gid;
      } else {
        post_data.pid = this.pid;
      }

      var _iterator = _createForOfIteratorHelper(this.ball_list),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (Number.isNaN(item.play_rate * 1) || Number.isNaN(item.high_rate * 1) || Number.isNaN(item.b_rate * 1) || Number.isNaN(item.c_rate * 1) || Number.isNaN(item.lower * 1) || Number.isNaN(item.added * 1)) {
            this.$message({
              message: "请输入正确的数值！",
              type: "error",
              offset: 400
            });
            return;
          } else if (item.play_rate * 1 > item.high_rate * 1) {
            return this.$message.error(item.ball + "赔率设定不能超过上限");
          } else if (item.b_rate * 1 > item.high_rate * 1) {
            return this.$message.error(item.ball + "赔率设定不能超过上限");
          } else if (item.c_rate * 1 > item.high_rate * 1) {
            return this.$message.error(item.ball + "赔率设定不能超过上限");
          } else {
            post_data.oid.push(item.id);
            post_data.ball.push(item.ball);
            post_data.place.push(item.place);
            post_data.b_rate.push(item.b_rate);
            post_data.c_rate.push(item.c_rate);
            post_data.high_rate.push(item.high_rate);
            post_data.high_b_rate.push(item.high_rate);
            post_data.high_c_rate.push(item.high_rate);
            post_data.added.push(item.added);
            post_data.lower.push(item.lower);
            post_data.play_rate.push(item.play_rate);
            post_data.play_str.push(this.play_str);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (this.user_id != "") {
        post_data.user_id = this.user_id;
      }

      this.axios.post(url, post_data).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this.$message({
            message: result.data.shortMessage,
            type: "success",
            offset: 400
          });
        }
      });
    },
    get_ball_set: function get_ball_set() {
      var _this2 = this;

      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var obj = arguments.length > 2 ? arguments[2] : undefined;
      var user_id = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
      var db = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";
      console.log(row);
      this.db = db;
      this.obj = obj;
      this.play_str = obj.play_str;

      if (obj.gid) {
        this.gid = obj.gid;
        this.pid = "";
      } else {
        this.pid = obj.pid;
        this.gid = "";
      }

      if (user_id != "") {
        this.user_id = user_id;
        obj.user_id = user_id;
      } else {
        this.user_id = "";
      }

      if (data !== "") {
        this.ball_list = data;
      } else {
        this.axios.post("play/auto_ball_rate", _objectSpread({
          db: this.db
        }, obj)).then(function (result) {
          if (result.data.status == 200) {
            _this2.ball_list = result.data.repsoneContent.list;
          }
        });
      }
    }
  }
};