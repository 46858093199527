/*
 * @Author: your name
 * @Date: 2022-03-29 23:41:58
 * @LastEditTime: 2022-05-02 13:12:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\plugin\pcddNum.js
 */

/**处理pc蛋蛋的号码，包括加拿大28.幸运28，生成对应的颜色 */
export function pcddNum(num) {
  var arr = [// 绿
  {
    num: 1,
    color: "#67C23A"
  }, {
    num: 4,
    color: "#67C23A"
  }, {
    num: 7,
    color: "#67C23A"
  }, {
    num: 10,
    color: "#67C23A"
  }, {
    num: 16,
    color: "#67C23A"
  }, {
    num: 19,
    color: "#67C23A"
  }, {
    num: 22,
    color: "#67C23A"
  }, {
    num: 25,
    color: "#67C23A"
  }, // 蓝
  {
    num: 2,
    color: "#409EFF"
  }, {
    num: 5,
    color: "#409EFF"
  }, {
    num: 8,
    color: "#409EFF"
  }, {
    num: 11,
    color: "#409EFF"
  }, {
    num: 17,
    color: "#409EFF"
  }, {
    num: 20,
    color: "#409EFF"
  }, {
    num: 23,
    color: "#409EFF"
  }, {
    num: 26,
    color: "#409EFF"
  }, // 红
  {
    num: 3,
    color: "#F56C6C"
  }, {
    num: 6,
    color: "#F56C6C"
  }, {
    num: 9,
    color: "#F56C6C"
  }, {
    num: 12,
    color: "#F56C6C"
  }, {
    num: 15,
    color: "#F56C6C"
  }, {
    num: 18,
    color: "#F56C6C"
  }, {
    num: 21,
    color: "#F56C6C"
  }, {
    num: 24,
    color: "#F56C6C"
  }, // 灰
  {
    num: 0,
    color: "#909399"
  }, {
    num: 13,
    color: "#909399"
  }, {
    num: 14,
    color: "#909399"
  }, {
    num: 27,
    color: "#909399"
  }];

  for (var i = 0; i < arr.length; i++) {
    if (num == arr[i].num) {
      num = arr[i];
    }
  }

  arr = null;
  return num;
}