import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import axios from '../../../axios/axios';
export default {
  data: function data() {
    return {
      set_data: [],
      active_gid: '',
      game_list: [],
      game_group: [],

      /**彩种分组 */
      play_param: "",
      type: "",
      //当前模式，单个/分类
      active_pid: "",
      //当前激活的分类pid
      get_url: "",
      fast: {
        // bet_lock: "",
        straightbet_low: "",
        straightbet_high: "",
        single_high: "",
        // bonus: "",
        relax: ""
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("play/class_game_lock", {
      pid: 1
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.game_list = vm.$store.state.game_list;
          var data = result.data.repsoneContent.list;
          vm.set_data = [];
          vm.type = "list";

          for (var item in data) {
            vm.set_data.push(data[item]);
          }

          var game_group = result.data.repsoneContent.play_arr;

          for (var key in game_group) {
            game_group[key].pid = key;
            vm.game_group.push(game_group[key]);
          }

          vm.active_pid = vm.game_group[0].pid;
        });
      }
    });
  },
  methods: {
    //切换分类
    change_group: function change_group(pid) {
      this.type = "list";
      this.get_game_set_data(this.type, pid);
    },
    //切换彩种
    change_game: function change_game(gid) {
      this.type = "one";
      this.get_game_set_data(this.type, gid);
    },
    //提交保存设置
    sub_set: function sub_set() {
      var _this = this;

      var post_data = "";
      var url = "";

      if (this.type === "one") {
        post_data = {
          gid: this.active_gid,
          play_arr_str: [],
          bet_lock: [],
          straightbet_low: [],
          straightbet_high: [],
          single_high: [],
          bonus: [],
          relax: []
        };
        url = "/play/game_lock_exe";
      } else {
        post_data = {
          pid: this.active_pid,
          play_arr_str: [],
          bet_lock: [],
          straightbet_low: [],
          straightbet_high: [],
          single_high: [],
          bonus: [],
          relax: []
        };
        url = "/play/class_game_lock_exe";
      }

      var _iterator = _createForOfIteratorHelper(this.set_data),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (Number.isNaN(item.bet_lock * 1) || Number.isNaN(item.straightbet_low * 1) || Number.isNaN(item.straightbet_high * 1) || Number.isNaN(item.single_high * 1) || Number.isNaN(item.bonus * 1) || Number.isNaN(item.relax * 1)) {
            return this.$message.error('请输入正确的数值！');
          } else {
            post_data.play_arr_str.push(item.play_str);
            post_data.bet_lock.push(item.bet_lock);
            post_data.straightbet_low.push(item.straightbet_low);
            post_data.straightbet_high.push(item.straightbet_high);
            post_data.single_high.push(item.single_high);
            post_data.bonus.push(item.bonus);
            post_data.relax.push(item.relax);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.axios.post(url, post_data).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this.$message.success(result.data.shortMessage);

          if (_this.type === "one") {
            _this.get_game_set_data("one", 1);
          } else {
            _this.get_game_set_data("list", 1);
          }
        }
      });
    },
    //快速设置某一列
    fast_set_col: function fast_set_col() {
      for (var key in this.fast) {
        if (this.fast[key]) {
          for (var i = 0; i < this.set_data.length; i++) {
            this.set_data[i][key] = this.fast[key];
          }
        }
      }
    },
    //重置
    reset: function reset() {
      this.active_gid = 1;
      this.get_game_set_data("one", 1);

      for (var key in this.fast) {
        this.fast[key] = "";
      }
    },
    //获取设置数据
    get_game_set_data: function get_game_set_data(type, id) {
      var _this2 = this;

      this.type = type;
      var obj = {};

      if (type === "one") {
        type = "gid";
        this.active_gid = id;
        this.active_pid = '';
        this.get_url = "/play/game_lock";
        obj = {
          gid: id
        };
      } else {
        type = "pid";
        this.active_gid = ""; // console.log(pid);

        this.active_pid = id;
        this.get_url = "/play/class_game_lock";
        obj = {
          pid: id
        };
      }

      this.axios.post(this.get_url, obj).then(function (result) {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          var data = result.data.repsoneContent.list;
          _this2.set_data = [];

          for (var item in data) {
            _this2.set_data.push(data[item]);
          }

          var game_group = result.data.repsoneContent.play_arr;

          if (game_group.length != _this2.game_group.length) {
            _this2.game_group = [];

            for (var key in game_group) {
              game_group[key].pid = key;

              _this2.game_group.push(game_group[key]);
            }
          }
        }
      });
    },
    get_game_list: function get_game_list() {
      var _this3 = this;

      this.axios.post("main/game_list" + this.$store.state.url_query_info).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this3.game_list = result.data.repsoneContent.game_list;
        }
      });
    }
  }
};