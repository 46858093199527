import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import paihang from "./paihang.vue";
export default {
  components: {
    paihang: paihang
  },
  props: ["game_info_data", "paihang_post_obj"],
  data: function data() {
    return {
      pl_value: 0.005,
      update_time: 10000,
      xuzhu_shizhan: "1",
      pk: "0",
      updeta_data_btn: "",
      timer: "",
      countDown_h: "--",
      countDown_m: "--",
      countDown_s: "--",
      timerF: "",
      time_text: "距离封盘",
      paihang: {
        show: false,
        list: []
      }
    };
  },
  created: function created() {
    this.clear_timer();
    window.clearInterval(this.timerF);
    this.pk = this.$store.state.instant_pk;
  },
  beforeUnmount: function beforeUnmount() {
    this.clear_timer();
    window.clearInterval(this.timerF);
  },
  methods: {
    get_all_ph: function get_all_ph(name) {
      var _this = this;

      this.$emit("get_paihang_post_obj"); // console.log(this.paihang_post_obj);

      var obj = this.paihang_post_obj;
      var level_two = ""; // let level_three = "";

      if (name == "lm") {
        level_two = "5"; // level_three = "0";
      }

      if (name == "bz") {
        level_two = "8";
      }

      if (name == "lx") {
        level_two = "9";
      }

      if (name == "wl") {
        level_two = "12";
      }

      obj.level_two = level_two; // obj.level_three = level_three;
      // console.log(obj);

      this.axios.post("userlet/getAllTraderLet", obj).then(function (result) {
        if (result.data.status == 200) {
          // this.paihang.list = result.data.repsoneContent.list;
          _this.paihang.show = true;

          _this.$nextTick(function () {
            _this.$refs["paihang"].get_list(result.data.repsoneContent.list, name, obj);
          });
        }
      });
    },
    updeta_pl_value: function updeta_pl_value(value) {
      this.$emit("updeta_pl_value", value);
    },
    change_xuzhu_shizhan: function change_xuzhu_shizhan(value) {
      this.$emit("change_xuzhu_shizhan", value);
    },
    change_pk: function change_pk(pk) {
      // console.log(pk);
      this.$emit("change_pk", pk);
    },
    time_down: function time_down(time) {
      if (time == "00:00:00") {
        this.time_text = "已封盘";
      } else {
        this.time_text = "距离封盘";
      }

      window.clearInterval(this.timerF);
      var str = time;
      var arr = str.split(":");
      var seconds = arr[0] * 3600 + arr[1] * 60 + arr[2] * 1;
      var self = this;
      var t = seconds;
      this.timerF = setInterval(function () {
        t -= 1;

        if (t >= 0) {
          var hour = Math.floor(t / 3600 % 24);
          var min = Math.floor(t / 60 % 60);
          var sec = Math.floor(t % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          self.countDown_h = hour;
          self.countDown_m = min;
          self.countDown_s = sec; // console.log("距離封盤--" + hour + ":" + min + " " + sec)
        } else {
          window.clearInterval(this.timerF); // self.updeta_data('now');
        }
      }, 1000);
    },
    updeta_data_now: function updeta_data_now() {
      this.updeta_data("now");
    },
    clear_timer: function clear_timer() {
      window.clearInterval(this.timer);
    },
    updeta_data: function updeta_data() {
      var _this2 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.clear_timer();
      this.updeta_data_btn = "";
      this.updeta_data_btn = this.update_time / 1000;
      var that = this;

      if (type == "now") {
        that.$emit("get_game_info", that.pk);
      } else {
        this.timer = setInterval(function () {
          that.updeta_data_btn--;

          if (that.updeta_data_btn == 0) {
            _this2.clear_timer();

            that.updeta_data_btn = "";
            that.$emit("get_game_info", that.pk);
          }
        }, 1000);
      }
    },
    clear_all_timer: function clear_all_timer() {
      window.clearInterval(this.timer);
      window.clearInterval(this.timerF);
    }
  }
};