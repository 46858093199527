export default {
  data: function data() {
    return {
      notice_list: [],
      //弹窗公告
      dialog: {
        visible: false,
        title: "站内公告",
        list: []
      },
      balance: "",
      now_users_manager: "",
      now_users_member: "",
      user_bet_count: "",
      color: "",
      predefineColors: ["#409EFF", "#67C23A", "#E6A23C", "#F56C6C", "#606266", "#508f02", "#7a64b2", "#8d5f4f"],
      sid: "",
      last_type: "",
      online_status: 0
    };
  },
  created: function created() {
    this.get_balance();
    this.color = this.$store.state.userinfo.color;
    this.change_color(this.color); // this.get_new_notice();
    // this.get_tc_notice();

    this.do_list(); // this.sid = window.setInterval(() => {
    //     this.get_balance();
    //     this.do_list();
    // }, 10000)
  },
  unmounted: function unmounted() {
    window.clearInterval(this.sid);
  },
  methods: {
    go_tongji: function go_tongji(type) {
      this.$router.push({
        path: "/home/zaixiantongji",
        name: "zaixiantongji",
        query: {
          type: type,
          db: this.$store.state.gs.checked
        }
      });
      this.last_type = type;
    },
    go_zijinrizhi: function go_zijinrizhi() {
      this.$router.push("/home/zijinrizhi");
    },
    change_color: function change_color(color) {
      // console.log(color);
      if (color == null) {
        return;
      }

      this.$store.state.userinfo.color = color;
      this.$emit("change_color", color);
    },

    /**获取站内弹窗公告 */
    get_tc_notice: function get_tc_notice() {
      var _this = this;

      this.axios.post("party/notice", {
        page: 1,
        s_type: 2,
        limit: 1
      }).then(function (result) {
        // console.log(result.data);
        if (result.data.status === 200) {
          _this.dialog.list = result.data.repsoneContent.list;

          if (_this.dialog.list.length > 0) {
            if (_this.$store.state.notice_hasshow) {} else {}

            _this.dialog.visible = true;
            _this.$store.state.notice_hasshow = true;
          }
        }
      });
    },
    get_new_notice: function get_new_notice() {
      var _this2 = this;

      this.axios.post("party/notice", {
        page: 1,
        s_type: 1
      }).then(function (result) {
        // console.log(result.data)
        if (result.data.status == 200 && result.data.shortMessage == "获取成功") {
          _this2.notice_list = result.data.repsoneContent.list;
        }
      });
    },
    get_balance: function get_balance() {
      var _this3 = this;

      this.axios.post("account/get_balance?" + this.$store.state.url_query_info).then(function (result) {
        if (result.data.status === 200) {
          // console.log(this.$store.state.userinfo)
          _this3.is_kuaicai = result.data.repsoneContent.is_kuaicai;
          _this3.is_liuhe = result.data.repsoneContent.is_liuhe;
          _this3.balance = result.data.repsoneContent.balance;
          _this3.kuaicai_balance = result.data.repsoneContent.kuaicai_balance;
          _this3.$store.state.userinfo.balance = result.data.repsoneContent.balance;
          _this3.$store.state.userinfo.kuaicai_balance = result.data.repsoneContent.kuaicai_balance;
          _this3.now_users_manager = result.data.repsoneContent.now_users_manager;
          _this3.now_users_member = result.data.repsoneContent.now_users_member;
          _this3.user_bet_count = result.data.repsoneContent.user_bet_count;
          _this3.online_status = result.data.repsoneContent.online_status;
        }
      });
    },
    do_list: function do_list() {
      this.axios.post("account/do_list").then(function (result) {
        // console.log(result.data)
        if (result.data.status == 200 && result.data.shortMessage == "获取成功") {}
      });
    }
  }
};