import axios from '../../../axios/axios';
export default {
  components: {},
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post('user_version2/member_list', {
      type: 3,
      page: 1,
      //页码
      userState: 1,
      //状态
      sortName: "0",
      //排序类型：时间0 账号1
      userFlag: "0",
      //搜索类型：账号0 昵称1
      sortType: "0",
      //排序顺序：降0 升1
      zstype: "",
      //所属会员，zgs直属会员，fgs普通会员
      searchkey: "",
      //搜索时输入的内容
      limit: 30,
      uid: "",
      start_time: "",
      end_time: "",
      zc_time: "",
      filter: [{
        value: 1,
        label: "启用"
      }, {
        value: 3,
        label: "全部"
      }, {
        value: 2,
        label: "冻结"
      }, {
        value: 0,
        label: "停用"
      }]
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.users = result.data.repsoneContent.list;
        });
      }
    });
  },
  data: function data() {
    return {
      post_agency: {
        type: 3,
        page: 1,
        //页码
        userState: 1,
        //状态
        sortName: "0",
        //排序类型：时间0 账号1
        userFlag: "0",
        //搜索类型：账号0 昵称1
        sortType: "0",
        //排序顺序：降0 升1
        zstype: "",
        //所属会员，zgs直属会员，fgs普通会员
        searchkey: "",
        //搜索时输入的内容
        limit: 30,
        uid: "",
        start_time: "",
        end_time: "",
        zc_time: "",
        filter: [{
          value: 1,
          label: "启用"
        }, {
          value: 3,
          label: "全部"
        }, {
          value: 2,
          label: "冻结"
        }, {
          value: 0,
          label: "停用"
        }]
      },
      group: [{
        name: '总监',
        type: 3
      }, {
        name: '分公司',
        type: 4
      }, {
        name: '大股东',
        type: 5
      }, {
        name: '股东',
        type: 6
      }, {
        name: '总代理',
        type: 7
      }, {
        name: '代理',
        type: 8
      }, {
        name: '会员',
        type: 0
      }],
      users: [],
      pagination: {
        total_records: 0,
        limit: 30,
        page: 1
      }
    };
  },
  created: function created() {},
  methods: {
    change_group: function change_group(type) {
      this.post_agency.type = type;
      this.post_agency.page = 1;
      this.get_users();
    },
    change_page: function change_page(page) {
      this.post_agency.page = page;
      this.get_users();
    },
    get_users: function get_users() {
      var _this = this;

      this.axios.post('user_version2/member_list', this.post_agency).then(function (result) {
        if (result.data.status == 200) {
          _this.users = result.data.repsoneContent.list;
          _this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }
  }
};