<!--
 * @Author: your name
 * @Date: 2022-03-13 23:15:27
 * @LastEditTime: 2024-08-14 16:36:19
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\report\report.vue
-->
<template>
    <div class="report">
        <div class="report-form" v-show="!dialog.visible" :style="{ width: width }">
            <div class="panel">
                <div class="panel-title">
                    <h2>报表查询</h2>
                </div>
                <div class="panel-body">
                    <div class="xunitable">
                        <!-- <el-table :data="xunitabledata" border small highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                            <el-table-column prop="label" align="right" label></el-table-column>
                            <el-table-column prop="a" align="left" label></el-table-column>
                            <el-table-column prop="b" align="left" label></el-table-column>
                            <el-table-column prop="c" align="left" label></el-table-column>
                        </el-table>-->
                        <el-table ref="tableRef" :data="xunitabledata" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" show-summary>
                            <el-table-column prop="username" align="center" label="级别" min-width="60px"></el-table-column>
                            <el-table-column prop="real_name" label="名称" align="center"></el-table-column>
                            <el-table-column prop="bet_count" label="笔数" align="center"></el-table-column>
                            <el-table-column prop="sum_bet" label="总投注额" align="center"></el-table-column>
                            <el-table-column prop="balance" label="有效金额" align="center"></el-table-column>
                            <el-table-column prop="hy_shuyin" label="会员输赢" align="center"></el-table-column>
                            <el-table-column prop="receive_subline" label="应收下线" align="center"></el-table-column>
                            <el-table-column prop="sup_occupy_lock" label="实占成数" align="center" width="60"></el-table-column>
                            <el-table-column prop="occu_balance" label="实占注额" align="center"></el-table-column>
                            <el-table-column prop="bonus" label="占货比" align="center" width="47"></el-table-column>
                            <el-table-column prop="occu_winlose" label="实占输赢" align="center"></el-table-column>
                            <el-table-column prop="occu_rebate" label="实占退水" align="center"></el-table-column>
                            <el-table-column prop="real_occupy_result" label="实占结果" align="center"></el-table-column>
                            <el-table-column prop="earn_rebate" label="赚取退水" align="center"></el-table-column>
                            <el-table-column v-if="$store.state.userinfo.group_id == 1" prop="eran" label="赚取赔差" align="center" width="60"></el-table-column>
                            <el-table-column prop="real_result" label="退水后结果" align="center"></el-table-column>
                            <el-table-column prop="cont_sup" label="贡献上级" align="center"></el-table-column>
                            <el-table-column prop="receive_supline" label="应付上级" align="center"></el-table-column>
                        </el-table>
                    </div>
                    <div class="myform" v-show="!dialog.visible">
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">下注类型：</el-col>
                            <el-col class="myform-item-value" :span="20">
                                <el-select v-model="game_class_checked" size="small" style="width: 140px" @change="select_class">
                                    <el-option v-for="item of game_class" :key="item.name" :label="item.label" :value="item.name" />
                                </el-select>
                                <span v-show="game_class_checked == 'kc'">
                                    <el-select v-model="form.gid" size="small" style="width: 140px; margin: 0 6px" @change="select_one_game">
                                        <el-option v-for="item of kc_game_list" :key="item.id" :label="item.gname" :value="item.id" />
                                    </el-select>
                                </span>
                                <span v-show="game_class_checked == 'lhc'">
                                    <el-select v-model="form.gid" size="small" style="width: 140px; margin: 0 6px" @change="select_one_game">
                                        <el-option v-for="item of lhc_game_list" :key="item.id" :label="item.gname" :value="item.id" />
                                    </el-select>
                                </span>
                                <span v-show="form.game_play != ''">
                                    <el-select v-model="form.game_play" size="small" style="width: 140px" @change="select_game_play">
                                        <el-option v-for="item of game_play_list" :key="item.name" :label="item.name" :value="item.name" />
                                    </el-select>
                                </span>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">用户名：</el-col>
                            <el-col class="myform-item-value" :span="20">
                                <el-input v-model="form.username" placeholder="查找用户名" clearable size="small" style="width: 140px" @change="get_report_wait" />
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">
                                <el-radio v-model="date_or_qnum" label="按期数：" @change="select_qnum"></el-radio>
                            </el-col>
                            <el-col class="myform-item-value" :span="20">
                                <el-select v-model="form.qid" size="small" style="width: 200px" @change="select_game_play" placeholder="--- 请选择 ---">
                                    <el-option v-for="item of qnum_list" :key="item.gid" :label="item.gname + '-' + item.qnum" :value="item.qnum" />
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">
                                <el-radio v-model="date_or_qnum" label="按日期：" @change="select_qnum"></el-radio>
                            </el-col>
                            <el-col class="myform-item-value" :span="20" style="padding: 3px">
                                <datepicker ref="datePicker" from="报表查询" @getDate="getDate" :showBtns="true" :onlyUpdate="onlyUpdate" />
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">报表类型：</el-col>
                            <el-col class="myform-item-value" :span="20">
                                <el-radio-group v-model="form.report_type" @change="get_report_wait">
                                    <el-radio label="交收报表">交收报表</el-radio>
                                    <el-radio label="分类报表">分类报表</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="4">结算状态：</el-col>
                            <el-col class="myform-item-value" :span="20">
                                <el-radio-group v-model="form.settle_state" @change="get_report_wait">
                                    <el-radio :label="3">已结算</el-radio>
                                    <el-radio :label="2">未结算</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="sub" v-show="!dialog.visible">
                    <el-button size="small" type="warning" @click="get_report_btn_click">{{ btntext }}</el-button>
                    <el-button v-show="$store.state.userinfo.user_id == 1" size="small" type="primary" @click="send_report">发送报表</el-button>
                </div>
            </div>
        </div>
        <!-- 报表数据 -->
        <div v-show="dialog.visible">
            <jiaoshou
                v-show="form.report_type == '交收报表'"
                ref="jiaoshou"
                :bbdata="bbdata"
                :search_title_info="search_title_info"
                :form="form"
                :group_id="group_id"
                :show_waibu="show_waibu"
                :bh_cs="game_detail_total.post"
                :game_list="game_list"
                @change_page="change_page_js"
                @search_username="get_report"
                @search_sub="search_sub"
                @get_game_detail="get_game_detail"
                @back_search="dialog_close"
            />
            <fenlei v-show="form.report_type == '分类报表'" ref="fenlei" :bbdata="bbdata" @change_page="change_page_fl" :form="form" :search_title_info="search_title_info" @back_search="dialog_close" @search_paly="search_paly" />
        </div>
    </div>
</template>
<script>
import jiaoshou from "./jiaoshou.vue";
import fenlei from "./fenlei.vue";
import datepicker from "../homePublic/datepicker.vue";
export default {
    components: { jiaoshou, fenlei, datepicker },
    data() {
        return {
            xunitabledata: [
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
                {
                    username: "",
                    real_name: "",
                    bet_count: "",
                    sum_bet: "",
                    balance: "",
                    hy_shuyin: "",
                    receive_subline: "",
                    sup_occupy_lock: "",
                    occu_balance: "",
                    bonus: "",
                    occu_winlose: "",
                    occu_rebate: "",
                    real_occupy_result: "",
                    earn_rebate: "",
                    eran: "",
                    real_result: "",
                    cont_sup: "",
                    receive_supline: "",
                },
            ],
            width: "630px",
            loading: true,
            dialog: {
                visible: false,
                title: "交收报表",
            },
            date_or_qnum: "按日期：",
            check_game_list: [], //选中的彩种
            checked_all: true, //是否选择全部彩种
            form: {
                report_type: "交收报表",
                settle_state: 3,
                gid: 0,
                gids: [],
                game_play: "",
                qid: "",
                date: { start: "", end: "" },
                uid: "",
                username: "",
            },
            game_class_checked: "all", //选中的类别
            game_class: [
                //全部类别
                { name: "all", label: "--- 所有 ---" },
                { name: "kc", label: "快彩" },
                { name: "lhc", label: "六合彩" },
            ],
            kc_game_list: [], //快彩列表
            lhc_game_list: [], //六合彩列表
            game_play_list_checked: "",
            game_play_list: [], //玩法
            game_list: [], //用户的彩种
            qnum_list: [], //期号
            js_page: 1,
            fl_page: 1,
            onlyUpdate: true,

            game_detail_total: {
                //明细账
                post: {}, //明细post参数
            },
            group_id: "",
            show_waibu: false,
            btntext: "查询报表",
            search_title_info: "",

            bbdata: {},
            bbdta_ready: false,
            source: null, //存放取消的请求方法
            db: "",
        };
    },
    computed: {
        listenint_router_view() {
            return this.$store.state.int_router_view;
        },
    },
    watch: {
        listenint_router_view(newVal, oldVal) {
            console.log(newVal, oldVal);
            // 此处处理逻辑
            this.dialog_close();
            this.$store.state.int_router_view = false;
            // if (newVal) {
            // }
            this.int();
        },
    },
    created() {
        this.get_game_list();
    },
    methods: {
        cancelQuest() {
            if (typeof this.source === "function") {
                this.source("终止请求", "终止请求"); //取消请求
            }
        },
        int() {
            this.dialog.visible = false;
            this.bbdta_ready = false;
            this.$refs.jiaoshou.dialog.visible = "报表";
            this.$refs.jiaoshou.game_detail_data.open = false;
            this.$refs.jiaoshou.back_list = [];
            this.db = "";
        },
        send_report() {
            this.axios.post("operate/send_report", this.qs.stringify(this.$refs.datePicker.getShowTimeValue())).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                }
            });
        },
        //选择类别
        select_class(name) {
            if (name == "all") {
                this.checked_all = true;
                this.check_game_list = this.game_list.map((item) => item.id);
            }
            if (name == "kc") {
                this.checked_all = false;
                this.check_game_list = [];
                this.kc_game_list.map((item) => this.check_game_list.push(item.id));
            }
            if (name == "lhc") {
                this.checked_all = false;
                this.check_game_list = [];
                this.lhc_game_list.map((item) => this.check_game_list.push(item.id));
            }
            this.form.qid = "";
            this.form.gids = this.check_game_list;
            this.form.gid = 0;
            this.form.game_play = "";
        },
        //选择单个彩种
        select_one_game(gid) {
            this.checked_all = false;
            this.form.gid = gid;
            this.form.qid = "";
            this.check_game_list = [gid];
            this.form.gids = [gid];
            this.get_game_play_list(gid);
            this.get_report();
            if (this.game_class_checked == "kc") {
                this.form.gname = this.kc_game_list.find((item) => item.id == gid).gname;
            } else {
                this.form.gname = this.lhc_game_list.find((item) => item.id == gid).gname;
            }
        },
        //选择玩法
        select_game_play() {},
        dialog_close() {
            this.js_page = 1;
            this.fl_page = 1;
            this.form.uid = "";
            this.form.report_type = "交收报表";
            this.form.settle_state = 3;
            this.form.username = "";
            this.dialog.visible = false;
            this.width = "630px";
            // this.change_game_all(true);
            this.$nextTick(() => {
                this.$refs["datePicker"].setDate("今天");
            });
        },
        //分类代理点击
        search_sub(id, db = "") {
            this.db = db;
            this.form.uid = id;
            this.form.username = "";
            this.js_page = 1;
            this.get_report().then(() => {
                this.get_report_btn_click();
            });
        },
        //分类分页
        change_page_fl(page) {
            this.fl_page = page;
            this.get_report().then(() => {
                this.get_report_btn_click();
            });
        },
        //交收分页
        change_page_js(page) {
            this.js_page = page;
            this.get_report().then(() => {
                this.get_report_btn_click();
            });
        },
        //分类根据玩法查询
        search_paly(name) {
            this.form.game_play = name;
            this.get_report();
        },
        get_report() {
            const _this = this;
            this.cancelQuest(); //在请求发出前取消上一次未完成的请求；
            return new Promise((resolve, reject) => {
                if (this.form.date.start === "" && this.form.date.end === "") {
                    this.form.date = this.$refs["datePicker"].getShowTimeValue();
                }
                let arr = [];
                if (this.checked_all) {
                    this.game_list.forEach((item) => arr.push(item.id));
                    this.form.gids = arr;
                } else {
                    arr = this.form.gids;
                }
                if ((this.search_title_info = "按日期：")) {
                    this.search_title_info = `按日期查询：${this.form.date.start.split(" ")[0]} 至 ${this.form.date.end.split(" ")[0]}`;
                } else {
                    this.search_title_info = `按期数查询：${
                        this.game_list.find((item) => {
                            item.gid == form.gid;
                        }).gname
                    }--${this.form.qid}`;
                }
                if (this.form.report_type == "交收报表") {
                    // console.log(this.form.qid);
                    this.dialog.title = "交收报表";
                    this.loading = true;
                    let post = {
                        game_list: this.game_list,
                        gid: arr,
                        BeginDate: this.form.date.start,
                        EndDate: this.form.date.end,
                        method_name: this.form.game_play,
                        t_status: this.form.settle_state,
                        u_id: this.form.uid,
                        page: this.js_page,
                        limit: 40,
                        period: this.form.qid,
                        username: this.form.username,
                        // is_select: this.checked_all ? 1 : 0,
                        is_select: this.game_list.length < this.$store.state.game_list.length ? 0 : 1,
                        db: this.db,
                    };
                    this.game_detail_total.post = JSON.parse(JSON.stringify(post));
                    this.axios
                        .post("operate/ReportList_B_kc", post, {
                            cancelToken: new this.axios.CancelToken(function executor(c) {
                                _this.source = c;
                            }),
                        })
                        .then((result) => {
                            if (result.data.status == 200) {
                                // console.log(result.data.repsoneContent)
                                this.loading = false;
                                if (this.form.username != "") {
                                    this.show_waibu = false;
                                } else if (this.form.username == "" && result.data.repsoneContent.bet.length > 0 && result.data.repsoneContent.bet[0].group_id == 3) {
                                    this.show_waibu = true;
                                } else {
                                    this.show_waibu = false;
                                }
                                // console.log(this.form.date);
                                this.bbdata = result.data.repsoneContent;
                                this.bbdta_ready = true;
                                // this.dialog.visible = true;
                                // this.btntext = '查询报表';
                                // this.$nextTick(() => {
                                //     this.$refs.jiaoshou.proc_data(result.data.repsoneContent);
                                // });
                                // this.width = '100%';
                                if (this.bbdta_ready && this.dialog.visible) {
                                    this.$nextTick(() => {
                                        this.$refs.jiaoshou.proc_data(result.data.repsoneContent);
                                    });
                                }
                            }
                            if (result.data.shortMessage == "系统结算中，请稍后") {
                                window.setTimeout(() => {
                                    this.btntext = "查询报表";
                                }, 2000);
                            }
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error.message);
                        });
                }
                if (this.form.report_type == "分类报表") {
                    this.dialog.title = "分类报表";
                    this.loading = true;
                    this.axios
                        .post(
                            "operate/ReportList_T_kc",
                            {
                                gid: arr,
                                BeginDate: this.form.date.start,
                                EndDate: this.form.date.end,
                                method_name: this.form.game_play,
                                t_status: this.form.settle_state,
                                period: this.form.qid,
                                page: this.fl_page,
                                limit: 40,
                                // is_select: this.checked_all ? 1 : 0,
                                is_select: this.game_list.length < this.$store.state.game_list.length ? 0 : 1,
                                username: this.form.username,
                                db: this.db,
                            },
                            {
                                cancelToken: new this.axios.CancelToken(function executor(c) {
                                    _this.source = c;
                                }),
                            }
                        )
                        .then((result) => {
                            if (result.data.status == 200) {
                                // this.dialog.visible = true;
                                this.bbdata = result.data.repsoneContent;
                                this.bbdta_ready = true;
                                // this.loading = false;
                                // this.btntext = '查询报表';
                                // // console.log(result.data.repsoneContent)
                                // this.width = '100%';
                                if (this.bbdta_ready && this.dialog.visible) {
                                    this.$nextTick(() => {
                                        this.$refs.fenlei.proc_data(result.data.repsoneContent);
                                    });
                                }
                            }
                            if (result.data.shortMessage == "系统结算中，请稍后") {
                                window.setTimeout(() => {
                                    this.btntext = "查询报表";
                                }, 2000);
                            }
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error.message);
                        });
                }
            });
            // console.log(this.form.date)
        },
        //交收明细账
        get_game_detail() {
            this.axios.post("operate/ReportList_Class", this.game_detail_total.post).then((result) => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    let list = result.data.repsoneContent.class;
                    list.forEach((game) => {
                        game.bet.bet.forEach((item) => {
                            item.small = game.bet.small;
                        });
                    });
                    this.$nextTick(() => {
                        this.$refs.jiaoshou.proc_game_detail_data(list);
                    });
                }
            });
        },
        //获取游戏玩法
        get_game_play_list(gid) {
            if (gid != "0") {
                this.axios
                    .post("ajax/get_period_type", {
                        gid: gid,
                    })
                    .then((result) => {
                        if (result.data.status == 200) {
                            // console.log(result.data.repsoneContent)
                            this.game_play_list = result.data.repsoneContent.playoption;
                            this.game_play_list.unshift({
                                id: 0,
                                name: "全部",
                            });
                            this.form.game_play = result.data.repsoneContent.playoption[0].name;
                            // this.qnum_list = result.data.repsoneContent.phaseoption;
                            // this.qnum_list.unshift({
                            //     id: 0,
                            //     qnum: "全部"
                            // })
                            // if (this.date_or_qnum === '按期数：') {
                            //     this.form.qid = this.qnum_list[1].qnum;
                            // } else {
                            //     this.form.qid = '';
                            // }
                        }
                    });
            } else {
                // this.qnum_list = [];
                this.form.qid = "";
                this.game_play_list = [];
                this.form.game_play = "";
            }
        },
        get_game_list() {
            // this.game_list = this.$store.state.game_list;
            this.game_list = this.$store.state.operate_game_list;
            //判断是否只有六合彩
            let only_lhc = false;
            let lhc = [20, 34, 49, 50];
            if (this.game_list.length <= lhc.length) {
                for (var i = 0; i < this.game_list.length; i++) {
                    if (lhc.includes(this.game_list[i].id)) {
                        only_lhc = true;
                    } else {
                        only_lhc = false;
                    }
                }
            }
            if (only_lhc) {
                this.game_class = [
                    //全部类别
                    { name: "all", label: "--- 所有 ---" },
                    { name: "lhc", label: "六合彩" },
                ];
            } else {
                if (
                    this.game_list.find((item) => item.id == 20)?.status == 1 ||
                    this.game_list.find((item) => item.id == 34)?.status == 1 ||
                    this.game_list.find((item) => (item.id == 49?.status) == 1) ||
                    this.game_list.find((item) => (item.id == 50?.status) == 1)
                ) {
                    this.game_class = [
                        //全部类别
                        { name: "all", label: "--- 所有 ---" },
                        { name: "kc", label: "快彩" },
                        { name: "lhc", label: "六合彩" },
                    ];
                } else {
                    this.game_class = [
                        //全部类别
                        { name: "all", label: "--- 所有 ---" },
                        { name: "kc", label: "快彩" },
                    ];
                }
            }
            this.group_id = this.$store.state.userinfo.group_id;
            this.checked_all = true;
            this.kc_game_list = [{ id: 0, gname: "--- 所有快彩 ---" }];
            this.lhc_game_list = [{ id: 0, gname: "--- 所有六合彩 ---" }];
            let all_gid = [];
            this.game_list.map((item) => {
                if (item.id == 20 || item.id == 34 || item.id == 49 || item.id == 50) {
                    this.lhc_game_list.push(item);
                } else {
                    this.kc_game_list.push(item);
                }
                all_gid.push(item.id);
            });
            //获取所有期数
            this.axios.post("ajax/get_all_period", { gid: all_gid }).then((result) => {
                if (result.data.status == 200) {
                    this.qnum_list = result.data.repsoneContent;
                }
            });
            // this.axios.post("main/game_list").then(result => {
            //     if (result.data.status == 200) {
            //     }
            // })
        },
        select_qnum(e) {
            this.date_or_qnum = e;
            if (e === "按日期：") {
                this.form.qid = "";
            } else {
                // this.form.qid = this.qnum_list[1].qnum;
            }
        },
        get_report_btn_click() {
            // console.log(this.bbdta_ready);
            this.btntext = "查询中...";
            if (this.bbdta_ready) {
                this.dialog.visible = true;
                if (this.dialog.title == "交收报表") {
                    this.$nextTick(() => {
                        this.$refs.jiaoshou.proc_data(this.bbdata);
                    });
                } else {
                    this.$nextTick(() => {
                        this.$refs.fenlei.proc_data(this.bbdata);
                    });
                }
                this.width = "100%";
                this.btntext = "查询报表";
            } else {
                this.get_report().then(() => {
                    this.dialog.visible = true;
                    if (this.dialog.title == "交收报表") {
                        this.$nextTick(() => {
                            this.$refs.jiaoshou.proc_data(this.bbdata);
                        });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.fenlei.proc_data(this.bbdata);
                        });
                    }
                    this.width = "100%";
                    this.btntext = "查询报表";
                });
            }
        },
        get_report_wait() {
            this.dialog.visible = false;
            this.bbdta_ready = false;
            this.get_report();
        },
        //获取时间
        getDate(date, updata) {
            // console.log(date, updata);
            this.bbdta_ready = false;
            this.form.date = date;
            this.get_report();
            // if (updata === 'updata') {
            // }
        },
    },
};
</script>
<style scoped>
.report {
    margin: 0 auto;
    width: 100%;
}
.report-form {
    margin: 0 auto;
}
.xunitable {
    position: absolute;
    z-index: -1;
    top: -100px;
}
.sub {
    margin-top: 10px;
}
</style>