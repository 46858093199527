import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import axios from "../../axios/axios";
import ssc from "./game/ssc.vue";
import pk10 from "./game/pk10.vue";
import lhc from "./game/lhc.vue";
import xy28 from "./game/xy28.vue";
import k3 from "./game/k3.vue";
import pl5 from "./game/pl5.vue";
import { pcddNum } from "../../plugin/pcddNum";
import { lhcNum } from "../../plugin/lhcNum";
import { get_lhc_sx } from "../../plugin/sx";
import { duijiangma } from "../../plugin/duijiangma";
export default {
  name: "lishikaijiang",
  components: {
    ssc: ssc,
    pk10: pk10,
    lhc: lhc,
    xy28: xy28,
    k3: k3,
    pl5: pl5
  },
  data: function data() {
    return {
      componentId: "",
      game_list: [],
      checked_gname: "",
      gid: "",
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      history_data: []
    };
  },
  computed: {
    listenint_router_view: function listenint_router_view() {
      return this.$store.state.int_router_view;
    }
  },
  watch: {
    listenint_router_view: function listenint_router_view(newVal, oldVal) {
      // console.log(newVal, oldVal);
      // 此处处理逻辑
      this.get_game_list();
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var checked_gname = to.query.gname;
    var gid = to.query.gid;
    var componentId = "";

    if (gid == 1 || gid == 4 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
      componentId = "ssc";
    }

    if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
      componentId = "pk10";
    }

    if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
      componentId = "lhc";
    }

    if (gid == 22 || gid == 23) {
      componentId = "xy28";
    }

    if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
      componentId = "k3";
    }

    if (gid == 10) {
      componentId = "pl5";
    } // 通过 `vm` 访问组件实例


    axios.post("betresult/index", {
      lid: gid,
      page: 1,
      limit: 30
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.pagination = result.data.repsoneContent.pagination;
          vm.game_list = vm.$store.state.game_list;
          var list = result.data.repsoneContent.list; //处理是pc蛋蛋的结果

          if (gid == 22 || gid == 23) {
            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }
          } //处理六合彩


          if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
            for (var _i = 0; _i < list.length; _i++) {
              var arr = [];

              for (var _j = 0; _j < list[_i].res.length; _j++) {
                arr.push(lhcNum(lhcNum(list[_i].res[_j], vm.$filters.time(list[_i].closetime).split(" ")[0])));
              }

              list[_i].res_s = get_lhc_sx("开奖", arr);
            }
          }

          duijiangma(list, gid);
          vm.history_data = list;
          vm.gid = gid;
          vm.checked_gname = checked_gname;
          vm.componentId = componentId;
        });
      }
    });
  },
  methods: {
    change_page: function change_page(p) {
      this.page = p;
      this.get_history_data(this.gid);
    },
    get_history_data: function get_history_data(gid) {
      var _this = this;

      // this.history_data = [];
      this.axios.post("betresult/index", {
        lid: gid,
        page: this.page,
        // start_time: this.date.start,
        // end_time: this.date.end,
        limit: 30
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.pagination = result.data.repsoneContent.pagination;
          _this.history_data = result.data.repsoneContent.list;
          var list = result.data.repsoneContent.list; //处理是pc蛋蛋的结果

          if (gid == 22 || gid == 23) {
            for (var i = 0; i < list.length; i++) {
              for (var j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }
          } //处理六合彩


          if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
            for (var _i2 = 0; _i2 < list.length; _i2++) {
              var arr = [];

              for (var _j2 = 0; _j2 < list[_i2].res.length; _j2++) {
                arr.push(lhcNum(lhcNum(list[_i2].res[_j2], _this.$filters.time(list[_i2].closetime).split(" ")[0])));
              }

              list[_i2].res_s = get_lhc_sx("开奖", arr);
            }
          }

          _this.history_data = list;

          if (gid == 1 || gid == 4 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
            _this.componentId = "ssc";
          }

          if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
            _this.componentId = "pk10";
          }

          if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
            _this.componentId = "lhc";
          }

          if (gid == 22 || gid == 23) {
            _this.componentId = "xy28";
          }

          if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
            _this.componentId = "k3";
          }

          if (gid == 10) {
            _this.componentId = "pl5";
          } //处理兑奖码


          duijiangma(list, gid);
          _this.gid = gid;
        }
      });
    },
    check_game: function check_game(gname) {
      // this.componentId = '';
      var gid = this.game_list.find(function (item) {
        return item.gname == gname;
      }).id;
      this.get_history_data(gid);
    },
    get_game_list: function get_game_list() {
      var _this2 = this;

      this.axios.post("main/game_list").then(function (result) {
        if (result.data.status === 200) {
          _this2.game_list = result.data.repsoneContent.game_list;
          _this2.checked_gname = _this2.game_list[0].gname;

          _this2.check_game(_this2.game_list[0].gname);
        }
      });
    }
  }
};