import _toConsumableArray from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.json.stringify.js";
import axios from "../../axios/axios";
import gameinfo from "./gameinfo.vue";
import fc3d from "./games/fc3d.vue";
import k3 from "./games/k3.vue";
import lhc from "./games/lhc.vue";
import pcdd from "./games/pcdd.vue";
import pk10 from "./games/pk10.vue";
import pl35 from "./games/pl35.vue";
import ssc from "./games/ssc.vue";
import { get_lhc_sx } from "../../plugin/sx";
import { lhcNum } from "../../plugin/lhcNum";
import { pcddNum } from "../../plugin/pcddNum";
export default {
  name: "instant",
  components: {
    gameinfo: gameinfo,
    fc3d: fc3d,
    k3: k3,
    lhc: lhc,
    pcdd: pcdd,
    pk10: pk10,
    pl35: pl35,
    ssc: ssc
  },
  data: function data() {
    return {
      params: "",
      comp: "",
      game_info_data: {
        title: ""
      },
      pk: "0",
      szsz: "1",
      chang_long: [],
      sum: {
        all_balance: "0",
        own_balance: "0",
        sums: []
      },
      loss_data: [],
      sub_link: "",
      is_manlet: "-1",
      //补货状态
      zupai: {
        //排行/组排/快速补货
        list: [],
        page: 1,
        pagination: {
          total_pages: 0,
          total_records: 0,
          page_size: 0
        },
        type: ""
      },
      s_type: "1",
      balls: "",
      pl_value: 0.005,
      //每次赔率加减值
      can_change_pl: true,
      //注单明细
      zdmx: {
        visible: false,
        page: 1,
        pagination: {
          page_size: "",
          total_pages: "",
          total_records: ""
        },
        row: "",
        data_list: []
      },
      //缓存数据
      cacheData: {
        tm: null,
        tma: null,
        tmb: null,
        sb: null,
        tx: null,
        zm: null,
        ztm1: null,
        ztm2: null,
        ztm3: null,
        ztm4: null,
        ztm5: null,
        ztm6: null,
        zm1_6: null,
        lma: null,
        lma1: null,
        lma2: null,
        lma3: null,
        lma4: null,
        lma5: null,
        yx: null,
        yxbz: null,
        bz5: null,
        bz6: null,
        bz7: null,
        bz8: null,
        bz9: null,
        bz10: null,
        bz11: null,
        bz12: null,
        lx2: null,
        lx3: null,
        lx4: null,
        lx5: null,
        lx2bz: null,
        lx3bz: null,
        lx4bz: null,
        lx5bz: null,
        lxyz: null,
        lm: null,
        ws: null,
        wsbz: null,
        wl: null,
        wlbz: null,
        sxws: null
      },
      source: null,
      //存放取消的请求方法
      //各个公司的大总监
      gs_users: {
        visible: false,
        list: [],
        checked_k_id: "",
        checked_user_id: "",
        checked_db: "",
        pagination: {
          total_pages: 0,
          total_records: "0",
          page_size: 10
        }
      },
      paihang_post_obj: null
    };
  },
  watch: {
    "game_info_data.title": function game_info_dataTitle(val, oldVal) {
      var _this2 = this;

      if (val != oldVal) {
        this.$nextTick(function () {
          // console.log(val, oldVal, this.comp);
          if (_this2.comp == "pl35") {
            _this2.$refs[_this2.comp].change_title(val);
          } else {
            _this2.$refs[_this2.comp].pl_popver.show = false;
            _this2.$refs[_this2.comp].bh.show = false;
          }
        });
        this.change_background();
      }
    },
    "$route.query": function $routeQuery(toParams, previousParams) {
      var _this3 = this;

      // 对路由变化做出响应...
      // console.log(toParams);
      if (toParams.change_play == 0) {
        //如果不是切换玩法，改变默认选择的大总监
        this.gs_users.list = this.$store.state.jszd_users;
        this.gs_users.checked_k_id = this.gs_users.list[0].k_id;
        this.gs_users.checked_user_id = this.gs_users.list[0].id;
        this.gs_users.checked_db = this.gs_users.list[0].db;
      }

      this.sub_link = ""; // console.log(toParams, previousParams);

      if (toParams.link != undefined && toParams.link.split("=")[0] == "trader/index?ball" && previousParams.link != undefined && previousParams.link.split("=")[0] == "trader/index?ball") {
        this.params = toParams;
        this.game_info_data.title = toParams.title;
        this.comp = toParams.comp;
        this.$nextTick(function () {
          _this3.$refs[_this3.comp].is_int_submenu = true;
        });

        if (toParams.link.split("=")[0] == "trader/index?ball" && previousParams.link.split("=")[0] == "trader/index?ball") {
          this.change_submenu(toParams);
        } else {
          this.$refs["gameinfo"].clear_all_timer();
        }

        this.get_game_info(this.pk, this.sub_uid, "noloss");
        this.$refs["gameinfo"].clear_all_timer();
      } else {
        this.params = this.$store.state.checked_menu;
        this.comp = this.params.comp;
      }
    }
  },
  // beforeRouteEnter(to, from, next) {
  //     next()
  // },
  created: function created() {
    var _this4 = this;

    this.gs_users.list = this.$store.state.jszd_users;
    this.gs_users.checked_k_id = this.gs_users.list[0].k_id;
    this.gs_users.checked_user_id = this.gs_users.list[0].id;
    this.gs_users.checked_db = this.gs_users.list[0].db; // console.log(this.$store.state);

    this.params = this.$store.state.checked_menu;
    this.comp = this.params.comp;
    this.game_info_data = this.$store.state.instant_gameinfo;
    this.change_background();
    this.game_info_data.title = this.params.title; // console.log(this.comp, this.game_info_data);

    var strs = "";

    if (typeof this.game_info_data.upopennumber == "string" && this.game_info_data.upopennumber != "") {
      strs = this.game_info_data.upopennumber.split(",");
    } else {
      strs = this.game_info_data.upopennumber;
    }

    var arr = [];
    var arr1 = [];
    /**判断解析六合彩球号，颜色，生肖 */

    if (this.game_info_data.gid == 20 || this.game_info_data.gid == 34 || this.game_info_data.gid == 48 || this.game_info_data.gid == 49 || this.game_info_data.gid == 50 || this.game_info_data.gid == 51) {
      this.$nextTick(function () {
        _this4.$refs["lhc"].int_submenu();
      });

      for (var i = 0; i < strs.length; i++) {
        arr.push(lhcNum(strs[i], this.game_info_data.upopenstarttime.split(" ")[0]));
      } // console.log(strs);


      if (this.game_info_data.upopennumber != "") {
        arr = get_lhc_sx("开奖", arr);
      }

      if (this.game_info_data.upopennumber_cs) {
        for (var _i = 0; _i < this.game_info_data.upopennumber_cs.length; _i++) {
          arr1.push(lhcNum(this.game_info_data.upopennumber_cs[_i], this.game_info_data.upopenstarttime.split(" ")[0]));
        }

        arr1 = get_lhc_sx("开奖", arr1);
      }
    } //pc蛋蛋，加拿大28，幸运28
    else if (this.game_info_data.gid == "22" || this.game_info_data.gid == "23") {
      for (var _i2 = 0; _i2 < strs.length; _i2++) {
        arr.push(pcddNum(strs[_i2]));
      }
    } else {
      /**其他彩种球号，cqssc,pk10 */
      for (var _i3 = 0; _i3 < strs.length; _i3++) {
        arr.push(strs[_i3]);
      }
    }

    this.game_info_data.upopennumber = arr;
    this.game_info_data.upopennumber_cs = arr1;
    this.sub_link = "";
    this.pk = this.$store.state.instant_pk;
    this.get_loss_data();
    this.getsum();
    this.get_chang_long(this.game_info_data.gid);
    this.change_is_manlet(this.game_info_data.gid);
    this.$nextTick(function () {
      _this4.$refs["gameinfo"].time_down(_this4.game_info_data.stop_time);

      _this4.$refs["gameinfo"].updeta_data();
    });
  },
  beforeUnmount: function beforeUnmount() {
    this.sub_uid = "";
    this.$refs["gameinfo"].clear_all_timer();
  },
  methods: {
    chenge_gs_user: function chenge_gs_user(k_id) {
      var _this5 = this;

      var user = this.gs_users.list.find(function (item) {
        return item.k_id == k_id;
      }); // console.log(user);

      this.gs_users.checked_k_id = k_id;
      this.gs_users.checked_user_id = user.id;
      this.gs_users.checked_db = user.db;
      this.params.gid = user.game_list.find(function (game) {
        return game.gname == _this5.$store.state.instant_checked_gname;
      }).id;
      this.get_game_info(this.pk, this.gs_users.checked_user_id);
    },
    change_play: function change_play(name) {
      this.$emit("change_play", name);
    },
    zdmx_change_page: function zdmx_change_page(page) {
      this.zdmx.page = page;
      this.get_zdmx_data(this.zdmx.row);
    },
    get_zdmx_data: function get_zdmx_data(row) {
      var _this6 = this;

      // console.log(row);
      this.zdmx.row = row;
      var obj = {
        gid: this.game_info_data.gid,
        phaseid: this.game_info_data.p_id,
        page: this.zdmx.page,
        limit: 30,
        status: 2,
        qnum: this.game_info_data.nn,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db,
        szsz: this.szsz
      };

      if (row.type_zteb) {
        obj.type_zteb = row.type_zteb;
      }

      if (row.level_s != undefined) {
        if (row.level_two != undefined) {
          obj.levle_s = "".concat(row.level_two, "_").concat(row.level_three, "_").concat(row.level_four);
          obj.four_key = row.four_key;
        } else {
          obj.levle_s = row.level_s;
        }
      } else if (row.key != undefined) {
        obj.levle_s = row.key;
      } else {
        if (this.game_info_data.gid == 20 || this.game_info_data.gid == 34 || this.game_info_data.gid == 49 || this.game_info_data.gid == 50 || this.game_info_data.gid == 51) {
          if (row.level_two != undefined) {
            obj.levle_s = row.level_two;

            if (row.level_three != undefined) {
              obj.levle_s += "_".concat(row.level_three);

              if (row.level_four != undefined) {
                obj.levle_s += "_".concat(row.level_four);
              }
            }
          }
        } // console.log(obj.levle_s);


        if (obj.id != row.newid) {
          obj.oddsid = row.newid;
        } else {
          obj.oddsid = row.id;
        }
      }

      if (this.game_info_data.nn == this.game_info_data.upopenphase) {
        obj.status = 1;
      }

      if (this.balls == "tm") {
        obj.phaseid_tmab = 1;
      }

      this.axios.post("betbackup/order_detail_new", obj).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this6.zdmx.pagination = result.data.repsoneContent.pagination;
          var _obj = result.data.repsoneContent.list;
          _this6.zdmx.data_list = [];

          for (var key in _obj) {
            _this6.zdmx.data_list.push(_obj[key]);
          }

          _this6.zdmx.visible = true;
        }
      });
    },
    change_pl: function change_pl(plobj) {
      var _this7 = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

      // console.log(plobj);
      if (!this.can_change_pl) {
        return;
      }

      this.can_change_pl = false;
      var row = plobj.row;
      var uid = this.$store.state.isUserSetLoss;
      uid === "" ? uid = this.$store.state.userinfo.user_id : "";
      var plusminus = plobj.plusminus;
      var wtvalue = "";

      if (plusminus == "plus") {
        wtvalue = plobj.pl * 1 + this.pl_value * 1;
      } else if (plusminus == "minus") {
        wtvalue = plobj.pl * 1 - this.pl_value * 1;
      } else {
        wtvalue = plobj.pl_change_value * 1;
      }

      wtvalue = wtvalue.toFixed(3) * 1;

      if (type == "") {
        if (wtvalue > row.maxpl || wtvalue < row.minpl) {
          this.can_change_pl = true;
          return this.$message.error("\u8D85\u51FA\u4FEE\u6539 ".concat(row.name, " \u7684\u8D54\u7387\u8303\u56F4"));
        }

        if (row.name == "不连0尾") {
          // console.log(this.list);
          var list = this.list[0];
          var oddsid = [];
          var _wtvalue = [];
          var old_pl = [];

          for (var i = 1; i < list.length; i++) {
            // console.log(list[i]);
            if (row.pl < list[i].minpl || row.pl > list[i].maxpl) {
              this.get_loss_data();
              this.can_change_pl = true;
              return this.$message.error("\u8D85\u51FA\u4FEE\u6539 ".concat(list[i].name, " \u7684\u8D54\u7387\u8303\u56F4"));
            } else {
              oddsid.push(list[i].newid);

              _wtvalue.push(plusminus == "plus" ? list[i].pl + this.pl_value : list[i].pl - this.pl_value);

              old_pl.push(list[i].old_pl);
            }
          }

          row.dis_pl = true;
          this.axios.post("trader/update_rate", {
            gid: this.game_info_data.gid,
            oddsid: oddsid,
            wtvalue: _wtvalue,
            old_pl: old_pl,
            // userid: uid,
            userid: this.$store.state.userinfo.user_id,
            user: "",
            phaseid: this.$store.state.lottery.p_id,
            tmab: this.balls == "tm" ? 1 : "",
            user_id: this.gs_users.checked_user_id,
            db: this.gs_users.checked_db
          }).then(function (result) {
            if (result.data.status == 200) {
              _this7.$message.closeAll(); // console.log(result.data.repsoneContent)


              _this7.$message.success(result.data.shortMessage);

              _this7.get_loss_data();
            }
          });
        } else {
          row.dis_pl = true;
          var _plusminus = row.plusminus;
          this.axios.post("trader/update_rate", {
            gid: this.game_info_data.gid,
            oddsid: row.newid,
            wtvalue: wtvalue,
            maxpl: row.maxpl,
            minpl: row.minpl,
            old_pl: plobj.old_pl,
            // userid: uid,
            userid: this.$store.state.userinfo.user_id,
            user: "",
            phaseid: this.game_info_data.p_id,
            tmab: this.balls == "tm" ? 1 : "",
            user_id: this.gs_users.checked_user_id,
            db: this.gs_users.checked_db
          }).then(function (result) {
            if (result.data.status == 200) {
              _this7.$message.closeAll(); // console.log(result.data.repsoneContent)


              _this7.$message.success(result.data.shortMessage);

              _this7.get_loss_data();
            }
          });
        }
      } else {
        row.dis_pl = true;
        this.axios.post("trader/update_dz_rate", {
          gid: this.game_info_data.gid,
          qnum: this.game_info_data.nn,
          // oddsid: row.newid,
          wtvalue: wtvalue,
          // maxpl: row.maxpl,
          // minpl: row.minpl,
          old_pl: plobj.old_pl,
          // userid: uid,
          // userid: this.$store.state.userinfo.user_id,
          // user: '',
          // phaseid: this.game_info_data.p_id,
          // tmab: this.balls == 'tm' ? 1 : '',
          ball: plobj.row.ball,
          level_four: plobj.row.level_s.level_four,
          level_three: plobj.row.level_s.level_three,
          level_two: plobj.row.level_s.level_two,
          user_id: this.gs_users.checked_user_id,
          db: this.gs_users.checked_db
        }).then(function (result) {
          if (result.data.status == 200) {
            _this7.$message.closeAll(); // console.log(result.data.repsoneContent)


            _this7.$message.success(result.data.shortMessage);

            _this7.get_loss_data();
          }
        });
      }
    },
    updeta_pl_value: function updeta_pl_value(value) {
      this.pl_value = value;
    },
    change_submenu: function change_submenu(menu) {
      // console.log(menu);
      this.zupai.page = 1;
      this.sub_link = menu.link;

      if (this.sub_link.includes("tm") || this.sub_link.includes("lma")) {
        this.get_olny_pl();
      } else {
        this.get_loss_data();
      }

      this.change_background();
    },
    //切换盘口
    changepk: function changepk(pk) {
      // console.log(pk);
      this.$store.state.instant_pk = this.pk = pk;
      this.get_loss_data();
    },
    //切换实占虚占
    change_xuzhu_shizhan: function change_xuzhu_shizhan(value) {
      this.szsz = value;
      this.zupai.page = 1;
      this.get_loss_data();
      this.get_zupai();
      this.getsum();
    },
    change_zupai_type: function change_zupai_type(value) {
      this.s_type = value;
      this.zupai.page = 1;
      this.get_zupai();
    },
    change_zupai_page: function change_zupai_page(page) {
      this.zupai.page = page;
      this.get_zupai();
    },
    //六合彩获取组排/补货
    get_zupai: function get_zupai() {
      var _this8 = this;

      var list = []; //获取有投注的信息，进行整合

      var obj = {
        is_manlet: this.is_manlet,
        play_id: this.params.gid,
        balls: this.balls,
        pk: this.pk,
        qnum: this.game_info_data.nn,
        szsz: this.szsz,
        s_type: this.s_type,
        page: this.zupai.page,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      }; // this.paihang_post_obj = JSON.parse(JSON.stringify(obj));

      this.axios.post("userlet/getAllLet", obj).then(function (result) {
        if (result.data.status == 200) {
          var data_list = result.data.repsoneContent.list;
          _this8.zupai.pagination = result.data.repsoneContent.pagination;
          _this8.zupai.pagination.total_records *= 1;
          _this8.zupai.type = result.data.repsoneContent.type; // console.log(data_list);

          if (data_list.length < 1) {
            return _this8.zupai.list = [];
          }

          if (_this8.balls.includes("lma") || _this8.balls.includes("bz") || _this8.balls.includes("lx") || _this8.balls.includes("wl") || _this8.balls == "zm1_6") {
            data_list.forEach(function (item) {
              item.let = {};
              item.let.control_let_s = item.control_let;
              item.let.control_let = "";
              item.let.rate = "";
              item.let.rebate_level = ""; // item.let.rate = item.rate;
              // item.let.rebate_level = item.rebate_level;

              item.let.level_s = item.level_s;
              item.name = item.level_s.name;
              item.let.level_key = item.level_key;
              item.let.list_bonus = item.list_bonus;
              item.let.oc_bill = item.oc_bill;
              item.let.profit_bill = item.profit_bill;
              item.let.occupy_bill_s = item.occupy_bill_s;
            });
            _this8.zupai.list = data_list.sort(function (a, b) {
              return b.let.oc_bill - a.let.oc_bill;
            });
          } else {
            list.forEach(function (item) {
              item.let.control_let = "";
              item.let.rate = "";
              item.let.rebate_level = "";
              item.let.ball = item.name;
              item.let.level_key = "";
              item.let.list_bonus = "";
              item.let.oc_bill = "";
              item.let.profit_bill = item.szsz_amount[4];
              item.let.occupy_bill_s = "";
              data_list.forEach(function (data) {
                if (item.name == data.level_s.name) {
                  // item.let.control_let = data.control_let * 1 || '';
                  item.let.control_let_s = data.control_let || ""; //已补

                  item.let.control_let = "";
                  item.let.rate = data.rate * 1 || "";
                  item.let.rebate_level = data.rebate_level * 1 || "";
                  item.let.level_key = data.level_key;
                  item.let.list_bonus = data.list_bonus;
                  item.let.oc_bill = data.oc_bill;
                  item.let.occupy_bill_s = data.occupy_bill_s;
                  item.let.profit_bill = data.profit_bill;
                  item.level_s = data.level_s;
                  item.oc_bill_tm = _this8.balls == "tm" || _this8.balls.includes("tmzx") ? data.occupy_bill_tm : "";
                }
              });
            });
            _this8.zupai.list = list.sort(function (a, b) {
              return b.let.oc_bill - a.let.oc_bill;
            });
          }
        }
      });
    },
    //获取赔率,匹配盘口和实占1,虚占0
    get_loss_data: function get_loss_data() {
      var _this9 = this;

      var _this = this;

      this.cancelQuest();
      var postobj = {
        gid: this.params.gid,
        pk: this.pk,
        szsz: this.szsz,
        //实占1,虚占0
        type: "0",
        //1，代表会员，0代理
        periods_status: this.game_info_data.nn == this.game_info_data.upopenphase ? 1 : 0,
        ws: this.game_info_data.result_res.ws ? this.game_info_data.result_res.ws : "",
        zodiac: this.game_info_data.result_res.zodiac ? this.game_info_data.result_res.zodiac : "",
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      };

      if (this.game_info_data.result_res.ws) {}

      var link = ""; // console.log(this.$store.state,this.params);

      if (this.sub_link == "") {
        link = this.params.link;
      } else {
        link = this.sub_link;
      }

      var balls = this.sub_link == "" ? this.params.link.split("=")[1] : this.sub_link.split("=")[1]; // console.log(balls);

      this.balls = balls; // console.log(this.$store.state.checked_menu);
      //加载缓存数据

      var _loop = function _loop(key) {
        if (key == balls && _this9.cacheData[key] != null) {
          _this9.$nextTick(function () {
            _this9.$refs[_this9.comp].proc(_this9.cacheData[key]);
          });
        }
      };

      for (var key in this.cacheData) {
        _loop(key);
      }

      this.axios.post(link, postobj, {
        cancelToken: new this.axios.CancelToken(function executor(c) {
          _this.source = c;
        })
      }).then(function (result) {
        if (result.data.status == 200) {
          _this9.loss_data = result.data.repsoneContent;
          _this9.cacheData[balls] = JSON.parse(JSON.stringify(_this9.loss_data)); //缓存数据
          // console.log(this.comp, this.loss_data);

          _this9.$refs[_this9.comp].proc(_this9.loss_data);

          if (!_this9.can_change_pl) {
            _this9.can_change_pl = true;
          }
        }
      }); //判断是否获取六合彩组排数据

      if (this.params.title == "连码" || this.params.title == "自选不中" || this.params.title == "连肖" || this.params.title == "六肖一中" || this.params.title == "尾连") {
        this.get_zupai();
      }

      this.get_paihang_post_obj();
    },
    get_game_info: function get_game_info() {
      var _this10 = this;

      var pk = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var user_id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var loos = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      // console.log(this.params);
      this.get_chang_long(this.params.gid);
      this.pk = pk;
      this.axios.post("trader/index_header", {
        gid: this.params.gid,
        pk: pk,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      }).then(function (result) {
        if (result.data.status == 200) {
          var game_info = result.data.repsoneContent;
          _this10.$store.state.instant_gameinfo = game_info;
          var strs = "";

          if (typeof game_info.upopennumber == "string" && game_info.upopennumber != "") {
            strs = game_info.upopennumber.split(",");
          }

          var arr = [];
          var arr1 = [];
          game_info.upopennumber = arr;
          game_info.upopennumber_cs = arr1;
          _this10.game_info_data = game_info;
          _this10.game_info_data.title = _this10.params.title;
          /**判断解析六合彩球号，颜色，生肖 */

          if (game_info.gid == 20 || game_info.gid == 34 || game_info.gid == 48 || game_info.gid == 49 || game_info.gid == 50 || game_info.gid == 51) {
            _this10.$nextTick(function () {
              _this10.$refs["lhc"].int_submenu();
            });

            for (var i = 0; i < strs.length; i++) {
              arr.push(lhcNum(strs[i], game_info.upopenstarttime.split(" ")[0]));
            } // console.log(game_info.upopennumber);


            if (strs != "") {
              arr = get_lhc_sx("开奖", arr);
            }

            if (game_info.upopennumber_cs) {
              for (var _i4 = 0; _i4 < game_info.upopennumber_cs.length; _i4++) {
                arr1.push(lhcNum(game_info.upopennumber_cs[_i4], game_info.upopenstarttime.split(" ")[0]));
              }

              arr1 = get_lhc_sx("开奖", arr1);
            }
          } //pc蛋蛋，加拿大28，幸运28
          else if (game_info.gid == "22" || game_info.gid == "23") {
            for (var _i5 = 0; _i5 < strs.length; _i5++) {
              arr.push(pcddNum(strs[_i5]));
            }
          } else {
            /**其他彩种球号，cqssc,pk10 */
            for (var _i6 = 0; _i6 < strs.length; _i6++) {
              arr.push(strs[_i6]);
            }
          }

          if (loos == "") {
            _this10.get_loss_data();
          }

          _this10.getsum();

          _this10.change_is_manlet(game_info.gid);

          _this10.$nextTick(function () {
            _this10.$refs["gameinfo"].time_down(game_info.stop_time);

            _this10.$refs["gameinfo"].updeta_data();
          });

          _this10.change_background();
        }
      });
    },
    //两面长龙
    get_chang_long: function get_chang_long() {
      var _this11 = this;

      var gid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

      if (gid == "" || gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
        return false;
      }

      this.axios.post("ajax/changlong", {
        gid: gid,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          if (result.data.repsoneContent) {
            var data = result.data.repsoneContent.data.msg;
            var list = [].concat(_toConsumableArray(data[0]), _toConsumableArray(data[1])); // console.log(list);

            list.sort(function (a, b) {
              return b.b_s_qnum - a.b_s_qnum;
            });
            list.forEach(function (item) {
              item.name = item.big_small == undefined ? item.odd_even : item.big_small;
            });
            _this11.chang_long = list;
          }
        }
      });
    },
    //处理总额数据
    getsum: function getsum() {
      var _this12 = this;

      this.axios.post("trader/bet_count", {
        gid: this.game_info_data.gid,
        qnum: this.game_info_data.nn,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          if (result.data.repsoneContent !== "") {
            _this12.sum.all_balance = result.data.repsoneContent.all_balance;
            _this12.sum.own_balance = result.data.repsoneContent.own_balance;
            var list = result.data.repsoneContent.bet_count_level || [];
            var sums = [];
            list.forEach(function (item) {
              sums.push(item);
            });
            _this12.sum.sums = sums;
          }
        }
      });
    },
    //判断是否可以补货
    change_is_manlet: function change_is_manlet(gid) {
      if (gid == 20 || gid == 34 || gid == 49 || gid == 50 || gid == 51) {
        if (this.$store.state.userinfo.group_id == 2) {
          this.is_manlet = "0";
        } else if (this.$store.state.userinfo.group_id > 2 && this.$store.state.userinfo.is_manlet == 1) {
          this.is_manlet = "1";
        }
      } else {
        this.is_manlet = "-1";
      }
    },
    change_background: function change_background() {
      if (this.game_info_data.gid != 20 && this.game_info_data.gid != 34 && this.game_info_data.gid != 49 && this.game_info_data.gid != 50) {
        if (this.game_info_data.isopen == 0 || this.game_info_data.stop_time == "00:00:00") {
          var tags = document.getElementsByClassName("el-table__cell");
          this.$nextTick(function () {
            // console.log(tags);
            for (var index = 0; index < tags.length; index++) {
              if (tags[index].style.backgroundColor == "" || tags[index].style.backgroundColor == "#ffffff" || tags[index].style.backgroundColor == "rgb(255, 255, 255)") {
                tags[index].style.backgroundColor = "#eeeeee";
              }
            }
          });
        } else {
          var _tags = document.getElementsByClassName("el-table__cell");

          this.$nextTick(function () {
            for (var index = 0; index < _tags.length; index++) {
              if (_tags[index].style.backgroundColor == "#eeeeee" || _tags[index].style.backgroundColor == "rgb(238, 238, 238)") {
                _tags[index].style.backgroundColor = "#ffffff";
              }
            }
          });
        }
      }
    },
    //只快速获取赔率，不含盈亏
    get_olny_pl: function get_olny_pl() {
      var _this13 = this;

      var postobj = {
        gid: this.params.gid,
        pk: this.pk,
        szsz: this.szsz,
        //实占1,虚占0
        type: "0",
        //1，代表会员，0代理
        periods_status: this.game_info_data.nn == this.game_info_data.upopenphase ? 1 : 0,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      };
      var link = ""; // console.log(this.$store.state,this.params);

      if (this.sub_link == "") {
        link = this.params.link;
      } else {
        link = this.sub_link;
      }

      var balls = this.sub_link == "" ? this.params.link.split("=")[1] : this.sub_link.split("=")[1]; // console.log(balls);

      postobj.ball = balls;
      this.axios.post("trader/index_backup", postobj).then(function (result) {
        if (result.data.status == 200) {
          _this13.loss_data = result.data.repsoneContent;

          _this13.$refs[_this13.comp].proc(_this13.loss_data);

          if (!_this13.can_change_pl) {
            _this13.can_change_pl = true;
          }
        }

        _this13.get_loss_data();
      });
    },
    cancelQuest: function cancelQuest() {
      if (typeof this.source === "function") {
        this.source("终止请求", "终止请求"); //取消请求
      }
    },
    get_paihang_post_obj: function get_paihang_post_obj() {
      this.paihang_post_obj = {
        is_manlet: this.is_manlet,
        play_id: this.params.gid,
        balls: this.balls,
        pk: this.pk,
        qnum: this.game_info_data.nn,
        szsz: this.szsz,
        s_type: this.s_type,
        page: this.zupai.page,
        user_id: this.gs_users.checked_user_id,
        db: this.gs_users.checked_db
      };
    }
  }
};