<!--
 * @Author: your name
 * @Date: 2022-03-17 23:49:43
 * @LastEditTime: 2024-09-18 17:52:33
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\views\zizhanghao.vue
-->
<template>
    <div class="huiyuan">
        <div class="panel" :style="{ width: panelwidth }">
            <div class="panel-title">
                <h2>{{ title }}</h2>
                <el-button v-show="other_view.visible" style="float: right; margin-top: 3px" size="small" type="warning" @click="other_view_back">返回</el-button>
            </div>
            <div class="panel-body">
                <div v-show="!other_view.visible">
                    <div style="text-align: left; padding: 3px 0">
                        <el-button size="small" @click="add_user" type="warning">新增子账号</el-button>
                        <div style="float: right">
                            <el-select v-model="post_huiyuan.userState" class="m-2" placeholder="Select" size="small" @change="get_users" style="width: 70px">
                                <el-option v-for="item in post_huiyuan.filter" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                        </div>
                    </div>
                    <el-table :data="users" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="is_online" label="在线" align="center">
                            <template #default="scope">
                                <img :src="scope.row.is_online == 1 ? require('../../../assets/zaixian.png') : require('../../../assets/lixian.png')" />
                            </template>
                        </el-table-column>
                        <el-table-column property="username" label="账号" align="center">
                            <template #default="scope">
                                <span style="color: red">{{ scope.row.username }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="username" label="所属" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.type_status == 0">原账号</span>
                                <span v-show="scope.row.type_status == 1" style="color: #409eff">后台</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="real_name" label="名称" align="center" />
                        <el-table-column property="posttime" label="新增日期" align="center">
                            <template #default="scope">{{ $filters.time(scope.row.posttime, "notime") }}</template>
                        </el-table-column>
                        <el-table-column property="now_time" label="最后操作时间" align="center">
                            <template #default="scope">{{ $filters.time(scope.row.now_time) }}</template>
                        </el-table-column>
                        <el-table-column property="ip_last" label="最后操作IP" align="center" />
                        <el-table-column property="ip_last_address" label="最后操作地址" align="center" />

                        <el-table-column property="status" label="状态" v-if="show_otherset" align="center">
                            <template #default="scope">
                                <!-- 是直属会员才有功能 -->
                                <div v-show="$store.state.userinfo.transfer_status_agent == 1">
                                    <el-button v-show="scope.row.status == 1" type="success" size="small" plain @click="change_status(scope.row)">启用</el-button>
                                    <el-button v-show="scope.row.status == 0" type="danger" size="small" plain @click="change_status(scope.row)">停用</el-button>
                                    <el-button v-show="scope.row.status == 2" type="info" size="small" plain @click="change_status(scope.row)">冻结</el-button>
                                </div>
                                <div v-show="!$store.state.userinfo.transfer_status_agent == 1">
                                    <span v-show="scope.row.status == 1" style="color: #67c23a">启用</span>
                                    <span v-show="scope.row.status == 0" style="color: #f56c6c">停用</span>
                                    <span v-show="scope.row.status == 2" style="color: #909399">冻结</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="功能" width="220">
                            <template #default="scope">
                                <span v-show="scope.row.kuaicai_level_id != 4 && scope.row.level_id != 4">
                                    <el-link style="margin: 0 0 0 5px" icon="Edit" @click.prevent="edit_user(scope.row)">修改</el-link>
                                    <el-link v-show="show_otherset" style="margin: 0 0 0 5px" icon="Calendar" @click.prevent="open_loginLog(scope.row)">日志</el-link>
                                    <el-link v-show="show_otherset" style="margin: 0 0 0 5px" icon="tickets" @click.prevent="open_changeLog(scope.row)">记录</el-link>
                                </span>
                                <el-link v-show="$store.state.userinfo.group_id < 6 && show_otherset" style="margin: 0 0 0 5px" icon="Checked" @click.prevent="fenpei(scope.row)">分配账号</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <el-pagination
                            class="paging"
                            hide-on-single-page
                            @current-change="change_page"
                            small
                            v-model="pagination.page"
                            :page-size="post_huiyuan.limit"
                            :pager-count="5"
                            :total="pagination.total_records * 1"
                            layout=" total,prev, pager, next, jumper,"
                        />
                    </div>
                </div>
                <template v-if="other_view.visible">
                    <component :ref="other_view.activated_name" :is="other_view.activated_name" :userinfo="other_view.row" :addinfo="addinfo" :add_user_data="add_user_data" @other_view_back="other_view_back"></component>
                </template>
            </div>
        </div>
        <!-- 修改状态 -->
        <el-dialog v-model="change_user_status.visible" width="260px" class="mydialogclass">
            <template #header>
                <span class="mydialogtitle">{{ `修改 ${row_user.username} 状态` }}</span>
            </template>
            <el-radio-group v-model="change_user_status.status">
                <el-radio label="1">启用</el-radio>
                <el-radio label="2">冻结</el-radio>
                <el-radio label="0">停用</el-radio>
            </el-radio-group>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="small" @click="cancel_change_status">取消</el-button>
                    <el-button size="small" type="primary" @click="change_status(row_user, true)">确定修改</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import axios from "../../../axios/axios";
import loginLog from "../plublic/loginLog.vue";
import changeLog from "../plublic/changeLog.vue";
import addZizhanghao from "../adduser/addZizhanghao.vue";
import editZizhanghao from "../edituser/editZizhanghao.vue";
import fenpeizhanghao from "../plublic/fenpeizhanghao.vue";
export default {
    components: { loginLog, changeLog, addZizhanghao, editZizhanghao, fenpeizhanghao },
    data() {
        return {
            users: [],
            title: "子账号管理",
            panelwidth: "100%",
            post_huiyuan: {
                type: 100,
                page: 1, //页码
                userState: 1, //状态
                sortName: "0", //排序类型：时间0 账号1
                userFlag: "0", //搜索类型：账号0 昵称1
                sortType: "0", //排序顺序：降0 升1
                zstype: "", //所属会员，zgs直属会员，fgs普通会员
                searchkey: "", //搜索时输入的内容
                limit: 30,
                uid: "",
                start_time: "",
                end_time: "",
                zc_time: "",
                filter: [
                    { value: 1, label: "启用" },
                    { value: 3, label: "全部" },
                    { value: 2, label: "冻结" },
                    { value: 0, label: "停用" },
                ],
            },
            pagination: {
                total_records: 0,
                limit: 30,
                page: 1,
            },
            // 修改状态
            change_user_status: {
                visible: false,
                status: "",
            },
            //选中的用户信息
            row_user: {
                id: "",
                username: "",
                status: "",
            },
            other_view: {
                activated_name: "",
                visible: false,
                row: "",
            },
            add_user_data: {},
            addinfo: {
                sup_group_name: "",
                sub_group_name: "会员",
                sub_list: [],
            },
            show_otherset: true,
        };
    },
    computed: {
        relod_sub_menu_data() {
            return this.$store.state.relod_sub_menu_data;
        },
    },
    watch: {
        relod_sub_menu_data(newVal, oldVal) {
            // console.log(newVal, oldVal);
            if (newVal) {
                this.get_users();
                this.$store.state.relod_sub_menu_data = false;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        // console.log(to);
        let show_otherset = true;
        let url = "user_version2/user_list";
        if (to.query.uid != "") {
            url = "user_version2/get_user_list";
            show_otherset = false;
        }
        axios
            .post(url, {
                type: 100,
                page: 1, //页码
                userState: 1, //状态
                sortName: "0", //排序类型：时间0 账号1
                userFlag: "0", //搜索类型：账号0 昵称1
                sortType: "0", //排序顺序：降0 升1
                zstype: "", //所属会员，zgs直属会员，fgs普通会员
                searchkey: "", //搜索时输入的内容
                limit: 30,
                uid: "",
                user_id: to.query.uid ? to.query.uid : "",
                start_time: "",
                end_time: "",
                zc_time: "",
                admin_type: 3,
                db: to.query.db ? to.query.db : "",
            })
            .then((result) => {
                if (result.data.status == 200) {
                    next((vm) => {
                        vm.users = result.data.repsoneContent.list;
                        vm.pagination = result.data.repsoneContent.pagination;
                        vm.show_otherset = show_otherset;
                    });
                }
            });
    },
    methods: {
        //分配账号
        fenpei(row) {
            this.other_view.row = row;
            this.other_view.row.sup_group_name = "上级";
            this.other_view.row.sub_group_name = "子账号";
            this.axios
                .post("user_version2/sub_account", {
                    uid: row.id,
                    act: "list",
                    admin_type: 3,
                    db: this.$route.query.db ? this.$route.query.db : "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.activated_name = "fenpeizhanghao";
                        this.title = `为 ${row.username} 分配账号`;
                        this.panelwidth = "70%";
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["fenpeizhanghao"].getdata(result.data.repsoneContent, row.id);
                        });
                    }
                });
        },
        //修改
        edit_user(row) {
            this.other_view.row = row;
            this.other_view.row.sup_group_name = "上级";
            this.other_view.row.sub_group_name = "子账号";
            this.axios
                .post(`${this.show_otherset ? "user_version2/user_edit" : "user_version2/get_user_edit"}`, {
                    uid: row.id,
                    admin_type: 3,
                    db: this.$route.query.db ? this.$route.query.db : "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.activated_name = "editZizhanghao";
                        this.title = "修改子账号";
                        this.panelwidth = "70%";
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["editZizhanghao"].get_user_edit(result.data.repsoneContent, this.show_otherset);
                        });
                    }
                });
        },
        //新增
        add_user() {
            this.other_view.row = {
                sup_group_name: "上级",
                sub_group_name: "子账号",
            };
            this.axios
                .post(`${this.show_otherset ? "user_version2/user_add" : "user_version2/get_user_add"}`, {
                    group_id: this.$store.state.userinfo.group_id,
                    admin_type: 3,
                    db: this.$route.query.db ? this.$route.query.db : "",
                    user_id: this.$route.query.uid ? this.$route.query.uid : "",
                })
                .then((result) => {
                    if (result.data.status == "200") {
                        this.other_view.activated_name = "addZizhanghao";
                        this.title = "新增子账号";
                        this.panelwidth = "70%";
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["addZizhanghao"].get_user_edit(result.data.repsoneContent, this.show_otherset);
                        });
                    }
                });
        },
        //打开用户修改记录
        open_changeLog(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "changeLog";
            this.title = "修改记录";
            this.panelwidth = "100%";
            this.axios
                .post("log/admin_log", {
                    uid: row.id,
                    page: 1,
                    limit: 20,
                    username: row.username,
                    admin_type: 3,
                    db: this.$route.query.db ? this.$route.query.db : "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["changeLog"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //打开用户登陆日志
        open_loginLog(row) {
            // console.log(row);
            this.other_view.row = row;
            this.other_view.activated_name = "loginLog";
            this.title = "登陆日志";
            this.panelwidth = "100%";
            this.axios
                .post("log/admin_login_log", {
                    uid: row.id,
                    page: 1,
                    limit: 10,
                    admin_type: 3,
                    db: this.$route.query.db ? this.$route.query.db : "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        // console.log(result.data.repsoneContent)
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["loginLog"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //取消修改用户状态
        cancel_change_status() {
            this.change_user_status.visible = false;
            this.get_users();
        },
        //修改用户状态
        change_status(row, submit = false) {
            //修改会员状态
            if (!submit) {
                this.row_user = row;
                this.change_user_status.status = row.status;
                this.change_user_status.visible = true;
            } else {
                this.axios
                    .post(`${this.show_otherset ? "user_version2/member_status" : "user_version2/get_member_status"}`, {
                        status: this.change_user_status.status,
                        uid: this.row_user.id,
                        admin_type: 3,
                        db: this.$route.query.db ? this.$route.query.db : "",
                    })
                    .then((result) => {
                        if (result.data.status == 200) {
                            this.$message({
                                message: result.data.shortMessage,
                                type: "success",
                            });
                            this.change_user_status.visible = false;
                            //刷新列表
                            this.get_users(this.link);
                        }
                    });
            }
        },
        //子账号用户list分页查询
        change_page(page) {
            this.post_huiyuan.page = page;
            this.get_users();
        },
        //获取子账号用户list
        get_users() {
            this.post_huiyuan.admin_type = 3;
            this.post_huiyuan.db = this.$route.query.db ? this.$route.query.db : "";
            this.post_huiyuan.user_id = this.$route.query.uid ? this.$route.query.uid : "";
            this.axios.post(`${this.show_otherset ? "user_version2/user_list" : "user_version2/get_user_list"}`, this.post_huiyuan).then((result) => {
                if (result.data.status == 200) {
                    this.users = result.data.repsoneContent.list;
                    this.pagination = result.data.repsoneContent.pagination;
                    this.other_view.visible = false;
                    this.panelwidth = "100%";
                }
            });
        },
        //其他页面返回
        other_view_back() {
            this.title = "子账号管理";
            this.get_users();
        },
    },
};
</script>