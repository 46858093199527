import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.join.js";
import axios from '../../../axios/axios';
export default {
  name: 'dailiipshezhi',
  components: {},
  data: function data() {
    return {
      list: [],
      title: '代理ip设置',
      dialog: {
        username: '',
        dip: '',
        qip: '',
        sip: '',
        ips: [],
        remark: '',
        id: ''
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("user_version2/user_ips", {}).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.list.forEach(function (item) {
            if (item.ips.includes(',')) {
              item.ips = item.ips.split(',');
            } else {
              item.ips = [item.ips];
            }
          });
        });
      }
    });
  },
  created: function created() {},
  methods: {
    //随机排列
    shuffle: function shuffle() {
      var arr = JSON.parse(JSON.stringify(this.dialog.ips));
      var temp = [];

      for (var i = arr.length; i > 0; i--) {
        var temRandom = Math.floor(Math.random() * i);
        temp.push(arr[temRandom]);
        arr.splice(temRandom, 1); //抽取一张后，要除去这张牌，然后在剩下的牌中继续抽
      }

      this.dialog.ips = temp;
    },
    //新增
    add: function add() {
      this.dialog.username = '';
      this.dialog.ip = '';
      this.dialog.remark = '';
      this.title = '新增代理ip';
    },
    //加单个
    add_ip: function add_ip() {
      if (this.dialog.dip == '') {
        return this.$message.error('请输入代理ip');
      } else {
        this.dialog.ips.push({
          text: this.dialog.dip
        });
        this.dialog.dip = '';
      }
    },
    //加起始段
    add_ip_qs: function add_ip_qs() {
      var _this = this;

      if (this.dialog.qip == '') {
        return this.$message.error('请输入开始ip');
      }

      if (this.dialog.sip == '') {
        return this.$message.error('请输入结束ip');
      }

      var qip = this.dialog.qip;
      var sip = this.dialog.sip;

      if (!this.isValidIP(qip)) {
        return this.$message.error('开始ip不正确');
      }

      if (!this.isValidIP(sip)) {
        return this.$message.error('结束ip不正确');
      }

      var q_list = qip.split('.');
      var s_list = sip.split('.');
      q_list.forEach(function (item) {
        if (item > 255) {
          return _this.$message.error('每段数值最大为255');
        } else {
          item = item * 1;
        }
      });
      s_list.forEach(function (item) {
        if (item > 255) {
          return _this.$message.error('每段数值最大为255');
        } else {
          item = item * 1;
        }
      }); // console.log(q_list, s_list);

      if (q_list[0] == s_list[0] && q_list[1] == s_list[1]) {
        var arr = [];
        var one = q_list[0];
        var two = q_list[1];

        if (q_list[2] == s_list[2]) {
          var three = q_list[2];

          if (q_list[3] == s_list[3]) {
            var four = q_list[3];
            this.dialog.ips.push({
              text: "".concat(one, ".").concat(two, ".").concat(three, ".").concat(four)
            });
          }

          if (q_list[3] < s_list[3]) {
            var num = s_list[3] * 1 + 1;
            var list = [];

            for (var index = q_list[3]; index < num; index++) {
              list.push(index);
            }

            list.forEach(function (item) {
              _this.dialog.ips.push({
                text: "".concat(one, ".").concat(two, ".").concat(three, ".").concat(item)
              });
            });
          }
        }

        if (q_list[2] < s_list[2]) {
          var _three = q_list[2];
          var three1 = s_list[2];
          var tou_list = [];
          var wei_list = [];
          var list1 = [];
          var list2 = [];

          for (var _index = q_list[3]; _index < 256; _index++) {
            tou_list.push(_index);
          }

          tou_list.forEach(function (item) {
            list1.push({
              text: "".concat(one, ".").concat(two, ".").concat(_three, ".").concat(item)
            });
          });

          for (var _index2 = 0; _index2 <= s_list[3]; _index2++) {
            wei_list.push(_index2);
          }

          wei_list.forEach(function (item) {
            list2.push({
              text: "".concat(one, ".").concat(two, ".").concat(three1, ".").concat(item)
            });
          });

          var _num = s_list[2] - q_list[2] - 1;

          var list3 = [];
          var three2 = q_list[2] * 1 + 1;

          if (_num > 0) {
            for (var _index3 = 0; _index3 < _num; _index3++) {
              for (var index1 = 0; index1 < 256; index1++) {
                list3.push({
                  text: "".concat(one, ".").concat(two, ".").concat(three2, ".").concat(index1)
                });
                ;
              }

              three2 += 1;

              if (three2 >= s_list[2]) {
                break;
              }
            }
          }

          this.dialog.ips = [].concat(list1, list2, list3);
        } // console.log(this.dialog.ips);

      } else {
        return this.$message.error('请确认起止ip段的一二节数字一致');
      }
    },
    //ip验证
    isValidIP: function isValidIP(ip) {
      var reg = /^((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;
      return reg.test(ip);
    },
    //删除单个已经ip标签
    handleClose: function handleClose(index) {
      this.dialog.ips.splice(index, 1);
    },
    //取消添加
    cancel_add: function cancel_add() {
      this.get_list();
      this.title = '代理ip设置';
      this.dialog.username = '';
      this.dialog.dip = '';
      this.dialog.qip = '';
      this.dialog.sip = '';
      this.dialog.remark = '';
      this.dialog.ips = [];
    },
    //提交添加
    sub_add: function sub_add() {
      var _this2 = this;

      var ips = [];
      this.dialog.ips.forEach(function (item) {
        if (item.text != '') {
          ips.push(item.text);
        }
      });

      if (ips.length == 0) {
        return this.$message.error('请输入代理ip');
      }

      this.axios.post("user_version2/user_ips_add", {
        username: this.dialog.username,
        ips: ips.join(','),
        remark: this.dialog.remark,
        act: 'add'
      }).then(function (result) {
        if (result.data.status == 200 || result.data.status) {
          _this2.$message.success(result.data.repsoneContent || '添加成功！');

          _this2.get_list();

          _this2.title = '代理ip设置';
        }
      });
    },
    //修改
    change_ip: function change_ip(row) {
      this.dialog.username = row.username;
      this.dialog.remark = row.remark;
      this.dialog.id = row.id;
      var ips = [];
      row.ips.forEach(function (item) {
        ips.push({
          text: item
        });
      });
      this.dialog.ips = ips;
      this.title = "\u4FEE\u6539\u4EE3\u7406ip";
    },
    sub_change: function sub_change() {
      var _this3 = this;

      var ips = [];
      this.dialog.ips.forEach(function (item) {
        if (item.text != '') {
          ips.push(item.text);
        }
      });

      if (ips.length == 0) {
        return this.$message.error('请输入代理ip');
      }

      this.axios.post("user_version2/user_ips_edit", {
        username: this.dialog.username,
        ips: ips.join(','),
        remark: this.dialog.remark,
        id: this.dialog.id,
        act: 'edit'
      }).then(function (result) {
        if (result.data.status == 200 || result.data.status) {
          _this3.$message.success(result.data.repsoneContent || '修改成功！');

          _this3.get_list();

          _this3.title = '代理ip设置';
        }
      });
    },
    //删除
    del: function del(id) {
      var _this4 = this;

      this.axios.post("user_version2/user_ips_edit", {
        id: id,
        act: 'del'
      }).then(function (result) {
        if (result.data.status == 200 || result.data.status) {
          _this4.$message.success(result.data.shortMessage || '删除成功！');

          _this4.get_list();
        }
      });
    },
    get_list: function get_list() {
      var _this5 = this;

      this.axios.post("user_version2/user_ips", {}).then(function (result) {
        if (result.data.status == 200) {
          _this5.list = result.data.repsoneContent.list;

          _this5.list.forEach(function (item) {
            if (item.ips.includes(',')) {
              item.ips = item.ips.split(',');
            } else {
              item.ips = [item.ips];
            }
          });
        }
      });
    }
  }
};