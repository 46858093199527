<template>
    <div class="gameDetail">
        <template v-if="list.length == 0"> 暂无数据 </template>
        <div v-for="(item, i) of list" :key="i">
            <div class="panel">
                <div class="panel-title">
                    <h2>{{ item.gname }}</h2>
                </div>
                <div class="panel-body">
                    <el-table :data="item.bet.bet" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" show-summary :summary-method="baobiao_sum">
                        <el-table-column prop="username" label="代理" align="center">
                            <template #default="scope">
                                <template v-if="scope.row.group_id === '0'">
                                    <span>{{ scope.row.username }}</span>
                                    <span style="color: #e6a23c">会员</span>
                                </template>
                                <el-link v-else type="success" @click="$emit('search_sub', scope.row)">{{ scope.row.username }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="real_name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="bet_count" label="笔数" align="center"></el-table-column>
                        <!-- <el-table-column prop="amount" label="余额" align="center"></el-table-column> -->
                        <el-table-column prop="sum_bet" label="总投注额" align="center"></el-table-column>
                        <el-table-column prop="balance" label="有效金额" align="center">
                            <template #default="scope">
                                <el-link v-if="scope.row.balance != '0'" type="success" @click="$emit('search_detail', scope.row, item.gid)">{{ scope.row.balance }}</el-link>
                                <span v-else>{{ scope.row.balance }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bonus" label="会员输赢" align="center">
                            <template #default="scope">
                                <div v-if="form.settle_state != 2">
                                    <span v-if="scope.row.bonus - scope.row.balance < 0" class="redcolor">{{ (scope.row.bonus - scope.row.balance).toFixed(0) }}</span>
                                    <span v-else>{{ (scope.row.bonus - scope.row.balance).toFixed(0) }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="receive_subline" label="应收下线" align="center">
                            <template #default="scope">
                                <div>
                                    <el-tooltip placement="top">
                                        <template #content>
                                            输赢：{{ (scope.row.balance - scope.row.bonus).toFixed(0) }}
                                            <br />
                                            退水：{{ scope.row.rebate }}
                                        </template>
                                        <span :class="scope.row.receive_subline < 0 ? 'redcolor' : ''">{{ scope.row.receive_subline }}</span>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="occupy_self" label="占成" align="center" width="40"></el-table-column>
                        <el-table-column prop="occu_balance" label="实占注额" align="center"></el-table-column>
                        <el-table-column prop="bonus" label="占货比" align="center" width="47">
                            <template #default="scope">
                                <span v-if="item.bet.small.occu_balance == 0">0</span>
                                <span v-else>{{ ((scope.row.occu_balance / item.bet.small.occu_balance) * 100).toFixed(0) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="occu_winlose" label="实占输赢" align="center">
                            <template #default="scope">
                                <div>
                                    <el-tooltip v-if="scope.row.group_id === '2'" placement="top">
                                        <template #content>
                                            输赢：{{ scope.row.occu_winlose - scope.row.eran }}
                                            <br />
                                            赚赔：{{ scope.row.eran }}
                                        </template>
                                        <span :class="scope.row.occu_winlose < 0 ? 'redcolor' : ''">{{ scope.row.occu_winlose }}</span>
                                    </el-tooltip>
                                    <div v-else>{{ scope.row.occu_winlose }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="occu_rebate" label="实占退水" align="center"></el-table-column>
                        <el-table-column prop="earn_rebate" label="赚取退水" align="center"></el-table-column>
                        <el-table-column prop="real_result" label="退水后结果" align="center">
                            <template #default="scope">
                                <span :class="scope.row.real_result < 0 ? 'redcolor' : ''">{{ scope.row.real_result }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cont_sup" label="贡献上级" align="center"></el-table-column>
                        <el-table-column prop="receive_supline" label="应付上级" align="center">
                            <template #default="scope">
                                <span :class="scope.row.receive_supline < 0 ? 'redcolor' : ''">{{ scope.row.receive_supline }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        form: {},
        list: {
            type: Array,
            default: [],
        },
    },
    methods: {
        baobiao_sum(param) {
            let { columns, data } = param;
            let sums = [];
            columns.forEach((column, index) => {
                if (column.label == "代理") {
                    return (sums[index] = "");
                }
                if (column.label == "名称") {
                    return (sums[index] = "合计");
                }
                // 笔数
                if (column.label == "笔数") {
                    return (sums[index] = data[0].small.bet_count);
                }
                //总投注额
                if (column.label == "总投注额") {
                    return (sums[index] = data[0].small.sum_bet);
                }
                //有效金额
                if (column.label == "有效金额") {
                    return (sums[index] = data[0].small.balance);
                }
                //会员输赢
                if (column.label == "会员输赢") {
                    return (sums[index] = (data[0].small.bonus * 1 - data[0].small.balance * 1).toFixed(0));
                }
                //应收下线
                if (column.label == "应收下线") {
                    return (sums[index] = data[0].small.receive_subline);
                }
                // //实占成数
                if (column.label == "占成") {
                    return (sums[index] = "");
                }
                //实占注额
                if (column.label == "实占注额") {
                    return (sums[index] = data[0].small.occu_balance);
                }
                //占货比
                if (column.label == "占货比") {
                    return (sums[index] = "");
                }
                //实占输赢
                if (column.label == "实占输赢") {
                    return (sums[index] = data[0].small.occu_winlose);
                }
                //实占退水
                if (column.label == "实占退水") {
                    return (sums[index] = data[0].small.occu_rebate);
                }
                //实占结果
                if (column.label == "实占结果") {
                    return (sums[index] = data[0].small.real_occupy_result);
                }
                //赚取退水
                if (column.label == "赚取退水") {
                    return (sums[index] = data[0].small.earn_rebate);
                }
                //赚取赔差
                if (column.label == "赚取赔差") {
                    return (sums[index] = data[0].small.eran);
                }
                //退水后结果
                if (column.label == "退水后结果") {
                    return (sums[index] = data[0].small.real_result);
                }
                //贡献上级
                if (column.label == "贡献上级") {
                    return (sums[index] = data[0].small.cont_sup);
                }
                //应付上级
                if (column.label == "应付上级") {
                    return (sums[index] = data[0].small.receive_supline);
                }
            });
            return sums;
        },
        //报表列的颜色
        cellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex != 0 && columnIndex != 1) {
                //合计列
                this.$nextTick(() => {
                    let dom = this.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0];
                    let val = this.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0].innerText;
                    dom.style.color = !Number.isNaN(val) && val < 0 ? "red" : "#000";
                });
                //数据列
                let columnProperty = this.data_list[rowIndex][column.property];
                if (columnProperty < 0) {
                    return "redcolor"; // class名称
                } else {
                    return "blackcolor"; // class名称
                }
            }
        },
    },
};
</script>

<style scoped>
.gameDetail {
    min-height: 50px;
    text-align: center;
    background-color: #eee;
}
.game-name {
    font-weight: bold;
}
</style>