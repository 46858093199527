import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  props: ["userinfo"],
  data: function data() {
    return {
      uname: "",
      pwd: "",
      allow_ip: "",

      /**权限 */
      menu: [],
      err: {
        uname: {
          visible: false,
          content: ""
        },
        pwd: {
          visible: false,
          content: ""
        }
      }
    };
  },
  // created() {
  //     this.get_user_edit();
  // },
  methods: {
    //分组全选
    change_item_fz: function change_item_fz(item, status) {
      // console.log(item, status);
      item.mk.forEach(function (mk) {
        mk.info.is_select = status;

        if (mk.ff.length > 0) {
          mk.ff.forEach(function (ff) {
            ff.info.is_select = status;
          });
        }
      });
    },
    handleCheckOneChange: function handleCheckOneChange(e, item) {
      if (e) {
        item.two.forEach(function (element) {
          element.is_select = true;
        });
      } else {
        item.two.forEach(function (element) {
          element.is_select = false;
        });
      }
    },
    handleCheckTwoChange: function handleCheckTwoChange(e, item) {
      if (!e && !item.two.find(function (i) {
        return i.is_select === true;
      })) {
        item.info.is_select = false;
      } else {
        item.info.is_select = true;
      }
    },

    /**获取用户修改菜单选项，权限 */
    get_user_edit: function get_user_edit(data) {
      this.uname = this.userinfo.real_name;
      this.allow_ip = data.user_info.allow_ip;
      var res = data.powers;
      this.menu = []; //生成分组

      for (var i = 0; i < res.length; i++) {
        if (res[i].class == 1) {
          this.menu.push({
            name: res[i].function,
            info: res[i],
            mk: []
          });
        }
      } //生成分组对应的模块


      if (res.find(function (item) {
        return item.function == "大股东";
      }) != undefined) {
        var obj = res.splice(res.findIndex(function (item) {
          return item.function == "大股东";
        }), 1)[0];
        res.splice(res.findIndex(function (item) {
          return item.function == "股东";
        }), 0, obj);
      }

      for (var _i = 0; _i < res.length; _i++) {
        if (res[_i].class == 2) {
          for (var j = 0; j < this.menu.length; j++) {
            if (res[_i].parent_id == this.menu[j].info.id) {
              if (res[_i].function == "大总监") {
                this.menu[j].mk.unshift({
                  name: res[_i].function,
                  info: res[_i],
                  ff: []
                });
              } else {
                this.menu[j].mk.push({
                  name: res[_i].function,
                  info: res[_i],
                  ff: []
                });
              }
            }
          }
        }
      } //生成模块对应的方法


      for (var _i2 = 0; _i2 < res.length; _i2++) {
        if (res[_i2].class == 3) {
          for (var _j = 0; _j < this.menu.length; _j++) {
            for (var k = 0; k < this.menu[_j].mk.length; k++) {
              if (res[_i2].parent_id == this.menu[_j].mk[k].info.id) {
                this.menu[_j].mk[k].ff.push({
                  name: res[_i2].function,
                  info: res[_i2]
                });
              }
            }
          }
        }
      } // console.log(this.menu );

    },

    /**提交修改 */
    post_change: function post_change() {
      var _this = this;

      if (this.pwd !== "" && !this.$filters.passwordReg(this.pwd)) {
        return this.err.pwd = {
          visible: true,
          content: "密码必须包含：大写字母、小写字母、特殊字符、数字，4种组合，8-20位"
        };
      }

      var list = [];
      this.menu.forEach(function (fz) {
        if (fz.info.is_select) {
          list.push(fz.info.id);
        }

        fz.mk.forEach(function (mk) {
          if (mk.info.is_select) {
            list.push(fz.info.id);
            list.push(mk.info.id);
          }

          mk.ff.forEach(function (ff) {
            if (ff.info.is_select) {
              list.push(fz.info.id);
              list.push(ff.info.id);
            }
          });
        });
      });
      list = Array.from(new Set(list));
      list = list.toString(); // console.log(list)

      this.axios.post("".concat(this.$route.query.db ? "user_version2/get_user_save" : "user_version2/user_save"), {
        uid: this.userinfo.id,
        userPassword: this.pwd,
        userNicker: this.uname,
        allow_ip: this.allow_ip,
        checkbox: list,
        admin_type: 3,
        db: this.$route.query.db ? this.$route.query.db : "",
        user_id: this.$route.query.uid ? this.$route.query.uid : ""
      }).then(function (result) {
        // console.log(result.data)
        if (result.data.status == "200") {
          _this.$message.success(result.data.shortMessage);
        }

        _this.finish_cancel();
      });
    },

    /**修改成功和取消修改，上报父级 */
    finish_cancel: function finish_cancel() {
      this.$emit("other_view_back", "");
    }
  }
};