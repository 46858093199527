<!--
 * @Author: your name
 * @Date: 2022-03-25 00:09:47
 * @LastEditTime: 2024-09-18 17:54:47
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\history\game\lhc.vue
-->

<template>
    <div class="lhc">
        <el-table
            :data="history_data"
            table-layout="auto"
            border
            size="small"
            highlight-current-row
            header-cell-class-name="table-header-cell"
            header-row-class-name="table-header-row"
            row-key="id"
            :expand-row-keys="expands"
            @row-click="clickRowHandle"
        >
            <el-table-column property="qnum" label="期号" align="center" width="70px"></el-table-column>
            <el-table-column label="开奖日期" align="center" width="150px">
                <template #default="scope">
                    <span>{{ $filters.time(scope.row.starttime) }}</span>
                </template>
            </el-table-column>
            <el-table-column property="res" label="开出号码" align="center">
                <template #default="scope">
                    <!-- <span class="nums-lhc" v-for="item in scope.row.res_s" :key="item.index" :style="{backgroundColor:item.color}">{{item.num}}</span> -->
                    <span v-if="scope.row.res_s[0] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[0].color }">{{ scope.row.res_s[0].num }}</span>
                    <span v-if="scope.row.res_s[1] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[1].color }">{{ scope.row.res_s[1].num }}</span>
                    <span v-if="scope.row.res_s[2] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[2].color }">{{ scope.row.res_s[2].num }}</span>
                    <span v-if="scope.row.res_s[3] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[3].color }">{{ scope.row.res_s[3].num }}</span>
                    <span v-if="scope.row.res_s[4] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[4].color }">{{ scope.row.res_s[4].num }}</span>
                    <span v-if="scope.row.res_s[5] != undefined" class="nums-lhc" :style="{ backgroundColor: scope.row.res_s[5].color }">{{ scope.row.res_s[5].num }}</span>
                    <el-icon v-if="scope.row.res_s[6] != undefined" color="#000" :size="30">
                        <Plus />
                    </el-icon>
                    <span v-if="scope.row.res_s[6] != undefined" class="nums-lhc" style="margin: 0" :style="{ backgroundColor: scope.row.res_s[6].color }">{{ scope.row.res_s[6].num }}</span>
                </template>
            </el-table-column>
            <el-table-column label="生肖" align="center">
                <template #default="scope">
                    <span style="margin: 0 2px" v-for="(item, index) in scope.row.res_s" :key="item.index">
                        <span>{{ item.sx }}</span>
                        <el-icon v-show="index == 5 && scope.row.res_s[6].num != ''" color="#000" :size="20">
                            <Plus />
                        </el-icon>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="生肖量" width="50" align="center" property="res_info2.sxl"></el-table-column>
            <el-table-column label="尾数量" width="50" align="center" property="res_info2.wsl"></el-table-column>
            <el-table-column label="特码" align="center">
                <el-table-column label="单双" width="40" align="center" property="res_info2.tema_ds"></el-table-column>
                <el-table-column label="大小" width="40" align="center" property="res_info2.tema_dx"></el-table-column>
                <el-table-column label="合单双" width="50" align="center" property="res_info2.tema_he_ds"></el-table-column>
                <el-table-column label="合大小" width="50" align="center" property="res_info2.tema_he_dx"></el-table-column>
                <el-table-column label="尾大小" width="50" align="center" property="res_info2.tema_wei_dx"></el-table-column>
            </el-table-column>
            <el-table-column label="总和" align="center">
                <el-table-column label="总和" width="40" align="center" property="res_info2.sum"></el-table-column>
                <el-table-column label="单双" width="50" align="center" property="res_info2.sum_ds"></el-table-column>
                <el-table-column label="大小" width="50" align="center" property="res_info2.sum_dx"></el-table-column>
            </el-table-column>
            <el-table-column property="djm" align="center" width="1" type="expand">
                <template #default="scope">
                    <div class="item-others-txid">
                        <div class="tl" v-for="tl in scope.row.djm" :key="tl.index">
                            <span v-for="t in tl" :key="t.index" :style="{ color: t.color }">{{ t.text }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    props: ["history_data"],
    data() {
        return {
            expands: [],
        };
    },
    methods: {
        clickRowHandle(row, column, event) {
            this.expands = [];
            if (this.expands.includes(row.id)) {
                this.expands = this.expands.filter((val) => val !== row.id);
            } else {
                this.expands.push(row.id);
            }
        },
    },
};
</script>

<style scoped>
.nums-lhc {
    display: inline-block;
    font-size: 16px;
    line-height: 27px;
    font-weight: bold;
    border-radius: 50%;
    height: 27px;
    width: 27px;
    margin: 0;
    margin-left: 6px;
    color: #fff;
}
</style>