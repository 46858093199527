<!--
 * @Author: your name
 * @Date: 2022-04-16 23:16:52
 * @LastEditTime: 2024-03-31 23:47:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\tongji.vue\zaixiantongji.vue
-->
<template>
    <div class="zaixiantongji" style="padding: 0 5px">
        <div style="text-align: center; padding: 5px">
            <span>当前选择公司：</span>
            <el-radio-group size="small" v-model="gs.checked" @change="get_bet_users" text-color="#fff" fill="#E6A23C">
                <el-radio-button v-for="item in gs.list" :key="item.username" :label="item.username">{{ item.name }}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="panel">
            <div class="panel-title">
                <h2>在线统计</h2>
            </div>
            <div class="panel-body">
                <el-table :data="group_count" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                    <el-table-column v-if="$store.state.userinfo.group_id < 2" align="center" label="大总监">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线大总监', 2)">{{ scope.row.dzj }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 3" align="center" label="总监">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线总监', 3)">{{ scope.row.zj }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 4" align="center" label="分公司">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线分公司', 4)">{{ scope.row.fgs }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 5" align="center" label="大股东">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线大股东', 5)">{{ scope.row.gd }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 5" align="center" label="股东">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线股东', 6)">{{ scope.row.gd }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 6" align="center" label="总代理">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线总代理', 7)">{{ scope.row.zdl }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$store.state.userinfo.group_id < 7" align="center" label="代理">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('在线代理', 8)">{{ scope.row.dl }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="会员">
                        <template #default="scope">
                            <el-link type="primary" @click="get_sub_users('投注会员', 0)">{{ scope.row.hy }}</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="panel" style="margin-top: 10px" v-show="!weijie.show">
            <div class="panel-title">
                <h2>
                    <span>{{ typename }}：电脑{{ pc_num }}人，手机：{{ phone_num }}人</span>
                </h2>
                <el-button style="float: right; margin-top: 3px" type="primary" size="small" @click="ref_list">刷新</el-button>
            </div>
            <div class="panel-body">
                <el-table :data="list" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                    <el-table-column property="id" label="编号" align="center"></el-table-column>
                    <el-table-column property="username" label="账号" align="center"></el-table-column>
                    <el-table-column property="real_name" label="姓名" align="center"></el-table-column>
                    <el-table-column property="platform" label="登录设备" align="center"></el-table-column>
                    <el-table-column property="diff_time" label="在线时长" align="center"></el-table-column>
                    <el-table-column property="posttime" label="最后操作时间" align="center"></el-table-column>
                    <el-table-column property="ip" label="IP" align="center"></el-table-column>
                    <el-table-column property="ip_address" label="IP地址" align="center"></el-table-column>
                    <el-table-column property="count" label="未结算注单" align="center">
                        <template #default="scope">
                            <el-link type="primary" @click="get_weijie(scope.row)">{{ scope.row.count }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column property label="操作" align="center">
                        <template #default="scope">
                            <span class="tichu" @click="tichu(scope.row)">踢出系统</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="paging"
                    small
                    v-model:current-page="page"
                    :page-size="20"
                    :pager-count="5"
                    :total="pagination.total_records * 1"
                    :page-count="pagination.total_pages * 1"
                    layout="total,prev,pager,next,jumper"
                    prev-text="上一页"
                    next-text="下一页"
                    hide-on-single-page
                />
            </div>
        </div>
        <div class="panel" style="margin-top: 10px" v-show="weijie.show">
            <div class="panel-title">
                <h2>
                    <span>未结算注单：{{ weijie.username }}</span>
                </h2>
                <div style="float: right">
                    <span style="margin: 0 20px">
                        <span>总计：</span>
                        <span style="color: red">{{ weijie.total.count }}单</span>
                        <span>&nbsp;&nbsp;注额：</span>
                        <span style="color: red">{{ weijie.total.balance }}</span>
                    </span>
                    <el-button type="primary" size="small" @click="weijie.show = false">返 回</el-button>
                </div>
            </div>
            <div class="panel-body">
                <el-table :data="weijie.list" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                    <el-table-column property="game_name" label="彩种" align="center"></el-table-column>
                    <el-table-column property="qnum" label="期号" align="center"></el-table-column>
                    <el-table-column property="order_id" label="注单号" align="center"></el-table-column>
                    <el-table-column property="platform" label="下注时间" align="center">
                        <template #default="scope">
                            <span>{{ $filters.time(scope.row.posttime, "报表注单") }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="game_level" label="下注明细" align="center">
                        <template #default="scope">
                            <span>{{ scope.row.game_level }}@</span>
                            <span style="color: red">{{ scope.row.list_bonus * 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="balance" label="下注额" align="center"></el-table-column>
                </el-table>
                <el-pagination
                    class="paging"
                    small
                    v-model="weijie.page"
                    @current-change="get_weijie(weijie.row, $event)"
                    :default-page-size="weijie.limit"
                    :pager-count="5"
                    :total="weijie.pagination.total_records * 1"
                    layout="total,prev,pager,next,jumper"
                    prev-text="上一页"
                    next-text="下一页"
                    hide-on-single-page
                />
            </div>
        </div>
    </div>
</template>

<script>
import { fa } from "element-plus/lib/locale";
import axios from "../../axios/axios";
export default {
    components: {},
    data() {
        return {
            type: "1", //页面类型，daili,huiyuan,touzhurenshu
            typename: "管理", //页面类型，daili,huiyuan,touzhurenshu
            group_count: [{ dzj: 0, zj: 0, fgs: 0, gd: 0, zdl: 0, dl: 0, hy: 0 }],
            list: [],
            page: 1,
            pagination: {
                page_size: "0", //每页条数
                total_pages: 0, //总页数
                total_records: "0", //总条数
            },
            pc_num: 0,
            phone_num: 0,
            search_group: "",
            weijie: {
                show: false,
                username: "",
                list: [],
                row: "",
                page: 1,
                limit: 20,
                pagination: {
                    page_size: "0", //每页条数
                    total_pages: 0, //总页数
                    total_records: "0", //总条数
                },
                total: "",
            },
            gs: {
                visible: false,
                list: [],
                checked: "",
                pagination: { total_pages: 0, total_records: "0", page_size: 10 },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        let type = to.query.type;
        console.log(to.query.db);
        if (type == 2) {
            axios
                .post("count/log_bet", {
                    page: 1,
                    limit: 20,
                    db: to.query.db,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        next((vm) => {
                            vm.type = 2;
                            vm.typename = "投注人数";
                            vm.proc_data(result.data.repsoneContent);
                            vm.gs.checked = to.query.db;
                            vm.gs.list = vm.$store.state.gs.list;
                        });
                    }
                });
        } else {
            axios
                .post("count/log_now", {
                    page: 1,
                    limit: 20,
                    group_status: type,
                    group_id: "",
                    db: to.query.db,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        next((vm) => {
                            vm.type = type;
                            vm.typename = type == 1 ? "在线管理" : "投注会员";
                            vm.search_group = "";
                            vm.proc_data(result.data.repsoneContent);
                            // 通过 `vm` 访问组件实例
                            vm.gs.checked = to.query.db;
                            vm.gs.list = vm.$store.state.gs.list;
                        });
                    }
                });
        }
    },
    watch: {
        $route(to, from) {
            this.type = to.query?.type || 1;
            this.search_group = "";
            this.ref_list();
        },
        page(n, o) {
            if (this.type == 2) {
                this.get_bet_users(n);
            } else {
                if (this.typename == "在线管理") {
                    this.get_users(n, "");
                } else {
                    this.get_users(n, this.type);
                }
            }
        },
    },
    methods: {
        //未结注单
        get_weijie(row, page) {
            this.weijie.row = row;
            this.weijie.username = row.username;
            this.axios
                .post("betbackup/no_order_list", {
                    user_id: row.id,
                    group_id: row.group_id,
                    start_time: "",
                    end_time: "",
                    page: page,
                    limit: 20,
                    db: this.gs.checked,
                })
                .then((result) => {
                    if (result.data.status === 200) {
                        if (row.count > 0) {
                            if (result.data.repsoneContent == "" || result.data.repsoneContent.longMessage == "暂无数据") {
                                row.count = 0;
                                this.$message("暂无数据，注单已结算");
                                this.get_bet_users(this.page);
                            } else {
                                this.weijie.list = result.data.repsoneContent.list;
                                this.weijie.pagination = result.data.repsoneContent.pagination;
                                this.weijie.total = result.data.repsoneContent.total;
                                this.weijie.show = true;
                            }
                        }
                    }
                });
        },
        ref_list() {
            if (this.type == 2) {
                this.get_bet_users();
            } else {
                this.get_users(1, this.search_group);
            }
        },
        //踢出系统
        tichu(row) {
            this.$messageBox
                .confirm("确认要将此用户踢出系统吗？", "操作确认")
                .then(() => {
                    this.axios.post("user_version2/user_online_status", { id: row.id, db: this.gs.checked }).then((result) => {
                        if (result.data.status === 200) {
                            this.$message.success(result.data.shortMessage);
                            this.get_users(this.page, this.search_group);
                        }
                    });
                })
                .catch(() => {});
        },
        //下级分类点击
        get_sub_users(group_name = "", group_id = 0) {
            this.typename = group_name;
            this.type = group_name == "投注会员" ? 0 : 1;
            this.search_group = group_id;
            this.get_users(1, group_id);
        },
        // change_page(page = 1) {
        //     // console.log(page);
        //     this.page = page;
        //     if (this.type == 2) {
        //         this.get_bet_users(page);
        //     } else {
        //         this.get_users(page, this.type);
        //     }
        // },
        get_bet_users(page = 1) {
            let obj = {
                page,
                limit: 20,
                db: this.gs.checked,
            };
            this.axios.post("count/log_bet", obj).then((result) => {
                if (result.data.status == 200) {
                    this.proc_data(result.data.repsoneContent);
                }
            });
        },
        get_users(page = 1, search_group = "") {
            let obj = {
                page,
                limit: 20,
                group_status: this.type,
                group_id: search_group,
                db: this.gs.checked,
            };
            this.axios.post("count/log_now", obj).then((result) => {
                if (result.data.status == 200) {
                    this.proc_data(result.data.repsoneContent);
                    this.weijie.show = false;
                }
            });
        },
        proc_data(data = {}) {
            let group_count = data.group_count;
            // console.log(data);
            group_count.forEach((item) => {
                if (item.group_id == 2) {
                    this.group_count[0].dzj = item.num;
                }
                if (item.group_id == 3) {
                    this.group_count[0].zj = item.num;
                }
                if (item.group_id == 4) {
                    this.group_count[0].fgs = item.num;
                }
                if (item.group_id == 5) {
                    this.group_count[0].gd = item.num;
                }
                if (item.group_id == 6) {
                    this.group_count[0].zdl = item.num;
                }
                if (item.group_id == 7) {
                    this.group_count[0].dl = item.num;
                }
                if (item.group_id == 0) {
                    this.group_count[0].hy = item.num;
                }
            });
            this.list = data.list;
            this.pagination = data.pagination;
            this.pc_num = data.pc_num;
            this.phone_num = data.phone_num;
        },
    },
};
</script>

<style scoped>
.tichu：hover {
    color: red;
    cursor: pointer;
}
.tichu {
    height: 18px;
    line-height: 18px;
    background-repeat: no-repeat;
    float: left;
    padding-left: 19px;
    margin-right: 5px;
    vertical-align: middle;
    background: url("../../assets/tixian.png") no-repeat 2px;
    text-decoration-line: none;
}
</style>