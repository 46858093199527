/*
 * @Author: your name
 * @Date: 2022-03-11 21:09:56
 * @LastEditTime: 2024-07-02 18:33:25
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\store\store.js
 */
import { createStore } from 'vuex'

export default createStore({
    // state() {
    //     return {
    //         userinfo: {
    //             user_id: '',
    //         },
    //         themeColor: '',
    //         themeColorhalf: '',
    //         themeColorLight: '',
    //         game_list: [],
    //         gid: '',
    //         instant_gameinfo: '',
    //         instant_checked_gid: '',
    //         instant_checked_gname: '',
    //         activated_sup: 'notice',
    //         notice_hasshow: false,//是否已经显示过公告弹窗
    //         int_router_view: false,//初始化路由页面
    //         instant_pk: '0',
    //         viewdata: {},
    //         watchsub_show: false,
    //         watchsub_sub_id: false,
    //         watchsub: {
    //             group_name: '',
    //             username: '',
    //         },
    //         relod_sub_menu_data: false,//是否刷新小菜单数据
    //         route_data: {
    //             zidongbuhuosheding: { list: [], play_arr: {} }
    //         },
    //         gs: {
    //             checked: '',
    //             list: [],
    //         },
    //         jszd_users: [],
    //         menulist: [],
    //         now_menu: '',
    //     };
    // },
    // mutations: {
    //     RESET_STATE(state) {
    //         console.log()
    //         // 重置所有状态到初始状态
    //         const initialState = state;
    //         Object.keys(state).forEach(key => {
    //             state[key] = initialState[key];
    //         });
    //     }
    // },
    // actions: {
    //     resetState({ commit }) {
    //         commit('RESET_STATE');
    //     }
    // },
    state: {
        userinfo: {
            user_id: '',
        },
        themeColor: '',
        themeColorhalf: '',
        themeColorLight: '',
        game_list: [],
        trader_game_list: [],
        operate_game_list: [],
        gid: '',
        instant_gameinfo: '',
        instant_checked_gid: '',
        instant_checked_gname: '',
        activated_sup: 'notice',
        notice_hasshow: false,//是否已经显示过公告弹窗
        int_router_view: false,//初始化路由页面
        instant_pk: '0',
        viewdata: {},
        watchsub_show: false,
        watchsub_sub_id: false,
        watchsub: {
            group_name: '',
            username: '',
        },
        relod_sub_menu_data: false,//是否刷新小菜单数据
        route_data: {
            zidongbuhuosheding: { list: [], play_arr: {} }
        },
        gs: {
            checked: '',
            list: [],
        },
        jszd_users: [],
        menulist: [],
        now_menu: '',
    },
})
