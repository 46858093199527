<!--
 * @Author: your name
 * @Date: 2022-04-22 11:48:17
 * @LastEditTime: 2023-01-02 16:23:55
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\inner\views\xitongchushisheding.vue
-->
<template>
    <!-- 系统初始设定 -->
    <div class="xitongchushisheding">
        <div class="panel">
            <div class="panel-title">
                <h2>系统初始设定</h2>
            </div>
            <div class="panel-body">
                <div class="title">
                    <div>
                        <el-link :underline="false">分类：</el-link>
                        <el-radio-group v-model="active_pid" size="small" @change="change_group">
                            <el-radio-button v-for="item in game_group" :key="item.name" :label="item.pid">{{ item.name }}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div style="margin:10px 0">
                        <el-link :underline="false">彩票：</el-link>
                        <el-radio-group v-model="active_gid" size="small" @change="change_game">
                            <el-radio-button v-for="item in game_list" :key="item.id" :label="item.id">{{ item.gname }}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="redcolor">当前选择：{{ checked_name }}</div>
                </div>
                <div class="theme-border topbox">
                    <span style="color:red">自动降赔设定：</span>
                    <el-radio v-model="play_param.twosides_switch" label="1">启用</el-radio>
                    <el-radio v-model="play_param.twosides_switch" label="2">禁用</el-radio>
                    <span style="color:red">同路“号码”随大路降赔率比例：</span>
                    <el-input size="small" style="width:100px" v-model="play_param.proportion"></el-input>&nbsp;(0为不随降)
                    <!-- <el-input size="small" style="width:150px" v-model="play_param.check_time"></el-input>&nbsp;(0-24小时) -->
                    <!-- <span>封盘时间：</span>
                    <el-input size="small" style="width:150px" v-model="play_param.check_time_night"></el-input>&nbsp;秒-->
                    <!-- <template v-if="$store.state.userinfo.group_id==1&&(checked_name=='六合彩'||checked_name=='澳门六合彩')">
                        <span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;开盘时间：</span>
                        <el-select v-model="play_param.check_time" class="m-2" placeholder="Select" size="small">
                            <el-option label="15点" value="15" />
                            <el-option label="16点" value="16" />
                            <el-option label="17点" value="17" />
                            <el-option label="18点" value="18" />
                            <el-option label="19点" value="19" />
                            <el-option label="20点" value="20" />
                        </el-select>
                    </template>-->
                    <template v-if="$store.state.userinfo.group_id == 2 && (checked_name == '香港六合彩' || checked_name == '快乐8六合彩' || checked_name == '澳门六合彩' || checked_name == '新澳门六合彩')">
                        <span style="color:red">&nbsp;&nbsp;&nbsp;&nbsp;开盘时间：</span>
                        <el-select v-model="play_param.check_time" class="m-2" placeholder="Select" size="small">
                            <!-- <el-option label="3点" value="30" />
                            <el-option label="3点30分" value="35" />
                            <el-option label="4点" value="40" />
                            <el-option label="4点30分" value="45" />
                            <el-option label="5点" value="50" />
                            <el-option label="5点30分" value="55" />
                            <el-option label="6点" value="60" />
                            <el-option label="6点30分" value="65" />
                            <el-option label="7点" value="70" />
                            <el-option label="7点30分" value="75" />
                            <el-option label="8点" value="80" />
                            <el-option label="8点30分" value="85" />
                            <el-option label="9点" value="90" />
                            <el-option label="9点30分" value="95" />
                            <el-option label="10点" value="100" />
                            <el-option label="10点30分" value="105" />
                            <el-option label="11点" value="110" />
                            <el-option label="11点30分" value="115" />
                            <el-option label="12点" value="120" />
                            <el-option label="12点30分" value="125" />
                            <el-option label="13点" value="130" />
                            <el-option label="13点30分" value="135" />
                            <el-option label="14点" value="140" />
                            <el-option label="14点30分" value="145" /> -->
                            <el-option label="15点" value="150" />
                            <el-option label="15点30分" value="155" />
                            <el-option label="16点" value="160" />
                            <el-option label="16点30分" value="165" />
                            <el-option label="17点" value="170" />
                            <el-option label="17点30分" value="175" />
                            <el-option label="18点" value="180" />
                            <!-- <el-option label="18点30分" value="185" />
                            <el-option label="19点" value="190" /> -->
                            <!-- <el-option label="20点" value="20" /> -->
                        </el-select>&nbsp;
                        <el-button type="primary" size="small" @click="sub_change">确认</el-button>
                    </template>
                </div>
                <el-row>
                    <el-col :span="6" v-for="(item, i) in play_param.twosides_proportion" :key="item.index" style="margin:3px 0">
                        <label class="item-label">
                            <span class="item-qnum">{{ i + 1 }}</span> 期 没出降&nbsp;
                        </label>
                        <el-input size="small" style="width:40px" v-model="item[0]"></el-input>
                        <label>&nbsp;连出降&nbsp;</label>
                        <el-input size="small" style="width:40px" v-model="item[1]"></el-input>
                    </el-col>
                </el-row>
                <div style="color:#F56C6C">注：此操作必须在官网上期结果后执行，如官网结果延迟将导致此操作延迟或失败</div>
                <div class="sub">
                    <el-button size="small" type="info" plain @click="reset()">重置</el-button>
                    <el-button size="small" type="primary" @click="sub_change">确认修改</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../../axios/axios'
export default {
    data() {
        return {
            active_gid: '',//当前激活的单个gid
            game_list: [],
            /**彩种分组 */
            game_group: [],
            play_param: {
                check_time: '',
            },
            type: "",//当前模式，单个/分类
            active_pid: "",//当前激活的分类pid
            get_url: "/play/auto_rate_alone",//获取数据时的url
            checked_name: '',
        }
    },
    beforeRouteEnter(to, from, next) {
        axios.post("play/auto_rate", { type: 1 }).then(result => {
            if (result.data.status == 200) {
                next(vm => {
                    vm.game_list = vm.$store.state.game_list;
                    vm.active_gid = ''
                    vm.checked_name = vm.game_list[0].gname;
                    // 通过 `vm` 访问组件实例
                    let group = [];
                    vm.play_param = "";
                    vm.type = 'list';
                    let game_group = result.data.repsoneContent.play_arr;
                    let play_param = result.data.repsoneContent.play_param;
                    for (const key in game_group) {
                        game_group[key].pid = key;
                        group.push(game_group[key]);
                    }
                    vm.game_group = group;
                    vm.play_param = play_param;
                    for (let i = 0; i < vm.game_group.length; i++) {
                        for (let j = 0; j < vm.game_group[i].gids.length; j++) {
                            if (vm.game_group[i].gids[j] == 1) {
                                vm.play_param.min_sec = vm.game_group[i].min_sec
                            }
                        }
                    }
                    vm.active_pid = group[0].pid;
                    vm.checked_name = vm.game_group.find(item => item.pid == vm.active_pid).name;
                })
            }
        })
    },
    methods: {
        reset() {
            if (this.type === "one") {
                this.get_game_group(this.type, this.active_gid);
            } else {
                this.get_game_group(this.type, this.active_pid);
            }
        },
        //提交修改
        sub_change() {
            // if (this.play_param.check_time < this.play_param.min_sec * 1) {
            //     return this.$message.error('当前设置的提前封盘时间不得低于' + this.play_param.min_sec + "秒");
            // }
            let url = "";
            let post_obj = "";
            if (this.type === "one") {
                url = "play/system_init_set_alone";
                post_obj = {
                    gid: this.active_gid,
                    check_time: this.play_param.check_time,
                    check_time_night: this.play_param.check_time_night,
                    twosides_switch: this.play_param.twosides_switch,
                    proportion: this.play_param.proportion,
                    twosides_proport: this.play_param.twosides_proportion
                }
            } else {
                url = "play/system_init_set";
                post_obj = {
                    pgid: this.active_pid,
                    check_time: this.play_param.check_time,
                    check_time_night: this.play_param.check_time_night,
                    twosides_switch: this.play_param.twosides_switch,
                    proportion: this.play_param.proportion,
                    twosides_proport: this.play_param.twosides_proportion
                }
            }
            this.axios.post(url, post_obj).then(result => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                }
            })
        },
        //切换
        change_group(pid) {
            this.type = "list";
            this.get_game_group(this.type, pid);
        },
        change_game(gid) {
            this.type = "one";
            this.get_game_group(this.type, gid);
        },
        //获取彩种数据
        get_game_group(type, id) {

            this.type = type;
            if (type === "one") {
                this.active_pid = '';
                this.active_gid = id;
                this.get_url = "/play/auto_rate_alone";
            } else {
                this.active_gid = "";
                this.active_pid = id;
                this.get_url = "/play/auto_rate";
            }
            this.axios.post(this.get_url, { type: id }).then(result => {
                if (result.data.status == 200) {
                    let group = [];
                    this.play_param = "";
                    let game_group = result.data.repsoneContent.play_arr;
                    let play_param = result.data.repsoneContent.play_param;
                    for (const key in game_group) {
                        game_group[key].pid = key;
                        group.push(game_group[key]);
                    }
                    this.game_group = group;
                    this.play_param = play_param;
                    for (let i = 0; i < this.game_group.length; i++) {
                        for (let j = 0; j < this.game_group[i].gids.length; j++) {
                            if (this.game_group[i].gids[j] == id) {
                                this.play_param.min_sec = this.game_group[i].min_sec
                            }
                        }
                    }
                    if (type === "one") {
                        this.checked_name = this.game_list.find(item => item.id == this.active_gid).gname;
                    } else {
                        this.checked_name = this.game_group.find(item => item.pid == this.active_pid).name;
                    }
                    console.log(this.checked_name);
                }
            })
        },
        get_game_list() {
            this.axios.post("main/game_list" + this.$store.state.url_query_info, this.qs.stringify()).then(result => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    this.game_list = result.data.repsoneContent.game_list;
                    this.active_gid = this.game_list[0].id;
                    this.checked_name = this.game_list[0].gname;
                    this.get_game_group('one', this.active_gid)
                }
            })
        },
    },
}
</script>

<style scoped>
.title {
    text-align: left;
}

.item-label {
    text-align: right;
    display: inline-block;
    width: 90px;
}

.item-qnum {
    color: #409eff;
    font-weight: bold;
}

.sub {
    margin-top: 10px;
}

.topbox {
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
}
</style>
