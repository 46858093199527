import _objectSpread from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.array.concat.js";
import axios from "../../../axios/axios";
import { lhcNum } from "../../../plugin/lhcNum";
import { get_lhc_sx } from "../../../plugin/sx";
export default {
  components: {},
  data: function data() {
    return {
      gid: "20",
      btns: [{
        type: "primary",
        gid: 20,
        name: "六合彩"
      }, {
        type: "",
        gid: 34,
        name: "澳门六合彩"
      }, {
        type: "",
        gid: 49,
        name: "新澳门六合彩"
      }, {
        type: "",
        gid: 50,
        name: "台湾六合彩"
      }, {
        type: "",
        gid: 51,
        name: "快乐8六合彩"
      }, {
        type: "",
        gid: 10,
        name: "排列五"
      }],
      list: [],
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      change: {
        show: false,
        act: "edit",
        id: "",
        qnum: "",
        today: new Date(),
        startdate: "",
        starttime: "16:00:00",
        closedate: "",
        closetime: "21:30:00",
        tema_closedate: "",
        tema_closetime: "21:31:00"
      },
      add: {
        show: false,
        act: "edit",
        qnum: "",
        today: new Date(),
        startdate: "",
        starttime: "16:00:00",
        closedate: "",
        closetime: "21:30:00",
        tema_closedate: "",
        tema_closetime: "21:31:00"
      },
      jiesuan: {
        show: false,
        act: "open",
        row: "",
        num_list: "",
        firstCode: "",
        secondCode: "",
        thirdCode: "",
        fourthCode: "",
        fifthCode: "",
        sixCode: "",
        sevenCode: ""
      },
      lhcNum: "",
      get_lhc_sx: "",
      gs: {
        visible: false,
        list: [],
        checked: "",
        pagination: {
          total_pages: 0,
          total_records: "0",
          page_size: 10
        }
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    // console.log(to.query.db);
    axios.post("play/num_list", {
      page: 1,
      gid: to.query.gid,
      start_time: "",
      end_time: "",
      db: to.query.db
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;

          for (var i = 0; i < vm.list.length; i++) {
            var arr = [];

            for (var j = 0; j < vm.list[i].res.length; j++) {
              arr.push(lhcNum(lhcNum(vm.list[i].res[j], vm.$filters.time(vm.list[i].starttime).split(" ")[0])));
            }

            vm.list[i].res = get_lhc_sx("开奖", arr);
          }

          if (vm.list.length > 0) {
            vm.add.qnum = vm.add.min_qnum = Number(vm.list[0].qnum) + 1;
          }

          vm.gs.list = vm.$store.state.gs.list;
          vm.gs.checked = to.query.db ? to.query.db : vm.gs.list[0].name;
          vm.btns = vm.$store.state.userinfo.lh_list;
          vm.gid = to.query.gid;
        });
      }
    });
  },
  created: function created() {
    this.lhcNum = lhcNum;
    this.get_lhc_sx = get_lhc_sx; // console.log(this.$store.state.userinfo);
  },
  methods: {
    send_one: function send_one() {
      this.sub_jiesuan("one");
    },
    sub_jiesuan: function sub_jiesuan() {
      var _this = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "all";
      var list = [this.jiesuan.firstCode, this.jiesuan.secondCode, this.jiesuan.thirdCode, this.jiesuan.fourthCode, this.jiesuan.fifthCode, this.jiesuan.sixCode, this.jiesuan.sevenCode];
      var nary = list.sort();

      for (var i = 0; i < list.length; i++) {
        if (nary[i] != "") {
          if (Number.isNaN(Number(nary[i] * 1))) {
            return this.$message.error("请填写正确的号码");
          }

          if (this.gid != 10 && (nary[i] < 1 || nary[i] > 49)) {
            return this.$message.error("六合号码为1-49");
          }

          if (this.gid != 10 && nary[i] == nary[i + 1]) {
            return this.$message.error("号码不可重复");
          }
        }
      } // return console.log(list);


      this.axios.post("play/num_edit", _objectSpread({
        gid: this.gid,
        db: this.gs.checked
      }, this.jiesuan)).then(function (result) {
        if (result.data.status == 200) {
          _this.$message.success(result.data.shortMessage);

          if (type == "all") {
            _this.jiesuan.show = false;
          }

          _this.get_list(1);
        }
      });
    },
    reset_code: function reset_code() {
      this.jiesuan.firstCode = "";
      this.jiesuan.secondCode = "";
      this.jiesuan.thirdCode = "";
      this.jiesuan.fourthCode = "";
      this.jiesuan.fifthCode = "";
      this.jiesuan.sixCode = "";
      this.jiesuan.sevenCode = "";
    },
    open_js: function open_js(row) {
      var _this2 = this;

      if (row.settle_status == "已结算") {
        return this.$message.error("当期已结算");
      }

      this.reset_code();
      this.axios.post("play/num_edit", _objectSpread({
        act: "res",
        gid: this.gid,
        db: this.gs.checked
      }, row)).then(function (result) {
        if (result.data.status == 200) {
          if (result.data.repsoneContent.list[0] != undefined) {
            var list = result.data.repsoneContent.list[0].res;

            if (list[0] != undefined) {
              _this2.jiesuan.firstCode = list[0];
            }

            if (list[1] != undefined) {
              _this2.jiesuan.secondCode = list[1];
            }

            if (list[2] != undefined) {
              _this2.jiesuan.thirdCode = list[2];
            }

            if (list[3] != undefined) {
              _this2.jiesuan.fourthCode = list[3];
            }

            if (list[4] != undefined) {
              _this2.jiesuan.fifthCode = list[4];
            }

            if (list[5] != undefined) {
              _this2.jiesuan.sixCode = list[5];
            }

            if (list[6] != undefined) {
              _this2.jiesuan.sevenCode = list[6];
            }
          }

          _this2.jiesuan.row = row;
          _this2.jiesuan.num_list = row.id;
          _this2.jiesuan.show = true;
        }
      });
    },
    //删除
    del_row: function del_row(row) {
      var _this3 = this;

      this.$confirm("\u786E\u5B9A\u5220\u9664 ".concat(row.qnum, " \u671F\u5417?"), "提示", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this3.axios.post("play/num_edit", {
          act: "del",
          id: row.id,
          gid: _this3.gid,
          qnum: row.qnum,
          db: _this3.gs.checked
        }).then(function (result) {
          if (result.data.status == 200) {
            _this3.$message.success(result.data.shortMessage);

            _this3.get_list(1);
          }
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "已取消操作"
        });
      });
    },
    //修改
    change_row: function change_row(row) {
      // console.log(row);
      this.change.id = row.id;
      this.change.qnum = row.qnum;
      this.change.startdate = this.$filters.time(row.starttime, "notime");
      this.change.closedate = this.$filters.time(row.closetime, "notime");
      this.change.tema_closedate = this.$filters.time(row.tema_closetime, "notime");
      this.change.starttime = "".concat(this.change.startdate, " ").concat(this.$filters.time(row.starttime, "nodate"));
      this.change.closetime = "".concat(this.change.closedate, " ").concat(this.$filters.time(row.closetime, "nodate"));
      this.change.tema_closetime = "".concat(this.change.tema_closedate, " ").concat(this.$filters.time(row.tema_closetime, "nodate"));
      this.change.show = true;
    },
    //提交修改
    change_pk_sub: function change_pk_sub() {
      var _this4 = this;

      var obj = {
        act: "edittime",
        id: this.change.id,
        qnum: "",
        starttime: "",
        closetime: "",
        tema_closetime: "",
        today: this.$filters.time(Date.parse(new Date()) / 1000),
        gid: this.gid,
        db: this.gs.checked
      }; // console.log(this.add);

      obj.qnum = this.change.qnum;
      obj.starttime = "".concat(this.$filters.time(Date.parse(this.change.startdate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.change.starttime) / 1000).split(" ")[1]);
      obj.closetime = "".concat(this.$filters.time(Date.parse(this.change.closedate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.change.closetime) / 1000).split(" ")[1]);
      obj.tema_closetime = "".concat(this.$filters.time(Date.parse(this.change.tema_closedate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.change.tema_closetime) / 1000).split(" ")[1]); // console.log(obj);

      this.axios.post("play/num_edit", obj).then(function (result) {
        if (result.data.status == 200) {
          _this4.$message.success(result.data.shortMessage);

          _this4.change.show = false;

          _this4.get_list(1);
        }
      });
    },
    ///提交新增
    add_pk_sub: function add_pk_sub() {
      var _this5 = this;

      // console.log(this.add);
      var obj = {
        act: "edit",
        qnum: "",
        starttime: "",
        closetime: "",
        tema_closetime: "",
        today: this.$filters.time(Date.parse(new Date()) / 1000),
        gid: this.gid,
        db: this.gs.checked
      }; // console.log(this.add);

      obj.qnum = this.add.qnum;
      obj.starttime = "".concat(this.$filters.time(Date.parse(this.add.startdate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.add.starttime) / 1000).split(" ")[1]);
      obj.closetime = "".concat(this.$filters.time(Date.parse(this.add.closedate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.add.closetime) / 1000).split(" ")[1]);
      obj.tema_closetime = "".concat(this.$filters.time(Date.parse(this.add.tema_closedate) / 1000, "notime"), " ").concat(this.$filters.time(Date.parse(this.add.tema_closetime) / 1000).split(" ")[1]); // console.log(obj);

      this.axios.post("play/num_edit", obj).then(function (result) {
        if (result.data.status == 200) {
          _this5.$message.success(result.data.shortMessage);

          _this5.add.show = false;

          _this5.get_list(1);
        }
      });
    },
    //新增
    add_pk: function add_pk() {
      this.add.today = new Date();
      var day = this.$filters.time(Date.parse(this.add.today) / 1000, "notime"); // console.log(day);

      this.add.startdate = day;
      this.add.closedate = day;
      this.add.tema_closedate = day;
      this.add.starttime = "".concat(this.add.startdate, " 16:00:00");
      this.add.closetime = "".concat(this.add.closedate, " 21:30:00");
      this.add.tema_closetime = "".concat(this.add.tema_closedate, " 21:31:00");
      this.add.show = true;
    },
    change_game: function change_game(item) {
      this.add.qnum = "";
      this.get_list(1, item.gid);
    },
    get_list: function get_list(page, gid) {
      var _this6 = this;

      this.page = page;
      this.axios.post("play/num_list", {
        page: page,
        gid: gid,
        start_time: "",
        end_time: "",
        db: this.gs.checked
      }).then(function (result) {
        if (result.data.status == 200) {
          _this6.list = result.data.repsoneContent.list;
          _this6.pagination = result.data.repsoneContent.pagination;

          if (gid != 10) {
            for (var i = 0; i < _this6.list.length; i++) {
              var arr = [];

              for (var j = 0; j < _this6.list[i].res.length; j++) {
                arr.push(lhcNum(lhcNum(_this6.list[i].res[j], _this6.$filters.time(_this6.list[i].starttime).split(" ")[0])));
              }

              _this6.list[i].res = get_lhc_sx("开奖", arr);
            }
          }

          if (_this6.page == 1) {
            _this6.add.qnum = _this6.add.min_qnum = Number(_this6.list[0].qnum) + 1;
          }

          _this6.gid = gid;
        }
      });
    }
  }
};