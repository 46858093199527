import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
export default {
  name: "zhinengcaopan",
  data: function data() {
    return {
      list: [],
      own_bonus: 0,
      //自设虚盘金额
      checked_all: false,
      is_open: 1,
      liangdong: {
        menus: ["A", "B", "C", "D"],
        checked_menu: "A",
        data: [{
          title: "二字定",
          list: []
        }, {
          title: "三字定",
          list: []
        }, {
          title: "二字现",
          list: []
        }, {
          title: "三字现",
          list: []
        }, {
          title: "四字现",
          list: []
        }]
      }
    };
  },
  methods: {
    get_zncp_data: function get_zncp_data(data) {
      var _this = this;

      // console.log(data);
      this.list = data.res_lower;
      this.list.forEach(function (item, index) {
        item.status = Boolean(item.status * 1);
        item.index = index + 1;
        _this.own_bonus = item.own_bonus * 1;
        item.range_bonus = item.range_bonus * 1;
        item.lower_rate = item.lower_rate * 1;
        item.range_list = [];

        for (var i = 0.1; i < 1; i += 0.1) {
          item.range_list.push((item.range_bonus * i).toFixed(0));
        }

        item.rate_list = [];

        for (var _i = 1; _i < 101; _i++) {
          item.rate_list.push((100 * _i).toFixed(0));
        }
      });
      this.make_liangdong_data(data.res_link);
    },
    save: function save() {
      var _this2 = this;

      var list = [];
      this.list.forEach(function (item) {
        list.push({
          id: item.id,
          status: item.status ? 1 : 0,
          own_bonus: item.own_bonus,
          range_bonus: item.range_bonus,
          linkage_id: item.linkage_id,
          lower_rate: item.lower_rate,
          is_open: _this2.is_open
        });
      });
      this.axios.post("trader/set_lower_linkage_trader", {
        gid: 10,
        act: "edit_lower",
        data: list
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.$message.success(result.data.shortMessage);
        }
      });
    },
    reset: function reset() {
      var _this3 = this;

      this.checked_all = false;
      this.is_open = 1;
      this.axios.post("trader/lower_rate_trader", {
        gid: 10
      }).then(function (result) {
        if (result.data.status == 200) {
          _this3.get_zncp_data(result.data.repsoneContent);
        }
      });
    },
    checked_liangdong_menu: function checked_liangdong_menu(item) {
      var _this4 = this;

      this.liangdong.checked_menu = item;
      var type = item == "A" ? "1" : item == "B" ? "2" : item == "C" ? "3" : item == "D" ? "4" : "1";
      this.axios.post("trader/lower_rate_trader", {
        gid: 10,
        flag: 1,
        type: type
      }).then(function (result) {
        if (result.data.status == 200) {
          var data = result.data.repsoneContent;

          _this4.make_liangdong_data(data);
        }
      });
    },
    reset_liangdong: function reset_liangdong() {
      this.checked_liangdong_menu(this.liangdong.checked_menu);
    },
    make_liangdong_data: function make_liangdong_data(res_link) {
      this.liangdong.data.forEach(function (item) {
        if (item.title == "二字定") {
          item.list = res_link.filter(function (item) {
            var matches = item.t_type.match(/X/g);
            return matches && matches.length === 2;
          });
        } else if (item.title == "三字定") {
          item.list = res_link.filter(function (item) {
            var matches = item.t_type.match(/X/g);
            return matches && matches.length === 1;
          });
        } else if (item.title == "二字现") {
          item.list = res_link.filter(function (item) {
            return item.t_type == "二字现";
          });
        } else if (item.title == "三字现") {
          item.list = res_link.filter(function (item) {
            return item.t_type == "三字现";
          });
        } else if (item.title == "四字现") {
          item.list = res_link.filter(function (item) {
            return item.t_type == "四字现";
          });
        }

        item.list.forEach(function (item) {
          item.status = Boolean(item.status * 1);
          item.lower_rate = item.lower_rate.replace(/\|/g, "/");
          item.rate_list = [];

          if (item.t_type.includes("字现")) {
            if (item.t_type == "二字现") {
              item.rate_list = ["0.01/0.02", "0.02/0.028", "0.03/0.042", "0.04/0.056", "0.05/0.07", "0.06/0.084", "0.07/0.098", "0.08/0.112", "0.09/0.126", "0.1/0.14"];
            }

            if (item.t_type == "三字现") {
              item.rate_list = ["0.01/0.02/0.04", "0.035/0.05/0.09", "0.05/0.1/0.2", "0.11/0.14/0.24", "0.135/0.17/0.29", "0.16/0.20/0.34", "0.185/0.23/0.39", "0.21/0.26/0.44", "0.235/0.29/0.49", "0.26/0.32/0.54", "0.285/0.35/0.59", "0.31/0.38/0.64", "0.335/0.41/0.69", "0.36/0.44/0.74", "0.385/0.47/0.79", "0.41/0.50/0.84", "0.435/0.53/0.89", "0.46/0.56/0.94", "0.485/0.71/1.3"];
            }

            if (item.t_type == "四字现") {
              var base = 0.01;

              while (base <= 3.82) {
                var second = base * 2;
                var third = second * 2;
                item.rate_list.push("".concat(base.toFixed(2), "/").concat(second.toFixed(2), "/").concat(third.toFixed(2), "/").concat(base.toFixed(2)));
                base += 0.01;
              }
            }
          } else {
            item.lower_rate = item.lower_rate * 1;

            for (var i = 1; i <= item.top_rate * 100; i++) {
              item.rate_list.push(i / 100);
            }
          }
        });
      });
    },
    save_liangdong: function save_liangdong() {
      var _this5 = this;

      var list = [];
      this.liangdong.data.forEach(function (item) {
        item.list.forEach(function (item) {
          list.push({
            id: item.id,
            status: item.status ? 1 : 0,
            lower_rate: item.lower_rate,
            type: item.type,
            level_two: item.level_two,
            level_three: item.level_three,
            level_four: item.level_four
          });
        });
      });
      this.axios.post("trader/set_lower_linkage_trader", {
        gid: 10,
        act: "edit_link",
        data: list
      }).then(function (result) {
        if (result.data.status == 200) {
          _this5.$message.success(result.data.shortMessage);
        }
      });
    }
  }
};