import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  name: "tybl",
  props: ["game_info_data"],
  data: function data() {
    return {
      ty_bl_list: [],
      currentPage: 1,
      pageSize: 500,
      fast_input_value: ""
    };
  },
  activated: function activated() {
    this.fast_input_value = "";
  },
  computed: {
    currentPageData: function currentPageData() {
      var start = (this.currentPage - 1) * this.pageSize;
      var end = start + this.pageSize;
      return this.ty_bl_list.slice(start, end);
    }
  },
  methods: {
    fast_input_btn_click: function fast_input_btn_click() {
      var _this = this;

      this.ty_bl_list.forEach(function (item) {
        item.bonus = _this.fast_input_value * 1;
      });
    },
    reset_ty_bl: function reset_ty_bl() {
      var _this2 = this;

      this.fast_input_value = "";
      this.axios.post("play/bonus_cap_mode_pl", {
        gid: 10,
        act: "list",
        qnum: this.game_info_data.nn,
        level_two: "19"
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.get_ty_bl_data(result.data.repsoneContent);
        }
      });
    },
    save_ty_bl: function save_ty_bl() {
      var _this3 = this;

      var data = [];
      this.currentPageData.forEach(function (item) {
        if (item.bonus != item.old_bonus) {
          data.push({
            play_id: 10,
            ball: item.ball,
            level_two: item.level_two,
            level_three: item.level_three,
            level_four: item.level_four,
            place: item.place,
            bonus: item.bonus
          });
        }
      });

      if (data.length > 0) {
        this.axios.post("play/bonus_cap_mode_pl", {
          gid: 10,
          act: "edit",
          data: data
        }).then(function (result) {
          if (result.data.status == 200) {
            _this3.$message.success(result.data.shortMessage);

            _this3.currentPageData.forEach(function (item) {
              item.old_bonus = item.bonus;
            });
          }
        }).catch(function (error) {
          _this3.$message.error("保存失败，请重试");

          console.error(error);
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
    },
    get_ty_bl_data: function get_ty_bl_data() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.ty_bl_list = data;
      this.ty_bl_list.forEach(function (item) {
        item.old_bonus = item.bonus = item.bonus * 1;
      });
      this.currentPage = 1; // 重置到第一页
    }
  }
};