<!--
 * @Author: your name
 * @Date: 2022-04-13 21:04:24
 * @LastEditTime: 2024-03-21 17:04:53
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\self\views\xiugaimima.vue
-->
<template>
    <div class="xiugaimima">
        <div class="panel" style="width: 500px">
            <div class="panel-title">
                <h2>修改密码</h2>
            </div>
            <div class="panel-body">
                <div class="myform">
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">当前密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-input ref="top_up_value" v-model="pwd.old" size="small" clearable show-password></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">新密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-input ref="top_up_value" v-model="pwd.value" size="small" clearable show-password></el-input>
                            <div style="display: inline-block">
                                <span>&nbsp;大写字母、小写字母、特殊字符、数字，3种组合，8-20位</span>
                                <el-popover placement="right" :width="320">
                                    <template #reference>
                                        <el-button style="margin: 0 0 0 10px; width: 35px" type="warning" plain size="small">说明</el-button>
                                    </template>
                                    <div style="color: #000">
                                        <div>小写字母：a-z</div>
                                        <div>大写字母：A-Z</div>
                                        <div>数字：0-9</div>
                                        <div>特殊字符：~!@#$%^&*()_+-?&lt;&gt;:.,</div>
                                        <div style="color: #f56c6c">注：上面可用做密码的特殊字符为英文字符，输入时请注意切换输入法为英文，其他字符将不会通过验证</div>
                                    </div>
                                </el-popover>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="4">重复新密码</el-col>
                        <el-col class="myform-item-value" :span="20">
                            <el-input ref="top_up_value" v-model="pwd.confirm" size="small" clearable show-password></el-input>
                        </el-col>
                    </el-row>
                </div>
                <el-button style="margin-top: 10px" type="warning" @click="sub">确认修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            pwd: {
                old: "",
                value: "",
                confirm: "",
            },
        };
    },
    created() {},
    methods: {
        reset() {
            this.pwd.old = "";
            this.pwd.value = "";
            this.pwd.confirm = "";
        },
        sub() {
            let old = this.pwd.old;
            let value = this.pwd.value;
            let confirm = this.pwd.confirm;
            if (old == "" || value == "" || confirm == "") {
                return this.$alert("密码不能为空", "错误提示", {
                    confirmButtonText: "确定",
                    type: "error",
                    callback: (action) => {},
                });
            }
            if (!this.$filters.passwordReg(value)) {
                return;
            }
            if (old == value) {
                return this.$message.error("新密码不能和旧密码相同");
            }
            if (confirm != value) {
                return this.$alert("两次输入的新密码不一样，请重新输入", {
                    confirmButtonText: "确定",
                    type: "error",
                    callback: (action) => {},
                });
            }
            this.axios
                .post("user_version2/user_info_save", {
                    txtid: this.$store.state.userinfo.user_id,
                    txtoldpwd: old,
                    txtnewpwd: value,
                    txtnewpwdcf: confirm,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        console.log(result.data.repsoneContent);
                        this.$alert(result.data.shortMessage, "成功提示", {
                            confirmButtonText: "确定",
                            type: "success",
                            callback: (action) => {
                                this.reset();
                            },
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
</style>