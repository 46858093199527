<!--
 * @Author: your name
 * @Date: 2022-04-19 16:11:07
 * @LastEditTime: 2024-03-31 23:46:19
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\inner\views\zhudansousuo.vue
-->
<template>
    <div class="search-order">
        <div class="panel" :style="{ width: show == 'form' ? '33%' : '100%' }">
            <div class="panel-title">
                <h2>注单搜索</h2>
                <el-button v-if="show == 'data'" style="float: right; margin-top: 3px" size="small" type="warning" @click="show = 'form'">返回</el-button>
            </div>
            <div class="panel-body">
                <template v-if="show == 'form'">
                    <!-- 搜索条件 -->
                    <div class="myform">
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">彩种</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-select v-model="form.gid" size="small" style="width: 140px">
                                    <el-option v-for="item in game_list" :key="item.id" :label="item.gname" :value="item.id" />
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">期号</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input v-model="form.qnum" placeholder="请输入期号" style="width: 140px" size="small"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">单号</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input v-model="form.order_id" placeholder="请输入单号" style="width: 140px" size="small"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">搜索账号</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input v-model="form.username" placeholder="请输入用户账号" style="width: 140px" size="small"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">注额范围</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input style="width: 65px" v-model="form.bet_money1" size="small"></el-input>
                                <span>&nbsp;-&nbsp;</span>
                                <el-input style="width: 65px" v-model="form.bet_money2" size="small"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">奖金范围</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input style="width: 65px" v-model.number="form.bet_result1" size="small"></el-input>
                                <span>&nbsp;-&nbsp;</span>
                                <el-input style="width: 65px" v-model.number="form.bet_result2" size="small"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">日期</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <datepicker ref="datepicker" @getDate="getDate" />
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">排序类型</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-radio-group v-model="form.amount_type">
                                    <el-radio label="3">下注时间</el-radio>
                                    <el-radio label="1">中奖金额</el-radio>
                                    <el-radio label="2">投注金额</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">注单状态</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-radio-group v-model="form.order_status">
                                    <el-radio label="3">已结算</el-radio>
                                    <el-radio label="2">未结算</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">排序方式</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-radio-group v-model="form.sort">
                                    <el-radio label="1">升序</el-radio>
                                    <el-radio label="2">降序</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                    </div>
                    <el-button type="primary" size="small" style="margin-top: 10px" @click="get_search_data">搜 索</el-button>
                </template>
                <template v-if="show == 'data'">
                    <!-- 搜索结果dialog表 -->
                    <el-table :data="dialog.data" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column prop="platform" label="平台" align="center" width="80"></el-table-column>
                        <el-table-column prop="game_name" label="彩种" align="center"></el-table-column>
                        <el-table-column prop="order_id" label="单号" align="center"></el-table-column>
                        <el-table-column prop="qnum" label="期号" align="center"></el-table-column>
                        <el-table-column prop="last_ip" label="投注ip" align="center"></el-table-column>
                        <el-table-column prop="order_staus" label="时间" align="center">
                            <template #default="scope">
                                <span>{{ $filters.time(scope.row.posttime, "noyear") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="game_level" label="玩法" align="center" width="220"></el-table-column>
                        <el-table-column prop="username" label="用户" align="center"></el-table-column>
                        <el-table-column prop="win_result" label="开奖结果" align="center"></el-table-column>
                        <el-table-column prop="bet_count" label="注数" align="center" width="50"></el-table-column>
                        <el-table-column prop="balance" label="投注金额" align="center"></el-table-column>
                        <el-table-column prop="bonus" label="中奖金额" align="center"></el-table-column>
                        <el-table-column prop="a_rebate" label="退水" align="center"></el-table-column>
                        <el-table-column prop="result" label="结果" align="center"></el-table-column>
                        <el-table-column prop="order_staus" label="状态" align="center">
                            <template #default="scope">
                                <span style="color: #f56c6c" v-if="scope.row.order_staus == '0'">未结算</span>
                                <span style="color: #67c23a" v-else-if="scope.row.order_staus == '1'">已结算</span>
                                <span style="color: #0fe60f" v-else-if="scope.row.order_staus == '2'">打和</span>
                                <span style="color: #b31816" v-else-if="scope.row.order_staus == '-1'">已取消</span>
                                <span style="color: #b31816" v-else-if="scope.row.order_staus == '-2'">已取消-未结算</span>
                                <span style="color: #b31816" v-else>已撤销</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <el-table :data="dialog.total" border size="small" highlight-current-row max-height="500">
                            <el-table-column prop="title" label align="center" width="80"></el-table-column>
                            <el-table-column prop="count" label="注数" align="center"></el-table-column>
                            <el-table-column prop="balance" label="投注金额" align="center"></el-table-column>
                            <el-table-column prop="bonus" label="中奖金额" align="center"></el-table-column>
                            <el-table-column prop="result" label="结果" align="center"></el-table-column>
                        </el-table>
                    </div>
                    <div class="paging">
                        <el-pagination
                            small
                            style="margin-top: 10px"
                            @current-change="change_page"
                            v-model="page"
                            :page-size="20"
                            :pager-count="5"
                            :total="pagination.total_records * 1"
                            layout="total,prev,pager,next,jumper"
                            prev-text="上一页"
                            next-text="下一页"
                        ></el-pagination>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
import datepicker from "../../homePublic/datepicker.vue";
export default {
    components: { datepicker },
    data() {
        return {
            show: "form",
            form: {
                gid: "0",
                date: "",
                qnum: "",
                username: "",
                bet_money1: "",
                bet_money2: "",
                bet_result1: "",
                bet_result2: "",
                amount_type: "3",
                sort: "2",
                order_id: "",
                order_status: "3",
            },
            dialog: {
                visible: false,
                data: [],
                total: [],
            },
            page: 1,
            pagination: {
                //分页信息
                total_pages: "",
                total_records: "",
                page_size: "",
            },
            game_list: [],
        };
    },
    mounted() {
        this.get_game_list();
        this.$refs["datepicker"].setDate("今天");
    },
    methods: {
        get_detail(row) {
            this.axios.post("betbackup/order_detail" + this.$store.state.url_query_info, this.qs.stringify(row)).then((result) => {
                if (result.data.status === 200) {
                    // console.log(result.data.repsoneContent)
                } else {
                    this.$alert(result.data.shortMessage, "错误提示", {
                        confirmButtonText: "确定",
                        type: "error",
                        callback: (action) => {},
                    });
                }
            });
        },

        change_page(page) {
            this.page = page;
            this.get_search_data();
        },
        //获取时间
        getDate(date) {
            this.form.date = date;
        },
        get_search_data() {
            if (this.form.bet_money1 && this.form.bet_money2) {
                if (this.form.bet_money2 * 1 < this.form.bet_money1 * 1) {
                    return this.$message.error("注额范围的数值输入不正确，第2项不得低于第1项");
                }
            }
            if (this.form.bet_result1 && this.form.bet_result2) {
                if (this.form.bet_result2 * 1 < this.form.bet_result1 * 1) {
                    return this.$message.error("结果范围的数值输入不正确，第2项不得低于第1项");
                }
            }
            this.axios
                .post("betbackup/order_list", {
                    gid: this.form.gid,
                    page: this.page,
                    start_time: this.form.date.start,
                    end_time: this.form.date.end,
                    username: this.form.username,
                    bet_money1: this.form.bet_money1,
                    bet_money2: this.form.bet_money2,
                    bet_result1: this.form.bet_result1,
                    bet_result2: this.form.bet_result2,
                    qnum: this.form.qnum,
                    amount_type: this.form.amount_type,
                    sort: this.form.sort,
                    order_id: this.form.order_id,
                    order_status: this.form.order_status,
                })
                .then((result) => {
                    // console.log(result.data.repsoneContent)
                    if (result.data.status == 200) {
                        if (result.data.repsoneContent) {
                            this.dialog.data = result.data.repsoneContent.list;
                            this.pagination = result.data.repsoneContent.pagination;
                            let total = result.data.repsoneContent.total;
                            total.title = "总计";
                            let sub_total = result.data.repsoneContent.sub_total;
                            sub_total.title = "小计";
                            this.dialog.total = [];
                            this.dialog.total.push(sub_total);
                            this.dialog.total.push(total);
                            // console.log(this.dialog.total)
                        } else {
                            this.dialog.data = [];
                            this.dialog.total = [];
                            this.pagination = {
                                total_pages: "",
                                total_records: "",
                                page_size: "",
                            };
                        }
                        this.show = "data";
                    }
                });
        },

        get_game_list() {
            this.axios.post("main/game_list").then((result) => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    this.game_list = result.data.repsoneContent.game_list;
                    this.game_list.unshift({
                        id: "0",
                        gname: "全部",
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.item-label {
    color: #409eff;
    margin-right: 6px;
    font-weight: bold;
}
.detail-item {
    padding: 0 6px;
    line-height: 30px;
    border-top: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
}
.detail-item:first-child {
    border-left: 1px solid #dcdfe6;
}
.detail-row-last {
    border-bottom: 1px solid #dcdfe6;
}
</style>
