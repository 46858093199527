/*
 * @Author: your name
 * @Date: 2022-03-11 16:28:34
 * @LastEditTime: 2024-08-14 16:33:55
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
// let params = {
//      user_id: '', uid: '', game_list: [],
// }
const routes = [
    {
        path: '/',
        name: '/',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        children: [
            // 站内消息
            {
                path: 'notice',
                name: 'notice',
                component: () => import('../components/notice/notice.vue'),
                children: [
                    {
                        path: 'zhannaxinxi',
                        name: 'zhannaxinxi',
                        component: () => import('../components/notice/views/zhannaxinxi.vue'),
                        // params,
                    }
                ],
            },
            //兑奖规则
            {
                path: 'djgz',
                name: 'djgz',
                component: () => import('../components/djgz/djgz.vue'),
                children: [
                    {
                        path: 'duijiangguize',
                        name: 'duijiangguize',
                        component: () => import('../components/djgz/views/gz.vue')
                    }
                ],
            },
            // 历史开奖
            {
                path: 'history',
                name: 'history',
                component: () => import('../components/history/history.vue'),
                children: [
                    {
                        name: 'lishikaijiang',
                        path: 'lishikaijiang',
                        component: () => import('../components/history/lishikaijiang.vue'),
                        props: true,
                    }
                ],
            },
            // 内部管理
            {
                path: 'inner',
                name: 'inner',
                component: () => import('../components/inner/inner.vue'),
                children: [
                    {
                        //ip设置
                        name: 'kongpanshezhi',
                        path: 'kongpanshezhi',
                        component: () => import('../components/inner/views/kongpanshezhi.vue')
                    },
                    {
                        //视频搜索
                        name: 'shipinsousuo',
                        path: 'shipinsousuo',
                        component: () => import('../components/inner/views/shipinsousuo.vue')
                    },
                    {
                        //ip设置
                        name: 'dailiipshezhi',
                        path: 'dailiipshezhi',
                        component: () => import('../components/inner/views/dailiipshezhi.vue')
                    },
                    {
                        //注单搜索
                        name: 'zhudansousuo',
                        path: 'zhudansousuo',
                        component: () => import('../components/inner/views/zhudansousuo.vue')
                    },
                    {
                        //注单备份
                        name: 'zhudanbeifen',
                        path: 'zhudanbeifen',
                        component: () => import('../components/inner/views/zhudanbeifen.vue')
                    },
                    {
                        //彩种配置
                        name: 'caizhongpeizhi',
                        path: 'caizhongpeizhi',
                        component: () => import('../components/inner/views/caizhongpeizhi.vue')
                    },
                    {
                        //系统初始设定
                        path: 'xitongchushisheding',
                        name: 'xitongchushisheding',
                        component: () => import('../components/inner/views/xitongchushisheding.vue')
                    },
                    {
                        //赔率设定
                        path: 'peilvsheding',
                        name: 'peilvsheding',
                        component: () => import('../components/inner/views/peilvsheding.vue')
                    },
                    {
                        //站内消息管理
                        path: 'zhannaxiaoxiguanli',
                        name: 'zhannaxiaoxiguanli',
                        component: () => import('../components/inner/views/zhannaxiaoxiguanli.vue')
                    },
                    {
                        //管理员操作日志
                        path: 'guanliyuancaozuorizhi',
                        name: 'guanliyuancaozuorizhi',
                        component: () => import('../components/inner/views/guanliyuancaozuorizhi.vue')
                    },
                    {
                        path: 'xitongrizhi',//系统日志
                        name: 'xitongrizhi',
                        component: () => import('../components/inner/views/xitongrizhi.vue')
                    },
                    {
                        path: 'caopanrizhi',//操盘日志
                        name: 'caopanrizhi',
                        component: () => import('../components/inner/views/caopanrizhi.vue')
                    },
                    {
                        path: 'jiangqiguanli',//奖期管理
                        name: 'jiangqiguanli',
                        component: () => import('../components/inner/views/jiangqiguanli.vue')
                    },
                    {
                        path: 'jiaoyisheding',//交易设定
                        name: 'jiaoyisheding',
                        component: () => import('../components/inner/views/jiaoyisheding.vue')
                    },
                    {
                        path: 'anquanmaguanli',//安全码管理
                        name: 'anquanmaguanli',
                        component: () => import('../components/inner/views/anquanmaguanli.vue')
                    },
                    {
                        path: 'liuhepankou',//六合盘口
                        name: 'liuhepankou',
                        component: () => import('../components/inner/views/liuhepankou.vue')
                    },
                    {
                        path: 'wanfazhuangtai',//玩法状态
                        name: 'wanfazhuangtai',
                        component: () => import('../components/inner/views/wanfazhuangtai.vue')
                    },
                    {
                        path: 'tuishuishezhi',//退水设置
                        name: 'tuishuishezhi',
                        component: () => import('../components/inner/views/tuishuishezhi.vue')
                    },
                ],
            },
            // 及时注单
            {
                path: 'instant',
                name: 'instant',
                component: () => import('../components/instant/instant.vue'),
                children: [],
            },
            // 报表
            {
                path: 'reportmain',
                name: 'reportmain',
                component: () => import('../components/report/reportmain.vue'),
                children: [
                    {
                        path: 'baobiaochaxun',
                        name: 'baobiaochaxun',
                        component: () => import('../components/report/report.vue')
                    },
                    {
                        path: 'zhudanchuli',
                        name: 'zhudanchuli',
                        component: () => import('../components/report/zhudanchuli.vue')
                    },
                ],
            },
            // 个人管理
            {
                path: 'self',
                name: 'self',
                component: () => import('../components/self/self.vue'),
                children: [
                    {
                        path: 'gerenziliao',
                        name: 'gerenziliao',
                        component: () => import('../components/self/views/gerenziliao.vue')
                    },
                    {
                        path: 'denglurizhi',
                        name: 'denglurizhi',
                        component: () => import('../components/self/views/denglurizhi.vue')
                    },
                    {
                        path: 'zijinrizhi',
                        name: 'zijinrizhi',
                        component: () => import('../components/self/views/zijinrizhi.vue')
                    },
                    {
                        path: 'xiugaimima',
                        name: 'xiugaimima',
                        component: () => import('../components/self/views/xiugaimima.vue')
                    },
                    {
                        path: 'zidongbuhuobiangengjilu',//自动补货变更记录
                        name: 'zidongbuhuobiangengjilu',
                        component: () => import('../components/self/views/zidongbuhuobiangengjilu.vue')
                    },
                    {
                        path: 'zidongbuhuosheding',//自动补货设定
                        name: 'zidongbuhuosheding',
                        component: () => import('../components/self/views/zidongbuhuosheding.vue')
                    },
                    {
                        path: 'erciyanzheng',
                        name: 'erciyanzheng',
                        component: () => import('../components/self/views/erciyanzheng.vue')
                    },
                    {
                        path: 'ipshezhi',
                        name: 'ipshezhi',
                        component: () => import('../components/self/views/ipshezhi.vue')
                    },
                    {
                        path: 'beishushezhi',
                        name: 'beishushezhi',
                        component: () => import('../components/self/views/beishushezhi.vue')
                    },
                    {
                        path: 'xinaofeidanbaobiao',
                        name: 'xinaofeidanbaobiao',
                        component: () => import('../components/self/views/xinaofeidanbaobiao.vue')
                    },
                ],
            },
            //用户管理
            {
                path: 'user',
                name: 'user',
                component: () => import('../components/user/user.vue'),
                children: [
                    {
                        path: 'dazongjian',
                        name: 'dazongjian',
                        component: () => import('../components/user/views/dazongjian.vue'),
                        props: true,
                    },
                    {
                        path: 'zongjian',
                        name: 'zongjian',
                        component: () => import('../components/user/views/zongjian.vue'),
                        props: true,
                    },
                    {
                        path: 'fengongsi',
                        name: 'fengongsi',
                        component: () => import('../components/user/views/fengongsi.vue'),
                        props: true,
                    },
                    {
                        path: 'dagudong',
                        name: 'dagudong',
                        component: () => import('../components/user/views/dagudong.vue')
                    },
                    {
                        path: 'gudong',
                        name: 'gudong',
                        component: () => import('../components/user/views/gudong.vue')
                    },
                    {
                        path: 'zongdaili',
                        name: 'zongdaili',
                        component: () => import('../components/user/views/zongdaili.vue')
                    },
                    {
                        path: 'daili',
                        name: 'daili',
                        component: () => import('../components/user/views/daili.vue')
                    },
                    {
                        path: 'huiyuan',
                        name: 'huiyuan',
                        component: () => import('../components/user/views/huiyuan.vue')
                    },
                    {
                        path: 'zizhanghao',
                        name: 'zizhanghao',
                        component: () => import('../components/user/views/zizhanghao.vue')
                    },
                ],
            },
            //顶部资金日志
            {
                path: 'zijinrizhi',
                name: 'zijinrizhi',
                component: () => import('../components/self/views/zijinrizhi.vue')
            },
            //顶部在线统计
            {
                path: 'zaixiantongji',
                name: 'zaixiantongji',
                component: () => import('../components/tongji/zaixiantongji.vue'),
                props: true,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
// router.beforeEach((to, from, next) => {
//     const isLogin = sessionStorage.getItem("islogin") ? "true" : "false"
//     if (to.path == '/' || to.path == '/login') {
//         next()
//     } else {
//         if (isLogin) {
//             next()
//         } else {
//             next({ name: 'login' });
//         }
//     }
// })

export default router
