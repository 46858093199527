/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-15 12:48:41
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-06-04 17:36:54
 * @FilePath: \pc_admin\src\filters\passwordReg.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ElMessageBox } from 'element-plus';
export function passwordReg(password = '') {
    const lowerCaseRegex = /[a-z]+/;
    const upperCaseRegex = /[A-Z]+/;
    const numberRegex = /[0-9]+/;
    const specialCharRegex = /[~!@#$%^&*()_\+\-?<>:.,]/; // 特殊字符包括非单词字符和下划线,最多含17个，
    const lengthRegex = /^[^\s]{8,20}$/;

    if (!lowerCaseRegex.test(password)) {
        ElMessageBox.confirm('密码必须包含小写字母', "提示信息", {
            customClass: 'myMessageBox',
            confirmButtonText: "确定",
            type: "error",
            callback: action => { }
        });

    }
    else if (!upperCaseRegex.test(password)) {
        ElMessageBox.confirm('密码必须包含大写字母', "提示信息", {
            customClass: 'myMessageBox',
            confirmButtonText: "确定",
            type: "error",
            callback: action => { }
        });
    }
    else if (!numberRegex.test(password)) {
        ElMessageBox.confirm('密码必须包含数字', "提示信息", {
            customClass: 'myMessageBox',
            confirmButtonText: "确定",
            type: "error",
            callback: action => { }
        });
    }
    // else if (!specialCharRegex.test(password)) {
    //     ElMessageBox.confirm('密码必须包特殊字符为  ~!@#$%^&*()_+-?<>:., 其中字符', "提示信息", {
    //         customClass: 'myMessageBox',
    //         confirmButtonText: "确定",
    //         type: "error",
    //         callback: action => { }
    //     });
    // }
    else if (!lengthRegex.test(password)) {
        ElMessageBox.confirm('密码长度限制8-20位', "提示信息", {
            customClass: 'myMessageBox',
            confirmButtonText: "确定",
            type: "error",
            callback: action => { }
        });
    }
    // 检查是否至少包含4种组合
    return (
        lowerCaseRegex.test(password) &&
        upperCaseRegex.test(password) &&
        numberRegex.test(password) &&
        // specialCharRegex.test(password) &&
        lengthRegex.test(password)
    );

}