import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

/*
 * @Author: your name
 * @Date: 2022-03-12 14:03:01
 * @LastEditTime: 2024-05-23 14:58:01
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\axios\index.js
 */
import axios from 'axios'; // 引入axios

import qs from 'qs'; // 引入qs模块，用来序列化post类型的数据
// import { Dialog } from 'vant';

import store from '../store/store';
import { ElMessage } from 'element-plus';
import { ElMessageBox } from 'element-plus';

if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_CURRENTMODE === 'test') {
    // axios.defaults.baseURL = 'https://apiroot.8yahu.net';
    axios.defaults.baseURL = 'https://apiroot.1688center.com';
  }

  if (process.env.VUE_APP_CURRENTMODE === '1cecil') {
    // axios.defaults.baseURL = 'http://adm.dist.com';
    axios.defaults.baseURL = 'http://root.dist.com';
  }

  if (process.env.VUE_APP_CURRENTMODE === 'dj5568') {
    // axios.defaults.baseURL = 'https://apiadmin.dj5568.com';
    axios.defaults.baseURL = 'https://apiroot.8yahu.net';
  }

  if (process.env.VUE_APP_CURRENTMODE === 'aoliu') {
    axios.defaults.baseURL = 'https://apiadmin.zy226.com';
  }

  if (process.env.VUE_APP_CURRENTMODE === 'xinaoliutwo') {
    axios.defaults.baseURL = 'https://apiadmin.ab55.one';
  }

  if (process.env.VUE_APP_CURRENTMODE === 'kuaicai1') {
    axios.defaults.baseURL = 'https://apiadmin.ab6.one';
  }
} else {}

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**修改配置信息，跨域保存session值 */

axios.defaults.withCredentials = true; // 设置了请求拦截器，处理下post方法传的参数格式问题

axios.interceptors.request.use(function (config) {
  //当查看下级模式时，增删改接口不能请求
  if (store.state.watchsub_show) {
    var cant_request_list = ['trader/update_rate', 'user_version2/member_save', 'user_version2/update_tuishui', 'user_version2/member_status', 'account/credit_recharge_kc', 'user_version2/user_info_save', 'check/check_add', 'check/check_edit', 'play/auto_ball_rate', 'play/bonus_set_exe', 'play/auto_ball_rate_exe', 'play/bonus_member', 'play/bonus_set_member_exe4', 'play/game_checked', 'play/system_init_set', 'play/system_init_set_alone', 'play/news_edit', 'play/news_insert' // 'play/news',
    ];

    if (cant_request_list.includes(config.url)) {
      var CancelToken = axios.CancelToken;
      var source = CancelToken.source();
      config.cancelToken = source.token;
      source.cancel("取消请求");
      ElMessage.error('查看下级模式中，不能修改下级数据！');
    }
  } // console.log(config.data);
  // if (config.data != undefined && store.state.gs.checked != '') {
  //     config.data['db'] = store.state.gs.checked;
  // }
  // 利用qs转换了一下data的格式，让post方法能够正确识别传参


  config.data = config.method == 'post' ? qs.stringify(config.data) : config.data;

  if (config.url !== 'login/ajax_login' && config.url !== 'login/vcode') {
    //拼接token
    config.url = "".concat(config.url).concat(config.url.includes('?') ? '&' : '?').concat(store.state.url_query_info); //根据用户判断加上前缀字符
    // if (store.state.userinfo.username == 'super_admin') {
    //     // console.log(config);
    //     config.url = `admin_${config.url}`
    // }
  }

  return config;
}, function (error) {
  // 这里出现错误可能是网络波动造成的，清空 pendingRequests 对象
  pendingRequests.clear();
  return Promise.reject(error);
});
axios.interceptors.response.use(function (res) {
  // console.log(res);
  if (res.data.resultCode === -201 && res.data.status === -1) {
    ElMessage.error(res.data.shortMessage);
    window.location.replace('/');
  } else if (res.data.status === -1) {
    if (res.data.shortMessage === '用户未登录' || res.data.shortMessage === '已在其他pc端登录' || res.data.shortMessage === '验证异常，请重新登陆') {
      window.location.replace('/');
    } else {
      // console.log(ElMessageBox);
      ElMessageBox.close();
      ElMessageBox.confirm(res.data.shortMessage, "提示信息", {
        customClass: 'myMessageBox',
        type: "error",
        callback: function callback(action) {}
      });
    }
  }

  return res;
}, function (error) {
  // 对响应错误做处理
  return Promise.reject(error);
});
export default axios;