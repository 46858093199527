import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import ssc from "../history/game/ssc.vue";
import pk10 from "../history/game/pk10.vue";
import lhc from "../history/game/lhc.vue";
import xy28 from "../history/game/xy28.vue";
import k3 from "../history/game/k3.vue";
import { pcddNum } from "../../plugin/pcddNum";
import { lhcNum } from "../../plugin/lhcNum";
import { get_lhc_sx } from "../../plugin/sx";
export default {
  props: ["from", "rowinfo", "form", "gameClassID"],
  components: {
    ssc: ssc,
    pk10: pk10,
    lhc: lhc,
    xy28: xy28,
    k3: k3
  },
  data: function data() {
    return {
      dialog: {
        visible: false,
        detail_data: [],
        page: 1,
        pagination: {
          //分页信息
          total_pages: "",
          total_records: "",
          page_size: ""
        },
        row: ""
      },
      isGetBuHuoDetail: false,
      history_data: [],
      componentId: "",
      gid: "",
      is_eran: "0",
      db: ""
    };
  },
  created: function created() {// this.search_detail(this.rowinfo, this.form);
    // console.log(this.rowinfo);
  },
  methods: {
    //获取显示开奖号码
    show_kaijiang: function show_kaijiang(row) {
      var _this = this;

      this.history_data = [];
      this.gid = row.game_id;
      var gid = this.gid;

      if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
        this.componentId = "ssc";
      } else if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
        this.componentId = "pk10";
      } else if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
        this.componentId = "lhc";
      } else if (gid == 22 || gid == 23) {
        this.componentId = "xy28";
      } else if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
        this.componentId = "k3";
      }

      this.axios.post("betresult/get_periods_result", {
        gid: row.game_id,
        qnum: row.qnum,
        db: this.db
      }).then(function (result) {
        if (result.data.status == 200) {
          if (result.data.repsoneContent.res != undefined) {
            _this.pagination = result.data.repsoneContent.pagination;
            _this.history_data = [result.data.repsoneContent]; //处理是pc蛋蛋的结果

            if (gid == 22 || gid == 23) {
              var list = [result.data.repsoneContent];

              for (var i = 0; i < list.length; i++) {
                for (var j = 0; j < list[i].res.length; j++) {
                  list[i].res[j] = pcddNum(list[i].res[j]);
                }

                list[i].sum = pcddNum(list[i].sum);
              }

              _this.history_data = list;
            } //处理六合彩


            if (gid == 20 || gid == 34 || gid == 48 || gid == 49 || gid == 50 || gid == 51) {
              var _list = [result.data.repsoneContent];

              for (var _i = 0; _i < _list.length; _i++) {
                var arr = [];

                for (var _j = 0; _j < _list[_i].res.length; _j++) {
                  arr.push(lhcNum(lhcNum(_list[_i].res[_j], _this.$filters.time(_list[_i].closetime).split(" ")[0])));
                }

                _list[_i].res_s = get_lhc_sx("开奖", arr);
              }

              _this.history_data = _list;
            }
          }
        }
      });
    },
    tableRowClassName_del_red_line: function tableRowClassName_del_red_line(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (row.remark === "后台撤单") {
        return "tableRowClassName_del_red_line";
      }

      return "";
    },
    dialog_change_page: function dialog_change_page(page) {
      this.dialog.page = page;
      this.search_detail(this.rowinfo);
    },
    search_detail: function search_detail(row) {
      var _this2 = this;

      this.dialog.row = row;
      var obj = {};

      if (this.from == "分类报表") {
        obj = this.rowinfo;
        obj.page = this.dialog.page; // obj = {
        //     gid: row.game_id,
        //     userid: "",
        //     phaseid: this.form.qid,
        //     page: this.dialog.page,
        //     limit: 20,
        //     t_status: this.form.settle_state,
        //     m_type: "zd",
        //     up_id: this.$store.state.userinfo.user_id,
        //     level_two: row.level_two,
        //     level_three: row.level_three,
        //     level_four: row.level_four,
        //     BeginDate: this.form.date.start,
        //     EndDate: this.form.date.end,
        // }
      }

      if (this.from == "交收报表") {
        obj = {
          gid: this.gameClassID === "" ? this.form.gid : this.gameClassID,
          //判断是总账还是明细账
          userid: row.id,
          page: this.dialog.page,
          limit: 20,
          t_status: this.form.settle_state,
          BeginDate: this.form.date.start,
          EndDate: this.form.date.end,
          phaseid: this.form.qid,
          bet_count: row.bet_count,
          db: this.db
        };

        if (this.isGetBuHuoDetail) {
          obj.is_manlet = 1;
          obj.is_fly = 1;
        }

        if (row.fly != undefined && row.fly != "") {
          obj.fly = row.fly;
        }

        if (row.is_fly != undefined && row.is_fly != "") {
          obj.is_fly = row.is_fly;
        }

        if (obj.fly == "1" || obj.is_fly == "1") {
          this.isGetBuHuoDetail = true;
          obj.userid = this.form.uid != "" && this.form.uid != undefined ? this.form.uid : "";
        } else {
          this.isGetBuHuoDetail = false;
        }
      }

      this.axios.post("operate/ReportDetail_B_kc", obj).then(function (result) {
        if (result.data.status == 200) {
          _this2.dialog.visible = true;
          _this2.is_eran = result.data.repsoneContent.is_eran;
          _this2.dialog.pagination = result.data.repsoneContent.pagination;
          _this2.dialog.detail_data = result.data.repsoneContent.list;
          var group_id = _this2.$store.state.userinfo.group_id;

          for (var i = 0; i < _this2.dialog.detail_data.length; i++) {
            if (_this2.dialog.detail_data[i].agent_list.length === 2) {
              var agent_list = [];
              agent_list.push(_this2.dialog.detail_data[i].agent_list[1]);
              _this2.dialog.detail_data[i].agent_list = agent_list;
            }

            for (var j = 0; j < _this2.dialog.detail_data[i].agent_list.length; j++) {
              if (_this2.dialog.detail_data[i].agent_list[j].name == "自己" || group_id == _this2.dialog.detail_data[i].agent_list[j].group_id) {
                _this2.dialog.detail_data[i].myResult = (_this2.dialog.detail_data[i].agent_list[j].occupy_bill * 1).toFixed(0);
              }
            }
          }
        }
      });
    },
    proc_data: function proc_data(data) {
      var isGetBuHuoDetail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var db = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      this.db = db;
      this.isGetBuHuoDetail = isGetBuHuoDetail;
      this.dialog.visible = true;
      this.is_eran = data.is_eran;
      this.dialog.pagination = data.pagination;
      this.dialog.detail_data = data.list;
      var group_id = this.$store.state.userinfo.group_id;

      for (var i = 0; i < this.dialog.detail_data.length; i++) {
        if (this.dialog.detail_data[i].agent_list.length === 2) {
          var agent_list = [];
          agent_list.push(this.dialog.detail_data[i].agent_list[1]);
          this.dialog.detail_data[i].agent_list = agent_list;
        }

        for (var j = 0; j < this.dialog.detail_data[i].agent_list.length; j++) {
          if (this.dialog.detail_data[i].agent_list[j].name == "自己" || group_id == this.dialog.detail_data[i].agent_list[j].group_id) {
            this.dialog.detail_data[i].myResult = (this.dialog.detail_data[i].agent_list[j].occupy_bill * 1).toFixed(0);
          }
        }
      }
    }
  }
};