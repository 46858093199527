<!--
 * @Author: your name
 * @Date: 2022-03-11 16:28:34
 * @LastEditTime: 2024-05-28 15:58:10
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\views\Home.vue
-->
<template>
    <div class="home">
        <div class="topbg"></div>
        <top ref="top" @change_color="change_color" />
        <usermenu ref="usermenu" @get_balance="$refs['top'].get_balance()" />
        <router-view class="router-view-main" @change_submenu="change_submenu" @change_play="change_play" @change_game_list="$refs['usermenu'].get_game_list()" />
    </div>
</template>

<script>
import axios from "../axios/axios";
import top from "../components/homePublic/top.vue";
import usermenu from "../components/homePublic/usermenu.vue";
export default {
    name: "Home",
    components: {
        top,
        usermenu,
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            activated: "",
            game_list: [],
            isRouterAlive: true,
        };
    },
    beforeRouteEnter(to, from, next) {
        axios.post("user_version2/branch?type=2").then((result) => {
            if (result.data.status === 200) {
                next((vm) => {
                    let list = result.data.repsoneContent.list;
                    vm.$store.state.gs.checked = list[0].username;
                    vm.$store.state.gs.list = list;
                });
            }
        });
    },
    created() {},
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        change_play(name) {
            this.$refs["usermenu"].submenu_change(name);
        },
        change_submenu(name, params, db) {
            // console.log(this.$refs['usermenu']);
            let menu = this.$refs["usermenu"].submenu.find((item) => item.title == name);
            this.$refs["usermenu"].click_sub_menu(menu, params, db);
        },
        change_color(color) {
            let strs = color.split(",");
            // let h = (strs[0].split('(')[1] * 1).toFixed(1);
            let h = strs[0].match(/\d+/g)[0];
            let s = strs[1].split("%")[0];
            let l = strs[2].split("%")[0];
            if (l < 95) {
                l = 95;
            }
            if (s > 35) {
                s = 35;
            }
            // console.log(h,s,l);
            let themeColorLight = `hsl(${h},${s}%,${l}%)`;
            let themeColorhalf = `hsl(${h},30%,75%)`;
            let themeColorhalf_l = `hsl(${h},50%,80%)`;
            this.$emit("get_color", color, themeColorLight, themeColorhalf, themeColorhalf_l);
            this.$nextTick(() => {
                this.$refs["usermenu"].change_color(color);
            });
        },
        get_activated(activated_text) {
            this.activated = activated_text;
        },
    },
};
</script>
<style>
.home {
    /* min-width: 1366px; */
    min-width: 1440px;
}
.topbg {
    background: #fff;
    height: 1px;
    position: relative;
    top: -1px;
}
</style>