<!--
 * @Author: your name
 * @Date: 2022-03-21 19:29:00
 * @LastEditTime: 2024-11-09 00:16:52
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\plublic\changeLog.vue
-->
<template>
    <div class="change-log" style="position: relative">
        <!-- <el-button v-show="$store.state.userinfo.group_name == '总公司'" style="position: absolute; right: 60px; top: -29px; z-index: 1" type="primary" size="small" @click="reset_login_name">重置（{{ userinfo.username }}）虚拟账号</el-button> -->
        <el-table :data="change_log_data" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
            <el-table-column property="id" label="编号" align="center"></el-table-column>
            <el-table-column property="uptime" label="时间" align="center"></el-table-column>
            <el-table-column property="act" label="操作" align="center"></el-table-column>
            <el-table-column property="old_value" label="原值" align="left">
                <template #default="scope">
                    <template v-if="typeof scope.row.old_value == 'string'">{{ scope.row.old_value }}</template>
                    <template v-else>
                        <div style="display: inlie-block" v-for="(item, key, i) in scope.row.old_value" :key="i">{{ key }}：{{ item }}</div>
                    </template>
                </template>
            </el-table-column>
            <el-table-column property="new_value" label="新值" align="left">
                <template #default="scope">
                    <template v-if="typeof scope.row.new_value == 'string'">{{ scope.row.new_value }}</template>
                    <template v-else>
                        <template v-if="Array.isArray(scope.row.new_value)">
                            <div style="display: inlie-block" v-for="item in scope.row.new_value" :key="item.id">{{ item.label }}：{{ item.value }}</div>
                        </template>
                        <template v-else>
                            <div style="display: inlie-block" v-for="(item, key) in scope.row.new_value" :key="item.id">{{ key }}：{{ item }}</div>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column property="admin_name" label="操作人" align="center"></el-table-column>
            <el-table-column property="ip" label="操作ip" align="center"></el-table-column>
        </el-table>
        <el-pagination class="paging" small @current-change="change_page" v-model="page" :page-size="20" :pager-count="5" :total="pagination.total_records * 1" layout="total,prev,pager,next,jumper" prev-text="上一页" next-text="下一页"
            hide-on-single-page />
    </div>
</template>

<script>
export default {
    props: ["userinfo"],
    data() {
        return {
            change_log_data: [],
            page: 1, //请求页码
            pagination: {
                //分页信息
                total_pages: "",
                total_records: "",
                page_size: "",
            },
        };
    },
    methods: {
        reset_login_name() {
            console.log(this.userinfo);
            this.$messageBox
                .confirm(`确定重置 ${this.userinfo.username} 的虚拟账号吗？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.axios
                        .post("user_version2/reset_login_name", {
                            db: this.userinfo.db ? this.userinfo.db : this.$route.query.db,
                            user_id: this.userinfo.id,
                        })
                        .then((result) => {
                            if (result.data.status == 200) {
                                this.$message.success(result.data.shortMessage);
                            }
                        });
                })
                .catch(() => { });
        },
        get_change_log_data() {
            this.axios
                .post("log/admin_log", {
                    uid: this.userinfo.id,
                    page: this.page,
                    limit: 20,
                    username: this.userinfo.username,
                    db: this.userinfo.db,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.change_log_data = result.data.repsoneContent.list;
                        this.pagination = result.data.repsoneContent.pagination;
                    }
                });
        },
        change_page(p) {
            this.page = p;
            this.get_change_log_data();
        },
        get_data(data) {
            data.list.forEach((item) => {
                if (Array.isArray(item.new_value)) {
                    item.new_value.sort((a, b) => a.id - b.id);
                }
            });
            this.change_log_data = data.list;
            this.pagination = data.pagination;
        },
    },
};
</script>
