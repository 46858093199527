import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  components: {},
  data: function data() {
    return {
      user_id: '',
      userlist: [],
      checkList: [],
      all_checked: false
    };
  },
  created: function created() {},
  methods: {
    sub: function sub() {
      var _this = this;

      if (this.checkList.length == 0) {
        return this.$message.error('您还未选择用户');
      }

      var list = [];
      this.userlist.forEach(function (user) {
        _this.checkList.forEach(function (item) {
          if (user.index == item) {
            list.push({
              db: user.db,
              id: user.id
            });
          }
        });
      });
      var members = list.reduce(function (acc, obj) {
        var key = obj.db;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(obj.id);
        return acc;
      }, {});
      this.axios.post("user_version2/sub_account", {
        user_id: this.user_id,
        act: 'add',
        members: members
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.$message.success(result.data.shortMessage);

          _this.$emit("other_view_back", "");
        }
      });
    },
    refslist: function refslist() {
      var _this2 = this;

      this.checkList = [];
      this.axios.post("user_version2/sub_account", {
        uid: this.user_id,
        act: 'list'
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.getdata(result.data.repsoneContent, _this2.user_id);
        }
      });
    },
    chang_allchecked: function chang_allchecked(s) {
      var _this3 = this;

      this.checkList = [];

      if (s) {
        this.userlist.forEach(function (item) {
          _this3.checkList.push(item.index);
        });
      }
    },
    getdata: function getdata(data) {
      var _this4 = this;

      var user_id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.userlist = data.list;
      this.user_id = user_id;
      this.userlist.forEach(function (item, index) {
        item.index = index;

        if (item.is_select == 1) {
          _this4.checkList.push(item.index);
        }
      });
    }
  }
};