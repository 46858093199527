/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-10-13 16:50:59
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-12-26 17:19:30
 * @FilePath: \pc_admin\src\filters\time.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function time(obj, type = '') {
    if (obj == null || obj == undefined || obj == '' || obj.length < 10) {
        return '-'
    }
    let date = '';
    date = new Date(obj * 1000);
    // if (typeof (obj) == 'string') {
    //     if (obj.length < 10) {
    //         date = new Date(obj * 1000);
    //     } else {
    //     }
    // } 
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let mi = date.getMinutes();
    let s = date.getSeconds();
    let w = date.getDay()
    let a = ["日", "一", "二", "三", "四", "五", "六"];
    w = a[w]
    m < 10 && (m = "0" + m);
    d < 10 && (d = "0" + d);
    h < 10 && (h = "0" + h);
    mi < 10 && (mi = "0" + mi);
    s < 10 && (s = "0" + s);
    // console.log(week);
    if (type === '报表注单') {
        return `${m}-${d} ${h}:${mi}:${s} 周${w}`
    } else if (type === 'noyear') {
        return `${m}-${d} ${h}:${mi}:${s}`
    } else if (type === 'notime') {
        return `${y}-${m}-${d}`
    } else if (type === 'nodate') {
        return `${h}:${mi}:${s}`
    } else if (type === 'month_day') {
        return `${m}-${d}`
    } else {
        return `${y}-${m}-${d} ${h}:${mi}:${s}`
    }

}