import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import axios from "../../../axios/axios";
export default {
  data: function data() {
    return {
      game_list: [],
      user: "",
      show_panel: true
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("play/getGameInfo").then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          var list = result.data.repsoneContent.list;
          list.forEach(function (element) {
            element.order = element.order == 0 ? "" : element.order;
          });
          vm.game_list = list;
        });
      }
    });
  },
  methods: {
    chageGameStatus: function chageGameStatus(item) {
      // console.log(item.id, item.gname);
      this.game_list.forEach(function (game) {
        if (game.id == item.id) {
          game.status = item.status == "1" ? "0" : "1";
        }
      });
    },
    save_list: function save_list() {
      var _this = this;

      // console.log(this.game_list);
      var new_list = [];
      this.game_list.forEach(function (game) {
        if (game.status == 1) {
          new_list.push({
            gid: game.id,
            order: game.order * 1
          });
        }
      }); //排序

      new_list = new_list.sort(function (a, b) {
        return a.order - b.order;
      });
      new_list.forEach(function (game, index) {
        // console.log(game, index);
        //自动排序不符合条件的order
        if ((game.order == "" || game.order <= 0 || game.order > new_list.length) && new_list.length > 1 && index != 0) {
          // console.log(index, game.order, new_list[index - 1]);
          game.order = new_list[index - 1].order + 1;
        }
      });

      if (new_list.length < 1) {
        return this.$alert("请至少选择1个彩种", "错误提示", {
          confirmButtonText: "确定",
          type: "error",
          callback: function callback(action) {}
        });
      }

      var obj = {
        play_name: new_list,
        db: this.user.db
      };

      if (this.user != "") {
        obj.agent_type = 1;
        obj.user_id = this.user.id; //判断是总公司设置彩种，需要传没有选中的彩种

        if (this.user.group_id == 2) {
          var cancel_playids = [];
          this.game_list.forEach(function (game) {
            if (game.status == 0) {
              cancel_playids.push(game.id);
            }
          });

          if (cancel_playids.length > 0) {
            obj.cancel_playids = cancel_playids;
          }
        }
      } //判断是一级总监设置彩种，需要传没有选中的彩种


      if (this.$store.state.userinfo.group_id == 2) {
        var cancel_playids_own = [];
        this.game_list.forEach(function (game) {
          if (game.status == 0) {
            cancel_playids_own.push(game.id);
          }
        });

        if (cancel_playids_own.length > 0) {
          obj.cancel_playids_own = cancel_playids_own;
        }
      }

      this.axios.post("play/game_checked", obj).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this.$message.success(result.data.shortMessage);

          if (_this.user != "") {
            _this.get_all_game(_this.user);
          } else {
            _this.get_all_game();

            _this.$emit("change_game_list");
          }
        }
      });
    },
    get_all_game: function get_all_game() {
      var _this2 = this;

      var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var show_panel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      // console.log(user);
      if (data != "") {
        this.user = user;
        this.show_panel = show_panel;
        var list = data.list;
        list.forEach(function (element) {
          element.order = element.order == 0 ? "" : element.order;
        });
        this.game_list = list;
      } else {
        var obj = {};

        if (user != "") {
          this.user = user;
          obj.agent_type = 1;
          obj.user_id = user.id;
          obj.db = user.db;
        }

        this.axios.post("play/getGameInfo", obj).then(function (result) {
          // console.log(result.data.repsoneContent)
          if (result.data.status == 200) {
            var _list = result.data.repsoneContent.list;

            _list.forEach(function (element) {
              element.order = element.order == 0 ? "" : element.order;
            });

            _this2.game_list = _list;
          }
        });
      }
    }
  }
};