import axios from "../../../axios/axios";
export default {
  components: {},
  data: function data() {
    return {
      list: [],
      pagination: {
        page_size: 20,
        total_pages: 8,
        total_records: ""
      },
      page: 1
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("user_version2/user_func_set", {
      type: "list"
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.list = result.data.repsoneContent;
        });
      }
    });
  },
  methods: {
    change: function change(row) {
      var _this = this;

      console.log(row);
      this.axios.post("user_version2/user_func_set", {
        type: "edit",
        db: row.db,
        user_id: row.id,
        status: row.status
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.$message.success(result.data.shortMessage);
        }

        _this.axios.post("user_version2/user_func_set", {
          type: "list"
        }).then(function (result) {
          if (result.data.status == 200) {
            _this.list = result.data.repsoneContent;
          }
        });
      });
    }
  }
};