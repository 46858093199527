import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import vPinyin from "../../plugin/pinyin";
import { jszd_menu } from "../../plugin/jszd_menu";
import flvjs from "flv.js";
export default {
  data: function data() {
    return {
      has_instant: false,
      game_list: [],
      menulist: [],
      submenu: [],
      activated_sup: "",
      activated_sub: "",
      gid: "",
      gname: "",
      show_gamelist: true,
      show_list: false,
      show_menulist: false,
      show_submenu: false,
      last_sup: "",
      last_sub: "",
      sub: {
        user: "",
        list: []
      },
      kjzb_dialog: {
        visible: false,
        url: ""
      },
      player: null,
      gs: {
        visible: false,
        list: [],
        checked: "",
        pagination: {
          total_pages: 0,
          total_records: "0",
          page_size: 10
        },
        usermenu_params: {
          menu: "",
          params: "",
          type: ""
        }
      },
      all_dzj: [],
      jszd_users: []
    };
  },
  created: function created() {
    var _this = this;

    if (this.game_list.length == 0) {
      this.get_game_list();
    } else {
      if (this.$store.state.jszd_users.length > 0) {
        this.jszd_users = this.$store.state.jszd_users;
        this.$store.state.instant_checked_gid = this.gid = this.jszd_users[0].game_list[0].id;
      } else {
        this.axios.post("user_version2/user_director").then(function (result) {
          if (result.data.status === 200) {
            var list = result.data.repsoneContent.list;
            _this.all_dzj = list;
            _this.jszd_users = [];
            list.forEach(function (user) {
              if (user.game_list.find(function (game) {
                return game.gname == _this.gname;
              })) {
                _this.jszd_users.push(user);
              }
            });
            _this.$store.state.jszd_users = _this.jszd_users;
            _this.$store.state.instant_checked_gid = _this.gid = _this.jszd_users[0].game_list[0].id;
          }
        });
      }
    } // console.log(this.$store.state.gs);


    this.gs.checked = this.$store.state.gs.checked;
    this.gs.list = this.$store.state.gs.list;
    this.axios.post("user_version2/branch?type=2").then(function (result) {
      if (result.data.status === 200) {
        var list = result.data.repsoneContent.list;
        _this.$store.state.gs.checked = _this.gs.checked = list[0].username;
        _this.$store.state.gs.list = _this.gs.list = list;

        if (_this.$store.state.menulist.length > 0) {
          _this.get_menulist();
        } else {
          _this.get_user_menu();
        }
      }
    });
  },
  beforeUnmount: function beforeUnmount() {
    this.destoryVideo();
  },
  methods: {
    close_kjzb: function close_kjzb() {
      this.destoryVideo();
      this.kjzb_dialog.visible = false;
    },
    destoryVideo: function destoryVideo() {
      if (this.player) {
        this.player.pause(); // 暂停播放数据流

        this.player.unload(); // 取消数据流加载

        this.player.detachMediaElement(); // 将播放实例从节点中取出

        this.player.destroy(); // 销毁播放实例

        this.player = null;
      }
    },
    //flvjs直播
    createVideo: function createVideo() {
      var _this2 = this;

      if (flvjs.isSupported()) {
        var videoElement = document.getElementById("videoElement");
        this.player = flvjs.createPlayer({
          type: "flv",
          isLive: true,
          hasAudio: true,
          // url: 'https://vdm6-ali.bemerswag.com/live/a6.flv'// flv视频流地址
          url: "https://vdm6-cnts.onmve.com/live/a6-2.flv" // flv视频流地址

        }, {
          cors: true,
          // 是否跨域
          // enableWorker: true, // 是否多线程工作
          enableStashBuffer: false,
          // 是否启用缓存
          // stashInitialSize: 384, // 缓存大小(kb)  默认384kb
          autoCleanupSourceBuffer: true,
          // 是否自动清理缓存
          isLive: true
        });
        this.player.attachMediaElement(videoElement); //挂载元素

        this.player.load(); //加载流

        this.player.play(); //播放流
      } // 报错重连


      this.player.on(flvjs.Events.ERROR, function (err, errdet) {
        // 参数 err 是一级异常，errdet 是二级异常
        if (err == flvjs.ErrorTypes.MEDIA_ERROR) {
          console.log("媒体错误");

          _this2.$message.error("媒体错误");

          if (errdet == flvjs.ErrorDetails.MEDIA_FORMAT_UNSUPPORTED) {
            console.log("媒体格式不支持");
          }
        }

        if (err == flvjs.ErrorTypes.NETWORK_ERROR) {
          console.log("网络错误");

          _this2.$message.error("网络错误,请重试");

          if (errdet == flvjs.ErrorDetails.NETWORK_STATUS_CODE_INVALID) {
            console.log("http状态码异常");
          }
        }

        if (err == flvjs.ErrorTypes.OTHER_ERROR) {
          console.log("其他异常：", errdet);
        }

        if (_this2.player) {
          _this2.destoryVideo(); // this.createVideo()

        }
      });
    },
    change_game: function change_game(gid) {
      var _this3 = this;

      this.gid = this.$store.state.instant_checked_gid = gid;
      this.gname = this.$store.state.instant_checked_gname = this.game_list.find(function (item) {
        return item.id == gid;
      }).gname;
      this.show_list = false;
      this.$store.state.instant_gameinfo = "";
      this.jszd_users = [];
      this.all_dzj.forEach(function (user) {
        if (user.game_list.find(function (game) {
          return game.gname == _this3.gname;
        })) {
          _this3.jszd_users.push(user);
        }
      });
      this.$store.state.jszd_users = this.jszd_users; // console.log(this.jszd_users);

      this.click_mian_menu({
        menu_name: "instant",
        bg: "#fff",
        color: "#333",
        index: 8
      });
      this.$store.state.now_menu = {
        menu_name: "instant",
        bg: "#fff",
        color: "#333",
        index: 8
      };
    },
    get_game_list: function get_game_list() {
      var _this4 = this;

      this.axios.post("main/game_list").then(function (result) {
        if (result.data.status === 200) {
          _this4.$store.state.game_list = result.data.repsoneContent.game_list;

          _this4.$store.state.game_list.sort(function (a, b) {
            return a.order - b.order;
          });

          result.data.repsoneContent.trader_game_list.sort(function (a, b) {
            return a.order - b.order;
          });
          _this4.game_list = _this4.$store.state.trader_game_list = result.data.repsoneContent.trader_game_list;
          _this4.$store.state.operate_game_list = result.data.repsoneContent.operate_game_list;

          _this4.$store.state.operate_game_list.sort(function (a, b) {
            return a.order - b.order;
          }); // console.log(this.game_list);


          if (_this4.game_list.length > 0) {
            _this4.gname = _this4.game_list[0].gname;
          } else {
            _this4.show_gamelist = false;
          }

          if (_this4.$store.state.instant_checked_gid !== "") {
            _this4.gid = _this4.$store.state.instant_checked_gid;
            _this4.gname = _this4.$store.state.instant_checked_gname;
          } else {
            _this4.$store.state.instant_checked_gid = _this4.gid = _this4.game_list[0].id;
            _this4.$store.state.instant_checked_gname = _this4.gname = _this4.game_list[0].gname;
          }

          _this4.axios.post("user_version2/user_director").then(function (result) {
            if (result.data.status === 200) {
              var list = result.data.repsoneContent.list;
              _this4.all_dzj = list;
              _this4.jszd_users = [];
              list.forEach(function (user) {
                if (user.game_list.find(function (game) {
                  return game.gname == _this4.gname;
                })) {
                  _this4.jszd_users.push(user);
                }
              });
              _this4.$store.state.jszd_users = _this4.jszd_users;
              _this4.$store.state.instant_checked_gid = _this4.gid = _this4.jszd_users[0].game_list[0].id;
            }
          });
        }
      });
    },
    //切换到下级页面
    change_sub: function change_sub(user_id) {
      var _this5 = this;

      // this.$emit('get_balance');
      this.click_mian_menu({
        menu_name: "notice",
        bg: this.$store.state.themeColor,
        color: "#fff",
        index: 1
      });
      var user_info = "";
      var act = "";

      if (user_id != "") {
        this.sub.list.forEach(function (item) {
          item.options.forEach(function (user) {
            if (user.id == user_id) {
              user_info = user;
              _this5.$store.state.watchsub.group_name = item.label;
              _this5.$store.state.watchsub.username = user.username;
            }
          });
        });
        act = "add";
        this.$store.state.watchsub_show = true;
      } else {
        this.sub.user = "";
        act = "del";
      }

      this.axios.post("user_version2/change_user", _objectSpread(_objectSpread({}, user_info), {}, {
        act: act
      })).then(function (result) {
        if (result.data.status === 200 && result.data.shortMessage == "切换成功") {
          if (act == "add") {
            _this5.$store.state.watchsub_sub_id = user_id;

            _this5.$message.success("切换到下级成功！只能查看数据，不能修改，增加，删除数据！");
          }

          if (act == "del" && result.data.repsoneContent == "") {
            _this5.$store.state.watchsub_show = false;
            _this5.$store.state.watchsub_sub_id = "";

            _this5.$message.success("切换到自己成功");

            _this5.axios.post("user_version2/change_user", _objectSpread(_objectSpread({}, user_info), {}, {
              act: "check"
            })).then(function (result) {
              if (result.data.repsoneContent.is_change_user == 1 && result.data.repsoneContent.sub_user_id != _this5.$store.state.userinfo.user_id) {
                _this5.$message.error("返回自己失败，请重试");

                _this5.$store.state.watchsub_show = true;
              }
            });
          }

          _this5.get_user_menu();

          _this5.get_game_list();

          _this5.$emit("get_balance"); //获取网站名称


          if (_this5.$store.state.userinfo.group_id == 1) {
            _this5.axios.get("/login/get_web_info?url=".concat(window.location.host)).then(function (result) {
              if (result.data.status == 1 && result.data.msg.web_name != "") {
                // document.title = result.data.msg.web_name;
                _this5.$store.state.web_name = result.data.msg.web_name;
              }
            });
          }
        }
      });
    },
    //获取下级用户
    get_sublist: function get_sublist() {
      var _this6 = this;

      this.axios.post("user_version2/member_xs_list").then(function (result) {
        if (result.data.status === 200) {
          var member_list = result.data.repsoneContent;
          var list = [{
            label: "总公司",
            options: []
          }, {
            label: "大总监",
            options: []
          }, {
            label: "总监",
            options: []
          }, {
            label: "分公司",
            options: []
          }, {
            label: "大股东",
            options: []
          }, {
            label: "股东",
            options: []
          }, {
            label: "总代理",
            options: []
          }, {
            label: "代理",
            options: []
          }];
          member_list.forEach(function (item) {
            list[item.group_id - 1].options.push(item);
          });
          var newlist = [];
          list.forEach(function (item) {
            if (item.options.length > 0) {
              newlist.push(item);
            }
          });
          _this6.sub.list = newlist;

          if (_this6.$store.state.watchsub_show) {
            _this6.sub.user = _this6.$store.state.watchsub_sub_id;
          } // console.log(this.sub.list);

        }
      });
    },
    change_color: function change_color(color) {
      this.menulist.forEach(function (item) {
        // console.log(item.color);
        if (item.color == "#fff") {
          item.bg = color;
        }
      });
    },
    click_game_list: function click_game_list() {
      this.show_list = !this.show_list;
    },
    mouseenter: function mouseenter(s) {
      this.show_list = true;
    },
    mouseleave: function mouseleave(s) {
      this.show_list = false;
    },
    confirm_quit: function confirm_quit() {
      var _this7 = this;

      this.axios.post("login/logout").then(function (result) {
        if (result.data.status === 200 && result.data.shortMessage === "已退出登录") {
          sessionStorage.clear();
          _this7.$store.state.isLogin = false;
          _this7.$store.state.notice = true; // this.quit = false;

          if (_this7.$store.state.userinfo.group_id != 1) {
            window.location.replace(result.data.repsoneContent);
          } else {
            // this.$store.state = {
            //     userinfo: {
            //         user_id: "",
            //     },
            //     themeColor: "",
            //     themeColorhalf: "",
            //     themeColorLight: "",
            //     game_list: [],
            //     gid: "",
            //     instant_gameinfo: "",
            //     instant_checked_gid: "",
            //     instant_checked_gname: "",
            //     activated_sup: "notice",
            //     notice_hasshow: false, //是否已经显示过公告弹窗
            //     int_router_view: false, //初始化路由页面
            //     instant_pk: "0",
            //     viewdata: {},
            //     watchsub_show: false,
            //     watchsub_sub_id: false,
            //     watchsub: {
            //         group_name: "",
            //         username: "",
            //     },
            //     relod_sub_menu_data: false, //是否刷新小菜单数据
            //     route_data: {
            //         zidongbuhuosheding: { list: [], play_arr: {} },
            //     },
            //     gs: {
            //         checked: "",
            //         list: [],
            //     },
            //     jszd_users: [],
            //     menulist: [],
            //     now_menu: "",
            // };
            _this7.$store.state.userinfo = {
              user_id: ""
            };
            _this7.$store.state.themeColor = "";
            _this7.$store.state.themeColorhalf = "";
            _this7.$store.state.themeColorLight = "";
            _this7.$store.state.game_list = [];
            _this7.$store.state.gid = "";
            _this7.$store.state.instant_gameinfo = "";
            _this7.$store.state.instant_checked_gid = "";
            _this7.$store.state.instant_checked_gname = "";
            _this7.$store.state.activated_sup = "notice";
            _this7.$store.state.notice_hasshow = false;
            _this7.$store.state.int_router_view = false;
            _this7.$store.state.instant_pk = "0";
            _this7.$store.state.viewdata = {};
            _this7.$store.state.watchsub_show = false;
            _this7.$store.state.watchsub_sub_id = false;
            _this7.$store.state.watchsub = {
              group_name: "",
              username: ""
            };
            _this7.$store.state.relod_sub_menu_data = false;
            _this7.$store.state.route_data = {
              zidongbuhuosheding: {
                list: [],
                play_arr: {}
              }
            };
            _this7.$store.state.gs = {
              checked: "",
              list: []
            };
            _this7.$store.state.jszd_users = [];
            _this7.$store.state.menulist = [];
            _this7.$store.state.now_menu = ""; // this.$store.dispatch("resetState");
            // console.log(this.$store.state.menulist);

            _this7.$router.replace("/");
          }
        }
      });
    },
    submenu_change: function submenu_change(label) {
      // console.log(label);
      this.last_sub = this.activated_sub;
      this.click_sub_menu(this.submenu.find(function (item) {
        return item.title == label;
      }));
    },
    //小菜单
    click_sub_menu: function click_sub_menu(menu) {
      var _this8 = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      // console.log(menu, params, type);
      this.activated_sub = menu.title; // console.log(this.last_sup, this.activated_sup);
      //小菜单是否重复点击，来刷新数据

      if (this.last_sub == this.activated_sub) {
        this.$store.state.relod_sub_menu_data = !this.$store.state.relod_sub_menu_data;
      }

      this.$store.state.checked_menu = menu;
      var sub_routename = vPinyin.chineseToPinYin(menu.title); // console.log(sub_routename);

      if (this.activated_sup == "instant") {
        if (type != "") {
          // console.log(this.jszd_users);
          menu.change_play = 0;
          this.axios.post("trader/index_header", {
            gid: this.gid,
            pk: "0",
            user_id: this.jszd_users[0].id,
            db: this.jszd_users[0].db
          }).then(function (result) {
            if (result.data.status == 200) {
              _this8.$store.state.instant_gameinfo = result.data.repsoneContent;

              _this8.$router.push({
                path: "/home/instant/instant",
                name: "instant",
                query: menu
              });
            }
          });
        } else {
          menu.change_play = 1;
          this.$router.push({
            path: "/home/instant/instant",
            name: "instant",
            query: menu
          });
        }
      } else {
        var pushobj = {
          path: "/home/".concat(this.activated_sup, "/").concat(sub_routename),
          name: sub_routename
        };

        if (sub_routename == "lishikaijiang" || sub_routename == "xitongrizhi" || sub_routename == "zidongbuhuosheding" || sub_routename == "wanfazhuangtai") {
          pushobj.query = {
            gid: this.gid,
            gname: this.gname
          };
          this.$router.push(pushobj);
        } else if (sub_routename == "peilvsheding") {
          pushobj.query = {
            user_id: this.$store.state.userinfo.user_id
          };
          this.$router.push(pushobj);
        } else if (sub_routename == "jiangqiguanli" || sub_routename == "liuhepankou" || sub_routename == "kongpanshezhi") {
          pushobj.query = {
            db: this.gs.checked
          };

          if (sub_routename == "liuhepankou") {
            pushobj.query = {
              db: this.gs.checked,
              gid: this.$store.state.userinfo.lh_list[0].gid
            }; // console.log(pushobj);
          }

          this.$router.push(pushobj);
        } else if (sub_routename == "zongjian" || sub_routename == "fengongsi" || sub_routename == "dagudong" || sub_routename == "gudong" || sub_routename == "zongdaili" || sub_routename == "daili" || sub_routename == "huiyuan") {
          if (type != "" && params != "") {
            pushobj.query = {
              uid: params,
              db: type
            };
            this.$router.push(pushobj);
          } else {
            return this.$message.error("请从大总监列表选择下级用户");
          }
        } else if (sub_routename == "zizhanghao") {
          pushobj.query = {
            uid: params,
            db: type
          };
          this.$router.push(pushobj); // console.log(type, params);
        } else if (sub_routename == "gerenziliao") {
          var gids = [];
          this.game_list.forEach(function (item) {
            gids.push(item.id);
          });

          if (gids.length > 4) {
            gids.length = 4;
          } // console.log(gids);


          this.axios.post("user_version2/index", {
            gids: gids
          }).then(function (result) {
            if (result.data.status == 200) {
              _this8.$store.state.viewdata = result.data.repsoneContent;

              _this8.$router.push(pushobj);
            }
          });
        } else {
          this.$router.push(pushobj);
        }
      }

      this.$nextTick(function () {
        _this8.submenu.forEach(function (item) {
          if (item.title == menu.title) {
            item.color = "#fff";
            item.bg = _this8.$store.state.themeColor;
          } else {
            item.bg = "#fff";
            item.color = "#333";
          }
        });
      }); //判断是否路由页面要重新初始化

      if (this.sub_routename == "baobiaochaxun") {
        this.$store.state.int_router_view = !this.$store.state.int_router_view;
      }
    },
    //主菜单
    click_mian_menu: function click_mian_menu(item) {
      var _this9 = this;

      // console.log(item);
      if (item.menu_name != "kjzb" && item.menu_name != "quit") {
        this.show_submenu = false;
      } // this.submenu = []


      var menu = item.menu_name;
      this.menulist.forEach(function (item) {
        item.bg = "#fff";
        item.color = "#333";

        if (item.menu_name == menu) {
          item.color = "#fff";
          item.bg = _this9.$store.state.themeColor;
        }
      });
      var submenu = [];
      this.last_sup = this.activated_sup;
      this.$store.state.activated_sup = this.activated_sup = menu; //点击开奖直播

      if (menu == "kjzb") {
        this.activated_sup = this.$route.matched[1].name;
        this.menulist.forEach(function (item) {
          item.bg = "#fff";
          item.color = "#333";

          if (item.menu_name == _this9.activated_sup) {
            item.color = "#fff";
            item.bg = _this9.$store.state.themeColor;
          }
        });

        if (this.$store.state.userinfo.website_name == "77test") {
          this.kjzb_dialog.visible = true;
          this.$nextTick(function () {
            _this9.createVideo();
          });
        } else if (this.$store.state.userinfo.website_name == "xinaoliutwo") {
          //新aoliu
          return window.open("https://00853lhc.com");
        } else if (this.$store.state.userinfo.website_name == "aoliu") {
          //老aoliu
          return window.open("https://a6tk23.com");
        }
      } else if (menu == "quit") {
        // this.quit = true;
        this.$messageBox.confirm("确认退出吗？", "操作确认", {
          customClass: "myMessageBox",
          customStyle: {
            backgroundColor: this.$store.state.themeColorLight,
            border: " 2px solid",
            borderColor: " ".concat(this.$store.state.themeColor),
            width: "200px"
          }
        }).then(function () {
          _this9.confirm_quit();
        }).catch(function () {
          _this9.activated_sup = _this9.$route.matched[1].name; // console.log(this.activated_sup);

          _this9.menulist.forEach(function (item) {
            item.bg = "#fff";
            item.color = "#333";

            if (item.menu_name == _this9.activated_sup) {
              item.color = "#fff";
              item.bg = _this9.$store.state.themeColor;
            }
          });
        });
      } else {
        if (this.activated_sup == "instant") {
          submenu = jszd_menu(this.gid);
        } else {
          // console.log(menu, this.menulist);
          submenu = this.menulist.find(function (item) {
            return item.menu_name == menu;
          }).ut.ut;
        }

        this.submenu = submenu;
        this.show_submenu = true; // console.log(submenu[0]);

        this.click_sub_menu(submenu[0], "", "init");
      }

      if (menu == "reportmain" && this.last_sup == "reportmain") {
        this.$store.state.int_router_view = !this.$store.state.int_router_view;
      }

      if (menu == "history" && this.last_sup == "history") {
        this.$store.state.int_router_view = !this.$store.state.int_router_view;
      }

      if (menu == "inner") {
        this.gs.visible = true;
      } else {
        this.gs.visible = false;
      }

      this.$store.state.now_menu = item;
    },

    /**获取用户菜单权限 */
    get_user_menu: function get_user_menu() {
      var _this10 = this;

      // this.menulist = [];
      this.show_menulist = false;
      this.show_submenu = false;
      this.axios.post("user_version2/user_menu", {
        token: this.$store.state.userinfo.token,
        user: this.$store.state.userinfo.user_id
      }).then(function (result) {
        if (result.data.status == 200 && result.data.shortMessage === "获取菜单成功") {
          var menu = result.data.repsoneContent.menulist;
          _this10.$store.state.menulist = JSON.parse(JSON.stringify(menu));

          _this10.get_menulist();
        }
      });
    },
    //生成菜单
    get_menulist: function get_menulist() {
      var _this11 = this;

      var menu = JSON.parse(JSON.stringify(this.$store.state.menulist));
      menu.forEach(function (item) {
        if (item.title == "站内消息") {
          item.menu_name = "notice";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 1;
        }

        if (item.title == "历史开奖") {
          item.menu_name = "history";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 2;
        }

        if (item.title == "报表查询") {
          item.menu_name = "reportmain";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 3;
        }

        if (item.title == "个人管理") {
          item.menu_name = "self";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 4;
        }

        if (item.title == "内部管理") {
          item.menu_name = "inner";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 5;
        }

        if (item.title == "数据统计") {
          item.menu_name = "statistics";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 6;
        }

        if (item.title == "用户管理") {
          item.menu_name = "user";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 7; // this.get_sublist();
        }

        if (item.title == "即时注单") {
          item.menu_name = "instant";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 8;
          _this11.has_instant = true;
        }

        if (item.title == "开奖直播") {
          item.menu_name = "kjzb";
          item.bg = "#fff";
          item.color = "#333";
          item.index = 9;
        }

        if (item.title == "站内消息" || item.title == "历史开奖" || item.title == "报表查询" || item.title == "个人管理" || item.title == "内部管理" || item.title == "数据统计" || item.title == "用户管理") {
          var _iterator = _createForOfIteratorHelper(item.ut.ut),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var sm = _step.value;
              sm.bg = "#fff";
              sm.color = "#333";
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      });
      menu.push({
        title: "安全退出",
        menu_name: "quit",
        index: 0,
        bg: "#fff",
        color: "#333"
      });
      this.menulist = menu;
      this.show_menulist = true;
      this.show_submenu = true; // console.log(this.menulist);
      // console.log(this.$store.state.now_menu);

      if (this.$store.state.now_menu != "") {
        // console.log(this.$store.state.now_menu);
        if (this.$store.state.now_menu.title == "即时注单") {
          this.gid = this.$store.state.instant_checked_gid;
        }

        this.click_mian_menu(this.$store.state.now_menu);
      } else {
        // console.log(this.menulist.find((item) => item.title == "报表查询") != undefined);
        if (this.menulist.find(function (item) {
          return item.title == "报表查询";
        }) != undefined) {
          this.click_mian_menu({
            menu_name: "reportmain",
            bg: this.$store.state.themeColor,
            color: "#fff",
            index: 7
          });
        } else {
          this.click_mian_menu({
            menu_name: this.menulist[0].menu_name,
            bg: this.$store.state.themeColor,
            color: "#fff",
            index: this.menulist[0].index
          });
        }
      }
    }
  }
};