import _toConsumableArray from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
export default {
  props: ['trader_diff', 'userinfo'],
  data: function data() {
    return {
      gid: '',
      game_list: [],
      loss_data: [],
      uid: "",
      username: "",

      /**彩种分组 */
      play_arr: [],
      fast: {
        inputnum: 0
      },
      type: "",
      //设置类型
      gids: '',
      pid: ''
    };
  },
  created: function created() {// console.log(this.trader_diff);
  },
  methods: {
    fastset: function fastset(val) {
      this.loss_data.forEach(function (item) {
        item.inputnum = val;
      });
    },
    sub_set: function sub_set() {
      var _this = this;

      var post_data = "";

      if (this.type === "one") {
        post_data = {
          gid: this.gid,
          uid: this.uid,
          play_arr_odds: [],
          play_rate: [],
          old_play_rate: [],
          db: this.userinfo.db
        };
      } else {
        post_data = {
          gids: this.gids,
          pid: this.pid,
          uid: this.uid,
          play_arr_odds: [],
          play_rate: [],
          old_play_rate: [],
          db: this.userinfo.db
        };
      }

      var _iterator = _createForOfIteratorHelper(this.loss_data),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          item.play_rate = (item.high_rate - item.inputnum).toFixed(4);

          if (item.play_rate * 1 > item.high_rate * 1 || item.play_rate * 1 < item.low_rate * 1) {
            // console.log(item);
            return this.$message({
              message: item.name + ' 赔率设定不能超过上限或者低于下限',
              type: 'error'
            });
            ;
          }

          if (item.old_play_rate != item.play_rate) {
            post_data.play_arr_odds.push(item.play_str);
            post_data.play_rate.push(item.play_rate);
            post_data.old_play_rate.push(item.old_play_rate);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (post_data.play_rate.length) {
        this.axios.post("play/member_agent_set_exe", post_data).then(function (result) {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            _this.$message({
              message: result.data.shortMessage,
              type: 'success'
            });

            _this.get_loss_data(_this.gid, _this.pid);
          }
        });
      } else {
        this.$message.warning('您未作出任何修改，数据已保持');
      }
    },
    fast_set_col: function fast_set_col() {
      for (var key in this.fast) {
        if (this.fast[key]) {
          for (var i = 0; i < this.loss_data.length; i++) {
            this.loss_data[i][key] = this.fast[key];
          }
        }
      }
    },
    get_data: function get_data(data, uid) {
      this.game_list = this.$store.state.game_list;
      this.gid = this.game_list[0].id;
      this.uid = uid;
      var play_arr = data.play_arr;

      if (this.play_arr.length < 1) {
        for (var key in play_arr) {
          play_arr[key].pid = key;
          this.play_arr.push(play_arr[key]);
        }
      }

      if (data.list.find(function (item) {
        return item.name == '特码B';
      }) != undefined) {
        var item = data.list.splice(data.list.findIndex(function (item) {
          return item.name == '特码B';
        }), 1)[0];
        data.list.splice(1, 0, item);
      }

      this.loss_data = _toConsumableArray(data.list);

      for (var i = 0; i < this.loss_data.length; i++) {
        this.loss_data[i].old_play_rate = this.loss_data[i].play_rate;
        this.loss_data[i].inputnum = (this.loss_data[i].high_rate - this.loss_data[i].play_rate).toFixed(4) * 1; // this.loss_data[i].inputnum_min = this.trader_diff.diff[0] * 1;

        this.loss_data[i].inputnum_min = 0;
        this.loss_data[i].inputnum_max = this.trader_diff.diff[1];
      }

      if (this.gid != '') {
        this.type = "one";
      }

      if (this.pid != '') {
        this.type = "group";
        pid == 18 ? this.gids = 'ssc' : "";
        pid == 19 ? this.gids = 'pk10' : "";
        pid == 31 ? this.gids = 'k3' : "";
        pid == 20 ? this.gids = 'lhc' : "";
        pid == 22 ? this.gids = 'pcdd' : "";
      }
    },
    get_loss_data: function get_loss_data() {
      var _this2 = this;

      var gid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var pid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      // console.log(this.trader_diff);
      this.gid = gid;
      this.pid = pid; // console.log(`gid: ${this.gid}--pid:${this.pid}`);

      this.axios.post("play/member_agent_set", {
        gid: gid,
        pid: pid,
        gids: this.gids,
        user_id: this.uid,
        db: this.userinfo.db
      }).then(function (result) {
        if (result.data.status == 200) {
          var play_arr = result.data.repsoneContent.play_arr;
          var list = result.data.repsoneContent.list;

          if (_this2.play_arr.length < 1) {
            for (var key in play_arr) {
              play_arr[key].pid = key;

              _this2.play_arr.push(play_arr[key]);
            }
          }

          if (list.find(function (item) {
            return item.name == '特码B';
          }) != undefined) {
            var item = list.splice(list.findIndex(function (item) {
              return item.name == '特码B';
            }), 1)[0];
            list.splice(1, 0, item);
          }

          _this2.loss_data = _toConsumableArray(list);

          for (var i = 0; i < _this2.loss_data.length; i++) {
            _this2.loss_data[i].old_play_rate = _this2.loss_data[i].play_rate;
            _this2.loss_data[i].inputnum = (_this2.loss_data[i].high_rate - _this2.loss_data[i].play_rate).toFixed(4) * 1; // this.loss_data[i].inputnum_min = this.trader_diff.diff[0] * 1;

            _this2.loss_data[i].inputnum_min = 0;
            _this2.loss_data[i].inputnum_max = _this2.trader_diff.diff[1] * 1; // this.$nextTick(() => {
            // })
          }

          if (gid != '') {
            _this2.type = "one";
          }

          if (pid != '') {
            _this2.type = "group";
            pid == 18 ? _this2.gids = 'ssc' : "";
            pid == 19 ? _this2.gids = 'pk10' : "";
            pid == 31 ? _this2.gids = 'k3' : "";
            pid == 20 ? _this2.gids = 'lhc' : "";
            pid == 22 ? _this2.gids = 'pcdd' : "";
          }
        }
      });
    }
  }
};