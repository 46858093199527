<!--
 * @Author: your name
 * @Date: 2022-03-17 23:36:52
 * @LastEditTime: 2024-03-09 14:27:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\user.vue
-->
<template>
    <div class="user">
        <router-view v-slot="{ Component }" class="user-router-view" @change_submenu="change_submenu">
            <component v-if="Component" :is="Component" />
        </router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        change_submenu(name, params, db) {
            this.$emit("change_submenu", name, params, db);
        },
    },
};
</script>
<style >
.user .user-router-view .status-btn {
    cursor: pointer;
    height: auto;
    line-height: 18px;
    margin-right: 0px;
    border: 1px #adb99d solid;
    background: #dde6cd;
    background-image: -webkit-linear-gradient(to top, #cfd9bc, #fffffa);
    background-image: -moz-linear-gradient(to top, #cfd9bc, #fffffa);
    background-image: linear-gradient(to top, #cfd9bc, #fffffa);
    padding: 2px;
    color: #000;
    white-space: nowrap;
    display: inline-block;
}
.user .user-router-view .status-btn:hover {
    background-image: linear-gradient(to top, #fffffa, #cfd9bc);
    color: #000;
}
</style>
