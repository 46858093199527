<!--
 * @Author: your name
 * @Date: 2022-05-02 23:24:32
 * @LastEditTime: 2024-08-17 12:03:52
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \super_admin\src\components\instant\games\lhc.vue
-->
<template>
    <div class="lhc loseinfo">
        <template v-if="title == '特码A+B'">
            <el-row :gutter="2">
                <el-col :span="21">
                    <el-row :gutter="2">
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmAB_1_25" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmAB_26_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmAB_lm" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="45" />
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table
                                :data="tmAB_sb"
                                border
                                size="small"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                style="margin-top: 3px"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="45">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="szsz_amount[1]" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="theme-border-half ztze-zgks-zgyl" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                                <p>
                                    总投注额：
                                    <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                                </p>
                                <p>
                                    最高亏损：
                                    <span style="color: red">{{ max_ks }}</span>
                                </p>
                                <p>
                                    最高盈利：
                                    <span>{{ max_yl }}</span>
                                </p>
                                <p>注：注额显示为所有与特码相关项（特码、特码色波、特码双面、特肖）合计注额</p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px">
                                <el-row>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_fuzhi_class" @click="set_kuishun_pailie('fu', 'tm_px_ab')">按亏损额负值排列</el-col>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_zhengzhi_class" @click="set_kuishun_pailie('zheng', 'tm_px_ab')">按亏损额正值排列</el-col>
                                </el-row>
                            </div>
                            <el-table
                                ref="tm_px_ab"
                                :data="tmpl"
                                border
                                size="small"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                height="750px"
                                scrollbar-always-on
                                :default-sort="kuisun_obj"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="pl" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="szsz_amount[1]" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="3">
                    <el-row :gutter="2">
                        <el-col :span="24">
                            <div class="title theme-border-half light-bg">
                                各项明细：
                                <span class="theme-text">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                            </div>
                            <div class="cl-item theme-border-half light-bg" v-for="item of sum.sums" :key="item.index" @click="change_play(item.name)">
                                <span>{{ item.name }}</span>
                                <span class="theme-text num">{{ szsz == 0 ? item.all_balance : item.own_balance }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '特码A'">
            <el-row :gutter="2">
                <el-col :span="21">
                    <el-row :gutter="2">
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmA_1_25" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmA_26_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmA_lm" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="45" />
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table
                                :data="tmA_sb"
                                border
                                size="small"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                style="margin-top: 3px"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="45">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="theme-border-half ztze-zgks-zgyl" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                                <p>
                                    总投注额：
                                    <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                                </p>
                                <p>
                                    最高亏损：
                                    <span style="color: red">{{ max_ks }}</span>
                                </p>
                                <p>
                                    最高盈利：
                                    <span>{{ max_yl }}</span>
                                </p>
                                <p>注：注额显示为所有与特码相关项（特码、特码色波、特码双面、特肖）合计注额</p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px">
                                <el-row>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_fuzhi_class" @click="set_kuishun_pailie('fu', 'tm_px_a')">按亏损额负值排列</el-col>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_zhengzhi_class" @click="set_kuishun_pailie('zheng', 'tm_px_a')">按亏损额正值排列</el-col>
                                </el-row>
                            </div>
                            <el-table
                                ref="tm_px_a"
                                :data="tmpl"
                                border
                                size="small"
                                height="750px"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                :default-sort="kuisun_obj"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="3">
                    <el-row :gutter="2">
                        <el-col :span="24">
                            <div class="title theme-border-half light-bg">
                                各项明细：
                                <span class="theme-text">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                            </div>
                            <div class="cl-item theme-border-half light-bg" v-for="item of sum.sums" :key="item.index" @click="change_play(item.name)">
                                <span>{{ item.name }}</span>
                                <span class="theme-text num">{{ szsz == 0 ? item.all_balance : item.own_balance }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '特码B'">
            <el-row :gutter="2">
                <el-col :span="21">
                    <el-row :gutter="2">
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmB_1_25" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmB_26_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px"></div>
                            <el-table :data="tmB_lm" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="45" />
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-table
                                :data="tmB_sb"
                                border
                                size="small"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                style="margin-top: 3px"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="45">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="theme-border-half ztze-zgks-zgyl" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                                <p>
                                    总投注额：
                                    <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                                </p>
                                <p>
                                    最高亏损：
                                    <span style="color: red">{{ max_ks }}</span>
                                </p>
                                <p>
                                    最高盈利：
                                    <span>{{ max_yl }}</span>
                                </p>
                                <p>注：注额显示为所有与特码相关项（特码、特码色波、特码双面、特肖）合计注额</p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="margin-top: 3px">
                                <el-row>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_fuzhi_class" @click="set_kuishun_pailie('fu', 'tm_px_b')">按亏损额负值排列</el-col>
                                    <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_zhengzhi_class" @click="set_kuishun_pailie('zheng', 'tm_px_b')">按亏损额正值排列</el-col>
                                </el-row>
                            </div>
                            <el-table
                                ref="tm_px_b"
                                :data="tmpl"
                                border
                                size="small"
                                height="750px"
                                highlight-current-row
                                header-cell-class-name="table-header-cell"
                                header-row-class-name="table-header-row"
                                table-layout="auto"
                                :default-sort="kuisun_obj"
                                :row-class-name="tableRowClassName"
                            >
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="3">
                    <el-row :gutter="2">
                        <el-col :span="24">
                            <div class="title theme-border-half light-bg">
                                各项明细：
                                <span class="theme-text">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                            </div>
                            <div class="cl-item theme-border-half light-bg" v-for="item of sum.sums" :key="item.index" @click="change_play(item.name)">
                                <span>{{ item.name }}</span>
                                <span class="theme-text num">{{ szsz == 0 ? item.all_balance : item.own_balance }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '色波'">
            <el-row :gutter="2">
                <el-col :span="12">
                    <div style="margin-top: 3px"></div>
                    <el-table :data="sb_ds" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="类型" align="center" width="34">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="280">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num" :style="{ backgroundColor: scope.row.color }">{{ num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <div style="margin-top: 3px"></div>
                    <el-table :data="sb_dx" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="类型" align="center" width="34">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="300">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num" :style="{ backgroundColor: scope.row.color }">{{ num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <div style="margin-top: 3px"></div>
                    <el-table :data="sb" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="34">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <div style="margin-top: 3px"></div>
                    <div class="theme-border-half" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                        <p>
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </p>
                        <p>
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </p>
                        <p>
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </p>
                    </div>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '特肖'">
            <el-row :gutter="2">
                <el-col :span="16">
                    <div style="margin-top: 3px"></div>
                    <el-table
                        :data="tx"
                        border
                        size="small"
                        highlight-current-row
                        header-cell-class-name="table-header-cell"
                        header-row-class-name="table-header-row"
                        table-layout="auto"
                        :row-class-name="tableRowClassName"
                        :default-sort="{ prop: 'szsz_amount[4]', order: 'ascending' }"
                    >
                        <el-table-column property="name" label="生肖" align="center" width="60">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="280">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num.num" :style="{ backgroundColor: num.color }">{{ num.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="150">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="szsz_amount[4]" label="盈亏" align="center" sortable>
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div style="margin-top: 3px"></div>
                    <div class="theme-border-half" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                        <p>
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </p>
                        <p>
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </p>
                        <p>
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </p>
                    </div>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '正码/正特'">
            <div class="theme-border" style="border-bottom: 2px solid; height: 22px; margin-top: 3px">
                <ul class="subNav">
                    <li v-for="item in zmzt_submenu" :key="item.title" :class="item.class" :style="{ color: item.class == 'theme-bg' ? '#fff' : '#000' }" @click="change_submenu(item)">{{ item.title }}</li>
                </ul>
            </div>
            <el-row :gutter="2" style="margin-top: 3px; max-height: 820px">
                <el-col :span="16">
                    <el-row :gutter="2">
                        <el-col :span="12">
                            <el-table :data="zmzt_1_25" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="12">
                            <el-table :data="zmzt_26_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width="100">
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="注额" align="center">
                                    <template #default="scope">
                                        <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                        <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="盈亏" align="center">
                                    <template #default="scope">
                                        <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="theme-border-half" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                                <p>
                                    总投注额：
                                    <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                                </p>
                                <p>
                                    最高亏损：
                                    <span style="color: red">{{ max_ks }}</span>
                                </p>
                                <p>
                                    最高盈利：
                                    <span>{{ max_yl }}</span>
                                </p>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_fuzhi_class" @click="set_kuishun_pailie('fu', 'zmzt_px')">按亏损额负值排列</el-col>
                        <el-col :span="12" :class="'theme-border-lhc-jszd-ykpl ' + kuisun_zhengzhi_class" @click="set_kuishun_pailie('zheng', 'zmzt_px')">按亏损额正值排列</el-col>
                    </el-row>
                    <el-table
                        ref="zmzt_px"
                        :data="zmztpl"
                        border
                        size="small"
                        height="800px"
                        highlight-current-row
                        header-cell-class-name="table-header-cell"
                        header-row-class-name="table-header-row"
                        table-layout="auto"
                        :default-sort="kuisun_obj"
                        :row-class-name="tableRowClassName"
                    >
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '正码1-6'">
            <el-row :gutter="2">
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码1</div>
                    <el-table :data="zm1" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码2</div>
                    <el-table :data="zm2" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码3</div>
                    <el-table :data="zm3" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码4</div>
                    <el-table :data="zm4" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码5</div>
                    <el-table :data="zm5" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">正码6</div>
                    <el-table :data="zm6" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <div class="theme-border-half" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                <p>
                    总投注额：
                    <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                </p>
                <p>
                    最高亏损：
                    <span style="color: red">{{ max_ks }}</span>
                </p>
                <p>
                    最高盈利：
                    <span>{{ max_yl }}</span>
                </p>
            </div>
        </template>
        <template v-if="title == '连码'">
            <el-row :gutter="2">
                <el-col :span="6">
                    <el-table :data="lm_submenu" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="checked" label="选择" align="center" width="100">
                            <template #default="scope">
                                <el-radio v-model="lm_submenu_checked" :label="scope.row.key" size="small" @change="change_submenu(scope.row, $event)">{{ scope.row.title }}</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column property="szsz_amount[1]" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="2">
                        <el-col :span="8">
                            <el-table :data="lm_1_16" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="balls == 'lma' || balls == 'lma3'">
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row.otherdata, 'plus', 'other_pl')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row.otherdata, $event, 'other_pl')">{{ scope.row.otherdata.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row.otherdata, 'minus', 'other_pl')"></div>
                                        </div>
                                        <div>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="8">
                            <el-table :data="lm_17_32" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="balls == 'lma' || balls == 'lma3'">
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row.otherdata, 'plus', 'other_pl')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row.otherdata, $event, 'other_pl')">{{ scope.row.otherdata.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row.otherdata, 'minus', 'other_pl')"></div>
                                        </div>
                                        <div>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="8">
                            <el-table :data="lm_33_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="balls == 'lma' || balls == 'lma3'">
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row.otherdata, 'plus', 'other_pl')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row.otherdata, $event, 'other_pl')">{{ scope.row.otherdata.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row.otherdata, 'minus', 'other_pl')"></div>
                                        </div>
                                        <div>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                            <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                            <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center; padding-bottom: 2px">
                        <!-- <el-radio-group v-model="zupai_type" size="small" @change="change_zupai_type">
                            <el-radio-button label="1" >单组</el-radio-button>
                            <el-radio-button label="2" >整组</el-radio-button>
                        </el-radio-group> -->
                        『
                        <span class="redcolor">{{ zupai_title }}</span> 』 按{{ zupai_type == 1 ? "单组" : "整组" }}累計 — 百大排行
                    </div>
                    <el-table :data="zupai.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" height="493px" table-layout="auto">
                        <el-table-column property="let.ball" label="号码" align="center">
                            <template #default="scope">
                                <div v-if="balls == 'tm' || balls == 'tma' || balls == 'tmb' || balls == 'zm' || balls.includes('ztm') || balls.includes('tmzx')" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'sb'" class="lhc-color-num-sb" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'tx' || balls == 'yx' || balls.includes('lma') || balls.includes('bz') || balls.includes('lx') || balls.includes('lm') || balls.includes('wl') || balls.includes('ws')">{{ scope.row.name }}</div>
                                <div v-if="balls == 'zm1_6'">{{ scope.row.let.level_s.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.profit_bill" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.let.profit_bill < 0 ? 'red' : '#000' }">{{ scope.row.let.profit_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.oc_bill" label="注额" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.let.oc_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.list_bonus" label="赔率" align="center">
                            <template #default="scope">
                                <template v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1 && zupai.type == 2">
                                    <div class="plus" @click="show_pl_popver(scope.row, 'plus', 'zupai')"></div>
                                    <span class="item-pl" @click="show_pl_popver(scope.row, $event, 'zupai')">{{ scope.row.let.list_bonus }}</span>
                                    <div class="minus" @click="show_pl_popver(scope.row, 'minus', 'zupai')"></div>
                                </template>
                                <template v-else>
                                    <span style="color: #409eff">{{ scope.row.let.list_bonus }}</span>
                                </template>
                            </template>
                        </el-table-column>

                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rate" label="赔率" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rate" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rebate_level" label="返点" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rebate_level" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column property="let.control_let" label="金额" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.control_let" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet!='-1'" property="control_let" label="已补" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.let.control_let_s*1==0||scope.row.let.control_let_s==undefined"></span>
                                <el-link v-else type="primary" @click="get_buhuo_detail(scope.row)">{{scope.row.let.control_let_s*1}}</el-link>
                            </template>
                        </el-table-column>-->
                    </el-table>
                    <el-pagination small :current-page="zupai.page" @current-change="$emit('change_zupai_page', $event)" layout="prev, pager, next,total" :page-size="20" :pager-count="5" :total="zupai.pagination.total_records" hide-on-single-page />
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '一肖'">
            <div class="theme-border" style="border-bottom: 2px solid; height: 22px; margin-top: 3px">
                <ul class="subNav">
                    <li v-for="item in yx_tabs" :key="item.title" :class="item.class" :style="{ color: item.class == 'theme-bg' ? '#fff' : '#000' }" @click="change_submenu(item)">{{ item.title }}</li>
                </ul>
            </div>
            <el-row :gutter="2" style="margin-top: 3px; max-height: 820px">
                <el-col :span="16">
                    <el-table
                        :data="yx"
                        border
                        size="small"
                        highlight-current-row
                        header-cell-class-name="table-header-cell"
                        header-row-class-name="table-header-row"
                        table-layout="auto"
                        :row-class-name="tableRowClassName"
                        :default-sort="{ prop: 'szsz_amount[4]', order: 'ascending' }"
                    >
                        <el-table-column property="name" label="生肖" align="center" width="60">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="280">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num.num" :style="{ backgroundColor: num.color }">{{ num.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="150">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="szsz_amount[4]" label="盈亏" align="center" sortable>
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-border-half" style="padding: 3px 7px; border: 1px solid">
                        <p>
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </p>
                        <p>
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </p>
                        <p>
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </p>
                    </div>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '自选不中'">
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="6">
                    <el-table :data="zxbz_submenu" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="checked" label="选择" align="center" width="100">
                            <template #default="scope">
                                <el-radio v-model="zxbz_submenu_checked" :label="scope.row.key" size="small" @change="change_submenu(scope.row, $event)">{{ scope.row.title }}</el-radio>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="pl" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row,'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row,$event)">{{scope.row.pl}}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row,'minus')"></div>
                            </template>
                        </el-table-column>-->
                        <el-table-column property="szsz_amount[1]" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;" @click="show_bh(scope.row,$event)" :style="{color:scope.row.szsz_amount[4]<0?'red':'#000'}">{{scope.row.szsz_amount[4]}}</span>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="2">
                        <el-col :span="8">
                            <el-table :data="zxbz_1_16" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="8">
                            <el-table :data="zxbz_17_32" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="8">
                            <el-table :data="zxbz_33_49" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                                <el-table-column property="name" label="号" align="center" width="33">
                                    <template #default="scope">
                                        <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column property="title" label="赔率" align="center" width>
                                    <template #default="scope">
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                        <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                        <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">
                        『
                        <span class="redcolor">{{ zupai_title }}</span> 』 按單組累計 — 百大排行
                    </div>
                    <el-table :data="zupai.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="let.ball" label="号码" align="center">
                            <template #default="scope">
                                <div v-if="balls == 'tm' || balls == 'tma' || balls == 'tmb' || balls == 'zm' || balls.includes('ztm') || balls.includes('tmzx')" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'sb'" class="lhc-color-num-sb" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'tx' || balls == 'yx' || balls.includes('lma') || balls.includes('bz') || balls.includes('lx') || balls.includes('lm') || balls.includes('wl') || balls.includes('ws')">{{ scope.row.name }}</div>
                                <div v-if="balls == 'zm1_6'">{{ scope.row.let.level_s.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.profit_bill" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.let.profit_bill < 0 ? 'red' : '#000' }">{{ scope.row.let.profit_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.oc_bill" label="注额" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.let.oc_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.list_bonus" label="赔率" align="center">
                            <template #default="scope">
                                <template v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1 && zupai.type == 2">
                                    <div class="plus" @click="show_pl_popver(scope.row, 'plus', 'zupai')"></div>
                                    <span class="item-pl" @click="show_pl_popver(scope.row, $event, 'zupai')">{{ scope.row.let.list_bonus }}</span>
                                    <div class="minus" @click="show_pl_popver(scope.row, 'minus', 'zupai')"></div>
                                </template>
                                <template v-else>
                                    <span style="color: #409eff">{{ scope.row.let.list_bonus }}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rate" label="赔率" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rate" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rebate_level" label="返点" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rebate_level" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column property="let.control_let" label="金额" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.control_let" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet!='-1'" property="control_let" label="已补" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.let.control_let_s*1==0||scope.row.let.control_let_s==undefined"></span>
                                <el-link v-else type="primary" @click="get_buhuo_detail(scope.row)">{{scope.row.let.control_let_s*1}}</el-link>
                            </template>
                        </el-table-column>-->
                    </el-table>
                    <el-pagination
                        small
                        :current-page="zupai.page"
                        @current-change="$emit('change_zupai_page', $event)"
                        layout="prev, pager, next,total"
                        :page-size="20"
                        :pager-count="5"
                        :total="zupai.pagination.total_records * 1"
                        hide-on-single-page
                    />
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '尾连'">
            <div class="theme-border" style="border-bottom: 2px solid; height: 22px; margin-top: 3px">
                <ul class="subNav">
                    <li v-for="item in wl_tabs" :key="item.title" :class="item.class" :style="{ color: item.class == 'theme-bg' ? '#fff' : '#000' }" @click="change_tabs(item)">{{ item.title }}</li>
                </ul>
            </div>
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="6">
                    <el-table :data="wl_submenu" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="checked" label="选择" align="center" width="100">
                            <template #default="scope">
                                <el-radio v-model="wl_submenu_checked" :label="scope.row.key" size="small" @change="change_submenu(scope.row, $event)">{{ scope.row.title }}</el-radio>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="pl" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row,'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row,$event)">{{scope.row.pl}}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row,'minus')"></div>
                            </template>
                        </el-table-column>-->
                        <el-table-column property="szsz_amount[1]" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;" @click="show_bh(scope.row,$event)" :style="{color:scope.row.szsz_amount[4]<0?'red':'#000'}">{{scope.row.szsz_amount[4]}}</span>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-table :data="wl" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="45">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="6">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center; padding-bottom: 2px">
                        <!-- <el-radio-group v-model="zupai_type" size="small" @change="change_zupai_type">
                            <el-radio-button label="1" >单组</el-radio-button>
                            <el-radio-button label="2" >整组</el-radio-button>
                        </el-radio-group> -->
                        『
                        <span class="redcolor">{{ zupai_title }}</span> 』 按{{ zupai_type == 1 ? "单组" : "整组" }}累計 — 百大排行
                    </div>
                    <el-table :data="zupai.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="let.ball" label="号码" align="center">
                            <template #default="scope">
                                <div v-if="balls == 'tm' || balls == 'tma' || balls == 'tmb' || balls == 'zm' || balls.includes('ztm') || balls.includes('tmzx')" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'sb'" class="lhc-color-num-sb" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'tx' || balls == 'yx' || balls.includes('lma') || balls.includes('bz') || balls.includes('lx') || balls.includes('lm') || balls.includes('wl') || balls.includes('ws')">{{ scope.row.name }}</div>
                                <div v-if="balls == 'zm1_6'">{{ scope.row.let.level_s.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.profit_bill" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.let.profit_bill < 0 ? 'red' : '#000' }">{{ scope.row.let.profit_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.oc_bill" label="注额" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.let.oc_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.list_bonus" label="赔率" align="center">
                            <template #default="scope">
                                <template v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1 && zupai.type == 2">
                                    <div class="plus" @click="show_pl_popver(scope.row, 'plus', 'zupai')"></div>
                                    <span class="item-pl" @click="show_pl_popver(scope.row, $event, 'zupai')">{{ scope.row.let.list_bonus }}</span>
                                    <div class="minus" @click="show_pl_popver(scope.row, 'minus', 'zupai')"></div>
                                </template>
                                <template v-else>
                                    <span style="color: #409eff">{{ scope.row.let.list_bonus }}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rate" label="赔率" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rate" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rebate_level" label="返点" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rebate_level" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column property="let.control_let" label="金额" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.control_let" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet!='-1'" property="control_let" label="已补" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.let.control_let_s*1==0||scope.row.let.control_let_s==undefined"></span>
                                <el-link v-else type="primary" @click="get_buhuo_detail(scope.row)">{{scope.row.let.control_let_s*1}}</el-link>
                            </template>
                        </el-table-column>-->
                    </el-table>
                    <el-pagination
                        small
                        :current-page="zupai.page"
                        @current-change="$emit('change_zupai_page', $event)"
                        layout="prev, pager, next,total"
                        :page-size="20"
                        :pager-count="5"
                        :total="zupai.pagination.total_records * 1"
                        hide-on-single-page
                    />
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '连肖'">
            <div class="theme-border" style="border-bottom: 2px solid; height: 22px; margin-top: 3px">
                <ul class="subNav">
                    <li v-for="item in lx_tabs" :key="item.title" :class="item.class" :style="{ color: item.class == 'theme-bg' ? '#fff' : '#000' }" @click="change_tabs(item)">{{ item.title }}</li>
                </ul>
            </div>
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="6">
                    <el-table :data="lx_submenu" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="checked" label="选择" align="center" width="100">
                            <template #default="scope">
                                <el-radio v-model="lx_submenu_checked" :label="scope.row.key" size="small" @change="change_submenu(scope.row, $event)">{{ scope.row.title }}</el-radio>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="pl" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row,'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row,$event)">{{scope.row.pl}}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row,'minus')"></div>
                            </template>
                        </el-table-column>-->
                        <el-table-column property="szsz_amount[1]" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;" @click="show_bh(scope.row,$event)" :style="{color:scope.row.szsz_amount[4]<0?'red':'#000'}">{{scope.row.szsz_amount[4]}}</span>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-table :data="lx" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="生肖" align="center" width="60">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="280">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num.num" :style="{ backgroundColor: num.color }">{{ num.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="150">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="6">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center; padding: 2px">
                        <!-- <el-radio-group v-model="zupai_type" size="small" @change="change_zupai_type">
                            <el-radio-button label="1" >单组</el-radio-button>
                            <el-radio-button label="2" >整组</el-radio-button>
                        </el-radio-group> -->
                        『
                        <span class="redcolor">{{ zupai_title }}</span> 』 按{{ zupai_type == 1 ? "单组" : "整组" }}累計 — 百大排行
                    </div>
                    <el-table :data="zupai.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="let.ball" label="号码" align="center">
                            <template #default="scope">
                                <div v-if="balls == 'tm' || balls == 'tma' || balls == 'tmb' || balls == 'zm' || balls.includes('ztm') || balls.includes('tmzx')" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'sb'" class="lhc-color-num-sb" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'tx' || balls == 'yx' || balls.includes('lma') || balls.includes('bz') || balls.includes('lx') || balls.includes('lm') || balls.includes('wl') || balls.includes('ws')">{{ scope.row.name }}</div>
                                <div v-if="balls == 'zm1_6'">{{ scope.row.let.level_s.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.profit_bill" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.let.profit_bill < 0 ? 'red' : '#000' }">{{ scope.row.let.profit_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.oc_bill" label="注额" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.let.oc_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.list_bonus" label="赔率" align="center">
                            <template #default="scope">
                                <template v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1 && zupai.type == 2">
                                    <div class="plus" @click="show_pl_popver(scope.row, 'plus', 'zupai')"></div>
                                    <span class="item-pl" @click="show_pl_popver(scope.row, $event, 'zupai')">{{ scope.row.let.list_bonus }}</span>
                                    <div class="minus" @click="show_pl_popver(scope.row, 'minus', 'zupai')"></div>
                                </template>
                                <template v-else>
                                    <span style="color: #409eff">{{ scope.row.let.list_bonus }}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rate" label="赔率" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rate" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rebate_level" label="返点" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rebate_level" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column property="let.control_let" label="金额" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.control_let" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet!='-1'" property="control_let" label="已补" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.let.control_let_s*1==0||scope.row.let.control_let_s==undefined"></span>
                                <el-link v-else type="primary" @click="get_buhuo_detail(scope.row)">{{scope.row.let.control_let_s*1}}</el-link>
                            </template>
                        </el-table-column>-->
                    </el-table>
                    <el-pagination
                        small
                        :current-page="zupai.page"
                        @current-change="$emit('change_zupai_page', $event)"
                        layout="prev, pager, next,total"
                        :page-size="20"
                        :pager-count="5"
                        :total="zupai.pagination.total_records * 1"
                        hide-on-single-page
                    />
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '六肖一中'">
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="6">
                    <el-table :data="lxyz_submenu" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="checked" label="选择" align="center" width="100">
                            <template #default="scope">
                                <el-radio v-model="lxyz_submenu_checked" :label="scope.row.key" size="small" @change="change_submenu(scope.row, $event)">{{ scope.row.title }}</el-radio>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="pl" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row,'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row,$event)">{{scope.row.pl}}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row,'minus')"></div>
                            </template>
                        </el-table-column>-->
                        <el-table-column property="szsz_amount[1]" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="szsz_amount[4]" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;" @click="show_bh(scope.row,$event)" :style="{color:scope.row.szsz_amount[4]<0?'red':'#000'}">{{scope.row.szsz_amount[4]}}</span>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-col>
                <el-col :span="12">
                    <el-table :data="lxyz" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="生肖" align="center" width="60">
                            <template #default="scope">
                                <span :style="{ color: scope.row.color, fontWeight: 'bold' }">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="nums" label="号" align="center" width="280">
                            <template #default="scope">
                                <span class="nums" v-for="num in scope.row.nums" :key="num.num" :style="{ backgroundColor: num.color }">{{ num.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="150">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer;" @click="show_bh(scope.row,$event)" :style="{color:scope.row.szsz_amount[4]<0?'red':'#000'}">{{scope.row.szsz_amount[4]}}</span>
                            </template>
                        </el-table-column>-->
                    </el-table>
                </el-col>
                <el-col :span="6">
                    <div class="theme-half-bg" style="font-weight: bold; color: #333; line-height: 29px; text-align: center">
                        『
                        <span class="redcolor">{{ zupai_title }}</span> 』 按單組累計 — 百大排行
                    </div>
                    <el-table :data="zupai.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column property="let.ball" label="号码" align="center">
                            <template #default="scope">
                                <div v-if="balls == 'tm' || balls == 'tma' || balls == 'tmb' || balls == 'zm' || balls.includes('ztm') || balls.includes('tmzx')" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'sb'" class="lhc-color-num-sb" :style="{ color: scope.row.info.color }">{{ scope.row.name }}</div>
                                <div v-if="balls == 'tx' || balls == 'yx' || balls.includes('lma') || balls.includes('bz') || balls.includes('lx') || balls.includes('lm') || balls.includes('wl') || balls.includes('ws')">{{ scope.row.name }}</div>
                                <div v-if="balls == 'zm1_6'">{{ scope.row.let.level_s.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.profit_bill" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.let.profit_bill < 0 ? 'red' : '#000' }">{{ scope.row.let.profit_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.oc_bill" label="注额" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.let.oc_bill }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="let.list_bonus" label="赔率" align="center">
                            <template #default="scope">
                                <template v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1 && zupai.type == 2">
                                    <div class="plus" @click="show_pl_popver(scope.row, 'plus', 'zupai')"></div>
                                    <span class="item-pl" @click="show_pl_popver(scope.row, $event, 'zupai')">{{ scope.row.let.list_bonus }}</span>
                                    <div class="minus" @click="show_pl_popver(scope.row, 'minus', 'zupai')"></div>
                                </template>
                                <template v-else>
                                    <span style="color: #409eff">{{ scope.row.let.list_bonus }}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rate" label="赔率" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rate" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet=='0'" property="let.rebate_level" label="返点" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.rebate_level" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column property="let.control_let" label="金额" align="center">
                            <template #default="scope">
                                <el-input size="small" v-model="scope.row.let.control_let" :disabled="scope.row.let.oc_bill<=0"></el-input>
                            </template>
                        </el-table-column>-->
                        <!-- <el-table-column v-if="is_manlet!='-1'" property="control_let" label="已补" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.let.control_let_s*1==0||scope.row.let.control_let_s==undefined"></span>
                                <el-link v-else type="primary" @click="get_buhuo_detail(scope.row)">{{scope.row.let.control_let_s*1}}</el-link>
                            </template>
                        </el-table-column>-->
                    </el-table>
                    <el-pagination
                        small
                        :current-page="zupai.page"
                        @current-change="$emit('change_zupai_page', $event)"
                        layout="prev, pager, next,total"
                        :page-size="20"
                        :pager-count="5"
                        :total="zupai.pagination.total_records * 1"
                        hide-on-single-page
                    />
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '两面'">
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="6">
                    <el-table :data="lianmian_w" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="45">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="6">
                    <el-table :data="lianmian_t" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="theme-border-half" style="padding: 3px 7px; margin-top: 3px; border: 1px solid">
                        <div style="line-height: 20px">
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </div>
                        <div style="line-height: 20px">
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </div>
                        <div style="line-height: 20px">
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" v-show="lianmian_h.length > 0">
                    <el-table :data="lianmian_h" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="6" v-show="lianmian_z.length > 0">
                    <el-table :data="lianmian_z" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="33">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '尾数'">
            <div class="theme-border" style="border-bottom: 2px solid; height: 22px; margin-top: 3px">
                <ul class="subNav">
                    <li v-for="item in ws_tabs" :key="item.title" :class="item.class" :style="{ color: item.class == 'theme-bg' ? '#fff' : '#000' }" @click="change_submenu(item)">{{ item.title }}</li>
                </ul>
            </div>
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="16">
                    <el-table
                        :data="ws"
                        border
                        size="small"
                        highlight-current-row
                        header-cell-class-name="table-header-cell"
                        header-row-class-name="table-header-row"
                        table-layout="auto"
                        :row-class-name="tableRowClassName"
                        :default-sort="{ prop: 'szsz_amount[4]', order: 'ascending' }"
                    >
                        <el-table-column property="name" label="号" align="center" width="45">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="szsz_amount[4]" label="盈亏" align="center" sortable>
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-border-half" style="padding: 3px 7px; border: 1px solid">
                        <p>
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </p>
                        <p>
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </p>
                        <p>
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </p>
                    </div>
                </el-col>
            </el-row>
        </template>
        <template v-if="title == '生肖/尾数量'">
            <el-row :gutter="2" style="margin-top: 3px">
                <el-col :span="8">
                    <el-table :data="sxl" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="45">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <el-table :data="wsl" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto" :row-class-name="tableRowClassName">
                        <el-table-column property="name" label="号" align="center" width="45">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="赔率" align="center" width="100">
                            <template #default="scope">
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="plus" @click="show_pl_popver(scope.row, 'plus')"></div>
                                <span class="item-pl" @click="show_pl_popver(scope.row, $event)">{{ scope.row.pl }}</span>
                                <div v-if="$store.state.userinfo.group_id < 4 && $store.state.userinfo.trader_status == 1" class="minus" @click="show_pl_popver(scope.row, 'minus')"></div>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="注额" align="center">
                            <template #default="scope">
                                <span v-show="scope.row.szsz_amount[1] !== ''" style="cursor: pointer" @click="get_zdmx_data(scope.row)">{{ scope.row.szsz_amount[1] }}</span>
                                <span v-show="scope.row.szsz_amount[1] === ''">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="title" label="盈亏" align="center">
                            <template #default="scope">
                                <span style="cursor: pointer" @click="show_bh(scope.row, $event)" :style="{ color: scope.row.szsz_amount[4] < 0 ? 'red' : '#000' }">{{ scope.row.szsz_amount[4] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="8">
                    <div class="theme-border-half" style="padding: 3px 7px; border: 1px solid">
                        <p>
                            总投注额：
                            <span style="color: #67c23a">{{ szsz == 0 ? sum.all_balance : sum.own_balance }}</span>
                        </p>
                        <p>
                            最高亏损：
                            <span style="color: red">{{ max_ks }}</span>
                        </p>
                        <p>
                            最高盈利：
                            <span>{{ max_yl }}</span>
                        </p>
                    </div>
                </el-col>
            </el-row>
        </template>
        <div v-show="pl_popver.show" ref="pl_popver" class="pl_popver">
            <div class="pl_popver_title">修改赔率:{{ pl_popver.row.name }}</div>
            <div>
                <el-divider>{{ pl_popver.value }}</el-divider>
            </div>
            <div style="text-align: center">
                赔率：
                <el-input style="width: 80px" v-model="pl_popver.pl_change_value" size="small" min="0"></el-input>
            </div>
            <div style="margin: 10px; text-align: center">
                <el-button size="small" @click="pl_popver.show = false">&nbsp;&nbsp;取消&nbsp;&nbsp;</el-button>
                <el-button type="primary" size="small" @click="sub_change_pl">&nbsp;&nbsp;确认&nbsp;&nbsp;</el-button>
            </div>
        </div>
        <div v-show="bh.show" ref="bh_popver" class="pl_popver">
            <div class="pl_popver_title">补货单:</div>
            <div>
                <el-divider>{{ bh.row.name || bh.row.title }}</el-divider>
            </div>
            <div style="text-align: center">
                赔率：
                <el-input style="width: 80px" :disabled="is_manlet != '0'" v-model="bh.pl_value" size="small" clearable></el-input>
            </div>
            <div style="text-align: center">
                金额：
                <el-input ref="bhje_input" style="width: 80px" v-model="bh.je_value" size="small" clearable></el-input>
            </div>
            <div style="margin: 10px; text-align: center">
                <el-button size="small" @click="bh.show = false">&nbsp;&nbsp;取消&nbsp;&nbsp;</el-button>
                <el-button type="primary" size="small" @click="sub_bh">&nbsp;&nbsp;补出&nbsp;&nbsp;</el-button>
            </div>
        </div>
        <!-- <div>{{game_info_data.upopennumber}}</div>
        <div>{{title}}</div>
        <div>{{balls}}</div>-->
    </div>
</template>

<script>
export default {
    components: {},
    props: ["game_info_data", "title", "chang_long", "sum", "balls", "zupai", "is_manlet", "pl_value", "pk", "szsz"],
    data() {
        return {
            pl_popver: {
                show: false,
                row: {},
                pl_value: "",
                pl_change_value: "",
                top: "",
                left: "",
                type: "1",
            },
            bh: {
                show: false,
                row: {},
                pl_value: "",
                je_value: "",
                rate: "",
                top: "",
                left: "",
            },
            sum_bet_balance: "", //总投注额
            max_ks: "", //最高亏损
            max_yl: "", //最高盈利
            kuisun_fuzhi_class: "theme-bg white-text",
            kuisun_zhengzhi_class: "theme-half-bg black-text",
            kuisun_obj: { prop: "szsz_amount[4]", order: "ascending" },
            zupai_title: "",
            is_int_submenu: true,
            zupai_type: "1",
            zupai_page: 1,
            // 特码A+B
            tmAB_1_25: [
                { id: 1, name: "1", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2, name: "2", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 3, name: "3", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 4, name: "4", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 5, name: "5", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 6, name: "6", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 7, name: "7", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 8, name: "8", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 9, name: "9", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 10, name: "10", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 11, name: "11", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 12, name: "12", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 13, name: "13", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 14, name: "14", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 15, name: "15", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 16, name: "16", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 17, name: "17", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 18, name: "18", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 19, name: "19", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 20, name: "20", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 21, name: "21", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 22, name: "22", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 23, name: "23", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 24, name: "24", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 25, name: "25", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmAB_26_49: [
                { id: 26, name: "26", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 27, name: "27", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 28, name: "28", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 29, name: "29", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 30, name: "30", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 31, name: "31", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 32, name: "32", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 33, name: "33", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 34, name: "34", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 35, name: "35", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 36, name: "36", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 37, name: "37", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 38, name: "38", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 39, name: "39", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 40, name: "40", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 41, name: "41", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 42, name: "42", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 43, name: "43", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 44, name: "44", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 45, name: "45", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 46, name: "46", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 47, name: "47", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 48, name: "48", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 49, name: "49", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmAB_lm: [
                { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            tmAB_sb: [
                { id: 74, name: "红波", color: "#f56c6c", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 75, name: "蓝波", color: "#409eff", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 76, name: "绿波", color: "#67C23A", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //特码A
            tmA_1_25: [
                { id: 1, name: "1", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2, name: "2", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 3, name: "3", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 4, name: "4", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 5, name: "5", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 6, name: "6", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 7, name: "7", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 8, name: "8", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 9, name: "9", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 10, name: "10", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 11, name: "11", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 12, name: "12", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 13, name: "13", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 14, name: "14", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 15, name: "15", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 16, name: "16", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 17, name: "17", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 18, name: "18", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 19, name: "19", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 20, name: "20", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 21, name: "21", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 22, name: "22", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 23, name: "23", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 24, name: "24", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 25, name: "25", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmA_26_49: [
                { id: 26, name: "26", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 27, name: "27", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 28, name: "28", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 29, name: "29", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 30, name: "30", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 31, name: "31", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 32, name: "32", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 33, name: "33", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 34, name: "34", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 35, name: "35", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 36, name: "36", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 37, name: "37", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 38, name: "38", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 39, name: "39", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 40, name: "40", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 41, name: "41", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 42, name: "42", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 43, name: "43", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 44, name: "44", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 45, name: "45", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 46, name: "46", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 47, name: "47", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 48, name: "48", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 49, name: "49", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmA_lm: [
                { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            tmA_sb: [
                { id: 74, name: "红波", color: "#f56c6c", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 75, name: "蓝波", color: "#409eff", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 76, name: "绿波", color: "#67C23A", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //特码B
            tmB_1_25: [
                { id: 1700, name: "1", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1701, name: "2", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1702, name: "3", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1703, name: "4", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1704, name: "5", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1705, name: "6", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1706, name: "7", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1707, name: "8", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1708, name: "9", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1709, name: "10", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1710, name: "11", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1711, name: "12", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1712, name: "13", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1713, name: "14", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1714, name: "15", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1715, name: "16", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1716, name: "17", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1717, name: "18", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1718, name: "19", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1719, name: "20", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1720, name: "21", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1721, name: "22", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1722, name: "23", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1723, name: "24", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1724, name: "25", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmB_26_49: [
                { id: 1725, name: "26", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1726, name: "27", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1727, name: "28", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1728, name: "29", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1729, name: "30", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1730, name: "31", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1731, name: "32", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1732, name: "33", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1733, name: "34", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1734, name: "35", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1735, name: "36", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1736, name: "37", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1737, name: "38", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1738, name: "39", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1739, name: "40", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1740, name: "41", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1741, name: "42", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1742, name: "43", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1743, name: "44", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1744, name: "45", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1745, name: "46", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1746, name: "47", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1747, name: "48", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1748, name: "49", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmB_lm: [
                { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            tmB_sb: [
                { id: 74, name: "红波", color: "#f56c6c", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 75, name: "蓝波", color: "#409eff", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 76, name: "绿波", color: "#67C23A", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            tmpl: [
                { id: 1, name: "1", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2, name: "2", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 3, name: "3", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 4, name: "4", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 5, name: "5", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 6, name: "6", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 7, name: "7", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 8, name: "8", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 9, name: "9", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 10, name: "10", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 11, name: "11", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 12, name: "12", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 13, name: "13", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 14, name: "14", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 15, name: "15", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 16, name: "16", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 17, name: "17", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 18, name: "18", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 19, name: "19", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 20, name: "20", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 21, name: "21", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 22, name: "22", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 23, name: "23", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 24, name: "24", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 25, name: "25", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 26, name: "26", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 27, name: "27", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 28, name: "28", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 29, name: "29", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 30, name: "30", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 31, name: "31", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 32, name: "32", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 33, name: "33", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 34, name: "34", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 35, name: "35", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 36, name: "36", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 37, name: "37", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 38, name: "38", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 39, name: "39", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 40, name: "40", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 41, name: "41", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 42, name: "42", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 43, name: "43", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 44, name: "44", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 45, name: "45", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 46, name: "46", game_type: "特码A+B", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 47, name: "47", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 48, name: "48", game_type: "特码A+B", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 49, name: "49", game_type: "特码A+B", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //色波
            sb: [
                { id: 74, name: "红波", game_type: "色波", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 75, name: "绿波", game_type: "色波", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 76, name: "蓝波", game_type: "色波", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            sb_ds: [
                {
                    id: 64,
                    name: "红单",
                    game_type: "色波",
                    nums: [1, 7, 13, 19, 23, 29, 35, 45],
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 65,
                    name: "红双",
                    game_type: "色波",
                    nums: [2, 8, 12, 18, 24, 30, 34, 40, 46],
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 68,
                    name: "蓝单",
                    game_type: "色波",
                    nums: [3, 9, 15, 25, 31, 37, 41, 47],
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 69,
                    name: "蓝双",
                    game_type: "色波",
                    nums: [4, 10, 14, 20, 26, 36, 42, 48],
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 72,
                    name: "绿单",
                    game_type: "色波",
                    nums: [5, 11, 17, 21, 27, 33, 39, 43, 49],
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 73,
                    name: "绿双",
                    game_type: "色波",
                    nums: [6, 16, 22, 28, 32, 38, 44],
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            sb_dx: [
                {
                    id: 62,
                    name: "红大",
                    game_type: "色波",
                    nums: [29, 30, 34, 35, 40, 45, 46],
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 63,
                    name: "红小",
                    game_type: "色波",
                    nums: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 66,
                    name: "蓝大",
                    game_type: "色波",
                    nums: [25, 26, 31, 36, 37, 41, 42, 47, 48],
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 67,
                    name: "蓝小",
                    game_type: "色波",
                    nums: [3, 4, 9, 10, 14, 15, 20],
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 70,
                    name: "绿大",
                    game_type: "色波",
                    nums: [27, 28, 32, 33, 38, 39, 43, 44, 49],
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 71,
                    name: "绿小",
                    game_type: "色波",
                    nums: [5, 6, 11, 16, 17, 21, 22],
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            tx: [
                {
                    id: 57,
                    name: "鼠",
                    game_type: "特肖",
                    nums: [
                        { num: 5, color: "#67C23A" },
                        { num: 17, color: "#67C23A" },
                        { num: 29, color: "#f56c6c" },
                        { num: 41, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 58,
                    name: "牛",
                    game_type: "特肖",
                    nums: [
                        { num: 4, color: "#409eff" },
                        { num: 16, color: "#67C23A" },
                        { num: 28, color: "#67C23A" },
                        { num: 40, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 59,
                    name: "虎",
                    game_type: "特肖",
                    nums: [
                        { num: 3, color: "#409eff" },
                        { num: 15, color: "#409eff" },
                        { num: 27, color: "#67C23A" },
                        { num: 39, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 60,
                    name: "兔",
                    game_type: "特肖",
                    nums: [
                        { num: 2, color: "#f56c6c" },
                        { num: 14, color: "#409eff" },
                        { num: 26, color: "#409eff" },
                        { num: 38, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 61,
                    name: "龙",
                    game_type: "特肖",
                    nums: [
                        { num: 1, color: "#f56c6c" },
                        { num: 13, color: "#f56c6c" },
                        { num: 25, color: "#409eff" },
                        { num: 37, color: "#409eff" },
                        { num: 49, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 50,
                    name: "蛇",
                    game_type: "特肖",
                    nums: [
                        { num: 12, color: "#f56c6c" },
                        { num: 24, color: "#f56c6c" },
                        { num: 36, color: "#409eff" },
                        { num: 48, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 51,
                    name: "马",
                    game_type: "特肖",
                    nums: [
                        { num: 11, color: "#67C23A" },
                        { num: 23, color: "#f56c6c" },
                        { num: 35, color: "#f56c6c" },
                        { num: 47, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 52,
                    name: "羊",
                    game_type: "特肖",
                    nums: [
                        { num: 10, color: "#409eff" },
                        { num: 22, color: "#67C23A" },
                        { num: 34, color: "#f56c6c" },
                        { num: 46, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 53,
                    name: "猴",
                    game_type: "特肖",
                    nums: [
                        { num: 9, color: "#409eff" },
                        { num: 21, color: "#67C23A" },
                        { num: 33, color: "#67C23A" },
                        { num: 45, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 54,
                    name: "鸡",
                    game_type: "特肖",
                    nums: [
                        { num: 8, color: "#f56c6c" },
                        { num: 20, color: "#409eff" },
                        { num: 32, color: "#67C23A" },
                        { num: 44, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 55,
                    name: "狗",
                    game_type: "特肖",
                    nums: [
                        { num: 7, color: "#f56c6c" },
                        { num: 19, color: "#f56c6c" },
                        { num: 31, color: "#409eff" },
                        { num: 43, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 56,
                    name: "猪",
                    game_type: "特肖",
                    nums: [
                        { num: 6, color: "#67C23A" },
                        { num: 18, color: "#f56c6c" },
                        { num: 30, color: "#f56c6c" },
                        { num: 42, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            //正码/正特
            zmzt_1_25: [
                { id: 201, name: "1", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 202, name: "2", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 203, name: "3", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 204, name: "4", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 205, name: "5", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 206, name: "6", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 207, name: "7", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 208, name: "8", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 209, name: "9", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 210, name: "10", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 211, name: "11", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 212, name: "12", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 213, name: "13", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 214, name: "14", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 215, name: "15", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 216, name: "16", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 217, name: "17", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 218, name: "18", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 219, name: "19", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 220, name: "20", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 221, name: "21", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 222, name: "22", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 223, name: "23", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 224, name: "24", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 225, name: "25", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zmzt_26_49: [
                { id: 226, name: "26", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 227, name: "27", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 228, name: "28", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 229, name: "29", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 230, name: "30", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 231, name: "31", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 232, name: "32", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 233, name: "33", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 234, name: "34", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 235, name: "35", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 236, name: "36", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 237, name: "37", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 238, name: "38", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 239, name: "39", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 240, name: "40", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 241, name: "41", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 242, name: "42", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 243, name: "43", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 244, name: "44", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 245, name: "45", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 246, name: "46", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 247, name: "47", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 248, name: "48", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 249, name: "49", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zmztpl: [
                { id: 201, name: "1", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 202, name: "2", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 203, name: "3", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 204, name: "4", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 205, name: "5", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 206, name: "6", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 207, name: "7", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 208, name: "8", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 209, name: "9", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 210, name: "10", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 211, name: "11", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 212, name: "12", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 213, name: "13", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 214, name: "14", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 215, name: "15", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 216, name: "16", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 217, name: "17", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 218, name: "18", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 219, name: "19", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 220, name: "20", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 221, name: "21", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 222, name: "22", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 223, name: "23", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 224, name: "24", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 225, name: "25", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 226, name: "26", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 227, name: "27", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 228, name: "28", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 229, name: "29", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 230, name: "30", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 231, name: "31", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 232, name: "32", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 233, name: "33", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 234, name: "34", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 235, name: "35", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 236, name: "36", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 237, name: "37", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 238, name: "38", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 239, name: "39", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 240, name: "40", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 241, name: "41", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 242, name: "42", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 243, name: "43", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 244, name: "44", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 245, name: "45", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 246, name: "46", game_type: "正码/正特", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 247, name: "47", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 248, name: "48", game_type: "正码/正特", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 249, name: "49", game_type: "正码/正特", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zmzt_submenu: [
                {
                    title: "正码",
                    link: "trader/index?ball=zm",
                    class: "theme-bg",
                },
                {
                    title: "正特一",
                    link: "trader/index?ball=ztm1",
                    class: "theme-half-bg",
                },
                {
                    title: "正特一B",
                    link: "trader/index?ball=ztm1b",
                    class: "theme-half-bg",
                },
                {
                    title: "正特二",
                    link: "trader/index?ball=ztm2",
                    class: "theme-half-bg",
                },
                {
                    title: "正特二B",
                    link: "trader/index?ball=ztm2b",
                    class: "theme-half-bg",
                },
                {
                    title: "正特三",
                    link: "trader/index?ball=ztm3",
                    class: "theme-half-bg",
                },
                {
                    title: "正特三B",
                    link: "trader/index?ball=ztm3b",
                    class: "theme-half-bg",
                },
                {
                    title: "正特四",
                    link: "trader/index?ball=ztm4",
                    class: "theme-half-bg",
                },
                {
                    title: "正特四B",
                    link: "trader/index?ball=ztm4b",
                    class: "theme-half-bg",
                },
                {
                    title: "正特五",
                    link: "trader/index?ball=ztm5",
                    class: "theme-half-bg",
                },
                {
                    title: "正特五B",
                    link: "trader/index?ball=ztm5b",
                    class: "theme-half-bg",
                },
                {
                    title: "正特六",
                    link: "trader/index?ball=ztm6",
                    class: "theme-half-bg",
                },
                {
                    title: "正特六B",
                    link: "trader/index?ball=ztm6b",
                    class: "theme-half-bg",
                },
            ],
            //正码1-6
            zm1: [
                { id: 1840, name: "大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1841, name: "小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1842, name: "单", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1843, name: "双", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1844, name: "合大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1845, name: "合小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1846, name: "合单", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1847, name: "合双", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1848, name: "尾大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1849, name: "尾小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1850, name: "红波", game_type: "正码1", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1851, name: "绿波", game_type: "正码1", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1852, name: "蓝波", game_type: "正码1", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zm2: [
                { id: 1853, name: "大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1854, name: "小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1855, name: "单", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1856, name: "双", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1857, name: "合大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1858, name: "合小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1859, name: "合单", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1860, name: "合双", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1861, name: "尾大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1862, name: "尾小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1863, name: "红波", game_type: "正码2", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1864, name: "绿波", game_type: "正码2", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1865, name: "蓝波", game_type: "正码2", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zm3: [
                { id: 1866, name: "大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1867, name: "小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1868, name: "单", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1869, name: "双", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1870, name: "合大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1871, name: "合小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1872, name: "合单", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1873, name: "合双", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1874, name: "尾大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1875, name: "尾小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1876, name: "红波", game_type: "正码3", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1877, name: "绿波", game_type: "正码3", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1879, name: "蓝波", game_type: "正码3", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zm4: [
                { id: 1880, name: "大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1881, name: "小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1882, name: "单", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1883, name: "双", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1884, name: "合大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1885, name: "合小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1886, name: "合单", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1887, name: "合双", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1888, name: "尾大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1889, name: "尾小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1890, name: "红波", game_type: "正码4", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1891, name: "绿波", game_type: "正码4", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1892, name: "蓝波", game_type: "正码4", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zm5: [
                { id: 1893, name: "大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1894, name: "小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1895, name: "单", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1896, name: "双", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1897, name: "合大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1898, name: "合小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1899, name: "合单", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1900, name: "合双", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1901, name: "尾大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1902, name: "尾小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1903, name: "红波", game_type: "正码5", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1904, name: "绿波", game_type: "正码5", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1905, name: "蓝波", game_type: "正码5", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zm6: [
                { id: 1906, name: "大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1907, name: "小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1908, name: "单", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1909, name: "双", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1910, name: "合大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1911, name: "合小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1912, name: "合单", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1913, name: "合双", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 1914, name: "尾大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1915, name: "尾小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1916, name: "红波", game_type: "正码6", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1917, name: "绿波", game_type: "正码6", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1918, name: "蓝波", game_type: "正码6", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //连码
            lm_submenu_checked: "5_1_0",
            lm_submenu: [
                {
                    title: "三全中",
                    link: "trader/index?ball=lma1",
                    key: "5_1_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "三中二",
                    link: "trader/index?ball=lma",
                    key: "5_0_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "二全中",
                    link: "trader/index?ball=lma2",
                    key: "5_2_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "二中特",
                    link: "trader/index?ball=lma3",
                    key: "5_3_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "特串",
                    link: "trader/index?ball=lma4",
                    key: "5_4_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "四中一",
                    link: "trader/index?ball=lma5",
                    key: "5_5_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            lm_1_16: [
                {
                    id: 1,
                    name: "1",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 2,
                    name: "2",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 3,
                    name: "3",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 4,
                    name: "4",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 5,
                    name: "5",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 6,
                    name: "6",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 7,
                    name: "7",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 8,
                    name: "8",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 9,
                    name: "9",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 10,
                    name: "10",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 11,
                    name: "11",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 12,
                    name: "12",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 13,
                    name: "13",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 14,
                    name: "14",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 15,
                    name: "15",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 16,
                    name: "16",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
            ],
            lm_17_32: [
                {
                    id: 17,
                    name: "17",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 18,
                    name: "18",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 19,
                    name: "19",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 20,
                    name: "20",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 21,
                    name: "21",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 22,
                    name: "22",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 23,
                    name: "23",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 24,
                    name: "24",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 25,
                    name: "25",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 26,
                    name: "26",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 27,
                    name: "27",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 28,
                    name: "28",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 29,
                    name: "29",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 30,
                    name: "30",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 31,
                    name: "31",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 32,
                    name: "32",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
            ],
            lm_33_49: [
                {
                    id: 33,
                    name: "33",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 34,
                    name: "34",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 35,
                    name: "35",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 36,
                    name: "36",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 37,
                    name: "37",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 38,
                    name: "38",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 39,
                    name: "39",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 40,
                    name: "40",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 41,
                    name: "41",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 42,
                    name: "42",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 43,
                    name: "43",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 44,
                    name: "44",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 45,
                    name: "45",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 46,
                    name: "46",
                    game_type: "连码",
                    color: "#f56c6c",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 47,
                    name: "47",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 48,
                    name: "48",
                    game_type: "连码",
                    color: "#409eff",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
                {
                    id: 49,
                    name: "49",
                    game_type: "连码",
                    color: "#67C23A",
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                    otherdata: { pl: "" },
                },
            ],
            //一肖
            yx_tabs: [
                {
                    title: "中",
                    link: "trader/index?ball=yx",
                    key: "yx",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "不中",
                    link: "trader/index?ball=yxbz",
                    key: "yxbz",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],

            yx: [
                {
                    id: 88,
                    name: "鼠",
                    game_type: "一肖",
                    nums: [
                        { num: 5, color: "#67C23A" },
                        { num: 17, color: "#67C23A" },
                        { num: 29, color: "#f56c6c" },
                        { num: 41, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 89,
                    name: "牛",
                    game_type: "一肖",
                    nums: [
                        { num: 4, color: "#409eff" },
                        { num: 16, color: "#67C23A" },
                        { num: 28, color: "#67C23A" },
                        { num: 40, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 90,
                    name: "虎",
                    game_type: "一肖",
                    nums: [
                        { num: 3, color: "#409eff" },
                        { num: 15, color: "#409eff" },
                        { num: 27, color: "#67C23A" },
                        { num: 39, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 91,
                    name: "兔",
                    game_type: "一肖",
                    nums: [
                        { num: 2, color: "#f56c6c" },
                        { num: 14, color: "#409eff" },
                        { num: 26, color: "#409eff" },
                        { num: 38, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 92,
                    name: "龙",
                    game_type: "一肖",
                    nums: [
                        { num: 1, color: "#f56c6c" },
                        { num: 13, color: "#f56c6c" },
                        { num: 25, color: "#409eff" },
                        { num: 37, color: "#409eff" },
                        { num: 49, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 81,
                    name: "蛇",
                    game_type: "一肖",
                    nums: [
                        { num: 12, color: "#f56c6c" },
                        { num: 24, color: "#f56c6c" },
                        { num: 36, color: "#409eff" },
                        { num: 48, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 82,
                    name: "马",
                    game_type: "一肖",
                    nums: [
                        { num: 11, color: "#67C23A" },
                        { num: 23, color: "#f56c6c" },
                        { num: 35, color: "#f56c6c" },
                        { num: 47, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 83,
                    name: "羊",
                    game_type: "一肖",
                    nums: [
                        { num: 10, color: "#409eff" },
                        { num: 22, color: "#67C23A" },
                        { num: 34, color: "#f56c6c" },
                        { num: 46, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 84,
                    name: "猴",
                    game_type: "一肖",
                    nums: [
                        { num: 9, color: "#409eff" },
                        { num: 21, color: "#67C23A" },
                        { num: 33, color: "#67C23A" },
                        { num: 45, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 85,
                    name: "鸡",
                    game_type: "一肖",
                    nums: [
                        { num: 8, color: "#f56c6c" },
                        { num: 20, color: "#409eff" },
                        { num: 32, color: "#67C23A" },
                        { num: 44, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 86,
                    name: "狗",
                    game_type: "一肖",
                    nums: [
                        { num: 7, color: "#f56c6c" },
                        { num: 19, color: "#f56c6c" },
                        { num: 31, color: "#409eff" },
                        { num: 43, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 87,
                    name: "猪",
                    game_type: "一肖",
                    nums: [
                        { num: 6, color: "#67C23A" },
                        { num: 18, color: "#f56c6c" },
                        { num: 30, color: "#f56c6c" },
                        { num: 42, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            //自选不中
            zxbz_submenu_checked: "8_0_0",
            zxbz_submenu: [
                {
                    title: "五不中",
                    link: "trader/index?ball=bz5",
                    key: "8_0_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "六不中",
                    link: "trader/index?ball=bz6",
                    key: "8_0_1",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "七不中",
                    link: "trader/index?ball=bz7",
                    key: "8_0_2",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "八不中",
                    link: "trader/index?ball=bz8",
                    key: "8_0_3",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "九不中",
                    link: "trader/index?ball=bz9",
                    key: "8_0_4",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "十不中",
                    link: "trader/index?ball=bz10",
                    key: "8_0_5",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "十一不中",
                    link: "trader/index?ball=bz11",
                    key: "8_0_6",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "十二不中",
                    link: "trader/index?ball=bz12",
                    key: "8_0_7",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            zxbz_1_16: [
                { id: 1, name: "1", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2, name: "2", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 3, name: "3", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 4, name: "4", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 5, name: "5", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 6, name: "6", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 7, name: "7", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 8, name: "8", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 9, name: "9", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 10, name: "10", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 11, name: "11", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 12, name: "12", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 13, name: "13", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 14, name: "14", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 15, name: "15", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 16, name: "16", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zxbz_17_32: [
                { id: 17, name: "17", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 18, name: "18", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 19, name: "19", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 20, name: "20", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 21, name: "21", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 22, name: "22", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 23, name: "23", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 24, name: "24", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 25, name: "25", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 26, name: "26", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 27, name: "27", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 28, name: "28", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 29, name: "29", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 30, name: "30", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 31, name: "31", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 32, name: "32", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            zxbz_33_49: [
                { id: 33, name: "33", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 34, name: "34", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 35, name: "35", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 36, name: "36", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 37, name: "37", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 38, name: "38", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 39, name: "39", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 40, name: "40", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 41, name: "41", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 42, name: "42", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 43, name: "43", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 44, name: "44", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 45, name: "45", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 46, name: "46", game_type: "连码", color: "#f56c6c", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 47, name: "47", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 48, name: "48", game_type: "连码", color: "#409eff", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 49, name: "49", game_type: "连码", color: "#67C23A", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //连肖
            lx_tabs: [
                {
                    title: "中",
                    link: "trader/index?ball=lx2",
                    key: "lx",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "不中",
                    link: "trader/index?ball=lx2bz",
                    key: "lxbz",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            lx_submenu_checked: "9_0",
            lx_submenu: [
                {
                    title: "连肖二肖",
                    link: "trader/index?ball=lx2",
                    key: "9_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "连肖三肖",
                    link: "trader/index?ball=lx3",
                    key: "9_1",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "连肖四肖",
                    link: "trader/index?ball=lx4",
                    key: "9_2",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "连肖五肖",
                    link: "trader/index?ball=lx5",
                    key: "9_3",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],

            lx: [
                {
                    id: 157,
                    name: "鼠",
                    game_type: "连肖",
                    nums: [
                        { num: 5, color: "#67C23A" },
                        { num: 17, color: "#67C23A" },
                        { num: 29, color: "#f56c6c" },
                        { num: 41, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 158,
                    name: "牛",
                    game_type: "连肖",
                    nums: [
                        { num: 4, color: "#409eff" },
                        { num: 16, color: "#67C23A" },
                        { num: 28, color: "#67C23A" },
                        { num: 40, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 165,
                    name: "虎",
                    game_type: "连肖",
                    nums: [
                        { num: 3, color: "#409eff" },
                        { num: 15, color: "#409eff" },
                        { num: 27, color: "#67C23A" },
                        { num: 39, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 166,
                    name: "兔",
                    game_type: "连肖",
                    nums: [
                        { num: 2, color: "#f56c6c" },
                        { num: 14, color: "#409eff" },
                        { num: 26, color: "#409eff" },
                        { num: 38, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 101,
                    name: "龙",
                    game_type: "连肖",
                    nums: [
                        { num: 1, color: "#f56c6c" },
                        { num: 13, color: "#f56c6c" },
                        { num: 25, color: "#409eff" },
                        { num: 37, color: "#409eff" },
                        { num: 49, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 102,
                    name: "蛇",
                    game_type: "连肖",
                    nums: [
                        { num: 12, color: "#f56c6c" },
                        { num: 24, color: "#f56c6c" },
                        { num: 36, color: "#409eff" },
                        { num: 48, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 133,
                    name: "马",
                    game_type: "连肖",
                    nums: [
                        { num: 11, color: "#67C23A" },
                        { num: 23, color: "#f56c6c" },
                        { num: 35, color: "#f56c6c" },
                        { num: 47, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 134,
                    name: "羊",
                    game_type: "连肖",
                    nums: [
                        { num: 10, color: "#409eff" },
                        { num: 22, color: "#67C23A" },
                        { num: 34, color: "#f56c6c" },
                        { num: 46, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 141,
                    name: "猴",
                    game_type: "连肖",
                    nums: [
                        { num: 9, color: "#409eff" },
                        { num: 21, color: "#67C23A" },
                        { num: 33, color: "#67C23A" },
                        { num: 45, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 142,
                    name: "鸡",
                    game_type: "连肖",
                    nums: [
                        { num: 8, color: "#f56c6c" },
                        { num: 20, color: "#409eff" },
                        { num: 32, color: "#67C23A" },
                        { num: 44, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 149,
                    name: "狗",
                    game_type: "连肖",
                    nums: [
                        { num: 7, color: "#f56c6c" },
                        { num: 19, color: "#f56c6c" },
                        { num: 31, color: "#409eff" },
                        { num: 43, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 150,
                    name: "猪",
                    game_type: "连肖",
                    nums: [
                        { num: 6, color: "#67C23A" },
                        { num: 18, color: "#f56c6c" },
                        { num: 30, color: "#f56c6c" },
                        { num: 42, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            //六肖一中
            lxyz_submenu_checked: "14_0",
            lxyz_submenu: [
                {
                    title: "六肖一中",
                    link: "trader/index?ball=lxyz",
                    key: "14_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],

            lxyz: [
                {
                    id: 1809,
                    name: "鼠",
                    game_type: "六肖一中",
                    nums: [
                        { num: 5, color: "#67C23A" },
                        { num: 17, color: "#67C23A" },
                        { num: 29, color: "#f56c6c" },
                        { num: 41, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1810,
                    name: "牛",
                    game_type: "六肖一中",
                    nums: [
                        { num: 4, color: "#409eff" },
                        { num: 16, color: "#67C23A" },
                        { num: 28, color: "#67C23A" },
                        { num: 40, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1811,
                    name: "虎",
                    game_type: "六肖一中",
                    nums: [
                        { num: 3, color: "#409eff" },
                        { num: 15, color: "#409eff" },
                        { num: 27, color: "#67C23A" },
                        { num: 39, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1800,
                    name: "兔",
                    game_type: "六肖一中",
                    nums: [
                        { num: 2, color: "#f56c6c" },
                        { num: 14, color: "#409eff" },
                        { num: 26, color: "#409eff" },
                        { num: 38, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1801,
                    name: "龙",
                    game_type: "六肖一中",
                    nums: [
                        { num: 1, color: "#f56c6c" },
                        { num: 13, color: "#f56c6c" },
                        { num: 25, color: "#409eff" },
                        { num: 37, color: "#409eff" },
                        { num: 49, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1802,
                    name: "蛇",
                    game_type: "六肖一中",
                    nums: [
                        { num: 12, color: "#f56c6c" },
                        { num: 24, color: "#f56c6c" },
                        { num: 36, color: "#409eff" },
                        { num: 48, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1803,
                    name: "马",
                    game_type: "六肖一中",
                    nums: [
                        { num: 11, color: "#67C23A" },
                        { num: 23, color: "#f56c6c" },
                        { num: 35, color: "#f56c6c" },
                        { num: 47, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1804,
                    name: "羊",
                    game_type: "六肖一中",
                    nums: [
                        { num: 10, color: "#409eff" },
                        { num: 22, color: "#67C23A" },
                        { num: 34, color: "#f56c6c" },
                        { num: 46, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1805,
                    name: "猴",
                    game_type: "六肖一中",
                    nums: [
                        { num: 9, color: "#409eff" },
                        { num: 21, color: "#67C23A" },
                        { num: 33, color: "#67C23A" },
                        { num: 45, color: "#f56c6c" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1806,
                    name: "鸡",
                    game_type: "六肖一中",
                    nums: [
                        { num: 8, color: "#f56c6c" },
                        { num: 20, color: "#409eff" },
                        { num: 32, color: "#67C23A" },
                        { num: 44, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1807,
                    name: "狗",
                    game_type: "六肖一中",
                    nums: [
                        { num: 7, color: "#f56c6c" },
                        { num: 19, color: "#f56c6c" },
                        { num: 31, color: "#409eff" },
                        { num: 43, color: "#67C23A" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
                {
                    id: 1808,
                    name: "猪",
                    game_type: "六肖一中",
                    nums: [
                        { num: 6, color: "#67C23A" },
                        { num: 18, color: "#f56c6c" },
                        { num: 30, color: "#f56c6c" },
                        { num: 42, color: "#409eff" },
                    ],
                    is_open: 1,
                    maxpl: "",
                    minpl: 0,
                    pl: "",
                    level_four: 0,
                    level_three: 0,
                    level_two: 3,
                    control_let: 0,
                    place: "2",
                    szsz_amount: ["", "", "", "", "", "", ""],
                },
            ],
            //两面
            lianmian_t: [
                { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            lianmian_h: [
                { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            lianmian_z: [
                { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            lianmian_w: [
                { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
                { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
            ],
            //尾数
            ws_tabs: [
                {
                    title: "中",
                    link: "trader/index?ball=ws",
                    key: "ws",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "不中",
                    link: "trader/index?ball=wsbz",
                    key: "wsbz",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            ws: [
                { id: 175, name: "0尾", namenum: "0", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 176, name: "1尾", namenum: "1", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 178, name: "2尾", namenum: "2", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 179, name: "3尾", namenum: "3", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 180, name: "4尾", namenum: "4", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 181, name: "5尾", namenum: "5", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 182, name: "6尾", namenum: "6", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 183, name: "7尾", namenum: "7", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 184, name: "8尾", namenum: "8", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 185, name: "9尾", namenum: "9", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //尾连
            wl_tabs: [
                {
                    title: "中",
                    link: "trader/index?ball=wl",
                    key: "wl",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "不中",
                    link: "trader/index?ball=wlbz",
                    key: "wlbz",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            wl_submenu_checked: "12_0",
            wl_submenu: [
                {
                    title: "二尾连",
                    link: "trader/index?ball=wl",
                    key: "12_0",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "三尾连",
                    link: "trader/index?ball=wl1",
                    key: "12_1",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "四尾连",
                    link: "trader/index?ball=wl2",
                    key: "12_2",
                    szsz_amount: ["", "", "", "", "", ""],
                },
                {
                    title: "五尾连",
                    link: "trader/index?ball=wl3",
                    key: "12_3",
                    szsz_amount: ["", "", "", "", "", ""],
                },
            ],
            wl: [
                { id: 1600, name: "连0尾", namenum: "0", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1601, name: "1尾", namenum: "1", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1602, name: "2尾", namenum: "2", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1603, name: "3尾", namenum: "3", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1604, name: "4尾", namenum: "4", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1605, name: "5尾", namenum: "5", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1606, name: "6尾", namenum: "6", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1607, name: "7尾", namenum: "7", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1608, name: "8尾", namenum: "8", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 1609, name: "9尾", namenum: "9", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            //生肖/尾数量
            sxl: [
                { id: 2018, name: "肖2", namenum: "2", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2019, name: "肖3", namenum: "3", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2020, name: "肖4", namenum: "4", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2021, name: "肖5", namenum: "5", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2022, name: "肖6", namenum: "6", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2023, name: "肖7", namenum: "7", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
            wsl: [
                { id: 2024, name: "尾2", namenum: "2", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2025, name: "尾3", namenum: "3", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2026, name: "尾4", namenum: "4", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2027, name: "尾5", namenum: "5", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2028, name: "尾6", namenum: "6", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
                { id: 2029, name: "尾7", namenum: "7", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
            ],
        };
    },
    created() {
        // console.log(this.game_info_data);
    },
    methods: {
        //中奖号码标记颜色
        tableRowClassName(colum) {
            // console.log(this.game_info_data);
            if (this.game_info_data.nn == this.game_info_data.upopenphase) {
                // if (this.game_info_data.isopen == 0 && this.game_info_data.nn == this.game_info_data.upopenphase) {
                let upopennumber = this.game_info_data.upopennumber;
                let result_res = this.game_info_data.result_res;
                if (this.title == "特码A+B" || this.title == "特码A" || this.title == "特码B") {
                    if (this.game_info_data.upopennumber[6].num == colum.row.name) {
                        return "themeColorhalf-row";
                    }
                    if (this.game_info_data.upopennumber[6].color == colum.row.color && (colum.row.name == "红波" || colum.row.name == "绿波" || colum.row.name == "蓝波")) {
                        return "themeColorhalf-row";
                    }
                }
                if (this.title == "两面" || this.title == "特码A+B" || this.title == "特码A" || this.title == "特码B") {
                    for (const key in result_res) {
                        if (result_res[key] == colum.row.name && key != "sum" && key != "sxl" && key != "wsl") {
                            return "themeColorhalf-row";
                        }
                    }
                    if (result_res.tema_ds == "双" && colum.row.name == "特双") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.tema_ds == "单" && colum.row.name == "特单") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.tema_dx == "大" && colum.row.name == "特大") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.tema_dx == "小" && colum.row.name == "特小") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.sum_ds == "总和单" && colum.row.name == "总单") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.sum_ds == "总和双" && colum.row.name == "总双") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.sum_dx == "总和大" && colum.row.name == "总大") {
                        return "themeColorhalf-row";
                    }
                    if (result_res.sum_dx == "总和小" && colum.row.name == "总小") {
                        return "themeColorhalf-row";
                    }
                    let hw_num = "";
                    if (this.game_info_data.upopennumber[6].num < 10) {
                        hw_num = this.game_info_data.upopennumber[6].num;
                    } else {
                        hw_num = this.game_info_data.upopennumber[6].num[0] * 1 + this.game_info_data.upopennumber[6].num[1] * 1;
                    }
                    if (hw_num <= 4 && colum.row.name == "合尾小") {
                        return "themeColorhalf-row";
                    }
                    if (hw_num > 4 && colum.row.name == "合尾大") {
                        return "themeColorhalf-row";
                    }
                    if (this.game_info_data.upopennumber[6].num * 1 == 25) {
                        return "";
                    }
                }
                if (this.title == "连码" || this.title == "自选不中") {
                    for (let index = 0; index < upopennumber.length; index++) {
                        if (upopennumber[index].num == colum.row.name) {
                            return "themeColorhalf-row";
                        }
                    }
                }
                if (this.title == "尾数" || this.title == "尾连") {
                    if (this.balls == "ws" || this.balls == "wl") {
                        for (let index = 0; index < upopennumber.length; index++) {
                            if (colum.row.namenum == upopennumber[index].num.toString().slice(-1)) {
                                return "themeColorhalf-row";
                            }
                        }
                    }
                    if (this.balls == "wsbz" || this.balls == "wlbz") {
                        let list = [];
                        for (let index = 0; index < upopennumber.length; index++) {
                            // if (upopennumber[index].num == 10) {
                            //     list.push(0)
                            // } else {
                            // }
                            list.push(upopennumber[index].num.toString().slice(-1));
                        }
                        // console.log(upopennumber,list);
                        if (!list.includes(colum.row.namenum)) {
                            return "themeColorhalf-row";
                        }
                    }
                }
                if (this.title == "色波") {
                    let nums = colum.row.nums;
                    if (nums != undefined) {
                        for (let j = 0; j < nums.length; j++) {
                            if (this.game_info_data.upopennumber[6].num == nums[j]) {
                                return "themeColorhalf-row";
                            }
                        }
                    } else {
                        if (this.game_info_data.upopennumber[6].num == colum.row.name) {
                            return "themeColorhalf-row";
                        }
                    }
                    if (this.game_info_data.upopennumber[6].color == colum.row.color && (colum.row.name == "红波" || colum.row.name == "绿波" || colum.row.name == "蓝波")) {
                        return "themeColorhalf-row";
                    }
                }
                if (this.title == "特肖") {
                    if (colum.row.name == upopennumber[6].sx) {
                        return "themeColorhalf-row";
                    }
                }
                if (this.title == "一肖" || this.title == "连肖" || this.title == "六肖一中") {
                    if (this.balls == "yxbz" || this.balls == "lx2bz" || this.balls == "lx3bz" || this.balls == "lx4bz" || this.balls == "lx5bz") {
                        if (!upopennumber.find((item) => item.sx == colum.row.name)) {
                            return "themeColorhalf-row";
                        }
                    } else {
                        for (let index = 0; index < upopennumber.length; index++) {
                            if (upopennumber[index].sx == colum.row.name) {
                                return "themeColorhalf-row";
                            }
                        }
                    }
                }
                if (this.title == "正码/正特") {
                    let zm_upopennumber = JSON.parse(JSON.stringify(upopennumber));
                    zm_upopennumber.length = 6;

                    if (this.balls == "zm") {
                        for (let index = 0; index < zm_upopennumber.length; index++) {
                            if (zm_upopennumber[index].num == colum.row.name) {
                                return "themeColorhalf-row";
                            }
                        }
                    }
                    if (this.balls == "ztm1" || this.balls == "ztm2" || this.balls == "ztm3" || this.balls == "ztm4" || this.balls == "ztm5" || this.balls == "ztm6") {
                        let index = this.balls[3] * 1 - 1;
                        // console.log(zm_upopennumber[index].num);
                        if (zm_upopennumber[index].num == colum.row.name) {
                            return "themeColorhalf-row";
                        }
                    }
                }
                if (this.title == "正码1-6") {
                    let zm_upopennumber = JSON.parse(JSON.stringify(upopennumber));
                    zm_upopennumber.length = 6;
                    for (let index = 0; index < zm_upopennumber.length; index++) {
                        if (zm_upopennumber[index].num != 49) {
                            if (zm_upopennumber[index].num >= 25 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "大") {
                                return "themeColorhalf-row";
                            }
                            if (zm_upopennumber[index].num <= 24 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "小") {
                                return "themeColorhalf-row";
                            }
                            if (zm_upopennumber[index].num % 2 !== 0 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "单") {
                                return "themeColorhalf-row";
                            }
                            if (zm_upopennumber[index].num % 2 == 0 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "双") {
                                return "themeColorhalf-row";
                            }
                            if (parseInt(zm_upopennumber[index].num / 10) + parseInt(zm_upopennumber[index].num % 10) >= 7 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合大") {
                                return "themeColorhalf-row";
                            }
                            if (parseInt(zm_upopennumber[index].num / 10) + parseInt(zm_upopennumber[index].num % 10) <= 6 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合小") {
                                return "themeColorhalf-row";
                            }
                            if ((parseInt(zm_upopennumber[index].num / 10) + parseInt(zm_upopennumber[index].num % 10)) % 2 == 0 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合双") {
                                return "themeColorhalf-row";
                            }
                            if ((parseInt(zm_upopennumber[index].num / 10) + parseInt(zm_upopennumber[index].num % 10)) % 2 !== 0 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合单") {
                                return "themeColorhalf-row";
                            }
                            if (zm_upopennumber[index].num % 10 <= 4 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "尾小") {
                                return "themeColorhalf-row";
                            }
                            if (zm_upopennumber[index].num % 10 >= 5 && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "尾大") {
                                return "themeColorhalf-row";
                            }
                        }
                        if (zm_upopennumber[index].color == "#f56c6c" && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "红波") {
                            return "themeColorhalf-row";
                        }
                        if (zm_upopennumber[index].color == "#409eff" && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "蓝波") {
                            return "themeColorhalf-row";
                        }
                        if (zm_upopennumber[index].color == "#67C23A" && index + 1 == colum.row.game_type.substr(-1) && colum.row.name == "绿波") {
                            return "themeColorhalf-row";
                        }
                    }
                }
                if (this.title == "生肖/尾数量") {
                    if (colum.row.game_type == "生肖量") {
                        let sxlist = [];
                        upopennumber.forEach((item) => {
                            sxlist.push(item.sx);
                        });
                        let list = [...new Set(sxlist)];
                        // console.log(sxlist,list);
                        if (list.length == colum.row.namenum) {
                            return "themeColorhalf-row";
                        }
                    }
                    if (colum.row.game_type == "尾数量") {
                        let wslist = [];
                        for (let index = 0; index < upopennumber.length; index++) {
                            wslist.push(upopennumber[index].num.toString().slice(-1));
                        }
                        let list = [...new Set(wslist)];
                        if (list.length == colum.row.namenum) {
                            return "themeColorhalf-row";
                        }
                    }
                }
            }
            if (this.game_info_data.stop_time == "00:00:00") {
                return "gray-row";
            }
            return "";
        },
        change_zupai_type(value) {
            this.$emit("change_zupai_type", value);
        },
        //数组中相同值的个数
        // getRepeatNum(arr) {
        //     return arr.reduce(function (prev, next) {
        //         prev[next] = (prev[next] + 1) || 1;
        //         return prev;
        //     }, {});
        // },
        change_play(name) {
            let t = name;
            if (t == "特码") {
                t = "特码A";
            }
            if (t == "正特1-6") {
                t = "正码/正特";
            }
            if (t == "正码") {
                t = "正码/正特";
            }
            if (t == "生肖量" || t == "尾数量") {
                t = "生肖/尾数量";
            }
            if (t == "连肖不中") {
                t = "连肖";
            }
            if (t == "尾数不中") {
                t = "尾数";
            }
            if (t == "尾连不中") {
                t = "尾连";
            }
            if (t == "一肖不中") {
                t = "一肖";
            }
            this.$emit("change_play", t);
        },
        sub_bh() {
            // console.log(this.bh.row);
            if (this.bh.row.szsz_amount) {
                if (this.bh.je_value * 1 > this.bh.row.szsz_amount[1] * 1) {
                    return this.$message.error("补货金额不能大于下注额");
                }
            } else {
                if (this.bh.je_value * 1 > this.bh.row.oc_bill * 1) {
                    return this.$message.error("补货金额不能大于下注额");
                }
            }
            if (this.bh.row.szsz_amount != undefined && this.bh.row.szsz_amount[4] != undefined) {
                if (this.bh.je_value * 1 > this.bh.row.szsz_amount[4] * -1) {
                    return this.$message.error("补货金额不能大于正亏损");
                }
            }
            if (this.bh.row.profit_bill) {
                if (this.bh.je_value * 1 > this.bh.row.profit_bill * -1) {
                    return this.$message.error("补货金额不能大于正亏损");
                }
            }
            if (this.bh.je_value != "" && this.bh.je_value != 0 && this.bh.je_value * 1 < 1) {
                return this.$message.error("补货金额不能小于1");
            }
            let obj = {
                user_id: this.$store.state.userinfo.user_id,
                play_id: this.game_info_data.gid,
                level_two: this.bh.row.level_two,
                level_three: this.bh.row.level_three,
                level_four: this.bh.row.level_four,
                place: this.bh.row.place,
                ball: this.bh.row.ball || this.bh.row.name,
                qnum: this.game_info_data.nn,
                is_manlet: this.is_manlet,
                control_let: this.bh.je_value,
                rate: this.bh.row.pl || this.bh.row.list_bonus,
                rebate_level: this.bh.row.rebate_level || 0,
                oc_bill_s: this.bh.row.szsz_amount ? this.bh.row.szsz_amount[this.bh.row.szsz_amount.length - 1] : this.bh.row.oc_bill,
                oc_bill_tm: "",
                is_update: "0",
                pk: this.pk,
                title: this.bh.row.title == undefined ? this.bh.row.game_type : this.bh.row.title,
                num: this.bh.row.num == undefined ? 1 : this.bh.row.num,
                periods_id: this.game_info_data.p_id,
            };
            // console.log(this.title, this.balls);
            // console.log(obj.oc_bill_s, obj.oc_bill_tm);
            if (this.balls == "tm" && !Number.isNaN(Number(this.bh.row.name))) {
                if (this.bh.row.szsz_amount_tmb != undefined) {
                    if (this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1] == "" || this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1] == "0") {
                        obj.oc_bill_tm = 0;
                    } else {
                        obj.oc_bill_tm = this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1];
                    }
                } else {
                    obj.oc_bill_tm = 0;
                }
            }
            if (this.title == "特码A+B" || this.title == "特码A" || this.title == "尾数") {
                obj.place = 1;
            }
            if (this.title == "特码B") {
                obj.place = 24;
            }
            if (this.title == "连码" || this.title == "连肖" || this.title == "自选不中" || this.title == "六肖一中" || this.title == "尾连") {
                obj.oc_bill_s = this.bh.row.occupy_bill_s;
            }
            //正特1-6这几个补货的place是3-8
            if (this.balls.includes("ztm")) {
                obj.place = this.balls.substr(-1) * 1 + 2;
            }
            this.axios.post("userlet/userLet", obj).then((result) => {
                if (result.data.shortMessage.includes("是否确认补货")) {
                    this.$confirm(result.data.shortMessage, "提示", {
                        customClass: "myMessageBox",
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            obj.is_update = "1";
                            this.axios.post("userlet/userLet", obj).then((result) => {
                                if (result.data.status == 200) {
                                    this.$message.success(result.data.shortMessage);
                                    this.bh.show = false;
                                }
                            });
                        })
                        .catch(() => {
                            obj.is_update = "0";
                            this.$message({
                                type: "info",
                                message: "已取消操作",
                            });
                        });
                } else {
                    this.$message.success(result.data.shortMessage);
                    this.bh.show = false;
                }
            });
        },
        show_bh(row, event) {
            // console.log(row, event, this.is_manlet);
            // console.log(row,this.game_info_data);
            this.bh.show = false;
            if (row.yk < 0 || row.profit_bill < 0) {
                if (this.is_manlet == "1" || this.is_manlet == "0") {
                    this.bh.row = row;
                    this.bh.pl_value = row.pl || row.list_bonus;
                    this.bh.je_value = "";
                    let width = document.body.clientWidth - event.clientX;
                    // console.log(event.clientX, document.body.clientWidth);
                    if (width < 140) {
                        this.$refs["bh_popver"].style.left = event.pageX - 140 + "px";
                    } else {
                        this.$refs["bh_popver"].style.left = event.pageX + "px";
                    }
                    this.$refs["bh_popver"].style.top = event.pageY + "px";
                    this.bh.show = true;
                    this.$refs["bhje_input"].focus();
                }
            }
        },
        get_zdmx_data(row) {
            // console.log(row);
            if ((row.szsz_amount && row.szsz_amount[1] != "") || row.oc_bill != 0) {
                this.$emit("get_zdmx_data", row);
            }
        },
        sub_change_pl() {
            if (this.pl_popver.type == "1") {
                this.$emit("change_pl", this.pl_popver);
            } else {
                this.$emit("change_pl", this.pl_popver, "zupai");
            }
        },
        show_pl_popver(row, event, type = "") {
            if (this.$store.state.userinfo.group_id >= 4 || this.$store.state.userinfo.trader_status == 0) {
                return false;
            }
            // console.log(row, event);
            this.pl_popver.show = false;
            if (type == "") {
                //单码
                if (event == "plus" || event == "minus") {
                    this.pl_popver.row = row;
                    this.pl_popver.old_pl = row.pl;
                    this.pl_popver.pl = row.pl;
                    this.pl_popver.pl_change_value = "";
                    this.pl_popver.plusminus = event;
                    this.$emit("change_pl", this.pl_popver);
                } else {
                    this.pl_popver.row = row;
                    this.pl_popver.value = row.pl;
                    this.pl_popver.old_pl = row.pl;
                    this.pl_popver.plusminus = "";
                    this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
                    this.$refs["pl_popver"].style.left = event.pageX + "px";
                    this.$refs["pl_popver"].style.top = event.pageY + "px";
                    this.pl_popver.show = true;
                }
            } else {
                //组排
                this.pl_popver.type = "2";
                if (event == "plus" || event == "minus") {
                    this.pl_popver.row = row;
                    this.pl_popver.old_pl = row.list_bonus;
                    this.pl_popver.pl = row.list_bonus;
                    this.pl_popver.pl_change_value = "";
                    this.pl_popver.plusminus = event;
                    // console.log(this.pl_popver);
                    this.$emit("change_pl", this.pl_popver, "zupai");
                } else {
                    this.pl_popver.row = row;
                    this.pl_popver.value = row.list_bonus;
                    this.pl_popver.old_pl = row.list_bonus;
                    this.pl_popver.plusminus = "";
                    this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
                    this.$refs["pl_popver"].style.left = event.pageX - 80 + "px";
                    this.$refs["pl_popver"].style.top = event.pageY + "px";
                    this.pl_popver.show = true;
                }
            }
        },
        //初始小玩法
        int_submenu() {
            if (this.is_int_submenu) {
                if (this.title == "正码/正特") {
                    this.zmzt_submenu.forEach((sub) => {
                        sub.class = "theme-half-bg";
                    });
                    this.zmzt_submenu[0].class = "theme-bg";
                }
                if (this.title == "一肖") {
                    this.yx_tabs.forEach((sub) => {
                        sub.class = "theme-half-bg";
                    });
                    this.yx_tabs[0].class = "theme-bg";
                }
                if (this.title == "尾数") {
                    this.ws_tabs.forEach((sub) => {
                        sub.class = "theme-half-bg";
                    });
                    this.ws_tabs[0].class = "theme-bg";
                }
                if (this.title == "连码") {
                    this.zupai_title = "三全中";
                    this.lm_submenu_checked = "5_1_0";
                }
                if (this.title == "自选不中") {
                    this.zupai_title = "五不中";
                    this.zxbz_submenu_checked = "8_0_0";
                }
                if (this.title == "连肖") {
                    this.zupai_title = "连肖二肖";
                    this.lx_tabs.forEach((sub) => {
                        sub.class = "theme-half-bg";
                    });
                    this.lx_tabs[0].class = "theme-bg";
                    this.change_tabs({ title: "中" });
                }
                if (this.title == "六肖一中") {
                    this.zupai_title = "六肖一中";
                }
                if (this.title == "尾连") {
                    this.zupai_title = "二尾连";
                    this.wl_tabs.forEach((sub) => {
                        sub.class = "theme-half-bg";
                    });
                    this.wl_tabs[0].class = "theme-bg";
                    this.change_tabs({ title: "中" });
                }
            }
        },
        //切换小tabs
        change_tabs(tabs) {
            // console.log(tabs);
            let submenu = {};
            if (this.title == "连肖") {
                this.lx_tabs.forEach((sub) => {
                    sub.class = "theme-half-bg";
                    if (tabs.title == sub.title) {
                        sub.class = "theme-bg";
                    }
                });
                if (tabs.title == "中") {
                    this.lx_submenu = [
                        {
                            title: "连肖二肖",
                            link: "trader/index?ball=lx2",
                            key: "9_0",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "连肖三肖",
                            link: "trader/index?ball=lx3",
                            key: "9_1",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "连肖四肖",
                            link: "trader/index?ball=lx4",
                            key: "9_2",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "连肖五肖",
                            link: "trader/index?ball=lx5",
                            key: "9_3",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                    ];
                    this.lx_submenu_checked = "9_0";
                } else {
                    this.lx_submenu = [
                        {
                            title: "二肖连不中",
                            link: "trader/index?ball=lx2bz",
                            key: "18_0",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "三肖连不中",
                            link: "trader/index?ball=lx3bz",
                            key: "18_1",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "四肖连不中",
                            link: "trader/index?ball=lx4bz",
                            key: "18_2",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "五肖连不中",
                            link: "trader/index?ball=lx5bz",
                            key: "18_3",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                    ];
                    this.lx_submenu_checked = "18_0";
                }
                submenu = this.lx_submenu[0];
            }
            if (this.title == "尾连") {
                this.wl_tabs.forEach((sub) => {
                    sub.class = "theme-half-bg";
                    if (tabs.title == sub.title) {
                        sub.class = "theme-bg";
                    }
                });
                if (tabs.title == "中") {
                    this.wl_submenu = [
                        {
                            title: "二尾连",
                            link: "trader/index?ball=wl",
                            key: "12_0",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "三尾连",
                            link: "trader/index?ball=wl1",
                            key: "12_1",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "四尾连",
                            link: "trader/index?ball=wl2",
                            key: "12_2",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "五尾连",
                            link: "trader/index?ball=wl3",
                            key: "12_3",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                    ];
                    this.wl_submenu_checked = "12_0";
                } else {
                    this.wl_submenu = [
                        {
                            title: "二尾连不中",
                            link: "trader/index?ball=wlbz",
                            key: "19_0",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "三尾连不中",
                            link: "trader/index?ball=wl1bz",
                            key: "19_1",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "四尾连不中",
                            link: "trader/index?ball=wl2bz",
                            key: "19_2",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                        {
                            title: "五尾连不中",
                            link: "trader/index?ball=wl3bz",
                            key: "19_3",
                            szsz_amount: ["", "", "", "", "", ""],
                        },
                    ];
                    this.wl_submenu_checked = "19_0";
                }
                submenu = this.wl_submenu[0];
            }
            this.change_submenu(submenu);
        },
        //切换小玩法
        change_submenu(item) {
            if (this.title == "正码/正特") {
                this.zmzt_submenu.forEach((sub) => {
                    sub.class = "theme-half-bg";
                    if (item.title == sub.title) {
                        sub.class = "theme-bg";
                    }
                });
            }
            if (this.title == "连码" || this.title == "自选不中" || this.title == "连肖" || this.title == "六肖一中" || this.title == "尾连") {
                this.zupai_title = item.title;
                this.pl_popver.show = false;
                this.zupai.page = 1;
            }
            if (this.title == "一肖") {
                this.yx_tabs.forEach((sub) => {
                    sub.class = "theme-half-bg";
                    if (item.title == sub.title) {
                        sub.class = "theme-bg";
                    }
                });
            }
            if (this.title == "尾数") {
                this.ws_tabs.forEach((sub) => {
                    sub.class = "theme-half-bg";
                    if (item.title == sub.title) {
                        sub.class = "theme-bg";
                    }
                });
            }

            this.is_int_submenu = false;
            this.$emit("change_submenu", item);
        },
        //盈亏正负排序设置
        set_kuishun_pailie(type = "fu", t = "tm_px_ab") {
            // console.log(this.$refs[t]);
            if (type == "fu") {
                this.kuisun_fuzhi_class = "theme-bg white-text";
                this.kuisun_zhengzhi_class = "theme-half-bg black-text";
                this.$refs[t].sort("szsz_amount[4]", "ascending");
            } else {
                this.kuisun_zhengzhi_class = "theme-bg white-text";
                this.kuisun_fuzhi_class = "theme-half-bg black-text";
                this.$refs[t].sort("szsz_amount[4]", "descending");
            }
        },
        proc(data) {
            // console.log(data);
            this.sum_bet_balance = data.sum_bet_balance;
            let play_odds = data.play_odds;
            let szsz_amount = {};
            if (data.szsz_amount) {
                szsz_amount = data.szsz_amount;
            } else {
                szsz_amount = {};
                data.szsz_amount_tm2 = {};
                data.szsz_amount_tmb = {};
            }
            // console.log(szsz_amount);
            let yk = [];
            let list_names = [];
            // console.log(this.title);
            if (this.title == "特码A+B") {
                data.play_odds_tm2.credit_list_lm.forEach((item) => {
                    play_odds.push(item);
                });
                // console.log(data);
                for (const key in data.szsz_amount_tm2.oddis_arr_lm) {
                    this.tmAB_lm.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_lm[key];
                        }
                    });
                }
                data.play_odds_tm2.credit_list_sb.forEach((item) => {
                    play_odds.push(item);
                });
                for (const key in data.szsz_amount_tm2.oddis_arr_sb) {
                    this.tmAB_sb.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_sb[key];
                        }
                    });
                }
                list_names = ["tmAB_1_25", "tmAB_26_49", "tmAB_lm", "tmAB_sb", "tmpl"];
            }
            if (this.title == "特码A") {
                data.play_odds_tm2.credit_list_lm.forEach((item) => {
                    play_odds.push(item);
                });
                for (const key in data.szsz_amount_tm2.oddis_arr_lm) {
                    this.tmA_lm.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_lm[key];
                        }
                    });
                }
                data.play_odds_tm2.credit_list_sb.forEach((item) => {
                    play_odds.push(item);
                });
                for (const key in data.szsz_amount_tm2.oddis_arr_sb) {
                    this.tmA_sb.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_sb[key];
                        }
                    });
                }
                list_names = ["tmA_1_25", "tmA_26_49", "tmA_lm", "tmA_sb", "tmpl"];
            }
            if (this.title == "特码B") {
                data.play_odds_tm2.credit_list_lm.forEach((item) => {
                    play_odds.push(item);
                });
                for (const key in data.szsz_amount_tm2.oddis_arr_lm) {
                    this.tmB_lm.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_lm[key];
                        }
                    });
                }
                data.play_odds_tm2.credit_list_sb.forEach((item) => {
                    play_odds.push(item);
                });
                for (const key in data.szsz_amount_tm2.oddis_arr_sb) {
                    this.tmB_sb.forEach((item) => {
                        if (key == item.id) {
                            szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_sb[key];
                        }
                    });
                }
                list_names = ["tmB_1_25", "tmB_26_49", "tmB_lm", "tmB_sb", "tmpl"];
            }
            if (this.title == "色波") {
                list_names = ["sb_ds", "sb_dx", "sb"];
            }
            if (this.title == "特肖") {
                list_names = ["tx"];
            }
            if (this.title == "正码/正特") {
                list_names = ["zmzt_1_25", "zmzt_26_49", "zmztpl"];
            }
            if (this.title == "正码1-6") {
                list_names = ["zm1", "zm2", "zm3", "zm4", "zm5", "zm6"];
            }
            if (this.title == "一肖") {
                list_names = ["yx"];
            }
            if (this.title == "两面") {
                list_names = ["lianmian_t", "lianmian_h", "lianmian_z", "lianmian_w"];
            }
            if (this.title == "尾数") {
                list_names = ["ws"];
            }
            if (this.title == "生肖/尾数量") {
                list_names = ["sxl", "wsl"];
            }
            //处理小玩法累计数据
            if (this.title == "连码") {
                // console.log(szsz_amount);
                for (const key in szsz_amount) {
                    this.lm_submenu.forEach((item) => {
                        item.szsz_amount = ["", "", "", "", "", ""];
                        // console.log(item.key);
                        if (key == item.key) {
                            item.szsz_amount = szsz_amount[key];
                        }
                    });
                }
                list_names = ["lm_1_16", "lm_17_32", "lm_33_49", "lm_submenu"];
                play_odds.forEach((element) => {
                    list_names.forEach((keyname) => {
                        this[keyname].forEach((item) => {
                            if (element.name == item.name) {
                                item.id = element.id;
                            }
                            if ((this.balls == "lma" || this.balls == "lma3") && data.play_odds_tm2.credit_list_lma != undefined) {
                                data.play_odds_tm2.credit_list_lma.forEach((e) => {
                                    if (e.name == item.name) {
                                        e.newid = e.id;
                                        e.pl *= 1;
                                        item.otherdata = e;
                                    }
                                });
                            }
                        });
                    });
                });
            }
            if (this.title == "自选不中") {
                this.zxbz_submenu.forEach((item) => {
                    item.szsz_amount = ["", "", "", "", "", ""];
                    for (const key in szsz_amount) {
                        if (key == item.key) {
                            item.szsz_amount = szsz_amount[key].split(",");
                        }
                    }
                });
                list_names = ["zxbz_1_16", "zxbz_17_32", "zxbz_33_49", "zxbz_submenu"];
                play_odds.forEach((element) => {
                    list_names.forEach((keyname) => {
                        this[keyname].forEach((item) => {
                            if (element.name == item.name) {
                                item.id = element.id;
                            }
                        });
                    });
                });
            }
            if (this.title == "连肖") {
                this.lx_submenu.forEach((item) => {
                    item.szsz_amount = ["", "", "", "", "", ""];
                    for (const key in szsz_amount) {
                        if (key == item.key) {
                            item.szsz_amount = szsz_amount[key].split(",");
                        }
                    }
                });
                list_names = ["lx", "lx_submenu"];
                play_odds.forEach((element) => {
                    list_names.forEach((keyname) => {
                        this[keyname].forEach((item) => {
                            if (element.name == item.name) {
                                item.id = element.id;
                            }
                        });
                    });
                });
            }
            if (this.title == "六肖一中") {
                this.lxyz_submenu.forEach((item) => {
                    item.szsz_amount = ["", "", "", "", "", ""];
                    for (const key in szsz_amount) {
                        if (key == item.key) {
                            item.szsz_amount = szsz_amount[key].split(",");
                        }
                    }
                });
                list_names = ["lxyz", "lxyz_submenu"];
            }
            if (this.title == "尾连") {
                this.wl_submenu.forEach((item) => {
                    item.szsz_amount = ["", "", "", "", "", ""];
                    for (const key in szsz_amount) {
                        if (key == item.key) {
                            item.szsz_amount = szsz_amount[key].split(",");
                        }
                    }
                });
                list_names = ["wl", "wl_submenu"];
                play_odds.forEach((element) => {
                    list_names.forEach((keyname) => {
                        this[keyname].forEach((item) => {
                            if (element.name == item.name) {
                                item.id = element.id;
                            }
                        });
                    });
                });
            }
            //处理小项
            play_odds.forEach((element) => {
                list_names.forEach((keyname) => {
                    this[keyname].forEach((item) => {
                        if (keyname == "zm1" || keyname == "zm2" || keyname == "zm3" || keyname == "zm4" || keyname == "zm5" || keyname == "zm6") {
                            if (element.id == item.id) {
                                item.is_open = element.is_open;
                                item.maxpl = element.maxpl * 1;
                                item.minpl = element.minpl * 1;
                                item.pl = element.pl * 1;
                                item.level_two = element.level_two;
                                item.level_three = element.level_three;
                                item.level_four = element.level_four;
                                item.control_let = element.control_let;
                                item.newid = element.id;
                                item.szsz_amount = ["", "", "", "", "", "", ""];
                                item.yk = 0;
                            }
                        } else {
                            if (element.id == item.id || element.name == item.name || (keyname == "ws" && element.name.includes(item.namenum))) {
                                item.is_open = element.is_open;
                                item.maxpl = element.maxpl * 1;
                                item.minpl = element.minpl * 1;
                                item.pl = element.pl * 1;
                                item.level_two = element.level_two;
                                item.level_three = element.level_three;
                                item.level_four = element.level_four;
                                item.control_let = element.control_let;
                                item.newid = element.id;
                                item.szsz_amount = ["", "", "", "", "", "", ""];
                                item.yk = 0;
                                // if (this.balls == 'lma' || this.balls == 'lma3') {
                                //     item.otherdata = element.otherdata * 1;
                                // }
                            }
                        }
                    });
                });
            });
            //处理小项注额盈亏
            for (const key in szsz_amount) {
                // console.log(key);
                list_names.forEach((keyname) => {
                    this[keyname].forEach((item) => {
                        // console.log(item);
                        if (key == item.id || key == item.newid || key == item.key) {
                            item.szsz_amount = szsz_amount[key].split(",");
                            // console.log(item.szsz_amount);
                            if (this.balls == "tm") {
                                if (data.szsz_amount_tmb[key] != undefined) {
                                    if (!Array.isArray(data.szsz_amount_tmb[key]) && !data.szsz_amount_tmb[key].length == 0) {
                                        if (data.szsz_amount_tmb[key].split != undefined) {
                                            item.szsz_amount_tmb = data.szsz_amount_tmb[key].split(",");
                                        }
                                    }
                                }
                            }
                            if (keyname == "zmztpl") {
                                item.type_zteb = 0;
                                if (data.szsz_amount_ztb && data.szsz_amount_ztb[key] != undefined) {
                                    if (!Array.isArray(data.szsz_amount_ztb[key]) && !data.szsz_amount_ztb[key].length == 0) {
                                        if (data.szsz_amount_ztb[key].split != undefined) {
                                            item.szsz_amount = data.szsz_amount_ztb[key].split(",");
                                            if (!this.balls.includes("b")) {
                                                item.type_zteb = 1;
                                            }
                                        }
                                    }
                                }
                            }
                            item.szsz_amount[4] *= 1;
                            item.yk = item.szsz_amount[4];
                        }
                        yk.push(item.yk);
                    });
                });
            }
            this.max_yl = yk.sort((a, b) => b - a)[0] || 0;
            this.max_ks = yk.sort((a, b) => a - b)[0] || 0;
        },
    },
};
</script>
<style scoped>
.ztze-zgks-zgyl p {
    margin: 5px 0;
}
.subNav {
    list-style-type: none;
    padding: 0;
    margin: 0;
    float: left;
}
.subNav li {
    width: 70px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    margin-right: 3px;
    float: left;
    cursor: pointer;
    border-radius: 3px 3px 0 0;
    color: #fff;
}
.nums {
    display: inline-block;
    height: 27px;
    width: 27px;
    line-height: 27px;
    color: #fff;
    border-radius: 50%;
    font-weight: bold;
    margin-left: 2px;
}
.cl-item:hover {
    cursor: pointer;
    background-color: #ffff2b;
}
</style>