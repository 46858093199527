import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
export default {
  components: {},
  data: function data() {
    return {
      userinfo: [],
      gamesinfo: []
    };
  },
  created: function created() {
    var _this = this;

    this.userinfo = [this.$store.state.viewdata];
    console.log(this.userinfo[0].group_id);

    if (this.userinfo[0].group_id == 1) {
      this.userinfo[0].group_name = '总公司';
    }

    if (this.userinfo[0].group_id == 2) {
      this.userinfo[0].group_name = '大总监';
    }

    if (this.userinfo[0].group_id == 3) {
      this.userinfo[0].group_name = '总监';
    }

    if (this.userinfo[0].group_id == 4) {
      this.userinfo[0].group_name = '分公司';
    }

    if (this.userinfo[0].group_id == 5) {
      this.userinfo[0].group_name = '大股东';
    }

    if (this.userinfo[0].group_id == 6) {
      this.userinfo[0].group_name = '股东';
    }

    if (this.userinfo[0].group_id == 7) {
      this.userinfo[0].group_name = '总代理';
    }

    if (this.userinfo[0].group_id == 8) {
      this.userinfo[0].group_name = '代理';
    }

    this.userinfo[0].user_id = this.$store.state.userinfo.user_id;
    var list = this.$store.state.viewdata.list;
    list.forEach(function (item) {
      item.list = _this.reSetData(item.list, Math.round(item.list.length / 2));
    });
    this.gamesinfo = list;
  },
  mounted: function mounted() {
    var gids = [];
    this.$store.state.game_list.forEach(function (item) {
      gids.push(item.id);
    });
    this.get_data(gids);
  },
  methods: {
    get_data: function get_data() {
      var _this2 = this;

      var gids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.axios.post("user_version2/index", {
        gids: gids
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.userinfo = [result.data.repsoneContent];

          if (_this2.userinfo[0].group_id == 1) {
            _this2.userinfo[0].group_name = '总公司';
          }

          if (_this2.userinfo[0].group_id == 2) {
            _this2.userinfo[0].group_name = '大总监';
          }

          if (_this2.userinfo[0].group_id == 3) {
            _this2.userinfo[0].group_name = '总监';
          }

          if (_this2.userinfo[0].group_id == 4) {
            _this2.userinfo[0].group_name = '分公司';
          }

          if (_this2.userinfo[0].group_id == 5) {
            _this2.userinfo[0].group_name = '股东';
          }

          if (_this2.userinfo[0].group_id == 6) {
            _this2.userinfo[0].group_name = '股东';
          }

          if (_this2.userinfo[0].group_id == 7) {
            _this2.userinfo[0].group_name = '总代理';
          }

          if (_this2.userinfo[0].group_id == 8) {
            _this2.userinfo[0].group_name = '代理';
          }

          _this2.userinfo[0].user_id = _this2.$store.state.userinfo.user_id;
          var list = result.data.repsoneContent.list;
          list.forEach(function (item) {
            item.list = _this2.reSetData(item.list, Math.round(item.list.length / 2));
          });
          _this2.gamesinfo = list;
        }
      });
    },
    reSetData: function reSetData(data, num) {
      //把一个数组分割成想要的数组长度
      var index = 0;
      var newArray = [];

      while (index < data.length) {
        newArray.push(data.slice(index, index += num));
      }

      return newArray;
    }
  }
};