import axios from '../../../axios/axios';
export default {
  components: {},
  data: function data() {
    return {
      log_data: [],
      pagination: {
        page_size: 20,
        total_pages: 8,
        total_records: ""
      },
      page: 1
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("log/admin_login_log", {
      page: 1,
      limit: 20
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.log_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; // 通过 `vm` 访问组件实例
        });
      }
    });
  },
  methods: {
    get_data: function get_data() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.axios.post("log/admin_login_log", {
        page: page,
        limit: 20
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.log_data = result.data.repsoneContent.list;
          _this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }
  }
};