import axios from '../../../axios/axios';
export default {
  components: {},
  data: function data() {
    return {
      list: [],
      page: 1,
      pagination: {
        total_pages: 0,
        total_records: "0",
        page_size: 10
      },
      setkp_show: false,
      setuser: {
        show: false,
        username: '',
        id: '',
        gname: ''
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("play/prize", {
      page: 1
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },
  created: function created() {},
  methods: {
    set_kp: function set_kp() {
      var _this = this;

      this.axios.post("play/prize", {
        type: 'edit',
        list: this.list
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.setkp_show = false;
        }
      });
    },
    set_kp_user: function set_kp_user() {
      var _this2 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'open';
      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      if (type == 'edit') {
        this.axios.post("play/prize_edit", {
          type: type,
          id: this.setuser.id,
          username: this.setuser.username,
          gname: this.setuser.gname
        }).then(function (result) {
          if (result.data.status == 200) {
            _this2.$message.success(result.data.shortMessage);

            _this2.set_kp_user('close');
          }
        });
      }

      if (type == 'open') {
        this.axios.post("play/prize_edit", {
          id: row.id
        }).then(function (result) {
          if (result.data.status == 200) {
            _this2.setuser.username = result.data.repsoneContent.list.username;
            _this2.setuser.gname = result.data.repsoneContent.list.gname;
            _this2.setuser.id = row.id;
            _this2.setuser.show = true;
          }
        });
      }

      if (type == 'close') {
        this.setuser = {
          show: false,
          username: '',
          id: '',
          gname: ''
        };
      }
    },
    get_list: function get_list() {
      var _this3 = this;

      this.axios.post("play/prize", {
        page: this.page
      }).then(function (result) {
        if (result.data.status == 200) {
          _this3.list = result.data.repsoneContent.list;
          _this3.pagination = result.data.repsoneContent.pagination;
        }
      });
    }
  }
};