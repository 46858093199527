<template>
    <div class="buhuo">
        <template v-if="!dialog.visible">
            <div class="title">
                <span class="username">{{ userinfo.username }}</span>
                <div style="float: right; margin-bottom: 3px">
                    <el-select v-model="game_id" size="small" placeholder="请选择" @change="game_change">
                        <el-option v-for="item in game_list" :key="item.id" :label="item.gname" :value="item.id"></el-option>
                    </el-select>
                    <el-button style="margin-left: 5px" size="small" icon="search" @click.prevent="get_buhuo_log">自动补货变更记录</el-button>
                </div>
            </div>
            <el-table :data="buhuo_data" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="name" label="补货类型" align="center"></el-table-column>
                <el-table-column property="let_type" label="计算方式" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.let_type == '1'">下注额</span>
                        <span v-else>{{ scope.row.let_type }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="control_let" label="控制额度" align="center"></el-table-column>
            </el-table>
        </template>
        <!-- 自动补货变更记录 -->
        <template v-if="dialog.visible">
            <div class="dialog-title">
                <span>自动补货变更记录</span>
                <el-button style="float: right" size="small" @click="dialog.visible = false">关闭</el-button>
            </div>
            <el-table :data="dialog.list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="gname" label="彩种类型" align="center" width="100"></el-table-column>
                <el-table-column property="write_time" label="变更时间" align="center" width="180">
                    <template #default="scope">
                        <span>{{ $filters.time(scope.row.write_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="remark" label="变更说明" align="center"></el-table-column>
                <el-table-column property="old_value" label="原值" align="center" width="80"></el-table-column>
                <el-table-column property="new_value" label="新值" align="center" width="80"></el-table-column>
                <el-table-column property="operator" label="变更人" align="center" width="120"></el-table-column>
                <el-table-column property="ip" label="IP" align="center" width="150"></el-table-column>
            </el-table>
            <el-pagination
                class="paging"
                @current-change="change_page"
                v-model="post_auto.page"
                :page-size="10"
                :pager-count="5"
                :total="dialog.pagination.total_records * 1"
                layout="total,prev,pager,next,jumper"
                prev-text="上一页"
                next-text="下一页"
                hide-on-single-page
            />
        </template>
    </div>
</template>

<script>
export default {
    props: ["userinfo"],
    data() {
        return {
            game_id: "",
            game_list: [],
            buhuo_data: [],
            /**自动补货变更记录 */
            dialog: {
                visible: false,
                list: [],
                pagination: {
                    page_size: 0,
                    total_pages: 0,
                    total_records: 0,
                },
            },
            post_auto: {
                type: "1", //个人管理：0,查看自己补货，用户管理：1查看下级补货
                uid: "",
                lid: "",
                page: 1,
                limit: 10,
            },
        };
    },
    methods: {
        change_page(page) {
            this.post_auto.page = page;
            this.get_buhuo_log();
        },
        /**查看自动补货记录 */
        get_buhuo_log() {
            this.post_auto.uid = this.userinfo.id;
            this.post_auto.lid = this.game_id;
            this.post_auto.db = this.$route.query.db ? this.$route.query.db : this.userinfo.db;
            this.axios.post("user_version2/viewAutoSaleLog", this.post_auto).then((result) => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    this.dialog.list = result.data.repsoneContent.list;
                    this.dialog.pagination = result.data.repsoneContent.pagination;
                    this.dialog.visible = true;
                }
            });
        },
        game_change(gid) {
            this.axios
                .post("user_version2/viewAutoLet", {
                    uid: this.userinfo.id,
                    gid,
                    db: this.$route.query.db ? this.$route.query.db : "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.buhuo_data = result.data.repsoneContent;
                    }
                });
        },
        /**获取彩种补货 */
        get_buhuo_data(data = "") {
            this.game_list = this.$store.state.game_list;
            this.game_id = this.game_list[0].id;
            this.buhuo_data = data;
        },
        /**获取彩种列表 */
        get_game_list(user_id = this.userinfo.id) {
            this.axios.post("main/game_user_list", { user_id: user_id, db: this.$route.query.db ? this.$route.query.db : "" }).then((result) => {
                if (result.data.status == 200) {
                    // console.log(result.data.repsoneContent)
                    this.game_list = result.data.repsoneContent.game_list;
                    if (this.game_list.length > 0) {
                        this.game_id = this.game_list[0].id;
                        this.get_buhuo_data();
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
.title {
    text-align: left;
    font-weight: bold;
    padding: 3px 0;
}

.username {
    color: #f56c6c;
}

.dialog-title {
    text-align: left;
    padding: 10px;
    font-size: 14px;
    color: #333;
    font-weight: bold;
}
</style>
