import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { h } from "vue";
export default {
  props: ["userinfo", "addinfo"],
  data: function data() {
    return {
      usertype: "",
      new_user_info: "",
      top_up: {
        value: "",
        show: false,
        text: ""
      },
      kuaicai_top_up: {
        value: "",
        show: false,
        text: ""
      },
      is_liuhe: 0,
      is_kuaicai: 0,
      remark: "",
      jine_type: "0",
      db: ""
    };
  },
  created: function created() {
    if (this.userinfo.db != undefined && this.userinfo.db != "") {
      this.db = this.userinfo.db;
    } else {
      // console.log(this.$route.query);
      this.db = this.$route.query.db;
    }

    this.usertype = this.userinfo.group_id == 0 ? "会员" : "代理";
    this.get_user_info(this.userinfo.kusaicai_liuhe_status.is_liuhe, this.userinfo.kusaicai_liuhe_status.is_kuaicai);
  },
  mounted: function mounted() {
    this.$refs["top_up_value"].focus(); // console.log(this.$refs);
  },
  methods: {
    reset: function reset() {
      this.top_up.value = "";
      this.kuaicai_top_up.value = "";
    },
    sub_top_up: function sub_top_up() {
      var _this = this;

      if (this.top_up.value == "" && this.kuaicai_top_up.value == "") {
        return;
      }

      if (Number.isNaN(this.kuaicai_top_up.value * 1) || this.kuaicai_top_up.value * 1 < 0) {
        this.kuaicai_top_up.show = true;
        this.kuaicai_top_up.text = "请输入正确的充值金额(快彩)";
        return;
      }

      if (Number.isNaN(this.kuaicai_top_up.value * 1) || this.kuaicai_top_up.value * 1 < 0) {
        this.kuaicai_top_up.show = true;
        this.kuaicai_top_up.text = "请输入正确的充值金额(六合)";
        return;
      }

      if (this.top_up.show || this.kuaicai_top_up.show) {
        return;
      }

      var obj = {
        uid: this.userinfo.id,
        agent_id: this.userinfo.agent_id,
        type: 1,
        pre_username: this.userinfo.parent_name,
        username: this.userinfo.username,
        group_id: this.userinfo.group_id,
        is_liuhe: this.is_liuhe,
        is_kuaicai: this.is_kuaicai,
        remark: this.remark,
        item_id: this.jine_type,
        usdt: this.top_up.value,
        db: this.db
      };

      if (this.is_liuhe == 1) {
        obj.txt_recharge = this.jine_type == 0 ? this.top_up.value : this.jine_type == 52 ? (this.top_up.value * this.new_user_info.usdt).toFixed(0) : "";
      }

      if (this.is_kuaicai == 1) {
        obj.kuaicai_txt_recharge = this.jine_type == 0 ? this.kuaicai_top_up.value : this.jine_type == 52 ? (this.kuaicai_top_up.value * this.new_user_info.usdt).toFixed(0) : "";
      }

      this.$confirm(h("div", null, [obj.kuaicai_txt_recharge * 1 > 0 ? h("p", null, "\u5145\u503C\u5FEB\u5F69\uFF1A".concat(obj.kuaicai_txt_recharge, "\u5143")) : "", obj.txt_recharge * 1 > 0 ? h("p", null, "\u5145\u503C\u516D\u5408\uFF1A".concat(obj.txt_recharge, "\u5143")) : ""]), "\u786E\u5B9A\u7ED9\uFF1A".concat(this.userinfo.username, " \u5145\u503C\u5417\uFF1F"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "myMessageBox",
        customStyle: {
          backgroundColor: this.$store.state.themeColorLight,
          border: " 2px solid",
          borderColor: " ".concat(this.$store.state.themeColor),
          width: "200px"
        }
      }).then(function () {
        _this.axios.post("account/credit_recharge_kc", obj).then(function (result) {
          if (result.data.status == 200) {
            _this.$message.success(result.data.shortMessage);

            _this.get_user_info();

            _this.$emit("other_view_back");
          }
        });
      }).catch(function () {
        _this.$message({
          type: "info",
          message: "已取消操作"
        });
      });
    },
    get_user_info: function get_user_info() {
      var _this2 = this;

      var is_liuhe = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var is_kuaicai = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.is_liuhe = is_liuhe;
      this.is_kuaicai = is_kuaicai;
      this.axios.post("account/credit_recharge_kc", {
        uid: this.userinfo.id,
        agent_id: this.userinfo.agent_id,
        type: "",
        group_id: this.userinfo.group_id,
        db: this.db
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.new_user_info = result.data.repsoneContent;
        }
      });
    }
  },
  watch: {
    "top_up.value": function top_up(newVal, oldVal) {
      if (this.jine_type == 0) {
        if (newVal > this.new_user_info.pre_balance * 1) {
          this.top_up.show = true;
          this.top_up.text = "不能超过最大金额：" + this.new_user_info.pre_balance;
        } else {
          this.top_up.show = false;
        }
      } else if (this.jine_type == 52) {
        if (newVal * this.new_user_info.usdt > this.new_user_info.pre_balance * 1) {
          this.top_up.show = true;
          this.top_up.text = "不能超过最大金额：" + this.new_user_info.pre_balance;
        } else {
          this.top_up.show = false;
        }
      }
    },
    "kuaicai_top_up.value": function kuaicai_top_up(newVal, oldVal) {
      if (this.jine_type == 0) {
        if (newVal > this.new_user_info.kuaicai_pre_balance * 1) {
          this.kuaicai_top_up.show = true;
          this.kuaicai_top_up.text = "不能超过最大金额：" + this.new_user_info.kuaicai_pre_balance;
        } else {
          this.kuaicai_top_up.show = false;
        }
      } else if (this.jine_type == 52) {
        if (newVal * this.new_user_info.usdt > this.new_user_info.kuaicai_pre_balance * 1) {
          this.kuaicai_top_up.show = true;
          this.kuaicai_top_up.text = "不能超过最大金额：" + this.new_user_info.kuaicai_pre_balance;
        } else {
          this.kuaicai_top_up.show = false;
        }
      }
    }
  }
};