import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";

/**六合彩生肖计算 */
// type：生成那种情况下的生肖
//arr，每个元素带有num,sx属性
export function get_lhc_sx(type, arr) {
  var idx = 0;
  var sxArr = ['猴', '鸡', '狗', '猪', '鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊'];

  if (type == "开奖") {
    for (var i = 0; i < arr.length; i++) {
      idx = arr[i].num; //计算生肖排序

      var time = arr[i].lottery_open.split('-');

      if (new Date("".concat(time[0], "-").concat(time[1], "-").concat(time[2])).getTime() < 1707494400000) {
        switch (Number(idx)) {
          case 12:
          case 24:
          case 36:
          case 48:
            arr[i].sx = '虎';
            break;

          case 11:
          case 23:
          case 35:
          case 47:
            arr[i].sx = '牛';
            break;

          case 10:
          case 22:
          case 34:
          case 46:
            arr[i].sx = '鼠';
            break;

          case 9:
          case 21:
          case 33:
          case 45:
            arr[i].sx = '猪';
            break;

          case 8:
          case 20:
          case 32:
          case 44:
            arr[i].sx = '狗';
            break;

          case 7:
          case 19:
          case 31:
          case 43:
            arr[i].sx = '鸡';
            break;

          case 6:
          case 18:
          case 30:
          case 42:
            arr[i].sx = '猴';
            break;

          case 5:
          case 17:
          case 29:
          case 41:
            arr[i].sx = '羊';
            break;

          case 4:
          case 16:
          case 28:
          case 40:
            arr[i].sx = '马';
            break;

          case 3:
          case 15:
          case 27:
          case 39:
            arr[i].sx = '蛇';
            break;

          case 2:
          case 14:
          case 26:
          case 38:
            arr[i].sx = '龙';
            break;

          case 1:
          case 13:
          case 25:
          case 37:
          case 49:
            arr[i].sx = '兔';
            break;
        }
      } else {
        switch (Number(idx)) {
          case 12:
          case 24:
          case 36:
          case 48:
            arr[i].sx = '蛇';
            break;

          case 11:
          case 23:
          case 35:
          case 47:
            arr[i].sx = '马';
            break;

          case 10:
          case 22:
          case 34:
          case 46:
            arr[i].sx = '羊';
            break;

          case 9:
          case 21:
          case 33:
          case 45:
            arr[i].sx = '猴';
            break;

          case 8:
          case 20:
          case 32:
          case 44:
            arr[i].sx = '鸡';
            break;

          case 7:
          case 19:
          case 31:
          case 43:
            arr[i].sx = '狗';
            break;

          case 6:
          case 18:
          case 30:
          case 42:
            arr[i].sx = '猪';
            break;

          case 5:
          case 17:
          case 29:
          case 41:
            arr[i].sx = '鼠';
            break;

          case 4:
          case 16:
          case 28:
          case 40:
            arr[i].sx = '牛';
            break;

          case 3:
          case 15:
          case 27:
          case 39:
            arr[i].sx = '虎';
            break;

          case 2:
          case 14:
          case 26:
          case 38:
            arr[i].sx = '兔';
            break;

          case 1:
          case 13:
          case 25:
          case 37:
          case 49:
            arr[i].sx = '龙';
            break;
        }
      }
    }
  }

  if (type == "初始化") {
    console.log(arr);

    for (var _i = 0; _i < arr.length; _i++) {
      for (var j = 0; j < arr[_i].betclass.length; j++) {
        for (var l = 0; l < arr[_i].betclass[j].betclass_nums.length; l++) {
          idx = arr[_i].betclass[j].betclass_nums[l].num;

          switch (Number(idx)) {
            case 12:
            case 24:
            case 36:
            case 48:
              arr[_i].betclass[j].name = '蛇';
              break;

            case 11:
            case 23:
            case 35:
            case 47:
              arr[_i].betclass[j].name = '马';
              break;

            case 10:
            case 22:
            case 34:
            case 46:
              arr[_i].betclass[j].name = '羊';
              break;

            case 9:
            case 21:
            case 33:
            case 45:
              arr[_i].betclass[j].name = '猴';
              break;

            case 8:
            case 20:
            case 32:
            case 44:
              arr[_i].betclass[j].name = '鸡';
              break;

            case 7:
            case 19:
            case 31:
            case 43:
              arr[_i].betclass[j].name = '狗';
              break;

            case 6:
            case 18:
            case 30:
            case 42:
              arr[_i].betclass[j].name = '猪';
              break;

            case 5:
            case 17:
            case 29:
            case 41:
              arr[_i].betclass[j].name = '鼠';
              break;

            case 4:
            case 16:
            case 28:
            case 40:
              arr[_i].betclass[j].name = '牛';
              break;

            case 3:
            case 15:
            case 27:
            case 39:
              arr[_i].betclass[j].name = '虎';
              break;

            case 2:
            case 14:
            case 26:
            case 38:
              arr[_i].betclass[j].name = '兔';
              break;

            case 1:
            case 13:
            case 25:
            case 37:
            case 49:
              arr[_i].betclass[j].name = '龙';
              break;
          }
        }
      }
    }
  }

  if (type == "快速下注初始化") {
    // console.log(arr)
    for (var _i2 = 0; _i2 < arr.length; _i2++) {
      if (arr[_i2].title == '生肖') {
        for (var _j = 0; _j < arr[_i2].nums.length; _j++) {
          idx = arr[_i2].nums[_j];

          switch (Number(idx)) {
            case 12:
            case 24:
            case 36:
            case 48:
              arr[_i2].betclass[_j].name = '蛇';
              break;

            case 11:
            case 23:
            case 35:
            case 47:
              arr[_i2].betclass[_j].name = '马';
              break;

            case 10:
            case 22:
            case 34:
            case 46:
              arr[_i2].betclass[_j].name = '羊';
              break;

            case 9:
            case 21:
            case 33:
            case 45:
              arr[_i2].betclass[_j].name = '猴';
              break;

            case 8:
            case 20:
            case 32:
            case 44:
              arr[_i2].betclass[_j].name = '鸡';
              break;

            case 7:
            case 19:
            case 31:
            case 43:
              arr[_i2].betclass[_j].name = '狗';
              break;

            case 6:
            case 18:
            case 30:
            case 42:
              arr[_i2].betclass[_j].name = '猪';
              break;

            case 5:
            case 17:
            case 29:
            case 41:
              arr[_i2].betclass[_j].name = '鼠';
              break;

            case 4:
            case 16:
            case 28:
            case 40:
              arr[_i2].betclass[_j].name = '牛';
              break;

            case 3:
            case 15:
            case 27:
            case 39:
              arr[_i2].betclass[_j].name = '虎';
              break;

            case 2:
            case 14:
            case 26:
            case 38:
              arr[_i2].betclass[_j].name = '兔';
              break;

            case 1:
            case 13:
            case 25:
            case 37:
            case 49:
              arr[_i2].betclass[_j].name = '龙';
              break;
          }
        }
      }
    }
  }

  return arr;
}