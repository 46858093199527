export default {
  data: function data() {
    return {
      ips: ["", ""]
    };
  },
  created: function created() {
    this.get_ips();
  },
  methods: {
    get_ips: function get_ips() {
      var _this = this;

      this.axios.post("user_version2/admin_ip").then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent);
          _this.ips = result.data.repsoneContent.allow_ip;
        }
      });
    },
    sub: function sub() {
      var _this2 = this;

      this.axios.post("user_version2/admin_ip", {
        type: "edit",
        ips: this.ips
      }).then(function (result) {
        if (result.data.status == 200) {
          _this2.$message.success(result.data.shortMessage);
        }
      });
    }
  }
};