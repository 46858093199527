import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-12-25 13:24:09
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-25 16:22:39
 * @FilePath: \pc_admin\src\filters\inputForNumber.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**
 * 格式化数字输入，允许输入大于0的数字和小数
 * @param {string} value - 输入值
 * @returns {string} - 格式化后的值
 */
export var inputForNumber = function inputForNumber(value) {
  var type_text = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '大于0的数';
  if (!value) return ''; // 大于0的数，允许小数

  if (type_text == '大于0的数') {
    value = value.replace(/[^\d.]/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/^(\d*\.\d*)\./, '$1'); // 特殊处理：允许输入"0"、"0."和"."作为开始

    if (value === '0' || value === '0.' || value === '.') {
      return value;
    } // 转换为数字进行比较


    var num = parseFloat(value); // 确保大于0

    return !isNaN(num) && num > 0 ? value : '';
  }

  if (type_text == '大于0的整数') {
    value = value.replace(/[^\d]/g, '');
    return !isNaN(value) && value > 0 ? value : '';
  }
};
export default {
  inputForNumber: inputForNumber
};