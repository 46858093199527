import axios from '../../../axios/axios';
export default {
  data: function data() {
    return {
      log_data: [],
      page: 1,
      active_gid: '',
      game_list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("log/system_log", {
      page: 1,
      gid: to.query.gid
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.game_list = vm.$store.state.game_list;
          vm.active_gid = to.query.gid;
          vm.log_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; //自动降赔率额度 and 每次降赔率设定

          for (var i = 0; i < vm.log_data.length; i++) {
            if (vm.log_data[i].operation_type == "1") {
              vm.log_data[i].old_value.old_auto = vm.log_data[i].old_value.lower_rate[2];
              vm.log_data[i].old_value.old_every = vm.log_data[i].old_value.lower_rate[3];
              vm.log_data[i].new_value.new_auto = vm.log_data[i].new_value.lower_rate[2];
              vm.log_data[i].new_value.new_every = vm.log_data[i].new_value.lower_rate[3];
            }
          }
        });
      }
    });
  },
  methods: {
    change_game: function change_game(gid) {
      console.log(gid);
      this.active_gid = gid;
      this.get_system_log(1);
    },
    get_system_log: function get_system_log() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.axios.post("log/system_log", {
        gid: this.active_gid,
        page: page
      }).then(function (result) {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          _this.log_data = result.data.repsoneContent.list;
          _this.pagination = result.data.repsoneContent.pagination; //自动降赔率额度 and 每次降赔率设定

          for (var i = 0; i < _this.log_data.length; i++) {
            if (_this.log_data[i].operation_type == "1") {
              // console.log(this.log_data[i].old_value.lower_rate)
              _this.log_data[i].old_value.old_auto = _this.log_data[i].old_value.lower_rate[2];
              _this.log_data[i].old_value.old_every = _this.log_data[i].old_value.lower_rate[3];
              _this.log_data[i].new_value.new_auto = _this.log_data[i].new_value.lower_rate[2];
              _this.log_data[i].new_value.new_every = _this.log_data[i].new_value.lower_rate[3];
            }
          }
        }
      });
    },
    get_game_list: function get_game_list() {
      var _this2 = this;

      this.axios.post("main/game_list").then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this2.game_list = result.data.repsoneContent.game_list;
          _this2.active_gid = _this2.game_list[0].id;

          _this2.get_system_log();
        }
      });
    }
  }
};