import _createForOfIteratorHelper from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
export default {
  props: ["userinfo", "add_user_data"],
  data: function data() {
    return {
      user_data: {
        pre_user_info: {
          username: "",
          level_id: ""
        },
        user_info: {
          group_id: "",
          user_play_status: ""
        },
        sup_occupy_fly: "0",
        com_odds: [1900, 1990]
      },
      nickname: "",
      //昵称
      level_id: [],
      //盘口
      pwd: {
        value: "",
        is_new_null: false,
        //新密码是否为空
        title: "“请输入8-20个字符！",
        //新密码错误提示语
        pwdStrengthColor: {
          //密码强度颜色
          c1: "#fff",
          c2: "#fff",
          c3: "#fff"
        }
      },
      allow_ip: "",
      // userCashPassword:"",
      transfer_status_show: 0,
      //现金模式显示
      transfer_status: "0",
      //现金模式
      moneyinout_status: "0",
      //是否出入款
      is_inmoney: 0,
      //是否显示入款统计
      autolet_status_show: 0,
      autolet_status: 0,
      //补货功能开关
      trader_status: false,
      occupy_status: "0",
      //是否占成
      allowmaxrate_kc: "0",
      //限占1，任占0
      lowmaxrate_kc: "",
      //限占值
      play_list: [{
        old_occupy: ""
      }],
      //游戏列表占成设置
      trader_diff_user: 0,
      //是否显示赚取赔率差
      trader_diff: {
        //赚取赔率差
        status: "0",
        diff: ["", ""]
      },
      add_oc_url: "0",
      note: "",
      //公司名称
      posttime: "",
      fast_game_set_value: "",
      //游戏占成快速设置值
      // sup_occupy_value: '',//自身占成
      sup_occupy_lock: "",
      //锁定自身占成
      is_extract: "0",
      //提现开关，1开0关
      flag_D: 0,
      //拖动条返点检测高于下级确认
      sup_occupy_catch: "1",
      //拦截下级补货开关
      // is_occupy_status: '0',//是否显示拦截下级补货开关
      is_manlet: "0",
      //修改2级及以下时手动补货
      show_is_manlet: false,
      //根据所有六合彩状态判断是否显示手补
      bill_reset: "0",
      //额度模式
      kuaicai_bill_reset: "0",
      //额度模式
      check_time: "",
      // add_group_id: '',
      allGamesSet: {
        occupy: "",
        game_list_status: ""
      },
      //快彩----------------------
      kuaicai_level_id: [],
      //盘口
      kuaicai_sup_occupy_lock: "",
      //锁定自身占成
      kuaicai_allowmaxrate_kc: "0",
      //限占1，任占0
      kuaicai_lowmaxrate_kc: "",
      //限占值
      auto_manlet_status: "0",
      show_bh: 0,
      intercept_proportion_status: "0",
      //拦截占余
      //dividend: "0", //分红拦截
      //kuaicai_dividend: "0",
      remainder: "",
      //占余成数归:0占成归自身,1限制下线占成数
      kuaicai_remainder: "",
      userState: "",
      err: {
        nickname: {
          visible: false,
          content: ""
        },
        pwd: {
          visible: false,
          content: ""
        },
        sup_occupy_lock: {
          visible: false,
          content: ""
        },
        lowmaxrate_kc: {
          visible: false,
          content: ""
        },
        kuaicai_sup_occupy_lock: {
          visible: false,
          content: ""
        },
        kuaicai_lowmaxrate_kc: {
          visible: false,
          content: ""
        },
        check_time: {
          visible: false,
          content: ""
        }
      },
      user_play_status: "",
      user_play_status_list: [],
      show_kuaicai: true,
      show_liuhe: true,
      db: "",
      lh_or_dpc: "六合"
    };
  },
  methods: {
    change_user_play_status_list: function change_user_play_status_list(list) {
      if (list.length < 1) {
        return this.$message.error("至少选择一个彩种");
      }

      if (list.length == 1 && list.includes("7") || list.length == 2 && list.includes("1") && list.includes("7")) {
        this.lh_or_dpc = "低频彩";
      } else {
        this.lh_or_dpc = "六合";

        if (this.user_data.user_info.level_id.length > 1) {
          this.level_id = this.user_data.user_info.level_id.split(",");
        } else {
          this.level_id = [this.user_data.user_info.level_id.toString()];
        }
      }
    },
    change_kuaicai_pk: function change_kuaicai_pk(list) {
      if (list.includes("0") && list[list.length - 1] != "0") {
        this.kuaicai_level_id.shift();
      } else if (list[list.length - 1] == "0") {
        this.kuaicai_level_id = ["0"];
      }
    },
    change_pk: function change_pk(list) {
      if (list.includes("0") && list[list.length - 1] != "0") {
        this.level_id.shift();
      } else if (list[list.length - 1] == "0") {
        this.level_id = ["0"];
      }
    },
    change_rzxz_lhc: function change_rzxz_lhc(s) {
      if (s == "0") {
        this.lowmaxrate_kc = this.user_data.user_info.proportion * 1;
      } else {
        if (this.user_data.pre_user_info.group_id > 2) {
          this.lowmaxrate_kc = this.user_data.pre_user_info.max_sup_lock * 1 - this.sup_occupy_lock * 1;
        } else {
          this.lowmaxrate_kc = this.user_data.pre_user_info.max_sup_lock * 1;
        }
      }
    },
    change_rzxz_kc: function change_rzxz_kc(s) {
      if (s == "0") {
        this.kuaicai_lowmaxrate_kc = this.user_data.user_info.kuaicai_proportion * 1;
      } else {
        if (this.user_data.pre_user_info.group_id > 2) {
          this.kuaicai_lowmaxrate_kc = this.user_data.pre_user_info.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
        } else {
          this.kuaicai_lowmaxrate_kc = this.user_data.pre_user_info.kuaicai_max_sup_lock * 1;
        }
      }
    },
    changhe_occupy_status: function changhe_occupy_status(s) {
      this.show_bh = s;
    },
    //游戏占成快速设置
    fast_game_set: function fast_game_set(value) {
      var _iterator = _createForOfIteratorHelper(this.play_list),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          item.power_status = this.allGamesSet.power_status;
          item.occupy = value;

          if (item.occupy >= value) {
            item.occupy = value;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    formatTooltip: function formatTooltip(value) {
      return (value / 200).toFixed(4);
    },

    /**获取用户新增菜单选项，权限 */
    get_user_edit: function get_user_edit(data) {
      var db = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      this.db = this.$route.query.db != undefined ? this.$route.query.db : db;
      this.nickname = this.userinfo.nickname;
      data.user_info.D_odds *= 1;
      data.com_odds[0] *= 1;
      data.com_odds[1] *= 1;
      this.level_id = data.user_info.level_id.split(",");
      this.allow_ip = data.user_info.allow_ip;
      this.autolet_status = data.user_info.autolet_status;
      this.sup_occupy_catch = data.user_info.sup_occupy_catch;
      this.moneyinout_status = data.user_info.moneyinout_status;
      this.trader_diff_user = data.user_info.trader_diff_user;
      this.trader_status = data.user_info.trader_status;
      this.transfer_status = data.user_info.transfer_status;
      this.is_inmoney = data.user_info.is_inmoney;
      this.userState = data.user_info.status;
      this.check_time = data.user_info.check_time; // this.occupy_status = data.user_info.occupy_status;

      this.occupy_status = "1";
      this.lowmaxrate_kc = data.user_info.proportion * 1 > data.pre_user_info.max_sup_lock * 1 ? data.pre_user_info.max_sup_lock : data.user_info.proportion;
      this.allGamesSet.game_list_status = data.user_info.game_list_status;
      this.allowmaxrate_kc = data.user_info.proportion_status == 0 ? "1" : "0"; //限占任占开关

      if (this.add_user_data.add_oc_url == 1) {
        this.add_oc_url = this.add_user_data.add_oc_url;
        this.note = data.user_info.note;
      } // this.play_list = data.play_list.filter(item => item.old_occupy_status == 1);


      this.play_list = data.play_list.filter(function (item) {
        return item.old_occupy_status == 1 || item.old_occupy_status == 2;
      }); // 所有六合彩打开才显示手补-------->

      var lhc = this.play_list.find(function (item) {
        return item.gname == "六合彩";
      });
      var amlhc = this.play_list.find(function (item) {
        return item.gname == "澳门六合彩";
      });

      if (lhc && lhc.occupy_status != undefined && lhc.occupy_status == 1 || amlhc && amlhc.occupy_status != undefined && amlhc.occupy_status == 1) {
        this.show_is_manlet = true;
      } else {
        this.show_is_manlet = false;
      } //------------------------------<


      this.bill_reset = data.user_info.bill_reset;
      this.kuaicai_bill_reset = data.user_info.kuaicai_bill_reset;
      this.sup_occupy_lock = data.user_info.sup_occupy_lock != undefined ? data.user_info.sup_occupy_lock * 1 > data.pre_user_info.max_sup_lock * 1 ? data.pre_user_info.max_sup_lock : data.user_info.sup_occupy_lock : "";

      if (data.user_info.trader_diff != undefined && data.user_info.trader_diff.status === "1") {
        this.trader_diff.status = data.user_info.trader_diff.status;
        this.trader_diff.diff = data.user_info.trader_diff.diff.split(",");
      }

      this.is_extract = data.user_info.extract_bill; // console.log(this.trader_diff.diff);

      this.user_data = data; // if (this.userKind_kc.includes('0') || this.userKind_kc.includes('4')) {
      //     this.old_D_odds = this.user_data.user_info.D_odds;
      //     this.old_sltDrawback_D = ((this.user_data.max_rebate / (this.user_data.com_odds[1] - this.user_data.com_odds[0])) * (this.user_data.user_info.D_odds - this.user_data.com_odds[0])).toFixed(3);
      // }

      if (this.$store.state.userinfo.group_id > 1) {
        this.is_manlet = data.user_info.is_manlet;
      } //快彩------------------------------>


      this.kuaicai_level_id = data.user_info.kuaicai_level_id.split(",");

      if (data.user_info.kuaicai_proportion * 1 > data.pre_user_info.kuaicai_max_sup_lock * 1) {
        this.kuaicai_lowmaxrate_kc = data.pre_user_info.kuaicai_max_sup_lock;
      } else {
        this.kuaicai_lowmaxrate_kc = data.user_info.kuaicai_proportion;
      }

      this.kuaicai_allowmaxrate_kc = data.user_info.kuaicai_proportion_status == 0 ? "1" : "0"; //限占任占开关

      if (data.user_info.kuaicai_sup_occupy_lock != undefined) {
        if (data.user_info.kuaicai_sup_occupy_lock * 1 > data.pre_user_info.kuaicai_max_sup_lock * 1) {
          this.kuaicai_sup_occupy_lock = data.pre_user_info.kuaicai_max_sup_lock;
        } else {
          this.kuaicai_sup_occupy_lock = data.user_info.kuaicai_sup_occupy_lock;
        }
      }

      if (this.user_data.pre_user_info.group_id > 0 && this.kuaicai_lowmaxrate_kc > this.user_data.pre_user_info.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1) {
        this.kuaicai_lowmaxrate_kc = this.user_data.pre_user_info.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
      }

      if (this.occupy_status == 1) {
        this.show_bh = 1;
      }

      if (this.show_bh == 1) {
        this.auto_manlet_status = this.user_data.user_info.auto_manlet_status + "";
      }

      this.intercept_proportion_status = this.user_data.user_info.intercept_proportion_status;
      this.remainder = this.user_data.user_info.remainder;
      this.kuaicai_remainder = this.user_data.user_info.kuaicai_remainder; // this.dividend = this.user_data.user_info.dividend;
      // this.kuaicai_dividend = this.user_data.user_info.kuaicai_dividend;

      this.user_data.user_info.status_kuaicai == 1 ? this.user_play_status_list.push("1") : "";
      this.user_data.user_info.status_liuhe == 1 ? this.user_play_status_list.push("2") : "";
      this.user_data.user_info.status_aomen == 1 ? this.user_play_status_list.push("3") : "";
      this.user_data.user_info.status_xinaomen == 1 ? this.user_play_status_list.push("4") : "";
      this.user_data.user_info.status_taiwan == 1 ? this.user_play_status_list.push("5") : "";
      this.user_data.user_info.status_kuaile8 == 1 ? this.user_play_status_list.push("6") : "";
      this.user_data.user_info.status_pailie5 == 1 ? this.user_play_status_list.push("7") : "";
    },
    // 拖动条返点检测
    user_rebate_check: function user_rebate_check(value) {
      var _this = this;

      this.axios.post("user_version2/user_rebate_check", {
        uid: this.userinfo.id,
        group_id: this.userinfo.group_id,
        D_odds: value
      }).then(function (result) {
        if (result.data.status == 200) {} else {
          if (result.data.shortMessage == "下级有高于当前设置") {
            if (_this.$store.state.userinfo.group_id > 2) {
              _this.flag_D = 0;
              _this.user_data.user_info.D_odds = _this.old_D_odds;

              _this.$alert("下级有高于当前设置", "错误提示", {
                confirmButtonText: "确定",
                type: "error"
              });
            } else {
              var p = (_this.user_data.max_rebate / (_this.user_data.com_odds[1] - _this.user_data.com_odds[0]) * (_this.user_data.user_info.D_odds - _this.user_data.com_odds[0])).toFixed(3);

              _this.$confirm("\u662F\u5426\u786E\u8BA4\u6240\u6709\u4E0B\u7EA7\u9AD8\u4E8E ".concat(p, "% \u7684\u4E00\u6837\u62C9\u5230\u76F8\u540C\u8FD4\u70B9"), "提示:下级有高于当前设置", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }).then(function () {
                _this.flag_D = 1;
              }).catch(function () {
                _this.flag_D = 0;
                _this.user_data.user_info.D_odds = _this.old_D_odds;

                _this.$message({
                  type: "info",
                  message: "已取消操作"
                });
              });
            }
          } else {
            _this.$alert(result.data.shortMessage, "错误提示", {
              confirmButtonText: "确定",
              type: "error"
            });
          }
        }
      });
    },

    /**提交修改 */
    post_change: function post_change() {
      var _this2 = this;

      if (this.nickname.length < 1) {
        return this.err.nickname = {
          visible: true,
          content: "名称最少1个字符"
        };
      }

      if (this.pwd.value !== "" && !this.$filters.passwordReg(this.pwd.value)) {
        return this.err.pwd = {
          visible: true,
          content: "密码必须包含：大写字母、小写字母、数字，3种组合，8-20位"
        };
      }

      if (this.user_data.pre_user_info.occupy_status == 1 && this.occupy_status == 1) {
        if (this.user_play_status_list.includes("2") || this.user_play_status_list.includes("3") || this.user_play_status_list.includes("4") || this.user_play_status_list.includes("5") || this.user_play_status_list.includes("6") || this.user_play_status_list.includes("7")) {
          if (this.sup_occupy_lock < 0 || this.sup_occupy_lock === "") {
            return this.err.sup_occupy_lock = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5C0F\u4E8E0")
            };
          }

          if (this.lowmaxrate_kc * 1 < 0) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5C0F\u4E8E0")
            };
          }

          if (this.sup_occupy_lock * 1 > this.user_data.pre_user_info.max_sup_lock * 1) {
            return this.err.sup_occupy_lock = {
              visible: true,
              content: "".concat(this.lh_or_dpc, "\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5927\u4E8E ").concat(this.user_data.pre_user_info.max_sup_lock, "%")
            };
          }

          if (this.user_data.pre_user_info.group_id > 0) {
            if (this.lowmaxrate_kc * 1 > this.user_data.pre_user_info.max_sup_lock - this.sup_occupy_lock) {
              this.err.lowmaxrate_kc = {
                visible: true,
                content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ").concat(this.user_data.pre_user_info.max_sup_lock * 1 - this.sup_occupy_lock * 1, "%")
              };
              return;
            } else {}
          } else {
            if (this.lowmaxrate_kc * 1 > this.user_data.pre_user_info.max_sup_lock * 1) {
              return this.err.lowmaxrate_kc = {
                visible: true,
                content: "".concat(this.lh_or_dpc, "\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ").concat(this.user_data.pre_user_info.max_sup_lock, "%")
              };
            }
          }
        } // 快彩------------------


        if (this.user_play_status == "kc") {
          if (this.kuaicai_sup_occupy_lock < 0 || this.kuaicai_sup_occupy_lock === "") {
            return this.err.kuaicai_sup_occupy_lock = {
              visible: true,
              content: "快彩保留占成不能小于0"
            };
          }

          if (this.kuaicai_lowmaxrate_kc * 1 < 0) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: "快彩限制下线可占成数不能小于0"
            };
          }

          if (this.kuaicai_sup_occupy_lock * 1 > this.user_data.pre_user_info.kuaicai_max_sup_lock * 1) {
            return this.err.kuaicai_sup_occupy_lock = {
              visible: true,
              content: "\u5FEB\u5F69\u4FDD\u7559\u5360\u6210\u4E0D\u80FD\u5927\u4E8E ".concat(this.user_data.pre_user_info.kuaicai_max_sup_lock, "%")
            };
          }

          if (this.user_data.pre_user_info.group_id > 0) {
            if (this.kuaicai_lowmaxrate_kc * 1 > this.user_data.pre_user_info.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1) {
              return this.err.kuaicai_lowmaxrate_kc = {
                visible: true,
                content: "\u5FEB\u5F69\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ".concat(this.user_data.pre_user_info.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1, "%")
              };
            }
          } else {
            if (this.kuaicai_lowmaxrate_kc * 1 > this.user_data.pre_user_info.kuaicai_max_sup_lock * 1) {
              return this.err.kuaicai_lowmaxrate_kc = {
                visible: true,
                content: "\u5FEB\u5F69\u9650\u5236\u4E0B\u7EBF\u53EF\u5360\u6210\u6570\u4E0D\u80FD\u5927\u4E8E ".concat(this.user_data.pre_user_info.kuaicai_max_sup_lock, "%")
              };
            }
          }
        }
      }

      if (this.check_time * 1 < 0) {
        return this.err.check_time = {
          visible: true,
          content: "提前封盘时间不能小于0"
        };
      }

      var game_change_list = []; //生成每个游戏占成修改的数组数据

      if (this.user_data.pre_user_info.occupy_status == 1 && this.occupy_status == 1) {
        for (var i = 0; i < this.play_list.length; i++) {
          game_change_list.push({
            id: this.play_list[i].id,
            power_status: this.play_list[i].power_status,
            // occupy_status: this.play_list[i].occupy_status,
            occupy_status: 1,
            // occupy: this.play_list[i].occupy,
            occupy: "kong",
            oid: this.play_list[i].oid,
            old_occupy: this.play_list[i].old_occupy
          });
        }
      } // console.log(this.user_data);


      var obj = {
        uid: this.user_data.user_info.id,
        par_uid: this.user_data.pre_user_info.id,
        agent_mark: this.user_data.user_info.agent_mark,
        group_id: this.userinfo.group_id,
        userNicker: this.nickname,
        userPassword: this.pwd.value,
        allow_ip: this.allow_ip,
        userKind_kc: this.level_id,
        autolet_status: this.autolet_status,
        trader_status: Number(this.trader_status),
        transfer_status: this.transfer_status,
        moneyinout_status: this.moneyinout_status,
        is_inmoney: this.is_inmoney,
        userAllowSale_kc: Number(this.autolet_status),
        allowmaxrate_kc: this.allowmaxrate_kc,
        lowmaxrate_kc: this.lowmaxrate_kc,
        userzhan_kc: this.occupy_status,
        userState: this.userState,
        check_time: this.check_time,
        game_change_list: game_change_list,
        type: "change_agency_info",
        note: this.note,
        sup_occupy_fly: 1,
        flag_D: this.flag_D,
        sup_occupy_catch: 1,
        bill_reset: this.bill_reset,
        kuaicai_bill_reset: this.kuaicai_bill_reset,
        sup_occupy_lock: this.sup_occupy_lock,
        game_list_status: this.allGamesSet.game_list_status,
        auto_manlet_status: this.auto_manlet_status,
        intercept_proportion_status: this.intercept_proportion_status,
        max_sup_occupy_lock: this.user_data.pre_user_info.max_sup_lock,
        //快彩----------
        kuaicai_userKind_kc: this.kuaicai_level_id,
        kuaicai_allowmaxrate_kc: this.kuaicai_allowmaxrate_kc,
        kuaicai_lowmaxrate_kc: this.kuaicai_lowmaxrate_kc,
        kuaicai_sup_occupy_lock: this.kuaicai_sup_occupy_lock,
        old_data: this.user_data,
        max_kuaicai_sup_occupy_lock: this.user_data.pre_user_info.kuaicai_max_sup_lock,
        //占余成数归
        remainder: this.remainder,
        kuaicai_remainder: this.kuaicai_remainder,
        // dividend: this.dividend,
        // kuaicai_dividend: this.kuaicai_dividend,
        user_play_status: this.user_play_status == "kc" ? 1 : this.user_play_status_list.toString(),
        db: this.$route.query.db ? this.$route.query.db : this.db
      };

      if (this.trader_diff.status === "1") {
        obj.trader_diff = {
          status: "1",
          diff: this.trader_diff.diff.toString()
        };
      } else {
        obj.trader_diff = {
          status: "0",
          diff: ""
        };
      }

      if (this.level_id.includes("0") || this.level_id.includes("4")) {
        obj.D_odds = this.user_data.user_info.D_odds;
        var sltDrawback_D = (this.user_data.max_rebate / (this.user_data.com_odds[1] - this.user_data.com_odds[0]) * (this.user_data.user_info.D_odds - this.user_data.com_odds[0])).toFixed(3); // console.log(this.old_sltDrawback_D,sltDrawback_D);

        if (this.old_sltDrawback_D != sltDrawback_D) {
          obj.sltDrawback_D = sltDrawback_D;
        }
      }

      if (this.posttime) {
        obj.posttime = this.posttime;
        obj.value_status = 1;
      }

      if (this.extract_bill == 1) {
        obj.extract_bill = this.add_user_data.is_extract;
      }

      if (this.$store.state.userinfo.group_id > 1) {
        obj.is_manlet = this.is_manlet;
      }

      this.axios.post("user_version2/member_save", obj).then(function (result) {
        if (result.data.status == 200) {
          _this2.$message.success(result.data.shortMessage);

          _this2.$emit("other_view_back");
        }
      });
    },

    /**修改成功和取消修改，上报父级 */
    finish_cancel: function finish_cancel() {
      this.$emit("other_view_back", "");
    },
    get_data: function get_data(data) {}
  }
};