<!--
 * @Author: your name
 * @Date: 2022-03-22 23:54:01
 * @LastEditTime: 2024-04-22 16:29:57
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\adduser\addZizhanghao.vue
-->
<template>
    <div class="add-sub">
        <div class="myform">
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="2">账号</el-col>
                <el-col class="myform-item-value" :span="22">
                    <el-popover :visible="err.name.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="name" size="small" placeholder="请输入登录账号" clearable @focus="err.name.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.name.content }}</span>
                        </span>
                    </el-popover>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="2">名称</el-col>
                <el-col class="myform-item-value" :span="22">
                    <el-popover :visible="err.uname.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="uname" size="small" placeholder="请输入名称" clearable @focus="err.uname.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.uname.content }}</span>
                        </span>
                    </el-popover>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="2">密码</el-col>
                <el-col class="myform-item-value" :span="22">
                    <el-popover :visible="err.pwd.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="pwd" size="small" placeholder="请输入登录密码" clearable show-password type="password" @focus="err.pwd.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.pwd.content }}</span>
                        </span>
                    </el-popover>
                    <div style="display: inline-block">
                        <span>&nbsp;大写字母、小写字母、特殊字符、数字，4种组合，8-20位</span>
                        <el-popover placement="right" :width="320">
                            <template #reference>
                                <el-button style="margin: 0 0 0 10px; width: 35px" type="warning" plain size="small">说明</el-button>
                            </template>
                            <div style="color: #000">
                                <div>小写字母：a-z</div>
                                <div>大写字母：A-Z</div>
                                <div>数字：0-9</div>
                                <div>特殊字符：~!@#$%^&*()_+-?&lt;&gt;:.,</div>
                                <div style="color: #f56c6c">注：上面可用做密码的特殊字符为英文字符，输入时请注意切换输入法为英文，其他字符将不会通过验证</div>
                            </div>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- 绑定IP -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="2">绑定IP</el-col>
                <el-col class="myform-item-value" :span="22">
                    <el-input style="width: 140px" v-model.trim="allow_ip" size="small" placeholder="请输入IP地址" clearable />
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="2">功能权限</el-col>
                <el-col class="myform-item-value" :span="22" style="padding: 10px; text-align: center">
                    <el-row class="theme-border-half fz" v-for="fz in menu" :key="fz.name">
                        <el-col :span="3" :style="{ 'line-height': fz.mk.length * 30 - 6 + 'px' }">
                            <el-checkbox v-model="fz.info.is_select" :label="fz.name" size="small" @change="change_item_fz(fz, $event)" />
                        </el-col>
                        <el-col :span="21">
                            <el-row class="theme-border-half mk" v-for="mk in fz.mk" :key="mk.name">
                                <el-col :span="4" v-if="mk.name == '其他通用'" class="qtty">
                                    <span>其他通用</span>
                                </el-col>
                                <el-col :span="4" v-else>
                                    <el-checkbox v-model="mk.info.is_select" :label="mk.name" size="small" />
                                </el-col>
                                <el-col class="theme-border-half ff" :span="20">
                                    <el-col v-for="ff in mk.ff" :key="ff.name" :span="4" style="margin-left: 10px">
                                        <el-checkbox v-model="ff.info.is_select" :label="ff.name" size="small" />
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div style="margin-top: 10px">
            <el-button size="small" type="primary" @click="post_change">确定添加</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uname: "", //名称
            name: "",
            pwd: "",
            allow_ip: "",
            /**权限 */
            menu: [],
            err: {
                name: {
                    visible: false,
                    content: "",
                },
                uname: {
                    visible: false,
                    content: "",
                },
                pwd: {
                    visible: false,
                    content: "",
                },
            },
        };
    },
    methods: {
        //分组全选
        change_item_fz(item, status) {
            // console.log(item, status);
            item.mk.forEach((mk) => {
                mk.info.is_select = status;
                if (mk.ff.length > 0) {
                    mk.ff.forEach((ff) => {
                        ff.info.is_select = status;
                    });
                }
            });
        },
        /**获取新增用户菜单选项，权限 */
        get_user_edit(data) {
            let res = data.powers;
            // console.log(res);
            this.menu = [];
            //生成分组
            for (let i = 0; i < res.length; i++) {
                res[i].is_select = false;
                if (res[i].class == 1) {
                    this.menu.push({
                        name: res[i].function,
                        info: res[i],
                        mk: [],
                    });
                }
            }
            //生成分组对应的模块
            if (res.find((item) => item.function == "大股东") != undefined) {
                let obj = res.splice(
                    res.findIndex((item) => item.function == "大股东"),
                    1
                )[0];
                res.splice(
                    res.findIndex((item) => item.function == "股东"),
                    0,
                    obj
                );
            }
            for (let i = 0; i < res.length; i++) {
                if (res[i].class == 2) {
                    for (let j = 0; j < this.menu.length; j++) {
                        if (res[i].parent_id == this.menu[j].info.id) {
                            if (res[i].function == "大总监") {
                                this.menu[j].mk.unshift({
                                    name: res[i].function,
                                    info: res[i],
                                    ff: [],
                                });
                            } else {
                                this.menu[j].mk.push({
                                    name: res[i].function,
                                    info: res[i],
                                    ff: [],
                                });
                            }
                        }
                    }
                }
            }
            //生成模块对应的方法
            for (let i = 0; i < res.length; i++) {
                if (res[i].class == 3) {
                    for (let j = 0; j < this.menu.length; j++) {
                        for (let k = 0; k < this.menu[j].mk.length; k++) {
                            if (res[i].parent_id == this.menu[j].mk[k].info.id) {
                                this.menu[j].mk[k].ff.push({
                                    name: res[i].function,
                                    info: res[i],
                                });
                            }
                        }
                    }
                }
            }
            // console.log(this.menu);
        },
        /**提交修改 */
        post_change() {
            if (this.name.length < 2) {
                return (this.err.name = { visible: true, content: "账号最少2个字符" });
            }
            if (!this.$filters.passwordReg(this.pwd)) {
                return (this.err.pwd = { visible: true, content: "密码必须包含：大写字母、小写字母、特殊字符、数字，4种组合，8-20位" });
            }
            let list = [];
            this.menu.forEach((fz) => {
                if (fz.info.is_select) {
                    list.push(fz.info.id);
                }
                fz.mk.forEach((mk) => {
                    if (mk.info.is_select) {
                        list.push(fz.info.id);
                        list.push(mk.info.id);
                    }
                    mk.ff.forEach((ff) => {
                        if (ff.info.is_select) {
                            list.push(fz.info.id);
                            list.push(ff.info.id);
                        }
                    });
                });
            });
            list = Array.from(new Set(list));
            list = list.toString();
            // return console.log(list)
            this.axios
                .post(`${this.$route.query.db ? "user_version2/get_user_save" : "user_version2/user_save"}`, {
                    userName: this.name,
                    userPassword: this.pwd,
                    userNicker: this.uname,
                    allow_ip: this.allow_ip,
                    checkbox: list,
                    db: this.$route.query.db ? this.$route.query.db : "",
                    user_id: this.$route.query.uid ? this.$route.query.uid : "",
                })
                .then((result) => {
                    // console.log(result.data)
                    if (result.data.status == "200") {
                        this.$message.success(result.data.shortMessage);
                    }
                    this.finish_cancel();
                });
        },
        /**修改成功和取消修改，上报父级 */
        finish_cancel() {
            this.$emit("other_view_back", "");
        },
    },
};
</script>

<style scoped>
.fz {
    border: 1px solid;
    margin-bottom: 2px;
}
.mk {
    text-align: left;
    padding: 0 6px;
    border-left: 1px solid;
    border-bottom: 1px solid;
}
.mk:last-child {
    border-bottom: 0;
}
.ff {
    text-align: left;
    padding: 0 6px;
    border-left: 1px solid;
}
.myform .myform-item:hover .el-col {
    background-color: #fff !important;
}
span {
    font-size: 12px;
}
.qtty {
    color: #606266;
    text-align: center;
}
</style>
