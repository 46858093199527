import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.number.to-fixed.js";
var __default__ = {
  data: function data() {
    return {
      themeColor: "",
      themeColorhalf: "",
      themeColorLight: ""
    };
  },
  created: function created() {
    var _this = this;

    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))));
    } //在页面刷新时将vuex里的信息保存到sessionStorage里


    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.addEventListener("unload", function () {
        window.sessionStorage.setItem("store", JSON.stringify(_this.$store.state));
      });
    } else {
      window.addEventListener("beforeunload", function () {
        window.sessionStorage.setItem("store", JSON.stringify(_this.$store.state));
      });
    } //获取网站名称
    // this.axios.get(`login/get_web_info?url=${window.location.host}`).then(result => {
    //     if (result.data.status == 1 && result.data.msg.web_name != '') {
    //         // document.title = result.data.msg.web_name;
    //         this.$store.state.web_name = result.data.msg.web_name;
    //     }
    // });


    this.themeColor = this.$store.state.themeColor;
    this.themeColorhalf = this.$store.state.themeColorhalf;
    this.themeColorLight = this.$store.state.themeColorLight;
  },
  methods: {
    get_color: function get_color(themeColor, themeColorLight, themeColorhalf, themeColorhalf_l) {
      this.themeColor = this.$store.state.themeColor = themeColor;
      this.themeColorhalf = this.$store.state.themeColorhalf = themeColorhalf;
      this.themeColorLight = this.$store.state.themeColorLight = themeColorLight;
      this.themeColorhalf_l = this.$store.state.themeColorhalf_l = themeColorhalf_l; // console.log(this.themeColor, this.themeColorhalf, this.themeColorLight);

      var arr = this.themeColor;
      arr = arr.split("(")[1].split(")")[0].split(",");
      arr[0] = arr[0].includes("deg") ? (arr[0].split("d")[0] * 1).toFixed(0) : (arr[0] * 1).toFixed(0);
      arr[1] = (arr[1].split("%")[0] * 1).toFixed(0);
      arr[2] = (arr[2].split("%")[0] * 1).toFixed(0);
      this.axios.post("user_version2/user_colors", {
        color: arr
      }).then(function (result) {
        if (result.data.status == 200) {// this.$message.success(result.data.shortMessage);
        }
      });
    }
  }
};
import { useCssVars as _useCssVars } from 'vue';

var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_ctx) {
    return {
      "1efad53f": _ctx.themeColor,
      "619e441c": _ctx.themeColorhalf,
      "1563df17": _ctx.themeColorLight,
      "731d9542": _ctx.themeColorhalf_l
    };
  });
};

var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;