import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.link.js";
import axios from "../../../axios/axios";
import loginLog from "../plublic/loginLog.vue";
import changeLog from "../plublic/changeLog.vue";
import topUp from "../plublic/topUp.vue";
import cashOut from "../plublic/cashOut.vue";
import cashFlow from "../plublic/cashFlow.vue";
import tuishui from "../plublic/tuishui.vue";
import buhuo from "../plublic/buhuo.vue";
import adddaili from "../adduser/addDaili.vue";
import editDaili from "../edituser/editDaili.vue";
export default {
  components: {
    loginLog: loginLog,
    changeLog: changeLog,
    topUp: topUp,
    cashOut: cashOut,
    cashFlow: cashFlow,
    tuishui: tuishui,
    buhuo: buhuo,
    adddaili: adddaili,
    editDaili: editDaili
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("user_version2/member_list", {
      type: 3,
      page: 1,
      //页码
      userState: 1,
      //状态
      sortName: "0",
      //排序类型：时间0 账号1
      userFlag: "0",
      //搜索类型：账号0 昵称1
      sortType: "0",
      //排序顺序：降0 升1
      zstype: "",
      //所属会员，zgs直属会员，fgs普通会员
      searchkey: "",
      //搜索时输入的内容
      limit: 30,
      uid: to.query.uid,
      start_time: "",
      end_time: "",
      zc_time: "",
      db: to.query.db ? to.query.db : ""
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.width = vm.$filters.stringlength(result.data.repsoneContent.list, vm.width);
          vm.users = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
          vm.amounts = result.data.repsoneContent.amounts; // console.log(to.query.uid);

          if (to.query.uid) {
            vm.post_agency.uid = to.query.uid;
          }

          if (to.query.db != undefined && to.query.db != "") {
            vm.gs.checked = to.query.db;
          } else {
            vm.gs.list = vm.$store.state.gs.list;
          }
        });
      }
    });
  },
  data: function data() {
    return {
      users: [],
      title: "总监管理",
      panelwidth: "100%",
      amounts: [0, 0, 0],
      post_agency: {
        type: 3,
        page: 1,
        //页码
        userState: 1,
        //状态
        sortName: "0",
        //排序类型：时间0 账号1
        userFlag: "0",
        //搜索类型：账号0 昵称1
        sortType: "0",
        //排序顺序：降0 升1
        zstype: "",
        //所属会员，zgs直属会员，fgs普通会员
        searchkey: "",
        //搜索时输入的内容
        limit: 30,
        uid: "",
        start_time: "",
        end_time: "",
        zc_time: "",
        filter: [{
          value: 1,
          label: "启用"
        }, {
          value: 3,
          label: "全部"
        }, {
          value: 2,
          label: "冻结"
        }, {
          value: 0,
          label: "停用"
        }]
      },
      pagination: {
        total_records: 0,
        limit: 30,
        page: 1
      },
      // 修改状态
      change_user_status: {
        visible: false,
        status: ""
      },
      //选中的用户信息
      row_user: {
        id: "",
        username: "",
        status: ""
      },
      other_view: {
        activated_name: "",
        visible: false,
        row: ""
      },
      add_user_data: {},
      addinfo: {
        sup_group_name: "大总监",
        sub_group_name: "总监",
        sub_list: []
      },
      width: {
        parent_name: {
          minwidth: "",
          label: "上级大总监"
        },
        username: {
          minwidth: "",
          label: "总监"
        },
        nickname: {
          minwidth: "",
          label: "名称"
        },
        balance: {
          minwidth: "",
          label: "彩票余额"
        },
        today_cash_in: {
          minwidth: "",
          label: "今日充值"
        },
        today_cash_out: {
          minwidth: "",
          label: "今日提现"
        }
      },
      gs: {
        visible: false,
        list: [],
        checked: ""
      }
    };
  },
  computed: {
    relod_sub_menu_data: function relod_sub_menu_data() {
      return this.$store.state.relod_sub_menu_data;
    }
  },
  watch: {
    relod_sub_menu_data: function relod_sub_menu_data(newVal, oldVal) {
      // console.log(newVal, oldVal);
      if (newVal) {
        this.get_users();
        this.$store.state.relod_sub_menu_data = false;
      }
    }
  },
  methods: {
    //解绑二维码
    jiebang_erweima: function jiebang_erweima(row) {
      var _this = this;

      this.$messageBox.confirm("\u786E\u5B9A\u89E3\u7ED1\u7528\u6237\uFF1A".concat(row.username, " \u7684\u8C37\u6B4C\u4E8C\u7EF4\u7801\u7ED1\u5B9A\u5417\uFF1F"), "确认解绑", {
        confirmButtonText: "解绑",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.axios.post("user_version2/setup_second_auth", {
          type: "del_user",
          uid: row.id,
          db: _this.$route.query.db ? _this.$route.query.db : ""
        }).then(function (result) {
          // console.log(result);
          if (result.data.status == 200) {
            _this.$alert(result.data.shortMessage, "提示", {
              confirmButtonText: "确定",
              type: "success"
            });

            _this.get_users();
          }
        });
      }).catch(function () {
        _this.$message("已取消解绑");
      });
    },
    //跳转下级
    go_user_subview: function go_user_subview(row, type, num) {
      // console.log(row, type, num);
      if (num > 0) {
        this.$emit("change_submenu", type, row.id, this.$route.query.db);
      }
    },
    //修改
    edit_user: function edit_user(row) {
      var _this2 = this;

      this.other_view.row = row;
      this.other_view.row.sup_group_name = "大总监";
      this.other_view.row.sub_group_name = "总监";
      this.other_view.row.groupid_zs = "2";
      this.axios.post("user_version2/member_add", {
        groupid_zs: row.groupid_zs,
        sltuid: "",
        db: this.$route.query.db ? this.$route.query.db : ""
      }).then(function (result) {
        if (result.data.status === 200) {
          _this2.add_user_data = result.data.repsoneContent;

          _this2.axios.post("user_version2/member_edit", {
            uid: row.id,
            par_uid: row.agent_id,
            groupid: row.group_id,
            userState: row.status,
            db: _this2.$route.query.db ? _this2.$route.query.db : ""
          }).then(function (result) {
            if (result.data.status == 200) {
              _this2.other_view.activated_name = "editDaili";
              _this2.title = "修改总监";
              _this2.panelwidth = "50%";
              _this2.other_view.visible = true;

              _this2.$nextTick(function () {
                _this2.$refs["editDaili"].get_user_edit(result.data.repsoneContent);
              });
            }
          });
        }
      });
    },
    //新增
    add_user: function add_user() {
      var _this3 = this;

      if (this.gs.checked == "") {
        this.gs.checked = this.$store.state.gs.checked;
        this.gs.visible = true;
        return;
      }

      this.other_view.row = {
        sup_group_name: "大总监",
        sub_group_name: "总监",
        groupid_zs: "2"
      };
      this.axios.post("user_version2/member_add", {
        groupid_zs: 2,
        sltuid: "",
        db: this.gs.checked
      }).then(function (result) {
        if (result.data.status === 200) {
          _this3.addinfo.sub_list = result.data.repsoneContent.member_list;

          _this3.axios.post("user_version2/member_add", {
            sltuid: _this3.addinfo.sub_list[0].id,
            groupid_zs: "",
            db: _this3.gs.checked
          }).then(function (result) {
            if (result.data.status === 200) {
              _this3.other_view.activated_name = "adddaili";
              _this3.title = "新增总监";
              _this3.panelwidth = "50%";
              _this3.other_view.visible = true;

              _this3.$nextTick(function () {
                _this3.$refs["adddaili"].get_user_edit(result.data.repsoneContent, _this3.gs.checked);
              });
            }
          });
        }
      });
    },
    //打开补货
    open_buhuo: function open_buhuo(row) {
      var _this4 = this;

      this.other_view.row = row;
      this.other_view.activated_name = "buhuo";
      this.title = "补货";
      this.panelwidth = "100%";
      var game_list = this.$store.state.game_list;

      if (game_list.length > 0) {
        var game_id = game_list[0].id;
        this.axios.post("user_version2/viewAutoLet", {
          uid: row.id,
          gid: game_id,
          db: this.$route.query.db ? this.$route.query.db : ""
        }).then(function (result) {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            _this4.other_view.visible = true;

            _this4.$nextTick(function () {
              _this4.$refs["buhuo"].get_buhuo_data(result.data.repsoneContent);
            });
          }
        });
      }
    },
    //打开退水设置
    open_tuishui: function open_tuishui(row) {
      var _this5 = this;

      this.other_view.row = row;
      this.other_view.activated_name = "tuishui";
      this.title = "退水设置";
      this.panelwidth = "100%";
      var gids = [];
      var all_gids = [];
      this.$store.state.game_list.forEach(function (item) {
        if (row.kusaicai_liuhe_status.is_kuaicai == 1) {
          if (item.id != 20 && item.id != 34 && item.id != 49 && item.id != 50 && item.id != 51) {
            gids.push(item.id);
          }
        } else {
          if (item.id == 20) {
            gids.push(20);
          }

          if (item.id == 34) {
            gids.push(34);
          }

          if (item.id == 49) {
            gids.push(49);
          }

          if (item.id == 50) {
            gids.push(50);
          }

          if (item.id == 51) {
            gids.push(51);
          }
        }

        all_gids.push(item.id);
      }); // console.log(gids);

      gids.length = gids.length > 4 ? 4 : gids.length;
      this.axios.post("user_version2/tuishui?uid=".concat(row.id), {
        uid: row.id,
        gids: gids,
        all_gids: all_gids,
        play_ids: "",
        times: 1,
        db: this.$route.query.db ? this.$route.query.db : ""
      }).then(function (result) {
        if (result.data.status == 200) {
          _this5.other_view.visible = true;

          _this5.$nextTick(function () {
            _this5.$refs["tuishui"].get_data(result.data.repsoneContent);
          });
        }
      });
    },
    //打开用户现金流
    open_cashFlow: function open_cashFlow(row) {
      var _this6 = this;

      this.other_view.row = row;
      this.other_view.activated_name = "cashFlow";
      this.title = "现金流";
      this.panelwidth = "100%";
      this.axios.post("account/credit_flow_list", {
        uid: row.id,
        page: 1,
        limit: 20,
        username: row.username,
        flag: "",
        db: this.$route.query.db ? this.$route.query.db : ""
      }).then(function (result) {
        if (result.data.status == 200) {
          _this6.other_view.visible = true;

          _this6.$nextTick(function () {
            _this6.$refs["cashFlow"].get_data(result.data.repsoneContent);
          });
        }
      });
    },
    //打开用户提现
    open_cashOut: function open_cashOut(row) {
      this.other_view.row = row;
      this.other_view.activated_name = "cashOut";
      this.title = "提现";
      this.panelwidth = "40%";
      this.other_view.visible = true;
    },
    //打开用户充值
    open_topUp: function open_topUp(row) {
      this.other_view.row = row;
      this.other_view.activated_name = "topUp";
      this.title = "充值";
      this.panelwidth = "40%";
      this.other_view.visible = true;
    },
    //打开用户修改记录
    open_changeLog: function open_changeLog(row) {
      var _this7 = this;

      this.other_view.row = row;
      this.other_view.activated_name = "changeLog";
      this.title = "修改记录";
      this.panelwidth = "100%";
      this.axios.post("log/admin_log", {
        uid: row.id,
        page: 1,
        limit: 20,
        username: row.username,
        db: this.$route.query.db ? this.$route.query.db : ""
      }).then(function (result) {
        if (result.data.status == 200) {
          _this7.other_view.visible = true;

          _this7.$nextTick(function () {
            _this7.$refs["changeLog"].get_data(result.data.repsoneContent);
          });
        }
      });
    },
    //打开用户登陆日志
    open_loginLog: function open_loginLog(row) {
      var _this8 = this;

      // console.log(row);
      this.other_view.row = row;
      this.other_view.activated_name = "loginLog";
      this.title = "登陆日志";
      this.panelwidth = "100%";
      this.axios.post("log/admin_login_log", {
        uid: row.id,
        page: 1,
        limit: 20,
        db: this.$route.query.db ? this.$route.query.db : ""
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this8.other_view.visible = true;

          _this8.$nextTick(function () {
            _this8.$refs["loginLog"].get_data(result.data.repsoneContent);
          });
        }
      });
    },
    //取消修改用户状态
    cancel_change_status: function cancel_change_status() {
      this.change_user_status.visible = false;
      this.get_users();
    },
    //修改用户状态
    change_status: function change_status(row) {
      var _this9 = this;

      var submit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      //修改会员状态
      if (!submit) {
        this.row_user = row;
        this.change_user_status.status = row.status;
        this.change_user_status.visible = true;
      } else {
        this.axios.post("user_version2/member_status", {
          status: this.change_user_status.status,
          uid: this.row_user.id,
          db: this.$route.query.db ? this.$route.query.db : ""
        }).then(function (result) {
          if (result.data.status == 200) {
            _this9.$message({
              message: result.data.shortMessage,
              type: "success"
            });

            _this9.change_user_status.visible = false; //刷新列表

            _this9.get_users(_this9.link);
          }
        });
      }
    },
    //总监用户list分页查询
    change_page: function change_page(page) {
      this.post_agency.page = page;
      this.get_users();
    },
    //获取总监用户list
    get_users: function get_users() {
      var _this10 = this;

      this.post_agency.db = this.$route.query.db ? this.$route.query.db : "", this.axios.post("user_version2/member_list", this.post_agency).then(function (result) {
        if (result.data.status == 200) {
          _this10.users = result.data.repsoneContent.list;
          _this10.pagination = result.data.repsoneContent.pagination;
          _this10.amounts = result.data.repsoneContent.amounts;
          _this10.other_view.visible = false;
          _this10.panelwidth = "100%";
        }
      });
    },
    //其他页面返回
    other_view_back: function other_view_back() {
      this.title = "总监管理";
      this.get_users();
    }
  }
};