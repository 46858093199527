import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
export default {
  props: ["history_data", "gid"],
  data: function data() {
    return {
      expands: []
    };
  },
  created: function created() {
    var _this = this;

    this.history_data.forEach(function (item, index) {
      if (index == 0) {
        _this.expands.push(item.id);
      }
    });
  },
  methods: {
    open_hx_url: function open_hx_url(row) {
      window.open("https://tronscan.org/#/transaction/".concat(row.txid));
    },
    clickRowHandle: function clickRowHandle(row, column, event) {
      this.expands = [];

      if (this.expands.includes(row.id)) {
        this.expands = this.expands.filter(function (val) {
          return val !== row.id;
        });
      } else {
        this.expands.push(row.id);
      }
    }
  }
};