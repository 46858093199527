import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import zd_2 from "./pl5_views/zd_2.vue";
import zd_3 from "./pl5_views/zd_3.vue";
import zd_4 from "./pl5_views/zd_4.vue";
import zx_2 from "./pl5_views/zx_2.vue";
import zx_3 from "./pl5_views/zx_3.vue";
import zx_4 from "./pl5_views/zx_4.vue";
import zhinengcaopan from "./pl5_views/zhinengcaopan.vue";
import tybl from "./pl5_views/tybl.vue";
export default {
  props: ["game_info_data", "chang_long", "sum", "pl_value", "show_change_pl_btn"],
  components: {
    zd_2: zd_2,
    zd_3: zd_3,
    zd_4: zd_4,
    zx_2: zx_2,
    zx_3: zx_3,
    zx_4: zx_4,
    zhinengcaopan: zhinengcaopan,
    tybl: tybl
  },
  data: function data() {
    return {
      currentComponent: "zd_2",
      previousComponent: "zd_2",
      ty: {
        show: false,
        list: [],
        page: 1,
        pagination: {
          total_pages: 1,
          total_records: "1",
          page_size: 50
        },
        checked_all: false
      },
      ty_list: {
        show: false,
        list: []
      },
      pl_bh_type: "sheng",
      pl_bh_value: "",
      sx_gj_type: "shangxian",
      sx_gj_value: "",
      ye_type: 1,
      fuzhi_list: [10, 20, 25, 30, 35, 40, 45, 65, 80, 100],
      fuzhi_checked_num: 0,
      batch_pl_dialog: {
        show: false,
        list: [],
        disabled_btn: false
      },
      is_manlet: "-1"
    };
  },
  watch: {
    pl_bh_type: function pl_bh_type(newVal) {
      this.pl_bh_value = "";
    },
    sx_gj_type: function sx_gj_type(newVal) {
      this.sx_gj_value = "";
    },
    currentComponent: function currentComponent(newComponent) {
      // 定义不需要记录历史的组件列表
      var excludeComponents = ["tybl", "zhinengcaopan"]; // 只有当新组件不在排除列表中，且与当前组件不同时才更新

      if (newComponent && !excludeComponents.includes(newComponent) && !excludeComponents.includes(this.currentComponent)) {
        this.previousComponent = this.currentComponent;
      }
    }
  },
  created: function created() {
    // console.log(this.$store.state.userinfo);
    //判断是否可以补货
    if (this.$store.state.userinfo.group_id == 2) {
      this.is_manlet = "0";
    } else if (this.$store.state.userinfo.group_id > 2 && this.$store.state.userinfo.is_manlet == "1") {
      this.is_manlet = "1";
    } else {
      this.is_manlet = "-1";
    }
  },
  methods: {
    add_sx_gj_value: function add_sx_gj_value() {
      var _this = this;

      if (this.sx_gj_value != "") {
        var data = [];
        var level_two = "";

        if (this.sx_gj_type == "gejia") {
          var list = this.getFilteredDataList();
          list.forEach(function (item) {
            item.list.forEach(function (ball) {
              data.push({
                play_id: 10,
                ball: ball.ball,
                level_two: ball.level_two,
                level_three: ball.level_three,
                level_four: ball.level_four,
                place: ball.place,
                bonus: _this.sx_gj_value
              });
            });
          });

          if (data.length == 0) {
            return this.$message("暂无可操作号码");
          }
        } else {
          level_two = this.currentComponent == "zd_2" ? "17" : this.currentComponent == "zd_3" ? "18" : this.currentComponent == "zd_4" ? "19" : this.currentComponent == "zx_2" ? "20" : this.currentComponent == "zx_3" ? "21" : "22";
        }

        this.axios.post("play/bonus_cap_mode_pl", {
          gid: 10,
          qnum: this.game_info_data.nn,
          bonus: this.sx_gj_value,
          act: this.sx_gj_type == "shangxian" ? "edit_all" : "edit_trader",
          data: data,
          level_two: level_two
        }).then(function (result) {});
      }
    },
    show_ty_bl_setting: function show_ty_bl_setting() {
      var _this2 = this;

      if (this.currentComponent == "tybl") {
        this.currentComponent = this.previousComponent;
      } else {
        this.axios.post("play/bonus_cap_mode_pl", {
          gid: 10,
          act: "list",
          qnum: this.game_info_data.nn,
          level_two: "19"
        }).then(function (result) {
          if (result.data.status == 200) {
            _this2.currentComponent = "tybl";

            _this2.$nextTick(function () {
              _this2.$refs["component"].get_ty_bl_data(result.data.repsoneContent);
            });
          }
        });
      }
    },
    show_zncp_setting: function show_zncp_setting() {
      var _this3 = this;

      if (this.currentComponent == "zhinengcaopan") {
        this.currentComponent = this.previousComponent;
      } else {
        this.axios.post("trader/lower_rate_trader", {
          gid: 10
        }).then(function (result) {
          if (result.data.status == 200) {
            _this3.currentComponent = "zhinengcaopan";

            _this3.$nextTick(function () {
              _this3.$refs["component"].get_zncp_data(result.data.repsoneContent);
            });
          }
        });
      }
    },
    change_filter_type: function change_filter_type(type, value) {
      console.log(type, value);

      if (type == "ye") {
        this.ye_type = value;
        this.fuzhi_checked_num = 0;
      }

      if (type == "fuzhi") {
        this.fuzhi_checked_num = value;
        this.ye_type = 0;
      }
    },
    get_bhmx_data: function get_bhmx_data(row) {
      this.$emit("get_bhmx_data", row);
    },
    showBallPopover: function showBallPopover(row, event) {
      this.pl_popver.show = false;
      this.bh.show = false;
      this.ballPopover.show = false;
      this.ballPopover.row = row;
      this.$refs["ball_popover"].style.left = event.pageX + "px";
      this.$refs["ball_popover"].style.top = event.pageY + "px";
      this.ballPopover.show = true;
    },
    sub_bh: function sub_bh(bh) {
      var _this4 = this;

      // console.log(bh);
      if (bh.bh_value == "") {
        return this.$message.error("请输入补货金额");
      }

      if (bh.bh_value * 1 > bh.row.oc_bill * 1) {
        return this.$message.error("补货金额不能大于下注额");
      }

      if (bh.bh_value * 1 > bh.row.profit_bill * -1) {
        return this.$message.error("补货金额不能大于正亏损");
      }

      var obj = {
        user_id: this.$store.state.userinfo.user_id,
        play_id: 10,
        level_two: bh.row.level_two,
        level_three: bh.row.level_three,
        level_four: bh.row.level_four,
        place: bh.row.place,
        ball: bh.row.ball || bh.row.name,
        qnum: this.game_info_data.nn,
        is_manlet: this.is_manlet,
        control_let: bh.bh_value,
        rate: bh.row.pl,
        rebate_level: 0,
        oc_bill_s: bh.row.oc_bill,
        oc_bill_tm: "",
        is_update: "0",
        pk: "",
        title: bh.row.game_type,
        num: 1,
        periods_id: this.game_info_data.p_id
      };
      this.axios.post("userlet/userLet", obj).then(function (result) {
        if (result.data.shortMessage.includes("是否确认补货")) {
          _this4.$confirm(result.data.shortMessage, "提示", {
            customClass: "myMessageBox",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            obj.is_update = "1"; //大总监手补

            if (_this4.$store.state.userinfo.group_id == 2) {
              obj.is_manlet = 1;
            }

            _this4.axios.post("userlet/userLet", obj).then(function (result) {
              if (result.data.status == 200) {
                _this4.$message.success(result.data.shortMessage);

                bh.show = false;

                _this4.$emit("change_pk");
              }
            });
          }).catch(function () {
            obj.is_update = "0";

            _this4.$message({
              type: "info",
              message: "已取消操作"
            });
          });
        } else {
          _this4.$message.success(result.data.shortMessage);

          bh.show = false;
        }
      });
    },
    finish_batch_pl: function finish_batch_pl() {
      var _this5 = this;

      this.batch_pl_dialog.disabled_btn = true;

      if (this.pl_bh_type == "chu") {
        var data = [];
        this.batch_pl_dialog.list.forEach(function (item) {
          item.list.forEach(function (ball) {
            data.push({
              ball: ball.ball,
              play_id: 10,
              level_s: {
                ball: ball.name,
                name: ball.name,
                level_four: ball.level_four,
                level_three: ball.level_three,
                level_two: ball.level_two,
                rate: ball.pl,
                place: ball.place
              },
              oc_bill_s: ball.oc_bill,
              place: ball.place,
              rate: ball.pl,
              control_let: _this5.pl_bh_value
            });
          });
        });
        var post_obj = {
          is_manlet: this.is_manlet,
          play_id: 10,
          balls: "",
          qnum: this.game_info_data.nn,
          pk: "",
          szsz: "",
          periods_id: this.game_info_data.p_id,
          data: data,
          is_update: "0"
        };
        this.axios.post("userlet/userAllLet", post_obj).then(function (result) {
          if (result.data.shortMessage.includes("是否确认补货")) {
            _this5.$confirm(result.data.shortMessage, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              post_obj.is_update = "1";

              _this5.axios.post("userlet/userAllLet", post_obj).then(function (result) {
                if (result.data.status == 200) {
                  _this5.$message.success(result.data.shortMessage);

                  _this5.batch_pl_dialog.show = false;

                  _this5.$emit("change_pk");
                }

                _this5.batch_pl_dialog.disabled_btn = false;
              });
            }).catch(function () {
              post_obj.is_update = "0";

              _this5.$message({
                type: "info",
                message: "已取消操作"
              });
            });
          } else {
            _this5.$message(result.data.shortMessage);
          }
        });
      } else {
        var _data = [];
        this.batch_pl_dialog.list.forEach(function (item) {
          item.list.forEach(function (ball) {
            _data.push(ball);
          });
        });
        var _post_obj = {
          gid: 10,
          userid: this.$store.state.userinfo.user_id,
          phaseid: this.game_info_data.p_id,
          data: _data
        };
        this.axios.post("trader/update_rate_pl5", _post_obj).then(function (result) {
          if (result.data.status == 200) {
            _this5.$message.success(result.data.shortMessage);

            _this5.batch_pl_dialog.show = false;

            _this5.$emit("change_pk");
          }

          _this5.batch_pl_dialog.disabled_btn = false;
        });
      }
    },
    getFilteredDataList: function getFilteredDataList() {
      var arr = [];

      if (this.currentComponent == "zd_2" || this.currentComponent == "zd_3") {
        var data_list = JSON.parse(JSON.stringify(this.$refs["component"].data_list)); //展平成一维数组

        var flattenedList = [];
        data_list.forEach(function (item) {
          flattenedList = flattenedList.concat(item.list);
        }); // 2. 取负值个数

        if (this.fuzhi_checked_num) {
          flattenedList.sort(function (a, b) {
            return a.profit_bill - b.profit_bill;
          });
          flattenedList = flattenedList.slice(0, this.fuzhi_checked_num).filter(function (item) {
            return item.profit_bill < 0;
          });
        } // 3.取停押页码*400


        if (this.ye_type) {
          if (this.currentComponent == "zd_2") {
            flattenedList = flattenedList.filter(function (item) {
              return item.is_lock == 1;
            });
          }

          if (this.currentComponent == "zd_3") {
            flattenedList = this.$refs["component"].cache_data.play_odds.flat().slice(0, this.ye_type * 400).filter(function (item) {
              return item.is_lock == 1;
            });
          }
        } // 4. 根据 name 属性重新分组


        data_list.forEach(function (item) {
          item.list = flattenedList.filter(function (ball) {
            return ball.game_type === item.name;
          });
        });
        arr = data_list;
      } else {
        var _data_list = JSON.parse(JSON.stringify(this.$refs["component"].data_list));

        if (this.fuzhi_checked_num) {
          _data_list = _data_list.splice(0, this.fuzhi_checked_num).filter(function (item) {
            return item.profit_bill < 0;
          });
          arr = [{
            name: "",
            list: _data_list
          }];
        }

        if (this.ye_type) {
          if (this.currentComponent == "zd_4") {
            _data_list = this.$refs["component"].cache_data.play_odds.flat().slice(0, this.ye_type * 500).filter(function (item) {
              return item.is_lock == 1;
            });
          } else {
            _data_list = _data_list.filter(function (item) {
              return item.is_lock == 1;
            });
          }

          arr = [{
            name: "",
            list: _data_list
          }];
        }
      }

      return arr;
    },
    add_pl_bh_value: function add_pl_bh_value() {
      var _this6 = this;

      if (this.pl_bh_value == "") {
        return;
      }

      var arr = this.getFilteredDataList();

      if (arr.length == 0) {
        return this.$message.error("没有可补货的号码");
      } // return console.log(arr);


      for (var i = 0; i < arr.length; i++) {
        for (var j = 0; j < arr[i].list.length; j++) {
          var value = Number(this.pl_bh_value);
          var currentPl = Number(arr[i].list[j].pl);

          if (this.pl_bh_type === "sheng" || this.pl_bh_type === "jiang") {
            var isAdd = this.pl_bh_type === "sheng";
            var newPl = isAdd ? currentPl + value : currentPl - value;
            var limit = isAdd ? arr[i].list[j].maxpl : arr[i].list[j].minpl;
            var errorMsg = isAdd ? "最高" : "最低";

            if (isAdd && newPl > limit || !isAdd && newPl < limit) {
              return this.$message.error("".concat(arr[i].list[j].name, "\u8D54\u7387\u4E0D\u80FD").concat(isAdd ? "超过" : "低于").concat(errorMsg).concat(limit * 1));
            }

            arr[i].list[j].old_pl = arr[i].list[j].pl;
            arr[i].list[j].wtvalue = newPl;
            arr[i].list[j].oddsid = arr[i].list[j].id;
            arr[i].list[j].gid = 10;
          }
        }
      }

      if (this.pl_bh_type === "chu") {
        for (var _i = 0; _i < arr.length; _i++) {
          arr[_i].list = arr[_i].list.filter(function (item) {
            return item.profit_bill < 0 && item.oc_bill >= _this6.pl_bh_value;
          });
        }
      }

      this.batch_pl_dialog.list = arr; // console.log(this.batch_pl_dialog.list);

      this.batch_pl_dialog.show = true;
    },
    formatNumber: function formatNumber(value) {
      // 如果是"各出"类型，只允许输入整数
      if (this.pl_bh_type === "chu") {
        return value.replace(/\D/g, "");
      } // 其他类型允许输入数字、小数点和负号
      // 移除非法字符（只保留数字、小数点和负号）


      value = value.replace(/[^\d.-]/g, ""); // 确保负号只能出现在开头

      value = value.replace(/(-.*?)-/g, "$1"); // 确保只有一个小数点

      value = value.replace(/\.{2,}/g, "."); // 保留两位小数

      value = value.replace(/^(-?\d+)\.(\d\d).*$/, "$1.$2"); // 如果以数点开头，添加前导0

      value = value.replace(/^\./, "0."); // 如果只有负号，则返回空

      if (value === "-" || value * 1 < 0) return "";
      return value;
    },
    change_title: function change_title(title) {
      var _this7 = this;

      this.$nextTick(function () {
        var newComponent = "";

        if (title == "二字定") {
          newComponent = "zd_2";
        } else if (title == "三字定") {
          newComponent = "zd_3";
        } else if (title == "四字定" || title == "虚盘") {
          newComponent = "zd_4";
        } else if (title == "二字现") {
          newComponent = "zx_2";
        } else if (title == "三字现") {
          newComponent = "zx_3";
        } else if (title == "四字现") {
          newComponent = "zx_4";
        } // 只在切换到普通组件时保存previousComponent


        if (newComponent && _this7.currentComponent !== "tybl" && _this7.currentComponent !== "zhinengcaopan") {
          _this7.previousComponent = _this7.currentComponent;
        }

        _this7.currentComponent = newComponent;
        _this7.fuzhi_checked_num = 0;
        _this7.ye_type = 1;
      });
    },
    change_pl: function change_pl(pl_popver) {
      this.$emit("change_pl", pl_popver);
    },
    get_zdmx_data: function get_zdmx_data(row) {
      this.$emit("get_zdmx_data", row);
    },
    change_submenu: function change_submenu(row) {
      this.$emit("change_submenu", row);
    },
    proc: function proc(data) {
      // 添加判断确保组件已经挂载
      if (this.currentComponent === "zhinengcaopan" || this.currentComponent === "tybl" || !this.$refs.component) {
        return;
      }

      this.$refs.component.proc(data);
    },
    set_ball_ty: function set_ball_ty(ballData) {
      var _this8 = this;

      console.log(ballData);
      this.axios.post("/play/bonus_cap_mode_pl", {
        act: "edit_lock",
        data: ballData
      }).then(function (result) {
        if (result.data.status == 200) {
          _this8.$message.success(result.data.shortMessage);

          _this8.$emit("change_pk");
        } else {
          _this8.$message.error(result.data.shortMessage);
        }
      });
    }
  }
};