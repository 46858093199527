import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.to-fixed.js";
import erciyanzheng from "../components/self/views/erciyanzheng.vue";
export default {
  name: "login",
  components: {
    erciyanzheng: erciyanzheng
  },
  props: {},
  data: function data() {
    return {
      show_view: "login",
      //changepass,//email
      form: {
        user: {
          user_name: "",
          passwd: "",
          checkcode: "",
          platform: "pc",
          auth: "",
          type: "google" //email//google

        }
      },
      year: "",
      first_change_pwd: {
        visible: false,
        txtid: "",
        txtoldpwd: "",
        txtnewpwd: "",
        txtnewpwdcf: "",
        salt: "",
        user_id: "",
        username: "",
        old_password: ""
      },
      err: {
        txtoldpwd: {
          visible: false,
          content: ""
        },
        txtnewpwd: {
          visible: false,
          content: ""
        },
        txtnewpwdcf: {
          visible: false,
          content: ""
        }
      },
      screenwidth: "auto",
      error_times: 0,
      codeimg: "",
      email_type: "qq",
      f_email: "",
      back_user_data: {},
      show_email_code: true,
      exp_time: 0,
      exp_time_loop_id: "",
      get_emailcode_btn_text: "获取",
      qrdata: ""
    };
  },
  created: function created() {
    this.year = new Date().getFullYear();

    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i)) {
      this.form.user.platform = "mobile";
    } else {
      this.form.user.platform = "pc";
    } // console.log(this.form.user.platform);

  },
  methods: {
    login: function login() {
      var _this2 = this;

      if (this.error_times >= 3 && this.checkcode == "") {
        return this.$message.error("請輸入驗證碼");
      }

      if (this.form.user.user_name != "" && this.form.user.passwd != "") {
        this.axios.post("login/ajax_login", this.form.user).then(function (result) {
          // console.log(result);
          if (result.data.status == 200 && result.data.shortMessage === "登录成功") {
            _this2.$store.state.isLogin = true;
            _this2.$store.state.userinfo = result.data.repsoneContent;
            _this2.$store.state.userinfo.user_name = _this2.form.user.user_name;
            var group_id = result.data.repsoneContent.group_id;
            var group_name = "";

            if (group_id == 1) {
              group_name = "总公司";
            }

            if (group_id == 2) {
              group_name = "大总监";
            }

            if (group_id == 3) {
              group_name = "总监";
            }

            if (group_id == 4) {
              group_name = "分公司";
            }

            if (group_id == 5) {
              group_name = "大股东";
            }

            if (group_id == 6) {
              group_name = "股东";
            }

            if (group_id == 7) {
              group_name = "总代理";
            }

            if (group_id == 8) {
              group_name = "代理";
            }

            _this2.$store.state.userinfo.group_name = group_name;
            _this2.$store.state.url_query_info = "user=" + _this2.$store.state.userinfo.user_id + "&token=" + _this2.$store.state.userinfo.token;
            sessionStorage.setItem("islogin", "true");
            _this2.$store.state.watchsub_show = false;
            _this2.$store.state.watchsub_sub_id = ""; //六合盘口

            var lh_list = [];

            if (_this2.$store.state.userinfo.power != undefined && _this2.$store.state.userinfo.power != "") {
              if (_this2.$store.state.userinfo.power.includes("160")) {
                lh_list.push({
                  type: "",
                  gid: 20,
                  name: "六合彩"
                });
              }

              if (_this2.$store.state.userinfo.power.includes("161")) {
                lh_list.push({
                  type: "",
                  gid: 34,
                  name: "澳门六合彩"
                });
              }

              if (_this2.$store.state.userinfo.power.includes("162")) {
                lh_list.push({
                  type: "",
                  gid: 49,
                  name: "新澳门六合彩"
                });
              }

              if (_this2.$store.state.userinfo.power.includes("163")) {
                lh_list.push({
                  type: "",
                  gid: 50,
                  name: "台湾六合彩"
                });
              }

              if (_this2.$store.state.userinfo.power.includes("177")) {
                lh_list.push({
                  type: "",
                  gid: 51,
                  name: "快乐8六合彩"
                });
              }

              if (_this2.$store.state.userinfo.power.includes("180")) {
                lh_list.push({
                  type: "",
                  gid: 10,
                  name: "排列五"
                });
              }

              if (lh_list.length > 0) {
                lh_list[0].type = "primary";
              }
            } //颜色默认


            if (_this2.$store.state.userinfo.color.length < 14) {
              _this2.$store.state.userinfo.color = "hsl(87, 97% ,28%)";
            }

            _this2.$store.state.userinfo.lh_list = lh_list; //获取网站名称

            _this2.axios.post("login/get_web_info").then(function (result) {
              // let website = result.data.msg.website;
              if (result.data.status == 1 && result.data.msg.web_name != "") {
                _this2.$store.state.web_name = result.data.msg.web_name;

                _this2.axios.post("main/game_list").then(function (result) {
                  if (result.data.status === 200) {
                    _this2.$store.state.trader_game_list = result.data.repsoneContent.trader_game_list;

                    _this2.$store.state.trader_game_list.sort(function (a, b) {
                      return a.order - b.order;
                    });

                    if (result.data.repsoneContent.trader_game_list.length > 0) {
                      _this2.$store.state.instant_checked_gid = result.data.repsoneContent.trader_game_list[0].id;
                      _this2.$store.state.instant_checked_gname = result.data.repsoneContent.trader_game_list[0].gname;
                    }

                    _this2.$store.state.operate_game_list = result.data.repsoneContent.operate_game_list;

                    _this2.$store.state.operate_game_list.sort(function (a, b) {
                      return a.order - b.order;
                    });

                    var list = result.data.repsoneContent.game_list;

                    if (list.length > 0) {
                      list.sort(function (a, b) {
                        return a.order - b.order;
                      });
                      _this2.$store.state.game_list = list;

                      _this2.$router.replace("/home");
                    } else {
                      _this2.$message.warning("暂无彩种信息，请联系管理");
                    }
                  }
                });
              }
            });
          } else if (result.data.status === 200 && (result.data.shortMessage === "第一次登陆需要修改密码" || result.data.shortMessage === "密码已重置请重新设置新密码")) {
            var data = result.data.repsoneContent;
            _this2.$store.state.url_query_info = "user=" + data.id + "&token=" + data.token;
            _this2.first_change_pwd.txtid = result.data.repsoneContent.id;
            _this2.first_change_pwd.user_id = result.data.repsoneContent.id;
            _this2.first_change_pwd.salt = result.data.repsoneContent.salt;
            _this2.first_change_pwd.username = _this2.form.user.user_name;
            _this2.first_change_pwd.old_password = result.data.repsoneContent.old_password;

            _this2.$alert(result.data.shortMessage, "安全提示", {
              confirmButtonText: "确定",
              type: "warning",
              callback: function callback() {
                _this2.show_view = "changepass";
              }
            });
          } else if (result.data.status === 200 && result.data.shortMessage === "第一次登陆需要绑定邮箱") {
            var _data = result.data.repsoneContent;
            _this2.$store.state.url_query_info = "user=" + _data.user_id + "&token=" + _data.token;

            _this2.$alert(result.data.shortMessage, "安全提示", {
              confirmButtonText: "确定",
              type: "warning",
              callback: function callback() {
                _this2.show_view = "email";
                _this2.email_type = "qq";
                _this2.f_email = "";
                _this2.back_user_data = _data;
              }
            });
          } else if (result.data.status === 200 && result.data.shortMessage === "第一次登陆需要绑定google") {
            var _data2 = result.data.repsoneContent;
            _this2.$store.state.url_query_info = "user=" + _data2.user_id + "&token=" + _data2.token;

            _this2.axios.post("user_version2/setup_second_auth").then(function (res) {
              if (res.data.status == 200) {
                _this2.qrdata = res.data.repsoneContent;

                _this2.$alert(result.data.shortMessage, "安全提示", {
                  confirmButtonText: "确定",
                  type: "warning",
                  callback: function callback() {
                    _this2.show_view = "google";

                    _this2.$nextTick(function () {
                      _this2.$refs["erciyanzheng"].auth = "";

                      _this2.$refs["erciyanzheng"].get_info(_this2.qrdata);
                    });
                  }
                });
              }
            });
          } else if (result.data.shortMessage === "登录失败，账号或密码错误!" || result.data.shortMessage === "账号或密码错误") {
            _this2.error_times += 1;

            if (_this2.error_times >= 3) {
              _this2.getcode();
            }
          } else if (result.data.shortMessage === "请输入正确的验证码") {
            _this2.error_times = 3;

            _this2.getcode();
          }
        });
      }
    },
    getcode: function getcode() {
      var _this3 = this;

      this.axios.get("login/vcode", {
        responseType: "arraybuffer"
      }).then(function (result) {
        if (true) {
          var bufferUrl = btoa(new Uint8Array(result.data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, ""));
          _this3.codeimg = "data:image/png;base64," + bufferUrl;
        }
      });
    },
    sub_change: function sub_change() {
      var _this4 = this;

      if (this.first_change_pwd.txtoldpwd == "") {
        return this.err.txtoldpwd = {
          visible: true,
          content: "请输入旧密码"
        };
      }

      if (this.first_change_pwd.txtnewpwd == "" || !this.$filters.passwordReg(this.first_change_pwd.txtnewpwd)) {
        return this.err.txtnewpwd = {
          visible: true,
          content: "新密码必须包含：大写字母、小写字母、数字，3种组合，8-20位"
        };
      }

      if (this.first_change_pwd.txtoldpwd == this.first_change_pwd.txtnewpwd) {
        return this.err.txtnewpwd = {
          visible: true,
          content: "新密码不能和旧密码相同"
        };
      }

      if (this.first_change_pwd.txtnewpwdcf !== this.first_change_pwd.txtnewpwd) {
        return this.err.txtnewpwdcf = {
          visible: true,
          content: "两次输入的新密码不一致"
        };
      }

      this.axios.post("user_version2/ResetPasswd", this.first_change_pwd).then(function (result) {
        if (result.data.status == 200) {
          _this4.$alert(result.data.shortMessage, "成功提示", {
            confirmButtonText: "确定",
            type: "success",
            callback: function callback() {
              _this4.form.user.passwd = _this4.first_change_pwd.txtnewpwdcf;
              _this4.show_view = "login";
            }
          });
        }
      });
    },
    bd_email: function bd_email() {
      var _this5 = this;

      var user_email = this.f_email + "".concat(this.email_type == "qq" ? "@qq.com" : "@gmail.com");

      if (!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(user_email)) {
        return this.$message.error("邮箱格式不正确，请重新输入");
      }

      this.$messageBox.confirm("\u60A8\u5C06\u8981\u7ED1\u5B9A\u7684\u90AE\u7BB1\uFF1A".concat(user_email), "再次确认邮箱地址", {
        confirmButtonText: "确认绑定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this5.axios.post("user_version2/user_email_save", {
          txtid: _this5.back_user_data.user_id,
          email: user_email
        }).then(function (result) {
          // console.log(result);
          if (result.data.status == 200) {
            _this5.$alert(result.data.shortMessage, "提示", {
              confirmButtonText: "确定",
              type: "success"
            });

            _this5.show_view = "login";
          }
        });
      }).catch(function () {
        _this5.$message("已取消绑定");
      });
    },
    get_email_code: function get_email_code() {
      var _this6 = this;

      if (this.form.user.user_name != "" && this.form.user.passwd != "") {
        this.get_emailcode_btn_text = "验证码发送中...";
        this.axios.post("login/send_email_code", this.form.user).then(function (result) {
          // console.log(result);
          if (result.data.status == 200) {
            _this6.$alert("验证码已经发送到您绑定的邮箱", result.data.shortMessage, {
              confirmButtonText: "确定",
              type: "success"
            });

            _this6.exp_time = ((result.data.repsoneContent.exp_time * 1000 - Date.now() + 1000) / 1000).toFixed(0) * 1;
            var _this = _this6;
            _this6.exp_time_loop_id = window.setInterval(function () {
              _this.exp_time -= 1;

              if (_this.exp_time <= 0) {
                window.clearInterval(_this.exp_time_loop_id);
                _this.exp_time = 0;
                _this.get_emailcode_btn_text = "获取";
              }
            }, 1000);
          }
        });
      } else {
        this.$message.error("请先输入账号密码，再获取验证码");
      }
    }
  }
};