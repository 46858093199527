import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.sort.js";
export default {
  components: {},
  props: ['title', 'chang_long', 'sum', 'pl_value'],
  data: function data() {
    return {
      d1: [{
        id: 11,
        name: "大",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 12,
        name: "小",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 13,
        name: "单",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 14,
        name: "双",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d2: [{
        id: 25,
        name: "大",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 26,
        name: "小",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 27,
        name: "单",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 28,
        name: "双",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d3: [{
        id: 39,
        name: "大",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 40,
        name: "小",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 41,
        name: "单",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 42,
        name: "双",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d4: [{
        id: 53,
        name: "大",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 54,
        name: "小",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 55,
        name: "单",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 56,
        name: "双",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      d5: [{
        id: 67,
        name: "大",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 68,
        name: "小",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 69,
        name: "单",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 70,
        name: "双",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      zh: [{
        id: 71,
        name: "总和大",
        game_type: "总和",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 72,
        name: "总和小",
        game_type: "总和",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 73,
        name: "总和单",
        game_type: "总和",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 74,
        name: "总和双",
        game_type: "总和",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      q3: [{
        id: 78,
        name: "豹子",
        game_type: "前三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 79,
        name: "顺子",
        game_type: "前三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 80,
        name: "对子",
        game_type: "前三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 81,
        name: "半顺",
        game_type: "前三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 82,
        name: "杂六",
        game_type: "前三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      z3: [{
        id: 83,
        name: "豹子",
        game_type: "中三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 84,
        name: "顺子",
        game_type: "中三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 85,
        name: "对子",
        game_type: "中三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 86,
        name: "半顺",
        game_type: "中三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 87,
        name: "杂六",
        game_type: "中三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      h3: [{
        id: 88,
        name: "豹子",
        game_type: "后三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 89,
        name: "顺子",
        game_type: "后三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 90,
        name: "对子",
        game_type: "后三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 91,
        name: "半顺",
        game_type: "后三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 92,
        name: "杂六",
        game_type: "后三",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh1v2: [{
        id: 93,
        name: "龙",
        game_type: "龙虎(1V2)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 94,
        name: "和",
        game_type: "龙虎(1V2)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 95,
        name: "虎",
        game_type: "龙虎(1V2)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh1v3: [{
        id: 96,
        name: "龙",
        game_type: "龙虎(1V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 97,
        name: "和",
        game_type: "龙虎(1V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 98,
        name: "虎",
        game_type: "龙虎(1V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh1v4: [{
        id: 99,
        name: "龙",
        game_type: "龙虎(1V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 100,
        name: "和",
        game_type: "龙虎(1V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 101,
        name: "虎",
        game_type: "龙虎(1V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh1V5: [{
        id: 120,
        name: "龙",
        game_type: "龙虎(1V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 121,
        name: "和",
        game_type: "龙虎(1V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 122,
        name: "虎",
        game_type: "龙虎(1V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh2V3: [{
        id: 102,
        name: "龙",
        game_type: "龙虎(2V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 103,
        name: "和",
        game_type: "龙虎(2V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 104,
        name: "虎",
        game_type: "龙虎(2V3)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh2V4: [{
        id: 105,
        name: "龙",
        game_type: "龙虎(2V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 106,
        name: "和",
        game_type: "龙虎(2V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 107,
        name: "虎",
        game_type: "龙虎(2V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh2V5: [{
        id: 108,
        name: "龙",
        game_type: "龙虎(2V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 109,
        name: "和",
        game_type: "龙虎(2V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 110,
        name: "虎",
        game_type: "龙虎(2V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh3V4: [{
        id: 111,
        name: "龙",
        game_type: "龙虎(3V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 112,
        name: "和",
        game_type: "龙虎(3V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 113,
        name: "虎",
        game_type: "龙虎(3V4)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh3V5: [{
        id: 114,
        name: "龙",
        game_type: "龙虎(3V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 115,
        name: "和",
        game_type: "龙虎(3V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 116,
        name: "虎",
        game_type: "龙虎(3V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lh4V5: [{
        id: 117,
        name: "龙",
        game_type: "龙虎(4V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 118,
        name: "和",
        game_type: "龙虎(4V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 119,
        name: "虎",
        game_type: "龙虎(4V5)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      dd1: [{
        id: 1,
        name: "0",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 2,
        name: "1",
        game_type: "第一球)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 3,
        name: "2",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 4,
        name: "3",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 5,
        name: "4",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 6,
        name: "5",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 7,
        name: "6",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 8,
        name: "7",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 9,
        name: "8",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 10,
        name: "9",
        game_type: "第一球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      dd2: [{
        id: 15,
        name: "0",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 16,
        name: "1",
        game_type: "第二球)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 17,
        name: "2",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 18,
        name: "3",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 19,
        name: "4",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 20,
        name: "5",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 21,
        name: "6",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 22,
        name: "7",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 23,
        name: "8",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 24,
        name: "9",
        game_type: "第二球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      dd3: [{
        id: 29,
        name: "0",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 30,
        name: "1",
        game_type: "第三球)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 31,
        name: "2",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 32,
        name: "3",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 33,
        name: "4",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 34,
        name: "5",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 35,
        name: "6",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 36,
        name: "7",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 37,
        name: "8",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 38,
        name: "9",
        game_type: "第三球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      dd4: [{
        id: 43,
        name: "0",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 44,
        name: "1",
        game_type: "第四球)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 45,
        name: "2",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 46,
        name: "3",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 47,
        name: "4",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 48,
        name: "5",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 49,
        name: "6",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 50,
        name: "7",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 51,
        name: "8",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 52,
        name: "9",
        game_type: "第四球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      dd5: [{
        id: 57,
        name: "0",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 58,
        name: "1",
        game_type: "第五球)",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 59,
        name: "2",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 60,
        name: "3",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 61,
        name: "4",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 62,
        name: "5",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 63,
        name: "6",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 64,
        name: "7",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 65,
        name: "8",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 66,
        name: "9",
        game_type: "第五球",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sum_bet_balance: '',
      max_ks: '',
      //最高亏损
      max_yl: '',
      //最高盈利
      pl_popver: {
        show: false,
        row: {},
        pl_value: '',
        pl_change_value: '',
        top: '',
        left: ''
      },
      bh: {
        show: false,
        row: {},
        pl_value: '',
        je_value: '',
        rate: '',
        top: '',
        left: ''
      }
    };
  },
  created: function created() {},
  methods: {
    get_zdmx_data: function get_zdmx_data(row) {
      if (row.szsz_amount[1] != '-') {
        this.$emit('get_zdmx_data', row);
      }
    },
    sub_change_pl: function sub_change_pl() {
      this.$emit('change_pl', this.pl_popver);
    },
    show_pl_popver: function show_pl_popver(row, event) {
      if (this.$store.state.userinfo.group_id >= 5 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      } // console.log(row, event);


      this.pl_popver.show = false;

      if (event == 'plus' || event == 'minus') {
        this.pl_popver.row = row;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.pl = row.pl;
        this.pl_popver.pl_change_value = '';
        this.pl_popver.plusminus = event;
        this.$emit('change_pl', this.pl_popver);
      } else {
        this.pl_popver.row = row;
        this.pl_popver.value = row.pl;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.plusminus = '';
        this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
        this.$refs['pl_popver'].style.left = event.pageX + 'px';
        this.$refs['pl_popver'].style.top = event.pageY + 'px';
        this.pl_popver.show = true;
      }
    },
    proc: function proc(data) {
      var _this = this;

      this.sum_bet_balance = data.sum_bet_balance;
      var play_odds = data.play_odds;
      var szsz_amount = data.szsz_amount;
      var yk = [];
      ['d1', 'd2', 'd3', 'd4', 'd5', 'zh', 'q3', 'z3', 'h3', 'lh1v2', 'lh1v3', 'lh1v4', 'lh1V5', 'lh2V3', 'lh2V4', 'lh2V5', 'lh3V4', 'lh3V5', 'lh4V5', 'dd1', 'dd2', 'dd3', 'dd4', 'dd5'].forEach(function (keyname) {
        _this[keyname].forEach(function (item) {
          play_odds.forEach(function (element) {
            if (element.id == item.id) {
              item.is_open = element.is_open;
              item.maxpl = element.maxpl * 1;
              item.minpl = element.minpl * 1;
              item.pl = element.pl * 1;
              item.level_two = element.level_two;
              item.level_three = element.level_three;
              item.level_four = element.level_four;
              item.control_let = element.control_let;
              item.szsz_amount = ['-', '-', '', '', '-', '-', ''];
              item.yk = 0;
              item.newid = element.id;
            }
          });

          for (var key in szsz_amount) {
            if (key == item.id) {
              item.szsz_amount = szsz_amount[key].split(',');
              item.yk = item.szsz_amount[4] * 1;
            }
          }

          yk.push(item.yk);
        });
      });
      this.max_yl = yk.sort(function (a, b) {
        return b - a;
      })[0];
      this.max_ks = yk.sort(function (a, b) {
        return a - b;
      })[0];
    }
  }
};