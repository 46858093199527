<template>
    <div class="shipinsousuo">
        <div class="panel">
            <div class="panel-title">
                <h2>视频搜索</h2>
            </div>
            <div class="panel-body">
                <div style="margin: 0 auto; width: 90%; margin-top: 10px; margin-bottom: 10px">
                    <span style="color: red">至少填一个号码：</span>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.firstCode" placeholder="" style="width: 120px">
                            <template #prepend>
                                <span style="color: #000">正码1</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.secondCode" placeholder="" style="width: 120px; margin-left: 5px">
                            <template #prepend>
                                <span style="color: #000">正码2</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.thirdCode" placeholder="" style="width: 120px; margin-left: 5px">
                            <template #prepend>
                                <span style="color: #000">正码3</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.fourthCode" placeholder="" style="width: 120px; margin-left: 5px">
                            <template #prepend>
                                <span style="color: #000">正码4</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.fifthCode" placeholder="" style="width: 120px; margin-left: 5px">
                            <template #prepend>
                                <span style="color: #000">正码5</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.sixCode" placeholder="" style="width: 120px; margin-left: 5px">
                            <template #prepend>
                                <span style="color: #000">正码6</span>
                            </template>
                        </el-input>
                    </div>
                    <el-icon color="#000" :size="30">
                        <Plus />
                    </el-icon>
                    <div style="display: inline-block">
                        <el-input v-model="video.post.sevenCode" placeholder="" style="width: 120px">
                            <template #prepend>
                                <span style="color: #000">特码</span>
                            </template>
                        </el-input>
                    </div>
                    <div style="display: inline-block">
                        <el-button type="" @click="reset" style="margin-left: 5px">重 置</el-button>
                        <el-button type="warning" @click="get_video" style="margin-left: 5px">搜 索</el-button>
                    </div>
                </div>
                <div style="margin: 0 auto; width: 90%">
                    <el-table :data="video.list" stripe border size="small" highlight-current-row max-height="500" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                        <el-table-column prop="operation_type" label="搜索数据" align="center">
                            <template #default="scope">
                                <span>{{ scope.row.name }}</span>
                                <el-button type="primary" @click="copyTextToClipboard(scope.row.name)" style="float: right; margin-left: 5px">复制</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="operation_type" label="号码" align="center">
                            <template #default="scope">
                                <template v-for="(item, index) in scope.row.kj" :key="index">
                                    <span class="nums-lhc" :style="{ background: item.color }">{{ item.num }}</span>
                                    <span style="margin: 0 5px 0 1px; font-weight: bold; font-size: 14px">{{ item.sx }}</span>
                                    <el-icon v-show="index == 5" color="#000" :size="30">
                                        <Plus />
                                    </el-icon>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="lock_qnum" label="期号" align="center">
                            <template #header>
                                <div style="text-align: center">期号<span>（勾选的期号为当前已锁定）</span></div>
                            </template>
                            <template #default="scope">
                                <template v-if="scope.row.lock_qnum != null && scope.row.lock_qnum.length > 0">
                                    <el-checkbox-group v-model="scope.row.lock_qnum_select">
                                        <el-checkbox v-for="item in scope.row.lock_qnum" :key="item" :label="item"></el-checkbox>
                                    </el-checkbox-group>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="lock_qnum" label="操作" align="center" width="100">
                            <template #default="scope">
                                <el-button v-if="scope.row.show_jieshuo" type="danger" @click="jiesuo_lock_qnum(scope.row)" style="display: inline-block; margin-left: 10px">解锁开奖</el-button>
                                <el-button v-else type="warning" @click="set_lock_qnum(scope.row)" style="display: inline-block; margin-left: 10px">锁定开奖</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { lhcNum } from "../../../plugin/lhcNum";
import { get_lhc_sx } from "../../../plugin/sx";
export default {
    data() {
        return {
            video: {
                post: {
                    firstCode: "",
                    secondCode: "",
                    thirdCode: "",
                    fourthCode: "",
                    fifthCode: "",
                    sixCode: "",
                    sevenCode: "",
                },
                list: [],
            },
        };
    },
    methods: {
        set_lock_qnum(row) {
            // return console.log(row.lock_qnum_select.toString());
            if (row.lock_qnum_select.length > 0) {
                this.$confirm(`确定锁定 ${row.lock_qnum_select.toString()} 期吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.axios
                            .post("play/lock_qnum", {
                                id: row.id,
                                qnum: row.lock_qnum_select.toString(),
                            })
                            .then((result) => {
                                if (result.data.status === 200) {
                                    this.$message.success(result.data.shortMessage);
                                    this.get_video();
                                }
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消操作",
                        });
                    });
            } else {
                this.$message.error("请先选择期数");
            }
        },
        jiesuo_lock_qnum(row) {
            // return console.log(row.lock_qnum_select.toString());
            if (row.lock_qnum_select.length > 0) {
                this.$confirm(`确定解锁 ${row.lock_qnum_select.toString()} 期吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.axios
                            .post("play/unlock_qnum", {
                                id: row.id,
                                qnum: row.lock_qnum_select.toString(),
                            })
                            .then((result) => {
                                if (result.data.status === 200) {
                                    this.$message.success(result.data.shortMessage);
                                    this.get_video();
                                }
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消操作",
                        });
                    });
            } else {
                this.$message.error("请先选择期数");
            }
        },
        reset() {
            this.video = {
                post: {
                    firstCode: "",
                    secondCode: "",
                    thirdCode: "",
                    fourthCode: "",
                    fifthCode: "",
                    sixCode: "",
                    sevenCode: "",
                },
                list: [],
            };
        },
        get_video() {
            this.axios.post("play/video", this.video.post).then((result) => {
                if (result.data.status === 200) {
                    let video_list = result.data.repsoneContent.list;
                    for (const item of video_list) {
                        let str = item.name;
                        str = str.replace(/\s+/g, " ");
                        item.kj = str.split(" ")[1].split(".")[0].split("-");
                        let arr = [];
                        for (let i = 0; i < item.kj.length; i++) {
                            arr.push(lhcNum(item.kj[i], this.$filters.time(Date.now() / 1000)));
                        }
                        item.kj = get_lhc_sx("开奖", arr);
                        if (item.lock_qnum != null && item.lock_qnum != "") {
                            item.lock_qnum = item.lock_qnum.split(",");
                        } else {
                            item.lock_qnum = [];
                        }
                    }
                    this.axios.post("play/get_qnums", this.video.post).then((result) => {
                        if (result.data.status === 200) {
                            let list = result.data.repsoneContent.list;
                            if (list != undefined && list != null && list != "" && list.length > 0) {
                                for (const item of video_list) {
                                    item.lock_qnum_select = JSON.parse(JSON.stringify(item.lock_qnum));
                                    for (const item1 of list) {
                                        item.lock_qnum.push(item1.qnum);
                                    }
                                    item.lock_qnum = Array.from(new Set(item.lock_qnum));
                                    if (item.lock_qnum_select.length > 0) {
                                        item.show_jieshuo = true;
                                    } else {
                                        item.show_jieshuo = false;
                                    }
                                }
                            }
                            this.video.list = video_list;
                            console.log(this.video.list);
                        }
                    });
                }
            });
        },
        copyTextToClipboard(text) {
            var textArea = document.createElement("textarea");

            // 隐藏文本域
            textArea.style.position = "fixed";
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = "0";
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            textArea.value = text;

            document.body.appendChild(textArea);
            textArea.select();

            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "复制成功" : "复制失败，请手动复制";
                this.$message.success(msg);
            } catch (err) {
                this.$message.error(msg);
            }

            document.body.removeChild(textArea);
        },
    },
};
</script>
<style  scoped>
.nums-lhc {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ccc;
    font-size: 16px;
}
</style>