<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-23 17:49:26
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-21 11:31:41
 * @FilePath: \super_admin\src\components\inner\views\anquanmaguanli.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="anquanmaguanli">
        <div class="panel" :style="{ width: title == '安全码管理' ? '100%' : '800px' }">
            <div class="panel-title">
                <h2>{{ title }}</h2>
                <el-button v-if="title == '安全码管理'" style="float: right" type="primary" size="small" @click="add">新增安全码</el-button>
                <el-button v-else style="float: right; margin-top: 3px" size="small" type="warning" @click="cancel_add">返回</el-button>
            </div>
            <div class="panel-body">
                <template v-if="title == '安全码管理'">
                    <el-table :data="list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <el-table-column prop="db" label="平台" align="center">
                            <template #default="scope">
                                <span class="redcolor">{{ scope.row.db }}</span>
                                <span>（{{ scope.row.username }}）</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="code" label="安全码" align="center"></el-table-column>
                        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                        <el-table-column prop="b_rate" label="功能" align="center">
                            <template #default="scope">
                                <el-button-group>
                                    <a class="a-icon i-changeLog theme-text" style="margin-right: 0" href="#" @click.prevent="change(scope.row)">修改</a>
                                    <a class="a-icon i-cashOut theme-text" style="margin-right: 0" href="#" @click.prevent="del(scope.row)">删除</a>
                                </el-button-group>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <template v-else>
                    <div class="myform">
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">选择平台</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <template v-if="title == '新增安全码'">
                                    <el-radio-group v-model="gs.checked">
                                        <el-radio v-for="item in gs.list" :key="item.db" :label="item.db">{{ item.db }}（{{ item.username }}/{{ item.note }}）</el-radio>
                                    </el-radio-group>
                                </template>
                                <template v-if="title == '修改安全码'">
                                    {{ gs.checked }}
                                </template>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">安全码</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input style="width: 200px" size="small" v-model="dialog.code" placeholder="请输入安全码"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">备注</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <el-input style="margin: 5px 0" size="small" type="textarea" v-model="dialog.remark" placeholder="请输入内容" :autosize="{ minRows: 3, maxRows: 6 }"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="myform-item">
                            <el-col class="myform-item-lable" :span="6">提示</el-col>
                            <el-col class="myform-item-value" :span="18">
                                <span class="redcolor">如有安全码重复请重新设置保存</span>
                            </el-col>
                        </el-row>
                    </div>
                    <div style="margin-top: 10px">
                        <el-button size="small" @click="cancel_add">取 消</el-button>
                        <el-button size="small" type="primary" @click="sub_add">确 定</el-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
export default {
    name: "anquanmaguanli",
    components: {},
    data() {
        return {
            list: [],
            title: "安全码管理",
            dialog: {
                code: "",
                remark: "",
                id: "",
            },
            gs: {
                list: [],
                checked: "",
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        axios.post("check/index", {}).then((result) => {
            if (result.data.status == 200) {
                next((vm) => {
                    // 通过 `vm` 访问组件实例
                    vm.list = result.data.repsoneContent.list;
                    vm.gs.list = result.data.repsoneContent.commany;
                    vm.gs.checked = vm.gs.list[0].db;
                });
            }
        });
    },
    created() {
        // console.log(this.$store.state);
    },
    methods: {
        change(row) {
            this.dialog.code = row.code;
            this.dialog.remark = row.remark;
            this.dialog.id = row.id;
            this.gs.checked = row.db;
            this.title = "修改安全码";
        },
        add() {
            this.dialog.code = "";
            this.dialog.remark = "";
            this.title = "新增安全码";
        },
        cancel_add() {
            this.get_list();
            this.title = "安全码管理";
        },
        sub_add() {
            let postobj = {
                code: this.dialog.code,
                remark: this.dialog.remark,
                act: "add",
                db: this.gs.checked,
            };
            if (this.dialog.id != "") {
                postobj.id = this.dialog.id;
            }
            this.axios.post("check/check_add", postobj).then((result) => {
                if (result.data.status == 200 || result.data.status) {
                    this.$message.success(result.data.repsoneContent || "添加成功！");
                    this.get_list();
                    this.title = "安全码管理";
                    this.dialog.id = "";
                    this.dialog.code = "";
                    this.dialog.remark = "";
                    this.gs.checked = this.gs.list[0].db;
                }
            });
        },
        del(row) {
            this.axios
                .post("check/check_edit", {
                    db: row.db,
                    id: row.id,
                    act: "del",
                })
                .then((result) => {
                    if (result.data.status == 200 || result.data.status) {
                        this.$message.success(result.data.shortMessage || "删除成功！");
                        this.get_list();
                    }
                });
        },
        get_list() {
            this.axios.post("check/index", {}).then((result) => {
                if (result.data.status == 200) {
                    this.list = result.data.repsoneContent.list;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>