import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.sort.js";
export default {
  components: {},
  props: ['title ', 'chang_long', 'sum', 'pl_value'],
  data: function data() {
    return {
      hz: [{
        id: 1,
        name: "3,18",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 2,
        name: "4,17",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 3,
        name: "5,16",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 4,
        name: "6,15",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 5,
        name: "7,14",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 6,
        name: "8,13",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 7,
        name: "9,12",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 8,
        name: "10,11",
        game_type: "和值",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      lm: [{
        id: 17,
        name: "大",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 18,
        name: "小",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 19,
        name: "单",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 20,
        name: "双",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 21,
        name: "单大",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 22,
        name: "双大",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 23,
        name: "单小",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 24,
        name: "双小",
        game_type: "两面",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      kd: [{
        id: 25,
        name: "[1]",
        game_type: "跨度",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 26,
        name: "[2]",
        game_type: "跨度",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 27,
        name: "[3]",
        game_type: "跨度",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 28,
        name: "[4]",
        game_type: "跨度",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 29,
        name: "[5]",
        game_type: "跨度",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      z6: [{
        id: 30,
        name: "半顺",
        game_type: "组六",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 31,
        name: "杂六",
        game_type: "组六",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 60,
        name: "全顺",
        game_type: "组六",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 61,
        name: "豹子",
        game_type: "组六",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sj: [{
        id: 32,
        name: "1",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 33,
        name: "2",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 34,
        name: "3",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 35,
        name: "4",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 36,
        name: "5",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 37,
        name: "6",
        game_type: "三军",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sthtx: [{
        id: 51,
        name: "通选",
        game_type: "三同号通选",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sthdx: [{
        id: 52,
        name: "单选",
        game_type: "三同号单选",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sbth: [{
        id: 53,
        name: "标准",
        game_type: "三不同号",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 54,
        name: "胆拖",
        game_type: "三不同号",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      slhtx: [{
        id: 55,
        name: "三连",
        game_type: "三连号通选",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      ethfx: [{
        id: 56,
        name: "复选",
        game_type: "二同号复选",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      ethdx: [{
        id: 57,
        name: "单选",
        game_type: "二同号单选",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      ebth: [{
        id: 58,
        name: "标准",
        game_type: "二不同号",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 59,
        name: "胆拖",
        game_type: "二不同号",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      hh: [{
        id: 38,
        name: "黑号",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 39,
        name: "红号",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 40,
        name: "大红",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 41,
        name: "小红",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 42,
        name: "大黑",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 43,
        name: "小黑",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 44,
        name: "红双",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 45,
        name: "红单",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 46,
        name: "黑双",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 47,
        name: "黑单",
        game_type: "红黑",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      hhm: [{
        id: 48,
        name: "四码红",
        game_type: "红黑码",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 49,
        name: "四码黑",
        game_type: "红黑码",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }, {
        id: 50,
        name: "五码黑",
        game_type: "红黑码",
        is_open: 1,
        maxpl: "",
        minpl: 0,
        pl: '',
        level_four: 0,
        level_three: 0,
        level_two: 3,
        control_let: 0,
        place: "2",
        szsz_amount: ['-', '-', '', '', '-', '-', '']
      }],
      sum_bet_balance: '',
      max_ks: '',
      //最高亏损
      max_yl: '',
      //最高盈利
      pl_popver: {
        show: false,
        row: {},
        pl_value: '',
        pl_change_value: '',
        top: '',
        left: ''
      },
      bh: {
        show: false,
        row: {},
        pl_value: '',
        je_value: '',
        rate: '',
        top: '',
        left: ''
      }
    };
  },
  created: function created() {},
  methods: {
    get_zdmx_data: function get_zdmx_data(row) {
      if (row.szsz_amount[1] != '-') {
        this.$emit('get_zdmx_data', row);
      }
    },
    sub_change_pl: function sub_change_pl() {
      this.$emit('change_pl', this.pl_popver);
    },
    show_pl_popver: function show_pl_popver(row, event) {
      if (this.$store.state.userinfo.group_id >= 5 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      } // console.log(row, event);


      this.pl_popver.show = false;

      if (event == 'plus' || event == 'minus') {
        this.pl_popver.row = row;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.pl = row.pl;
        this.pl_popver.pl_change_value = '';
        this.pl_popver.plusminus = event;
        this.$emit('change_pl', this.pl_popver);
      } else {
        this.pl_popver.row = row;
        this.pl_popver.value = row.pl;
        this.pl_popver.old_pl = row.pl;
        this.pl_popver.plusminus = '';
        this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
        this.$refs['pl_popver'].style.left = event.pageX + 'px';
        this.$refs['pl_popver'].style.top = event.pageY + 'px';
        this.pl_popver.show = true;
      }
    },
    proc: function proc(data) {
      var _this = this;

      this.sum_bet_balance = data.sum_bet_balance || 0;
      var play_odds = data.play_odds;
      var szsz_amount = data.szsz_amount;
      var yk = [];
      ['hz', 'lm', 'kd', 'z6', 'sj', 'sthtx', 'sthdx', 'sbth', 'slhtx', 'ethfx', 'ethdx', 'ebth', 'hh', 'hhm'].forEach(function (keyname) {
        _this[keyname].forEach(function (item) {
          play_odds.forEach(function (element) {
            if (element.id == item.id) {
              item.is_open = element.is_open;
              item.maxpl = element.maxpl * 1;
              item.minpl = element.minpl * 1;
              item.pl = element.pl * 1;
              item.level_two = element.level_two;
              item.level_three = element.level_three;
              item.level_four = element.level_four;
              item.control_let = element.control_let;
              item.szsz_amount = ['-', '-', '', '', '-', '-', ''];
              item.yk = 0;
              item.newid = element.id;
            }
          });

          for (var key in szsz_amount) {
            if (key == item.id) {
              item.szsz_amount = szsz_amount[key].split(',');
              item.yk = item.szsz_amount[4] * 1;
            }
          }

          yk.push(item.yk);
        });
      });
      this.max_yl = yk.sort(function (a, b) {
        return b - a;
      })[0];
      this.max_ks = yk.sort(function (a, b) {
        return a - b;
      })[0];
    }
  }
};