import axios from '../../../axios/axios';
export default {
  data: function data() {
    return {
      backup_list: [],
      page: 1,
      act: "",
      id: "",
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: '注单备份',
        s_date: '',
        e_date: '',
        day_list: ['', '', '']
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("betbackup/backup_list", {// page: 1,
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          // 通过 `vm` 访问组件实例
          vm.backup_list = result.data.repsoneContent.list; // vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },
  methods: {
    //下载
    download: function download(url) {
      window.open(url, '_blank');
    },
    //新增消息
    add_new: function add_new() {
      this.dialog.s_date = '0';
      this.dialog.e_date = '0';
      var day = new Date();
      var oneday = 24 * 60 * 60 * 1000;
      this.dialog.day_list[0] = this.$filters.time(day.getTime() / 1000, 'notime');
      ;
      this.dialog.day_list[1] = this.$filters.time((day.getTime() - oneday) / 1000, 'notime');
      this.dialog.day_list[2] = this.$filters.time((day.getTime() - oneday * 2) / 1000, 'notime');
      this.dialog.visible = '新增备份';
    },
    //修改新增消息
    sub_new: function sub_new() {
      var _this = this;

      if (this.dialog.s_date > this.dialog.e_date) {
        return this.$message.error("结束时间不能大于开始时间，请选择正确的结束时间！");
      }

      this.axios.post("betbackup/backup_add", {
        s_date: this.dialog.day_list[this.dialog.s_date],
        e_date: this.dialog.day_list[this.dialog.e_date]
      }).then(function (result) {
        if (result.data.status == 200) {
          _this.$message.success(result.data.shortMessage);

          _this.get_backup_list();
        }
      });
    },
    change_new: function change_new(row) {
      this.dialog.do = "change";
      this.dialog.visible = "修改站内消息";
      this.dialog.row = row;
    },
    //删除消息
    del_new: function del_new(id) {
      var _this2 = this;

      this.$confirm("此操作将永久删除该条消息, 是否继续?", "警告", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.axios.post("play/news", {
          page: _this2.page,
          act: 'del',
          id: id
        }).then(function (result) {
          if (result.data.status == 200) {
            _this2.$message({
              type: 'success',
              message: result.data.shortMessage,
              offset: 400
            });

            _this2.get_backup_list();
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      ;
    },
    //分页
    change_page: function change_page(page) {
      this.page = page;
      this.get_backup_list("", "");
    },
    get_backup_list: function get_backup_list() {
      var _this3 = this;

      this.axios.post("betbackup/backup_list", {// page: this.page,
      }).then(function (result) {
        if (result.data.status == 200) {
          _this3.backup_list = result.data.repsoneContent.list; // this.pagination = result.data.repsoneContent.pagination;

          _this3.dialog.visible = '注单备份';
        }
      });
    }
  }
};