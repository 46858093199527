import axios from '../../../axios/axios';
export default {
  components: {},
  data: function data() {
    return {
      log_data: [],
      bill_item: [],
      //类别
      page: 1,
      //请求页码
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      }
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    axios.post("account/bill_log", {
      uid: '',
      from_id: 0,
      s_date: '',
      e_date: '',
      page: 1,
      limit: 20
    }).then(function (result) {
      if (result.data.status == 200) {
        next(function (vm) {
          vm.log_data = result.data.repsoneContent.cash_list;
          vm.bill_item = result.data.repsoneContent.bill_item;
          vm.pagination = result.data.repsoneContent.pagination; // 通过 `vm` 访问组件实例
        });
      }
    });
  },
  // created() {
  //     this.get_data(this.page);
  // },
  methods: {
    //点击查询
    get_data: function get_data(page) {
      var _this = this;

      this.axios.post("account/bill_log", {
        uid: this.$store.state.userinfo.user_id,
        from_id: 0,
        s_date: '',
        e_date: '',
        page: page,
        limit: 20
      }).then(function (result) {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          _this.log_data = result.data.repsoneContent.cash_list;
          _this.bill_item = result.data.repsoneContent.bill_item;
          _this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }
  }
};